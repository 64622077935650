import { gql } from '@apollo/client';

export const LIST_ADMINS_QUERY = gql`
  query listAdmins($page: Int, $perPage: Int) {
    listAdmins(page: $page, perPage: $perPage) {
      data {
        id
        email
        firstName
        familyName
        createdAt
        updatedAt
      }
      page
      perPage
      total
    }
  }
`;

export const LIST_PROFESSIONALS_QUERY = gql`
  query listProfessionals($page: Int, $perPage: Int) {
    listProfessionals(page: $page, perPage: $perPage) {
      page
      perPage
      total
      data {
        id
        email
        firstName
        familyName
        code
        twoFactorEnabled
        accountStatus
        type
        createdAt
        updatedAt
      }
    }
  }
`;

export const LIST_ANALYSES_VIEW = gql`
  query listAnalysesView($viewType:String!, $page:Int, $perPage:Int) {
    listAnalysesView(viewType:$viewType, page:$page, perPage:$perPage) {
      page
      perPage
      total
      data {
        addedBy
        attachmentUrl
        client
        clientGid
        date
        professional
        professionalCode
        professionalViewable
      }
    }
  }
`;

export const LIST_ALL_HMA = gql`
  query listAllHma {
    listAllHma {
      professional {
        user {
          isActive
          id
          language
        }
        firstName
        familyName
        code
        type
      }
      id
      clientId
      clientGidId
      clientFamilyName
      clientFirstName
      adminId
      professionalViewable
      createdAt
    }
  }
`;

export const LIST_TERMS = gql`
  query listTerms($language: String) {
    listTerms(language: $language) {
      name
      admin {
        firstName
        familyName
        id
      }
      adminId
      slug
      id
      body
      updatedAt
      createdAt
    }
  }
`;

export const LIST_TAGS = gql`
  query listTags {
    listTags {
      name
      id
    }
  }
`;

export const LIST_FORUM_TOPICS = gql`
  query listForumTopics($page: Int, $perPage: Int) {
    listForumTopics(page:$page, perPage:$perPage) {
      page
      perPage
      total
      data {
        id
        type
        attributes {
          id
          body
          tags
          owner
          title
          notificationsCount
          category
          comments {
            id
            body
            owner
            professionalCode
            professionalLogoUrl
            postedAt
          }
          commentCount
          postedAt
          postedOn
        }
      }
    }
  }
`;

export const GET_FORUM_TOPIC = gql`
  query getForumTopic ($id:ID!) {
    getForumTopic(id:$id) {
      id
      type
      attributes {
        id
        body
        tags
        title
        category
        commentCount
        comments {
          id
          body
          owner
          postedAt
          professionalCode
          professionalLogoUrl
        }
        postedAt
        postedOn
        communitySubgroup {
          id
          type
          attributes {
            active
            name
            id
            members {
              id
              code
              email
              createdAt
              firstName
              familyName
            }
            slug
          }
        }
        notificationsCount
        professionalLogoUrl
      }
    }
  }
`;

export const LIST_COMMUNITY_SUBGROUPS = gql`
  query listCommunitySubgroups($page: Int, $perPage: Int) {
    listCommunitySubgroups(page: $page, perPage: $perPage) {
      page
      perPage
      total
      data {
        id
        type
        attributes {
          id
          name
          slug
          description
          active
          members {
            id
            code
            email
          }
        }
      }
    }
  }
`;