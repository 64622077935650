import { Input } from 'antd'
import { ChoicesColContainer } from './style'
import React from 'react'

type SingleQuestionProps = {
  label: string
  choices: any
  other?: any
  order: number
  handleMultiSelect: (choicesIndex: number, questionsIndex: number) => void
}

const MultiSelectQuestion: React.FC<SingleQuestionProps> = ({ label, choices, other, order, handleMultiSelect }) => {
  return (
    <ChoicesColContainer>
      <h3>{`${order} ${order ? '.' : ''} ${label}`}</h3>
      {choices.map((choice: any, index: number) => {
        return (
          <div className="question-container" key={`choice_${index}`} onClick={(e) => {
            e.stopPropagation();
            handleMultiSelect(index, order);
          }}>
            <div>
              <input
                type="checkbox"
                onChange={() => handleMultiSelect(index, order)}
                value={choice.title}
                name={`checkbox_question_${index}`}
                checked={choice.selected}
              />
              <label>{choice.title}</label>
            </div>
            {choice.title === 'Other' && choice.selected && (
              <div className="other">
                <p>{other.label}</p>
                <Input type="text" placeholder={other.placeholder} onClick={(e) => e.stopPropagation()} />
              </div>
            )}
          </div>
        )
      })}
    </ChoicesColContainer>
  );
};

export default MultiSelectQuestion;