import { Menu, MenuProps } from 'antd';
import { useState } from 'react';
import { NavText, SignOutBtn, StyledSider } from './style';
import { authActions } from '../../../redux/slices';

const ClientSidebar = () => {
  const auth = authActions;

  const onSignOut = () => {
    auth.removeAuth();
  };

  const navItems = [
    {
      icon: <i className="material-icons-outlined icon-font">dashboard</i>,
      label: <NavText to="/c">Dashboard</NavText>
    },
    {
      icon: <i className="material-icons-outlined icon-font">contact_page</i>,
      label: <NavText to="general_client_info">My Info</NavText>
    },
    {
      icon: <i className="material-icons-outlined icon-font">message</i>,
      label: <NavText to="/">Inmail</NavText>
    },
    {
      icon: <i className="material-icons-outlined icon-font">power_settings_new</i>,
      label: <SignOutBtn onClick={onSignOut}>Sign Out</SignOutBtn>
    }
  ];

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const items: MenuProps['items'] = navItems.map((item, index) => ({
    key: String(index + 1),
    icon: item.icon,
    label: item.label,
  }));

  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      breakpoint="xs"
      // collapsedWidth="0"
      onCollapse={(value) => setCollapsed(value)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        left: 0,
        top: 0
      }}
    >
      <Menu mode="inline" defaultSelectedKeys={['1']} items={items} />
    </StyledSider>
  )
};

export default ClientSidebar;