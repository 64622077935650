import { Menu, MenuProps, Modal } from 'antd';
import { useMemo, useState } from 'react';
import Heart from '../../../components/Icons/Heart';
import { ModalContentWrapper, NavText, SignOutBtn, StyledSider } from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions } from '../../../redux/slices';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const AdminSidebar = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);
  const auth = authActions;
  const location = useLocation();
  const pathname = location.pathname;
  const cleanedPath = pathname.startsWith("/a/") ? pathname.slice(3) : pathname;
  const [showSignoutModal, setShowSignoutModal] = useState<boolean>(false);

  const onSignOut = () => {
    setShowSignoutModal(false);

    Cookies.remove('user');
    Cookies.remove('auth_token');

    auth.removeAuth();
    if (user.__typename === 'Admin') {
      navigate('/admin/signin');
      return;
    }
    navigate('/');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navItems = [
    {
      icon: <i className="material-icons-outlined icon-font">dashboard</i>,
      label: <NavText to="/a">Dashboard</NavText>,
      key: 'dashboard'
    },
    {
      icon: <i className="material-icons-outlined icon-font">admin_panel_settings</i>,
      label: <NavText to="admins">Admins</NavText>,
      key: 'admins'
    },
    {
      icon: <i className="material-icons-outlined icon-font">supervisor_account</i>,
      label: <NavText to="professionals">Professionals</NavText>,
      key: 'professionals'
    },
    {
      icon: <i className="material-icons-outlined icon-font">forum</i>,
      label: <NavText to="forums">Community</NavText>,
      key: 'community'
    },
    {
      icon: <i className="material-icons-outlined icon-font">science</i>,
      label: <NavText to="hma_imports">HMA</NavText>,
      key: 'hma'
    },
    {
      icon: <Heart />,
      label: <NavText to="hrv_imports">HRV</NavText>,
      key: 'hrv'
    },
    {
      icon: <i className="material-icons-outlined icon-font">library_books</i>,
      label: <NavText to="terms">Terms</NavText>,
      key: 'terms'
    },
    {
      icon: <i className="material-icons-outlined icon-font">tag</i>,
      label: <NavText to="tags">Tags</NavText>,
      key: 'tags'
    },
    {
      icon: <i className="material-icons-outlined icon-font">shopping_bag</i>,
      label: <NavText to="/">Shop Admin</NavText>,
      key: 'shop_admin'
    },
    {
      icon: <i className="material-icons-outlined icon-font">power_settings_new</i>,
      label: <SignOutBtn onClick={() => setShowSignoutModal(true)}>Sign Out</SignOutBtn>
    }
  ];

  const activeIndex = useMemo(() => {
    const index = navItems?.findIndex(item => cleanedPath.startsWith(item.label.props.to));

    return index !== -1 ? (index + 1).toString() : null;
  }, [cleanedPath, navItems]);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const items: MenuProps['items'] = navItems.map((item, index) => ({
    key: String(index + 1),
    icon: item.icon,
    label: item.label,
  }));

  const handleBack = () => {
    setShowSignoutModal(false);
  };

  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      breakpoint="xs"
      onCollapse={(value) => setCollapsed(value)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        left: 0,
        top: 0
      }}
    >
      <div className="demo-logo-vertical" />
      <Modal
        title={`Signout`}
        open={showSignoutModal}
        onOk={onSignOut}
        onCancel={handleBack}
        // footer={null}
        width={350}
      >
        <ModalContentWrapper>
          <span>Are you sure you want to sign out?</span>
        </ModalContentWrapper>
      </Modal>
      <Menu mode="inline" defaultSelectedKeys={['1']} items={items} selectedKeys={[activeIndex!]} />
    </StyledSider>
  )
};

export default AdminSidebar;