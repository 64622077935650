import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
  .ant-card-body {
    padding: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const MainSection = styled.div`
  padding: 0 38px 0 0;
`;

export const Title = styled.p`
  font-size: 1.125rem;
  margin: .5rem 0;
  font-weight: 700;
`;

export const TooltipWrapper = styled.div`
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OTPWrapper = styled.div`
  margin: 1.5rem 0;
  text-align: center;
  > p {
    font-size: 12px;
    color: #9a9a9a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  > .ant-otp input {
    padding-inline: 15px !important;
    min-height: 50px;
  }
`;