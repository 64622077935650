import { Avatar, Button, Card, Popover, Select, Tag } from 'antd';
import styled from 'styled-components';
import { CommonCardContentProps, ModalHeaderProps, StyledSelectProps, TimelineWrapperProps } from './type';
import { ActionBtnProps } from '../../../types';
import { theme } from '../../../styles/theme';

export const Container = styled.div`
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  margin: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const UserInfoWrapper = styled.div`
  flex: 1;
  position: relative;
  width: 320px;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  background-color: #edf2f7;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
  border-radius: 1.5rem;
  background: white;
  margin-top: -12px;
  width: 100%;

  > .general-client-info-btn-container {
    color: ${theme.color.primary};
    cursor: pointer;
    margin-top: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    gap: 0.25rem;

    > i {
      font-size: 20px !important;
    }
  }
`;

export const UserName = styled.p`
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const UserDetails = styled.div`
  margin-top: 10px;
  div {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      font-size: 14px;
      color: #2d3748;
    }
  }
`;

export const TimelineWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 37px;
  background-color: #edf2f7;
  border-radius: 20px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);

  > button {
    &:nth-child(1) {
      flex: 0;
      padding: 0 1.25rem;
    }

    &:nth-child(2) {
      // background: #c6f6d5;
    }
  }
`;

export const TimelineWrapperButton = styled.button<TimelineWrapperProps>`
  cursor: pointer;
  display: flex
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;
  flex: 1;
  border-right: ${(props) => props.lastindex ? '0' : '2px solid #e3e9f1'};
  padding: 0 0.75rem;
`;

export const DashboardWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1.25rem;
  > div {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 50%;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
`;

export const ActionPlanStatusCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-right: 0.75rem;
  }
`;

export const CardExtraStyle = styled.div`
  display: flex;
  gap: 4px;
`;

export const GenderText = styled.span`
  text-transform: capitalize;
  margin-right: 3px;
`;

export const StyledSelect = styled(Select) <StyledSelectProps>`
  .ant-select-selector {
    background-color: ${(props) => props.bgColor ?? 'white'}!important;
  }
`;

export const CommonCardContent = styled.div<CommonCardContentProps>`
  display: flex;
  justify-content: space-between;
  border-bottom-width: ${(props) => props?.showbottommargin ? '1px' : '0'};
  border-bottom-style: solid;
  border-bottom-color: #e2e8f0;
  padding: .5rem 0;

  > div {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    > p {
      font-weight: bold;
    }
  }

  .tags {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
  }
`;

export const AnalysesListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e2e8f0;
    padding: 0.25rem;
    a {
      color: #2d3748;
      border-bottom: 2px dashed #bbb;
      font-weight: 700;

      &:hover {
        border-bottom: 2px solid #ffd900;
      }
    }
    small {
      font-size: .75rem;
      color: #718096;
      padding: .25rem;
    }
  }
`;

export const AnalysesItems = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding: 0.25rem 0;
    display: flex;
    flex: 1;
    justify-content: space-between;

    p {
      color: #718096;
      padding: .25rem;
    }
  }

  .actionBtns {
    display: flex;
    justify-content: flex-start;
  }
`;

export const ActionBtn = styled(Button) <ActionBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 5px;
  box-shadow: none;

  .ant-btn-icon {
    margin-inline-end: 1px !important;
    font-size: 24px;
  }

  span {
    font-size: .875rem;
    color: ${(props) => props?.active ? theme.color.orange : theme.color.primary};
  }

  &:hover {
    background: transparent !important;
  }
`;

export const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
`;

export const ModalHeader = styled.div<ModalHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.backgroundcolor ?? '#edf2f7'};
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.25rem 1rem;
  width: inherit;

  > p {
    color: ${(props) => props.lightbackground ? 'black' : 'white'}
  }

  > div {
    display: flex;
    align-items: center;
    > button {
      padding: 0 0.25rem;
      color: ${(props) => props.lightbackground ? 'black' : 'white'}
    }
  }
`;

export const ModalBody = styled.div`
  background: white;
  width: inherit;
  overflow: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.75rem 1rem;
`;

export const TimelineData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  gap: .75rem;
  margin-bottom: 0.25rem;

  .header {
    display: flex;
    justify-content: space-between;

    > div:nth-child(1) {
      border-radius: .25rem;
      font-weight: 700;
      background: #edf2f7;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div:nth-child(2) {
      > button {
        padding: 0 0.25rem;
      }
    }
  }

  .footer {
    font-style: italic;
    font-size: .75rem;
    color: #718096;
  }
`;

export const QuestionnaireGroups = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 450px;
  > .questionnaire-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    position: sticky;
    background: #f7fafc;
    border-width: 2px 0;
    border-color: #e2e8f0;
    border-style: solid;
    > p {
      font-size: 1rem;
    }
  }
`;

export const QuestionnaireData = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  border-top: 1px solid #edf2f7;
  padding: 0 1rem;

  > div:nth-child(1) {
    flex-grow: 1;
    align-items: flex-start;
    padding: .25rem .5rem;

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      > div {
        display: flex;
        gap: 0.25rem;
        align-items: flex-start;

        > a {
          &:nth-child(1) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 700;
            border-bottom: 2px dashed #bbb;
            color: #2d3748;

            &:hover {
              border-bottom: 2px dashed #ffd900;
            }
          }

          &:nth-child(2) {
            color: #319795;
          }
        }
      }

      > p {
        color: #718096;
      }
    }

  }

  > div:nth-child(2) {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin 1.5rem 0;
`;

export const StyledPopover = styled(Popover)`
  padding: 0;
`;

export const ModalLogScoreContainer = styled.div`
  > div {

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > .action-btns {
      position: absolute;
      right: 0;
    }
  }

  > .badge-container {
    gap: 12px;
  }
`;

export const ModalLogContent = styled.div`
  text-align: left;

  > div {
    > .title {
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }

    > h3 {
      margin-top: .5rem;
      font-weight: bold;
    }

    > .attachments-container {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${theme?.color?.primary};

        > span {
          display: flex;
          align-items: center;
          gap: .25rem;
        }
      }
    }
  }
`;

export const StyledTag = styled(Tag)`
  color: black !important;
  margin: 1rem 0;
  > span {
    font-weight: bold;
  }
`;