import React from 'react'
import { CommonInfoData, CommonInfoWrapper } from './style'
import { CommonInfoProps } from './type'
import { FormInfoType } from '../../../../types'

const CommonInfo: React.FC<CommonInfoProps> = ({
  items
}) => {
  return (
    <CommonInfoWrapper>
      {items.map((info: FormInfoType) => (
        <CommonInfoData>
          <p>{info.label}</p>
          <em>{info.value}</em>
        </CommonInfoData>
      ))}
    </CommonInfoWrapper>
  )
}

export default CommonInfo