import { Card } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
`;

export const HRVHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 2rem;

  .hrv-details {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      gap: 10px;

      :nth-child(1) {
        width: 8rem;
      }

      :nth-child(2) {
        font-weight: 700;
      }
    }
  }
`;