import styled from 'styled-components';

export const DraggableContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 2px;
`;

export const DraggableIcon = styled.div`
  padding: 4px;
  cursor: grab;
  display: flex;
  align-items: center;
`;
