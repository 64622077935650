import sc from 'styled-components';

export const CommonInfoWrapper = sc.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CommonInfoData = sc.div`
  p {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }

  em {
    font-size: .875rem;
    color: #2d3748;
  }
`;
