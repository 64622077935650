import { Button, Form, Input } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LanguageSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
  margin-bottom: 5px;
`;

export const LanguageSelect = styled.button`
  border-radius: 0;
  border: 0;
  background: none;
  cursor: pointer;
  background: #888;
  color: #fff;
  padding: 3px 6px;
  font-size: 12px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
`;

export const StyledForm = styled(Form)`
  border: 1px solid #acacac;
  width: 420px;
  min-height: 340px;
  padding: 38px;
  background: white;
`;

export const FormLabel = styled.label`
  font-size: 12px !important;
  font-weight: bold;
  color: #9a9a9a;
  text-transform: uppercase;
  font-family: 'Helvetica Neue', Arial, sans-serif;
`;

export const StyledInput = styled(Input)`
  border: 0;
  border-bottom: 1px solid #dddddd;
`;
export const StyledInputPassword = styled(Input.Password)`
  border: 0;
  border-bottom: 1px solid #dddddd;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  border: 0;
  height: 100%;
  padding: 12px;
  background: #7790ff;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ForgotPassword = styled.a`
  color: #939393;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #939393;
  }
`;

export const RequiredMark = styled.span`
  color: #ff4d4f;
  margin: 0 5px;
`;