import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { professionalLogInitialState } from './initial_states';
import { ProfessionalLogState } from './initial_states/type';
import { ClientType, LogType, TimelineEventsType } from '../../types';
import { Dayjs } from 'dayjs';

export const professionalLogSlice = createSlice({
  name: 'professionalLog',
  initialState: professionalLogInitialState,
  reducers: {
    setOpen(state: ProfessionalLogState) {
      state.isOpen = true;
    },
    toggleOpen(state: ProfessionalLogState) {
      state.isOpen = !state.isOpen;
    },
    setCurrentTab(state: ProfessionalLogState, action: PayloadAction<string>) {
      state.currentTab = action.payload;
    },
    toggleMinimize(state: ProfessionalLogState) {
      state.minimized = !state.minimized;
    },
    setMinimized(state: ProfessionalLogState) {
      state.minimized = true;
    },
    setMaximized(state: ProfessionalLogState) {
      state.minimized = false;
    },
    setLogData(state: ProfessionalLogState, action: PayloadAction<{ key: keyof LogType, value: any }>) {
      const { key, value } = action.payload;
      state.logs = {
        ...state.logs,
        [key]: value,
      };
    },
    resetLogData(state: ProfessionalLogState) {
      state.logs = professionalLogInitialState.logs;
    },
    setSelectedClient(state: ProfessionalLogState, action: PayloadAction<ClientType>) {
      state.selectedClient = action.payload;
    },
    setEditLog(state: ProfessionalLogState) {
      state.isEditLog = true;
    },
    setTimelineData(state: ProfessionalLogState, action: PayloadAction<{ key: keyof TimelineEventsType, value: any }>) {
      const { key, value } = action.payload;
      state.timeline = {
        ...state.timeline,
        [key]: value,
      };
    },
    setSelectedAge(state: ProfessionalLogState, action: PayloadAction<number>) {
      state.selectedAge = action.payload;
    },
    setSelectedDateOfEvent(state: ProfessionalLogState, action: PayloadAction<Dayjs>) {
      state.selectedDateOfEvent = action.payload;
    },
    resetTimelineData(state: ProfessionalLogState) {
      state.timeline = professionalLogInitialState.timeline;
    },
    setEditTimeline(state: ProfessionalLogState) {
      state.isEditTimeline = true;
    }
  }
});

export const professionalLogActions = professionalLogSlice.actions;