import { Button } from 'antd';
import sc from 'styled-components';
import { theme } from '../../../styles/theme';
import { StyledButtonProps } from './type';

export const StyledButton = sc(Button) <StyledButtonProps>`
  background-color: ${theme.bg.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: ${(props) => props?.padding ?? '5px'};
  box-shadow: none;

  .ant-btn-icon {
    margin-inline-end: 3px !important;
  }

  span {
    color: ${theme.color.primary};
    i {
      font-size: 18px;
    }
  }

  &:hover {
    background: #b2f5ea !important;
  }
`;