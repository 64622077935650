import { GET_HMA_ITEMS, GET_HRV_ITEMS, GET_IODINE_TEST_ITEMS, GET_IRCOMBI_ITEMS } from '../../services/graphql/queries/professional';
import { useQuery } from '@apollo/client';
import { useCallback } from 'react';

export const useGetClientIrCombiItems = (clientId: string) => {
  const { data, loading, refetch } = useQuery(GET_IRCOMBI_ITEMS, {
    variables: {
      clientId
    }
  });

  const fetchClientIrCombiItems = useCallback(() => {
    if (data) {
      return data.clientIrCombiItems;
    }
  }, [data]);

  return {
    data: data?.clientIrCombiItems,
    fetchData: fetchClientIrCombiItems,
    loading,
    refetch
  };
};

export const useGetHRVItems = (clientId: string) => {
  const { data, loading, refetch } = useQuery(GET_HRV_ITEMS, {
    variables: {
      clientId
    }
  });

  const fetchClientHRVItems = useCallback(() => {
    if (data) {
      return data.clientHrvItems;
    }
  }, [data]);

  return {
    data: data?.clientHrvItems,
    fetchData: fetchClientHRVItems,
    loading,
    refetch
  };
};

export const useGetHMAItems = (clientId: string) => {
  const { data, loading, refetch } = useQuery(GET_HMA_ITEMS, {
    variables: {
      clientId
    }
  });

  const fetchClientHMAItems = useCallback(() => {
    if (data) {
      return data.clientHmaItems;
    }
  }, [data]);

  return {
    data: data?.clientHmaItems,
    fetchData: fetchClientHMAItems,
    loading,
    refetch
  };
};

export const useGetIodineTestItems = (clientId: string) => {
  const { data, loading, refetch } = useQuery(GET_IODINE_TEST_ITEMS, {
    variables: {
      clientId
    }
  });

  const fetchClientIodineTestItems = useCallback(() => {
    if (data) {
      return data.clientIodineTestItems;
    }
  }, [data]);

  return {
    data: data?.clientIodineTestItems,
    fetchData: fetchClientIodineTestItems,
    loading,
    refetch
  };
};