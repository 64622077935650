import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CLIENT_QUESTIONNAIRE_LISTS, LIST_QUESTIONNAIRES } from '../../services/graphql/queries/professional';
import { PROFESSIONAL_ASSIGN_QUESTIONNAIRE_TO_CLIENT } from '../../services/graphql/mutations/professional';

export const useGetClientQuestionnaires = (clientId: string) => {
  const { data, loading, refetch } = useQuery(GET_CLIENT_QUESTIONNAIRE_LISTS, {
    variables: {
      clientId
    }
  });

  const fetchClientQuestionnaireLists = useCallback(() => {
    if (data) {
      return data.clientQuestionnaireAssignments;
    }
  }, [data]);

  return {
    data: data?.clientQuestionnaireAssignments,
    fetchData: fetchClientQuestionnaireLists,
    loading,
    refetch
  };
};

export const useAssignQuestionnaireToClient = () => {
  const [assignQuestionnaireToClient, { loading }] = useMutation(PROFESSIONAL_ASSIGN_QUESTIONNAIRE_TO_CLIENT);

  const createData = useCallback(async (values: any) => {
    try {
      const { data } = await assignQuestionnaireToClient({
        variables: {
          input: {
            clientId: values.clientId,
            ids: values.ids
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in assignQuestionnaireToClient: ', error);
    }
  }, [assignQuestionnaireToClient]);

  return {
    create: createData,
    loading
  };
};

export const useListQuestionnaires = () => {
  const { data, loading, refetch } = useQuery(LIST_QUESTIONNAIRES);

  const fetchQuestionnaireLists = useCallback(() => {
    if (data) {
      return data.listQuestionnaires;
    }
  }, [data]);

  return {
    data: data?.listQuestionnaires,
    fetchData: fetchQuestionnaireLists,
    loading,
    refetch
  };
};