import { ProfessionalLogState, ProfessionalStoreType, QuestionnairesState } from './type';

export const userInitialState = {
  token: '',
  firstName: '',
  id: '',
  email: '',
  user: {
    id: '',
    language: '',
    timezone: ''
  }
};

export const authInitialState = {
  user: null,
  token: '',
  isAuthenticated: false,
};

export const professionalInitialState: ProfessionalStoreType = {
  currentProfessional: null,
  professionals: [],
  listClients: null,
  actionPlanStatuses: null,
  selectedClient: null,
  messages: []
};

export const timezoneInitialState = {
  timezones: []
};

export const countriesInitialState = {
  countries: []
};

export const questionnairesInitialState: QuestionnairesState = {
  yourHealth: {
    totalPage: 6,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "In general, would you say your health is:",
        choices: [
          { title: "Excellent", selected: false },
          { title: "Very Good", selected: false },
          { title: "Good", selected: false },
          { title: "Fair", selected: false },
          { title: "Poor", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 2,
        label: "Compared to one year ago, how would you rate your health in general now?",
        choices: [
          { title: "Much better now than one year ago", selected: false },
          { title: "Somewhat better now than one year ago", selected: false },
          { title: "About the same as one year ago", selected: false },
          { title: "Somewhat worse now than one year ago", selected: false },
          { title: "Much worse now than one year ago", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 3,
        label: "The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        subQuestions: [
          {
            label: "Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Lifting or carrying groceries",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Climbing several flights of stairs",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Climbing one flight of stairs",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Bending, kneeling, or stooping",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Walking more than a mile",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Walking several blocks",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Walking one block",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
          {
            label: "Bathing or dressing yourself",
            choices: [
              { title: "Yes, limited a lot", selected: false },
              { title: "Yes, limited a little", selected: false },
              { title: "No, not limited at all", selected: false },
            ],
          },
        ],
        page: 2,
        type: 'multi'
      },
      {
        question_num: 4,
        label: "During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
        subQuestions: [
          {
            label: "Cut down the amount of time you spent on work or other activities",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false },
            ],
          },
          {
            label: "Accomplished less than you would like",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false },
            ],
          },
          {
            label: "Were limited in the kind of work or other activities",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false },
            ],
          },
          {
            label: "Had difficulty performing the work or other activities (for example, it took extra effort)",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false },
            ],
          },
        ],
        page: 3,
        type: 'multi'
      },
      {
        question_num: 5,
        label: "During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your mental health (for example, because you felt depressed or anxious)?",
        subQuestions: [
          {
            label: "Cut down the amount of time you spent on work or other activities",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false },
            ],
          },
          {
            label: "Accomplished less than you would like",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false },
            ],
          },
          {
            label: "	Didn't do work or other activities as carefully as usual",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false },
            ],
          },
        ],
        page: 3,
        type: 'multi'
      },
      {
        question_num: 6,
        label: "During the past 4 weeks, to what extent has your physical health or emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?",
        choices: [
          { title: "Not at all", selected: false },
          { title: "Slightly", selected: false },
          { title: "Moderately", selected: false },
          { title: "Quite a bit", selected: false },
          { title: "Extremely", selected: false },
        ],
        page: 4,
        type: 'single'
      },
      {
        question_num: 7,
        label: "How much bodily pain have you had during the past 4 weeks?",
        choices: [
          { title: "None", selected: false },
          { title: "Very mild", selected: false },
          { title: "Mild", selected: false },
          { title: "Moderate", selected: false },
          { title: "Severe", selected: false },
          { title: "Very severe", selected: false },
        ],
        page: 4,
        type: 'single'
      },
      {
        question_num: 8,
        label: "During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?",
        choices: [
          { title: "Not at all", selected: false },
          { title: "Slightly", selected: false },
          { title: "Moderately", selected: false },
          { title: "Quite a bit", selected: false },
          { title: "Extremely", selected: false },
        ],
        page: 4,
        type: 'single'
      },
      {
        question_num: 9,
        label: "These questions are about how you feel and how things have been with you during the past 4 weeks. For each question, please give the one answer that comes closest to the way you have been feeling. How much of the time during the past 4 weeks...",
        subQuestions: [
          {
            label: "Did you feel full of pep?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Have you been a very nervous person?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Have you felt so down in the dumps that nothing could cheer you up?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Have you felt calm and peaceful?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Did you have a lot of energy?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Have you felt downhearted and blue?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Did you feel worn out?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Have you been a happy person?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
          {
            label: "Did you feel tired?",
            choices: [
              { title: "All of the time", selected: false },
              { title: "Most of the time", selected: false },
              { title: "A good bit of the time", selected: false },
              { title: "Some of the time", selected: false },
              { title: "A little of the time", selected: false },
              { title: "None of the time", selected: false },
            ],
          },
        ],
        page: 5,
        type: 'multi'
      },
      {
        question_num: 10,
        label: "During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting with friends, relatives, etc.)?",
        choices: [
          { title: "All of the time", selected: false },
          { title: "Most of the time", selected: false },
          { title: "Some of the time", selected: false },
          { title: "A little of the time", selected: false },
          { title: "None of the time", selected: false },
        ],
        page: 6,
        type: 'single'
      },
      {
        question_num: 11,
        label: "Please choose the answer that best describes how true or false each of the following statements is for you.",
        subQuestions: [
          {
            label: "I seem to get sick a little easier than other people",
            choices: [
              { title: "Definitely true", selected: false },
              { title: "Mostly true", selected: false },
              { title: "Don't know", selected: false },
              { title: "Mostly false", selected: false },
              { title: "Definitely false", selected: false },
            ],
          },
          {
            label: "I am as healthy as anybody I know",
            choices: [
              { title: "Definitely true", selected: false },
              { title: "Mostly true", selected: false },
              { title: "Don't know", selected: false },
              { title: "Mostly false", selected: false },
              { title: "Definitely false", selected: false },
            ],
          },
          {
            label: "I expect my health to get worse",
            choices: [
              { title: "Definitely true", selected: false },
              { title: "Mostly true", selected: false },
              { title: "Don't know", selected: false },
              { title: "Mostly false", selected: false },
              { title: "Definitely false", selected: false },
            ],
          },
        ],
        page: 6,
        type: 'multi'
      },
    ],
  },
  generalWellBeing: {
    totalPage: 2,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "How have you been feeling in general?",
        choices: [
          { title: "In excellent spirits", selected: false },
          { title: "In very good spirits", selected: false },
          { title: "In good spirits mostly", selected: false },
          { title: "I've been up and down in spirits a lot", selected: false },
          { title: "In low spirits mostly", selected: false },
          { title: "In very low spirits", selected: false },
        ],
        page: 1
      },
      {
        question_num: 2,
        label: "Have you been bothered by nervousness or your nerves?",
        choices: [
          { title: "Extremely so-to the point where I could not work or take care of things.", selected: false },
          { title: "Very much so", selected: false },
          { title: "Quite a bit", selected: false },
          { title: "Some-enough to bother me", selected: false },
          { title: "A little", selected: false },
          { title: "Not at all", selected: false },
        ],
        page: 1
      },
      {
        question_num: 3,
        label: "Have you been in firm control of your behavior, thoughts, emotions, or feelings?",
        choices: [
          { title: "Yes, definitely so", selected: false },
          { title: "Yes, for the most part", selected: false },
          { title: "Generally so", selected: false },
          { title: "Not too well", selected: false },
          { title: "No, and I am somewhat disturbed", selected: false },
          { title: "No, and I am very disturbed", selected: false },
        ],
        page: 1
      },
      {
        question_num: 4,
        label: "Have you felt so sad, discouraged, or had so many problems that you wondered if anything was worthwhile?",
        choices: [
          { title: "Extremely-so to the point I have just about given up", selected: false },
          { title: "Very much so", selected: false },
          { title: "Quite a bit", selected: false },
          { title: "Some-enough to bother me", selected: false },
          { title: "A little bit", selected: false },
          { title: "Not at all", selected: false }
        ],
        page: 1
      },
      {
        question_num: 5,
        label: "Have you been under or felt you were under any strain, stress, or pressure?",
        choices: [
          { title: "Yes-almost more than I could bear", selected: false },
          { title: "Yes-quite a bit of pressure", selected: false },
          { title: "Yes-some, more than usual", selected: false },
          { title: "Yes-some, but about usual", selected: false },
          { title: "Yes-a little", selected: false },
          { title: "Not at all", selected: false }
        ],
        page: 1
      },
      {
        question_num: 6,
        label: "How happy, satisfied, or pleased have you been with your personal life?",
        choices: [
          { title: "Extremely happy-couldn't have been more satisfied or pleased", selected: false },
          { title: "Very happy", selected: false },
          { title: "Fairly happy", selected: false },
          { title: "Satisfied-pleased", selected: false },
          { title: "Somewhat dissatisfied", selected: false },
          { title: "Very dissatisfied", selected: false }
        ],
        page: 1
      },
      {
        question_num: 7,
        label: "Have you had reason to wonder if you were losing your mind, or losing control over the way you act, talk, think, feel, or of your memory?",
        choices: [
          { title: "Not at all", selected: false },
          { title: "Only a little", selected: false },
          { title: "Some, but not enough to be concerned", selected: false },
          { title: "Some, and I've been a little concerned", selected: false },
          { title: "Some, and I am quite concerned", selected: false },
          { title: "Much, and I'm very concerned", selected: false }
        ],
        page: 1
      },
      {
        question_num: 8,
        label: "Have you been anxious, worried, or upset?",
        choices: [
          { title: "Extremely so-to the point of being sick, or almost sick", selected: false },
          { title: "Very much so", selected: false },
          { title: "Quite a bit", selected: false },
          { title: "Some-enough to bother me", selected: false },
          { title: "A little bit", selected: false },
          { title: "Not at all", selected: false }
        ],
        page: 1
      },
      {
        question_num: 9,
        label: "Have you been waking up fresh and rested?",
        choices: [
          { title: "Every day", selected: false },
          { title: "Most every day", selected: false },
          { title: "Fairly often", selected: false },
          { title: "Less than half the time", selected: false },
          { title: "Rarely", selected: false },
          { title: "None of the time", selected: false }
        ],
        page: 1
      },
      {
        question_num: 10,
        label: "Have you been bothered by any illness, bodily disorder, pain, or fears about your health?",
        choices: [
          { title: "All of the time", selected: false },
          { title: "Most of the time", selected: false },
          { title: "A good bit of the time", selected: false },
          { title: "Some of the time", selected: false },
          { title: "A little of the time", selected: false },
          { title: "None of the time", selected: false }
        ],
        page: 1
      },
      {
        question_num: 11,
        label: "Has your daily life been full of things that are interesting to you?",
        choices: [
          { title: "All of the time", selected: false },
          { title: "Most of the time", selected: false },
          { title: "A good bit of the time", selected: false },
          { title: "Some of the time", selected: false },
          { title: "A little of the time", selected: false },
          { title: "None of the time", selected: false }
        ],
        page: 1
      },
      {
        question_num: 12,
        label: "Have you felt downhearted and blue?",
        choices: [
          { title: "All of the time", selected: false },
          { title: "Most of the time", selected: false },
          { title: "A good bit of the time", selected: false },
          { title: "Some of the time", selected: false },
          { title: "A little of the time", selected: false },
          { title: "None of the time", selected: false }
        ],
        page: 1
      },
      {
        question_num: 13,
        label: "Have you been feeling emotionally stable and sure of yourself?",
        choices: [
          { title: "All of the time", selected: false },
          { title: "Most of the time", selected: false },
          { title: "A good bit of the time", selected: false },
          { title: "Some of the time", selected: false },
          { title: "A little of the time", selected: false },
          { title: "None of the time", selected: false }
        ],
        page: 1
      },
      {
        question_num: 14,
        label: "Have you felt tired, worn out, used up, or exhausted?",
        choices: [
          { title: "All of the time", selected: false },
          { title: "Most of the time", selected: false },
          { title: "A good bit of the time", selected: false },
          { title: "Some of the time", selected: false },
          { title: "A little of the time", selected: false },
          { title: "None of the time", selected: false }
        ],
        page: 1
      },
      {
        question_num: 15,
        label: "How concerned or worried about your health have you been?",
        choices: [
          { title: "10", selected: false },
          { title: "8", selected: false },
          { title: "6", selected: false },
          { title: "4", selected: false },
          { title: "2", selected: false },
          { title: "0", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        maxLabel: 'Not concerned at all',
        minLabel: 'Very concerned'
      },
      {
        question_num: 16,
        label: "How relaxed or tense have you been?",
        choices: [
          { title: "10", selected: false },
          { title: "8", selected: false },
          { title: "6", selected: false },
          { title: "4", selected: false },
          { title: "2", selected: false },
          { title: "0", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        maxLabel: 'Very relaxed',
        minLabel: 'Very tense'
      },
      {
        question_num: 17,
        label: "How much energy, pep, and vitality have you felt?",
        choices: [
          { title: "10", selected: false },
          { title: "8", selected: false },
          { title: "6", selected: false },
          { title: "4", selected: false },
          { title: "2", selected: false },
          { title: "0", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        maxLabel: 'No energy at all, listless',
        minLabel: 'Very energetic, dynamic'
      },
      {
        question_num: 18,
        label: "How depressed or cheerful have you been?",
        choices: [
          { title: "10", selected: false },
          { title: "8", selected: false },
          { title: "6", selected: false },
          { title: "4", selected: false },
          { title: "2", selected: false },
          { title: "0", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        maxLabel: 'Very depressed',
        minLabel: 'Very cheerful'
      },
    ],
  },
  fourDimensionalComplaintList: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: '',
        label: "In the past week, have you suffered from:",
        subQuestions: [
          {
            label: "Dizzines or light-headedness?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Sore muscles?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Fainting?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Feeling of pain?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Back pain?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Excessive sweating?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Palpitations?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Headache?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Abdominal bloating?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Blurred vision or spots to the eyes?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Stuffiness?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Nausea or an upset stomach?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          }
        ],
        page: 1,
        type: 'multi'
      },
      {
        question_num: '',
        label: "In the past week, have you suffered from:",
        subQuestions: [
          {
            label: "Pain in the abdominal or stomach area?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Tingling in the fingers?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "A pressing or tight feeling in the chest?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Chest pain?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Dejection?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Just suddenly startled?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Worry?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Restless sleep?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Indefinable anxiety feelings?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Listlessness",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Trembling in the company of other people?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Anxiety or panic attacks?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          }
        ],
        page: 1,
        type: 'multi'
      },
      {
        question_num: '',
        label: "During the past week, have you felt:",
        subQuestions: [
          {
            label: "Tense?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Easily irritated?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Anxious?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          }
        ],
        page: 1,
        type: 'multi'
      },
      {
        question_num: '',
        label: "In the past week have you felt:",
        subQuestions: [
          {
            label: "That all is meaningless?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That you can come to nothing more?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That life isn't worth living?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That you are no longer interested in the people and things around you?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That you can't take it anymore?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That it would be better if some was dead?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That you can no longer enjoy anything?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That there is no way out of your situation?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "You can't take it anymore?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "That you don't feel like anything more?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          }
        ],
        page: 1,
        type: 'multi'
      },
      {
        question_num: '',
        label: "In the past week have you:",
        subQuestions: [
          {
            label: "Difficulty with clear thinking?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Trouble falling asleep?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Fear of leaving home alone?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          }
        ],
        page: 1,
        type: 'multi'
      },
      {
        question_num: '',
        label: "In the past week have you:",
        subQuestions: [
          {
            label: "Quickly emotional?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Fearful of something you are not afraid of at all (e.g., animals, heights, confined spaces)?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Afraid to travel on buses, trains, or trams?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Afraid of being embarrassed in the company of other people?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "In the past week, have you ever felt as if you are being threatened by an unknown danger?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "During the past week, have you ever thought 'If only I was dead'?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "During the past week, have you ever had pictures in your mind of a poignant event(s) you experienced?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "During the past week, have you ever struggled to get rid of thoughts or memories of a distressing event(s)?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Have you had to avoid certain places in the past week because they make you anxious?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          },
          {
            label: "Do you have to repeat some actions several times in the past weeks before you can do something else?",
            choices: [
              { title: "No", selected: false },
              { title: "Sometimes", selected: false },
              { title: "Regularly", selected: false },
              { title: "Often", selected: false },
              { title: "Constantly or Often", selected: false }
            ],
          }
        ],
        page: 1,
        type: 'multi'
      },
    ]
  },
  orientationToLife: {
    totalPage: 3,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "When you talk to people, do you have the feeling that they don’t understand you?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Never have this feeling',
        maxLabel: 'Always have this feeling'
      },
      {
        question_num: 2,
        label: "In the past, when you had to do something which depended upon cooperation with others, which feeling did you have?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Surely wouldn’t get done',
        maxLabel: 'Surely would get done'
      },
      {
        question_num: 3,
        label: "Think of people with whom you come into contact daily, aside from the ones to whom you feel closest. How well do you know most of them?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'You feel that they are strangers',
        maxLabel: 'You know them very well'
      },
      {
        question_num: 4,
        label: "Do you have the feeling that you don’t really care about what goes around you?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Very seldom or never',
        maxLabel: 'Very often'
      },
      {
        question_num: 5,
        label: "Has it happened in the past that you were surprised by the behavior of people whom you thought you knew well?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Never happened',
        maxLabel: 'Always happened'
      },
      {
        question_num: 6,
        label: "Has it happened that people whom you counted on disappointed you?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Never happened',
        maxLabel: 'Always happened'
      },
      {
        question_num: 7,
        label: "Life is:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Full of interest',
        maxLabel: 'Completely routine'
      },
      {
        question_num: 8,
        label: "Until now your life has had:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'No clear goals or purpose at all',
        maxLabel: 'Very clear goals and purpose'
      },
      {
        question_num: 9,
        label: "Do you have the feeling that you’re being treated unfairly?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Very often',
        maxLabel: 'Very seldom or never'
      },
      {
        question_num: 10,
        label: "In the past ten years your life has been:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 1,
        type: 'single',
        direction: 'row',
        minLabel: 'Full of changes without your knowing what will happen next',
        maxLabel: 'Completely consistent and clear'
      },
      {
        question_num: 11,
        label: "Most of the things you do in the future will probably be:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'Deadly boring',
        maxLabel: 'Completely fascinating'
      },
      {
        question_num: 12,
        label: "Do you have the feeling that you are in an unfamiliar situation and don’t know what to do?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'Very seldom or never',
        maxLabel: 'Very often'
      },
      {
        question_num: 13,
        label: "What best describes how you see life:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'There is no solution to painful things in life',
        maxLabel: 'One can always find a solution to painful things in life'
      },
      {
        question_num: 14,
        label: "When you think about your life, you very often:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'Ask yourself why you exist at all',
        maxLabel: 'Feel how good it is to be alive'
      },
      {
        question_num: 15,
        label: "When you face a difficult problem, the choice of a solution is:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'Always confusing and hard to find',
        maxLabel: 'Always completely clear'
      },
      {
        question_num: 16,
        label: "Doing the things you do everyday is:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'A source of pain and boredom',
        maxLabel: 'A source of deep pleasure and satisfaction'
      },
      {
        question_num: 17,
        label: "Your life in the future will probably be:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'Full of changes without your knowing what will happen next',
        maxLabel: 'Completely consistent and clear'
      },
      {
        question_num: 18,
        label: "When something unpleasant happened in the past your tendency was:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'To say “ok, that’s that, I have to live with it” and go on',
        maxLabel: '“To eat yourself up” about it'
      },
      {
        question_num: 19,
        label: "Do you have very mixed up feelings and ideas?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'Very seldom or never',
        maxLabel: 'Very often'
      },
      {
        question_num: 20,
        label: "When you do something that gives you a good feeling:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 2,
        type: 'single',
        direction: 'row',
        minLabel: 'It’s certain that something will happen to spoil the feeling',
        maxLabel: 'It’s certain that you’ll go on feeling good',
      },
      {
        question_num: 21,
        label: "Does it happen that you have feelings inside you would rather not feel?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'Very seldom or never',
        maxLabel: 'Very often'
      },
      {
        question_num: 22,
        label: "Do you anticipate that your personal life in the future will be:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'Totally without meaning and purpose',
        maxLabel: 'Full of meaning and purpose'
      },
      {
        question_num: 23,
        label: "Do you think that there will always be people whom you’ll be able to count on in the future?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'You doubt there will be',
        maxLabel: 'You’re certain there will be'
      },
      {
        question_num: 24,
        label: "Does it happen that you have the feeling that you don’t know exactly what’s about to happen?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'Very seldom or never',
        maxLabel: 'Very often'
      },
      {
        question_num: 25,
        label: "Many people – even those with a strong character – sometimes feel like sad sacks (losers) in certain situations. How often have you felt this way in the past?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'Never',
        maxLabel: 'Very often'
      },
      {
        question_num: 26,
        label: "When something happened, have you generally found that:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'You overestimated or underestimated its importance',
        maxLabel: 'You saw things in the right proportion'
      },
      {
        question_num: 27,
        label: "When you think of difficulties you are likely to face in important aspects of your life, do you have the feeling that:",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'You won’t succeed in overcoming the difficulties',
        maxLabel: 'You will always succeed in overcoming the difficulties'
      },
      {
        question_num: 28,
        label: "How often do you have the feeling that there’s little meaning in the things you do in your daily life?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'Very often',
        maxLabel: 'Very seldom or never'
      },
      {
        question_num: 29,
        label: "How often do you have feelings that you’re not sure you can keep under control?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
        ],
        page: 3,
        type: 'single',
        direction: 'row',
        minLabel: 'Very often',
        maxLabel: 'Very seldom or never'
      }
    ]
  },
  behavioralAvoidanceInhibition: {
    totalPage: 3,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "A person's family is the most important thing in life.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 2,
        label: "Even if something bad is about to happen to me, I rarely experience fear or nervousness.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 3,
        label: "I go out of my way to get things I want.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 4,
        label: "When I'm doing well at something I love to keep at it.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 5,
        label: "I'm always willing to try something new if I think it will be fun.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 6,
        label: "How I dress is important to me.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 7,
        label: "When I get something I want, I feel excited and energized.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 8,
        label: "Criticism or scolding hurts me quite a bit.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 9,
        label: "When I want something I usually go all-out to get it.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 10,
        label: "I will often do things for no other reason than that they might be fun.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 11,
        label: "It's hard for me to find the time to do things such as get a haircut.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 12,
        label: "If I see a chance to get something I want I move on it right away.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 13,
        label: "I feel pretty worried or upset when I think or know somebody is angry at me.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 14,
        label: "When I see an opportunity for something I like I get excited right away.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 15,
        label: "I often act on the spur of the moment.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 16,
        label: "If I think something unpleasant is going to happen I usually get pretty 'worked up'.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 17,
        label: "I often wonder why people act the way they do.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 18,
        label: "When good things happen to me, it affects me strongly.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 19,
        label: "I feel worried when I think I have done poorly at something important.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 20,
        label: "I crave excitement and new sensations.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 2,
        type: 'single'
      },
      {
        question_num: 21,
        label: "When I go after something I use a 'no holds barred' approach.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 3,
        type: 'single'
      },
      {
        question_num: 22,
        label: "I have very few fears compared to my friends.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 3,
        type: 'single'
      },
      {
        question_num: 23,
        label: "It would excite me to win a contest.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 3,
        type: 'single'
      },
      {
        question_num: 24,
        label: "I worry about making mistakes.",
        choices: [
          { title: "Very true for me", selected: false },
          { title: "Somewhat true for me", selected: false },
          { title: "Somewhat false for me", selected: false },
          { title: "Very false for me", selected: false },
        ],
        page: 3,
        type: 'single'
      },
    ]
  },
  conditionsDuringPregnancy: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: '',
        label: "How were the family conditions during the period of pregnancy?",
        choices: [
          { title: "I do not know", selected: false },
          { title: "Relaxed", selected: false },
          { title: "Slightly tensed", selected: false },
          { title: "Tensed", selected: false },
          { title: "Extremely tensed", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "How was the health condition of your mother during the pregnancy?",
        choices: [
          { title: "I do not know", selected: false },
          { title: "Good", selected: false },
          { title: "Moderate", selected: false },
          { title: "Not good", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "Did your mother use medicines during the pregnancy?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
          { title: "I don't know", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "Did your mother smoke during the pregnancy?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
          { title: "I don't know", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "Did your mother drink alcohol during the pregnancy?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
          { title: "I don't know", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "How was the delivery?",
        choices: [
          { title: "Home birth without details", selected: false },
          { title: "Home birth with complications", selected: false },
          { title: "Hospital delivery - spontaneous birth", selected: false },
          { title: "Hospital delivery - birth induced / induced", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "Did your mother experience childbirth as traumatic?",
        choices: [
          { title: "Not at all", selected: false },
          { title: "A little", selected: false },
          { title: "Very traumatic", selected: false },
          { title: "I don't know", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "What was your APGAR score after birth?",
        choices: [],
        page: 1
      },
      {
        question_num: '',
        label: "Have you been breast-fed?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "What was the family situation in the family in the first 2 years after your birth?",
        choices: [
          { title: "Harmonious and warm", selected: false },
          { title: "Tense", selected: false },
          { title: "Very tense and stressful", selected: false },
          { title: "I don't know", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "Were you ill during the first two years of life for which you received regular treatment?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "Did you grow up in your early childhood in an environment where you may have more contact with chemicals and / or pesticides (agricultural environment, agriculture and horticulture, near (petro) chemical factory, etc.)",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
        ],
        page: 1
      },
      {
        question_num: '',
        label: "Are there other details related to this stage of life, regarding yourself or your immediate environment?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
        ],
        page: 1
      }
    ]
  },
  emotionRegulation: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "When I want to feel more positive emotion (such as joy or amusement), I change what I'm thinking about.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 2,
        label: "I keep my emotions to myself.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 3,
        label: "When I want to feel less negative emotion (such as sadness or anger), I change what I'm thinking about.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 4,
        label: "When I'm feeling positive emotions, I am careful not to express them.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 5,
        label: "When I'm faced with a stressful situation, I make myself think about it in a way that helps me stay calm.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 6,
        label: "I control my emotions by not expressing them.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 7,
        label: "When I want to feel more positive emotion, I change the way I'm thinking about the situation.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 8,
        label: "I control my emotions by changing the way I think about the situation I'm in.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 9,
        label: "When I'm feeling negative emotion, I make sure not to express them.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 10,
        label: "When I want to feel less negative emotion, I change the way I'm thinking about the situation.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
    ]
  },
  expressivity: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "Whenever I feel positive emotions, people can easily see exactly what I am feeling.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 2,
        label: "I sometimes cry during sad movies.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 3,
        label: "People often do not know what I am feeling.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 4,
        label: "I laugh out loud when someone tells me a joke that I think is funny.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 5,
        label: "It is difficult for me to hide my fear.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 6,
        label: "When I'm happy, my feelings show.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 7,
        label: "My body reacts very strongly to emotional situations.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 8,
        label: "I've learned it is better to suppress my anger than to show it.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 9,
        label: "No matter how nervous or upset I am, I tend to keep a calm exterior.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 10,
        label: "I am an emotionally expressive person.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 11,
        label: "I have strong emotions.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 12,
        label: "I am sometimes unable to hide my feelings, even though I would like to.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 13,
        label: "Whenever I feel negative emotions, people can easily see exactly what I am feeling.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 14,
        label: "There have been times when I have not been able to stop crying even though I tried to stop.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 15,
        label: "I experience my emotions very strongly.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      },
      {
        question_num: 16,
        label: "What I'm feeling is written all over my face.",
        choices: [
          { title: "Strongly disagree", selected: false },
          { title: "Neutral", selected: false },
          { title: "Strongly agree", selected: false },
        ],
        page: 1,
        type: 'single'
      }
    ]
  },
  mentalEmotionalFunctioning: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "What are your most important drivers in life?",
        choices: [
          { title: "Religion", selected: false },
          { title: "Relationships", selected: false },
          { title: "Personal growth", selected: false },
          { title: "Material wealth", selected: false },
          { title: "Work", selected: false },
          { title: "Care for others", selected: false },
          { title: "Other", selected: false },
        ],
        other: {
          label: 'Please describe:',
          placeholder: 'Write here...',
          answer: '',
        },
        page: 1,
        type: 'checkbox'
      },
      {
        question_num: 2,
        label: "How do you describe your self image?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Low',
        maxLabel: 'Very good'
      },
      {
        question_num: 3,
        label: "I was accepted and welcomed as a child by my parents — how certain do you feel as to how this statement applies to you?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Not at all',
        maxLabel: 'Very well'
      },
      {
        question_num: 4,
        label: "Do you experience uncertainty with your behavior in relationships?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Not at all',
        maxLabel: 'A lot'
      },
      {
        question_num: 5,
        label: "Do you experience uncertainty with your performance at work?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Not at all',
        maxLabel: 'A lot'
      },
      {
        question_num: 6,
        label: "Do you experience uncertainty with your creativity?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Not at all',
        maxLabel: 'A lot'
      },
      {
        question_num: 7,
        label: "Do you experience uncertainty with your supervisor at work and ability to influence this?",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Not at all',
        maxLabel: 'A lot'
      },
      {
        question_num: 8,
        label: "Do you have other uncertainties? Write here...",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Not at all',
        maxLabel: 'A lot'
      },
      {
        question_num: 9,
        label: "Were you shy as a child?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false },
        ],
        page: 1,
        type: 'radioCol',
        minLabel: '',
        maxLabel: ''
      },
      {
        question_num: 10,
        label: "I feel safe / comfortable when I am with other people",
        choices: [
          { title: "1", selected: false },
          { title: "2", selected: false },
          { title: "3", selected: false },
          { title: "4", selected: false },
          { title: "5", selected: false },
          { title: "6", selected: false },
          { title: "7", selected: false },
          { title: "8", selected: false },
          { title: "9", selected: false },
          { title: "10", selected: false },
        ],
        page: 1,
        type: 'radio',
        minLabel: 'Not at all',
        maxLabel: 'Very safe'
      }
    ]
  },
  socialSupport: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "If I wanted to go on a trip for a day (for example, to the country or mountains), I would have a hard time finding someone to go with me.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 2,
        label: "I feel that there is no one I can share my most private worries and fears with.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 3,
        label: "If I were sick, I could easily find someone to help me with my daily chores.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 4,
        label: "There is someone I can turn to for advice about handling problems with my family.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 5,
        label: "If I decide one afternoon that I would like to go to a movie that evening, I could easily find someone to go with me.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 6,
        label: "When I need suggestions on how to deal with a personal problem, I know someone I can turn to.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 7,
        label: "I don't often get invited to do things with others.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 8,
        label: "If I had to go out of town for a few weeks, it would be difficult to find someone who would look after my house or apartment (the plants, pets, garden, etc.)",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 9,
        label: "If I wanted to have lunch with someone, I could easily find someone to join me.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 10,
        label: "If I was stranded 10 miles from home, there is someone I could call who could come and get me.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 11,
        label: "If a family crisis arose, it would be difficult to find someone who could give me good advice about how to handle it.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 12,
        label: "If I needed some help in moving to a new house or apartment, I would have a hard time finding someone to help me.",
        choices: [
          { title: "Definitely false", selected: false },
          { title: "Probably false", selected: false },
          { title: "Probably true", selected: false },
          { title: "Definitely true", selected: false }
        ],
        page: 1,
        type: "single"
      }
    ]
  },
  stressIndicator: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: "",
        label: "PHYSICAL INDICATORS: How often would you say:",
        subQuestions: [{
          label: "My body feels tense all over.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have a nervous sweat or sweaty palms.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have a hard time feeling really relaxed.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have severe or chronic lower back pain.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I get severe or chronic headaches.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I get tension or muscle spasms in my face, jaw, neck or shoulders.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "My stomach quivers or feels upset.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I get skin rashes or itching.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have problems with my bowels (constipation, diarrhea).",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I need to urinate more than most people.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "My ulcer bothers me.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I feel short of breath after mild exercise like climbing up four flights of stairs.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "Compared to most people, I have a very small or a very large appetite.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I don't really plan my meals for balanced nutrition.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "My weight is more than 15 pounds recommended for a person my height and build.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I smoke tobacco.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I get sharp chest pains when I'm physically active.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I lack physical energy.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "When I'm resting, my heart beats more than 100 times a minute.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "Because of my busy schedule I miss at least two meals during the week.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I spend less than 3 hours a week getting vigorous physical exercise (running, playing basketball, tennis, swimming, etc.).",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        }],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "SLEEP INDICATORS: How often would you say:",
        subQuestions: [{
          label: "I have trouble falling asleep.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I take pills to get to sleep.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have nightmares or repeated bad dreams.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I wake up at least once in the middle of the night for no apparent reason.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "No matter how much sleep I get, I awake feeling tired.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        }],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "BEHAVIORAL INDICATORS: How often would you say:",
        subQuestions: [{
          label: "I stutter or get tongue tied when I talk to other people.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I try to work while I'm eating lunch.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have to work later.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I go to work even when I feel sick.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have to bring work home.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I drink alcohol or use drugs to relax.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have more than two beers, eight ounces of wine or three ounces of hard liquor a day.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "When I drink, I like to get really drunk.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I get drunk or 'high' with other drugs more than once a week.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "When I'm feeling high from alcohol or drugs I will drive a motor vehicle.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I tend to stumble when walking, or have more accidents than other people.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "In any given week, I take at least one prescription drug without the recommendation of a physician, e.g. amphetamines, barbiturates.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have problems with my sex life.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "At least once during the week I will make bets for money.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "After dinner I spend more time alone than talking with my family or friends.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I arrive at work late.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "At least once during the week I have a shouting argument with a friend or family member.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I tend to forget things, e.g. appointments, birthdays.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I often feel lonely.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        }],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "EMOTIONAL INDICATORS: How often would you say:",
        subQuestions: [{
          label: "I have found the best way to deal with hassles and problems is to consciously avoid thinking or talking about them.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have trouble remembering things.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I feel anxious or frightened about problems I can't really describe.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I worry a lot.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "It is important for me not to show my emotions to my family.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "It is hard for me to relax at home.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "It's best if I don't tell even my closest friend how I'm really feeling.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I find it hard to talk when I get excited.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I feel very angry inside.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have temper outbursts I can't control.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "When people criticize me, even in friendly, constructive way, I feel offended.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I feel extremely sensitive and irritable.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "My emotions change unpredictably and without any apparent reason.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I feel like I really can't trust anyone.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I feel like other people don't understand me.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I really don't feel good about myself.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "Generally, I am not optimistic about my future.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I feel very tired and disinterested in life.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "Impulsive behavior has caused me problems.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I have felt so bad that I thought of hurting myself.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        }],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "PERSONAL HABITS: How often would you say:",
        subQuestions: [{
          label: "I spend less than three hours a week working on a hobby of mine.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I spend less than one hour a week writing personal letters, writing in a diary or writing creatively.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I spend less than 30 minutes a week talking casually with my neighbors.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I lack time to read the daily newspaper.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I watch television for entertainment more than one hour a day.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I drive in a motor vehicle faster than the speed limit for the excitement and challenge of it.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "I spend less than 30 minutes a day working toward a life goal or ambition of mine.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "My day to day living is not really affected by my religious beliefs or my philosophy of life.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        },
        {
          label: "When I feel stressed, it is difficult for me to plan time and activities to constructively release my stress.",
          choices: [{
            title: "Almost always",
            selected: false
          },
          {
            title: "Most of the time",
            selected: false
          },
          {
            title: "Some of the time",
            selected: false
          },
          {
            title: "Almost never",
            selected: false
          },
          {
            title: "Never",
            selected: false
          }]
        }],
        page: 1,
        type: "multi"
      },
    ]
  },
  neurotransmitters: {
    totalPage: 8,
    currentPage: 1,
    continueLater: null,
    parts: [{
      title: 'Part A – Determining you Dominant Nature – 200 questions',
      description: [
        'Instructions: Answer each question by answering either Yes or No. Answer the questions in terms of how you feel most of the time. For example, if you’ve had a bad night’s sleep and feel tired today, answer the questions that pertain to your energy levels based on how you feel on a more average day.',
        'NOTE: some questions are formulated negatively, and many people find it difficult to answer the question with "yes" or "no". Then ask yourself whether the statement is "true" or "false" in your case. If the statement is "true" then you indicate "yes".'
      ],
      partLabel: ['A1 – Dopamine', 'A2 - Acetylcholine', 'A3 - GABA', 'A4 - Serotonine']
    },
    {
      title: 'Part B – Defining your Deficiencies – 115 questions',
      description: [
        'Instructions: Answer each question with Yes or No. This second assessment will determine if you are deficient in any of the four neurotransmitters, including the one that governs your nature. Many of the questions relate to symptoms you might now be experiencing. Answer the questions in terms of how you feel right now; it doesn’t matter how long you’ve been experiencing these symptoms, or even if they occurred today for the first time.'
      ],
      partLabel: ['B1 - Dopamine deficiency', 'B2 - Acetylcholine deficiency', 'B3 – GABA deficiencies', 'B4 – Serotonine deficiency']
    }],
    questions: [
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "I find it easy to process my thoughts",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I concentrate effectively",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a deep thinker",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a quick thinker",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I become distracted because I do so many tasks at once",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I enjoy intense debate",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a good imagination",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to criticize and analyze my thoughts",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I have a lot of energy most of the time",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My blood pressure is often elevated",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Sometimes in my life I have had episodes of extreme energy",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have insomnia",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I find exercising invigorating",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't ordinarily need coffee to jump-start me in the morning",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My veins are visible and tend to look as though they might pop out of my skin",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to have a high body temperature",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I eat my lunch while I'm working",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I engage in sexual intercourse any",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "chance I get I have a temper",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I eat only to reenergize my body",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I love action movies",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Exercising makes me feel powerful",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
        ],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I am a very domineering individual",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I sometimes don’t notice my feelings",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I often have trouble listening to others because my own ideas dominate",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have been in many fights",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to be future-oriented",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am sometimes speculative",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Most people view me as thinking-oriented",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I daydream and often fantasize",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I like to read history and other non-fiction books",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I admire ingenuity",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can be slow in identifying how people can cause trouble",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't usually get tricked by people who say they need my help",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Most people view me as innovative",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "People have thought I have had some strange ideas, but I can always explain the basis for them rationally",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am often agitated or irritated",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Little things make me anxious or upset",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have fantasies of unlimited power",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I love spending money",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I dominate others in relationships",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very hard on myself",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I react aggressively to criticism, often becoming defensive in front of others",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "Some individuals view me as tough-minded",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Most people view me as achievement-oriented",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Some people say that I am irrational",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I will do anything to reach a goal",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I value a religious philosophy",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Incompetence makes me angry",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have high standards for myself and for others",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "multi"
      },
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "My memory is very strong",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am an excellent listener",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am good at remembering stories",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I usually do not forget a face",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very creative",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have an excellent attention span and rarely miss a thing",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have many good hunches",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I notice everything going on around me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a good imagination",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 2,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I tend to have a slow pulse",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My body has excellent tone",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a great figure/build",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have really low cholesterol",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When I eat, I love to experience the aromas and the beauty of food",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I love yoga and stretching my muscles",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "During sex, I am very sensual",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have had an eating disorder at some point in my life",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have tried many alternative remedies",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 2,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I am a perpetual romantic",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am in touch with my feelings",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to make decisions based on Hunches",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I like to speculate",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Some people say I have my head in the clouds",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I love reading fiction",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a rich fantasy life",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am creative when solving people problems",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very expressive; I like to talk about what's bothering me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am buoyant",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe that it is possible to have a mystical experience",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe in being a soul mate",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Sometimes the mystical can excite me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to overreact to my body",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I find it easy to change things; I am not set in my ways",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am deeply in touch with my emotions",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to love someone one minute and hate him or her the next",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am flirtatious",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't mind spending money if it benefits my relationships",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to fantasize when I’m having sex",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My relationships tend to be filled with Romance",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I love watching romantic movies",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I take risks in my love life",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 2,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "I foresee a better future",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am inspired to help other people",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe that all things are possible, particularly for those who are devoted",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am good at creating harmony between people",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Charity and altruism come from the heart, and I have plenty of both",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Others think me of as having vision",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My thoughts on religion often change",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am an idealist, but not a perfectionist",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I'm happy with someone who just treats me right",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 2,
        type: "multi"
      },
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "My memory is very strong",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am an excellent listener",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am good at remembering stories",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I usually do not forget a face",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very creative",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have an excellent attention span and rarely miss a thing",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have many good hunches",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I notice everything going on around me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a good imagination",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a stable attention span and can follow other people's logic",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I enjoy reading people more than books",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I retain most of what I hear",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can remember facts people tell me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I learn from my experiences",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am good at remembering names",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can focus very well on tasks and people’s stories",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 3,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I find it easy to relax",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a calm person",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I find it easy to fall asleep at night",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to have high physical endurance",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have low blood pressure",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I do not have a family history of stroke",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When it comes to sex, I am not very experimental",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have little muscle tension",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Caffeine has little effect on me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I take my time eating my meals",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I sleep well",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't have many harmful food cravings such as sugar",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Exercising is a regimented habit for me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 3,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I am not very adventurous",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I do not have a temper",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a lot of patience",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't enjoy philosophy",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I love watching sitcoms about families",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I dislike movies about other worlds or universes",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am not a risk-taker",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I keep past experiences in mind before I make decisions",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a realistic person",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe in closure",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I like facts and details",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When I make a decision, it's permanent",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I like to plan my day, week, month, etc.",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I collect things",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a little sad",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am afraid of confrontations and altercations",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I save up a lot of money in the event of a crisis",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to create strong, lasting bonds with others",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a stable pillar in people’s lives",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 3,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "I believe in the adage 'Early to bed, early to rise.'",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe in meeting deadlines",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I try to please others the best I can",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a perfectionist",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am good at maintaining long-lasting relationships",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I pay attention to where my money goes",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe that the world would be more peaceful if people would improve upon their morals",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very loyal and devoted to my loved ones",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have high ethical standards that I live by",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I pay close attention to laws, principles, and policies",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe in participating in service for the community",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 3,
        type: "multi"
      },
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "I can easily concentrate on manual-labor tasks",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a good visual memory",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very perceptive",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am an impulsive thinker",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I live in the here and now",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to say, 'Tell me the bottom line.'",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a slow book learner, but I learn easily from experience",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I need to experience something or work at it hands-on in order to understand it",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 4,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I sleep too much",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When it comes to sex, I am very experimental",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have low blood pressure",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very action-oriented",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very handy around the house",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very active outdoors",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I engage in daring activities such as skydiving and motorcycle riding",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can solve problems spontaneously",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I rarely have carbohydrate cravings",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I usually grab a quick meal on the run",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I'm usually not very consistent with my exercise routine; I may exercise daily for three weeks and then skip it for a month",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 4,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I live life in the immediate moment",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I like to perform/entertain in public",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to gather facts in an unorganized manner",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very flexible",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a great negotiator",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I often just like to 'eat, drink, and be merry'",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am dramatic",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am very artistic",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a good craftsman",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I'm a risk-taker when it comes to sports",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe in psychics",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can easily take advantage of others",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am cynical of others' philosophies",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I like to have fun",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My favorite type of movies are horror flicks",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am fascinated with weapons",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I rarely stick to a plan or agenda",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have trouble remaining faithful",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am easily able to separate and move on when relationships with loved ones end",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't pay much attention to how I spend my money",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have many frivolous relationships",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 4,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "I always keep my options open in case something better comes up",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't like working hard for long periods of time",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I believe things should have a function and purpose",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am optimistic",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I live in the moment",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I pray only when I'm in need of spiritual support",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't have particularly high morals and ethical values",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I do what I want, when I want to",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't care about being perfect; I just live my life",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Savings are for suckers",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 4,
        type: "multi"
      },
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "I have trouble paying consistent attention and concentrating",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I need caffeine to wake up",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I cannot think quickly enough",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I do not have a good attention span",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have trouble getting through a task even",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When it is interesting to me I am slow in learning new ideas",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 5,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I crave sugar",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have decreased libido",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I sleep too much",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a history of alcohol or addiction",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have recently felt worn out for no apparent reason",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I sometimes experience total exhaustion without even exerting myself",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have always battled weight problems",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have little motivation for sexual experiences",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have little trouble getting out of bed in the morning",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have had a craving for cocaine, amphetamines, or Ecstasy",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 5,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I feel fine just following others",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "People seem to take advantage of me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am feeling very down or depressed",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "People have told me I am too mellow",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have little urgency",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I let people criticize me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I always look to others to lead me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 5,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "I have lost my reasoning skills",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can't make good decisions",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 5,
        type: "multi"
      },
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "I lack imagination",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have difficulty remembering names when I first meet people",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have noticed that my memory ability is decreasing",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My significant other tells me I don’t have romantic thoughts",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can't remember my friend’s birthday",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have lost some of my creativity",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 6,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I have insomnia",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have lost muscle tone",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't exercise anymore",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I crave fatty food",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have experimented with hallucinogens or other illicit drugs",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I feel like my body is falling apart",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can't breathe easily",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 6,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I don't feel joy very often",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I feel despair",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I protect myself from being hurt by others by never telling much about myself",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I find it more comfortable to do things alone rather than in a large group",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Other people get angrier about bothersome things than I do",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I give in easily and tend to be submissive",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I rarely feel passionate about anything",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 6,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "I don't care about anyone’s stories but my own",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't pay attention to people's feelings",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I don't feel buoyant",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I'm obsessed with my deficiencies",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 6,
        type: "multi"
      },
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "I find it difficult to concentrate because I'm nervous and jumpy",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can't remember phone numbers",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have trouble finding the right word",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have trouble remembering things when I am put on the spot",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I know I am intelligent, but it is hard to show others",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My ability to focus comes and goes",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When I read, I find I have to go back over the same paragraph a few times or absorb the information",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am a quick thinker but can't always say what I mean",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 7,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I feel shaky sometimes tremble",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have frequent backaches and/or headaches",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to have shortness of breath",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to have heart palpitations",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to have cold hands",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I sometimes sweat too much",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am sometimes dizzy",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I often have muscle tension",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to get butterflies in my stomach",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I crave bitter foods",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am often nervous",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I like yoga because it helps me to relax",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I often feel fatigued even when I have had a good night's sleep",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I overeat",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 7,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I have mood swings",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I enjoy doing many things at one time, but I find it difficult to decide what to do first",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to do things just because I think they'd be fun",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When things are dull, I always try to introduce some excitement",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to be fickle, changing my mood and thoughts frequently",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to get overly excited about things",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My impulses tend to get me into a lot of trouble",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to be theatrical and draw attention to myself",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I speak my mind no matter what the reaction of others may be",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I sometimes have fits of rage and then feel terribly guilty",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I often tell lies to get out of trouble",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have always had less interest than the average person in sex",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 7,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "I don't play by the rules anymore",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have lost my friends",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can't sustain romantic relationships",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I consider the law arbitrary and without reason",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I now consider rules that I used to follow ridiculous",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 7,
        type: "multi"
      },
      {
        question_num: "",
        label: "Memory and Attention",
        subQuestions: [
          {
            label: "I am not very perceptive",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can't remember things that I have seen in the past",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a slow reaction time",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have a poor sense of direction",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 8,
        type: "multi"
      },
      {
        question_num: "",
        label: "Physical",
        subQuestions: [
          {
            label: "I have night sweats",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have insomnia",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to sleep in many different positions in order to feel comfortable",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I always awake early in the morning",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I can't relax",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I wake up at least two times per night It is difficult for me to fall back asleep",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "When I am awakened",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I crave salt",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have less energy to exercise",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am sad",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 8,
        type: "multi"
      },
      {
        question_num: "",
        label: "Personality",
        subQuestions: [
          {
            label: "I have chronic anxiety",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am easily irritated",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have thoughts of self-destruction",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I have had suicidal thoughts in my life",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I tend to dwell on ideas too much",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I am sometimes so structured that I become inflexible",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "My imagination takes over",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "Fear grips me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 8,
        type: "multi"
      },
      {
        question_num: "",
        label: "Character",
        subQuestions: [
          {
            label: "I can't stop thinking about the meaning of life",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "I no longer want to take risks",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            label: "The lack of meaning in my life is painful to me",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 8,
        type: "multi"
      }
    ]
  },
  foodAndIntakeDigestion: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: "",
        label: "I am vegetarian",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col",
      },
      {
        question_num: "",
        label: "I am flexitarian",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "I am veganist",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "In general I eat",
        choices: [
          { title: "a lot", selected: false },
          { title: "much", selected: false },
          { title: "normal", selected: false },
          { title: "not much", selected: false },
          { title: "hardly anything", selected: false }
        ],
        page: 1,
        type: "col",
      },
      {
        question_num: "",
        label: "I prefer to eat",
        placeholder: "Write here...",
        answer: '',
        page: 1,
        type: "input"
      },
      {
        question_num: "",
        label: "On a regular day I eat",
        subQuestions: [
          {
            label: "Fresh fruit (not fruit juices)",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Fresh vegetables",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "(Whole) grains, potatoes, legumes",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Omega-3 fatty acids (fish, linseed, walnuts, hemp, chia seed)",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Dairy products",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Eggs (as ingredient of a recipe)",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Cow milk or whipped cream",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Added sugars",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Refined flour",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Sweets and salt snacks",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Meat, poultry and fish",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          },
          {
            label: "Vegetable oils (olive oils, coconut oil, canola oil)",
            choices: [
              { title: "none", selected: false },
              { title: "1/8 plate", selected: false },
              { title: "1/4 plate", selected: false },
              { title: "1/2 plate", selected: false },
              { title: "3/4 plate", selected: false },
              { title: "1 plate or more", selected: false }
            ]
          }
        ],
        page: 1,
        type: "multi",
      },
      {
        question_num: "",
        label: "I fill my sandwich with",
        placeholder: "Write here...",
        answer: '',
        page: 1,
        type: "input"
      },
      {
        question_num: "",
        label: "How many sandwiches per day do you eat?",
        placeholder: "Write here...",
        answer: '',
        page: 1,
        type: "input"
      },
      {
        question_num: "",
        label: "I eat raw food every day",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col",
      },
      {
        question_num: "",
        label: "What kind of meat do you normally eat?",
        choices: [
          { title: "fish", selected: false },
          { title: "pork", selected: false },
          { title: "beef", selected: false },
          { title: "lamb", selected: false },
          { title: "poultry", selected: false },
          { title: "other", selected: false },
        ],
        other: {
          label: 'Please describe:',
          placeholder: 'Write here...',
          answer: '',
        },
        page: 1,
        type: 'checkbox'
      },
      {
        question_num: "",
        label: "I regularly eat raw nuts and seeds",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col",
      },
      {
        question_num: "",
        label: "Which type of oil do you use for frying, baking and roasting?",
        choices: [
          { title: "vegetable oil", selected: false },
          { title: "olive oil", selected: false },
          { title: "margarin", selected: false },
          { title: "butter", selected: false },
          { title: "coconut oil", selected: false },
          { title: "other ...", selected: false },
        ],
        other: {
          label: 'Please describe:',
          placeholder: 'Write here...',
          answer: '',
        },
        page: 1,
        type: 'checkbox'
      },
      {
        question_num: "",
        label: "Do you have a strong urge for sweet / bitter / salty / fatty foods?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "Can you easily skip a meal?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "Do you binge eat regularly?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "Do you drink coffee?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "Do you drink herbal tea?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "Do you often feel bloated after a meal?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "Are you allergic to specific foods?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "Did you ever follow a diet?",
        choices: [
          { title: "Yes", selected: false },
          { title: "No", selected: false }
        ],
        page: 1,
        type: "col"
      },
      {
        question_num: "",
        label: "After a meal, I suffer from",
        subQuestions: [
          {
            label: "Nausea",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Flatulence",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Diarrhea",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Blockage",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Bloated feeling",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Belching/heartburn",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Stomach ache",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Color deviations",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Blood in the stool",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Mucus in stool",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Pulpy stools",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Bad smelling stools (sour/rotten eggs)",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Greasy stools",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Bad breath or taste in the mouth",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Sores in the mouth (aphthous ulcers)",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Gastrointestinal infections/ulcers",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          },
          {
            label: "Anal itching",
            choices: [
              { title: "Not", selected: false },
              { title: "A little", selected: false },
              { title: "A lot", selected: false },
              { title: "Extremely", selected: false }
            ]
          }
        ],
        page: 1,
        type: "multi"
      }
    ]
  },
  vitaminB12Deficiency: {
    totalPage: 1,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: 1,
        label: "Neurological symptoms",
        questions: [
          {
            question_num: "1.1",
            label: "Do you suffer from irritations or numbness in the feet, hands and / or arms?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.2",
            label: "Are you diagnosed as a diabetic with peripheral neuropathy?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.3",
            label: "Do you suffer from loss of strength in arms and / or legs?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.4",
            label: "Do you ever suffer from a light feeling in the head or dizziness?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.5",
            label: "Do you tend to fall easily, or do you fall regularly?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.6",
            label: "Have you (or others) noticed that unusual changes (s) have occurred in your way of exercising?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.7",
            label: "Have you noticed that you have (more) problems with memory or thinking? (For example, an increased difficulty remembering names, difficulty remembering phone numbers, calculating change etc. Have you ever been confused or disoriented? Do you suffer from memory impairment or other symptoms of dementia?)",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.8",
            label: "Do you have trouble determining where different parts of the body are when you do not look at it? (For example, do you have difficulty walking in the dark (when you cannot see your feet)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.9",
            label: "Is your sense of touch or your experience disturbed by pain?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.10",
            label: "Has a doctor ever told you that you have a form of spasticity (lack of proper coordination of muscle contraction)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.11",
            label: "Do you suffer from tremors (vibrations)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "1.12",
            label: "Do you suffer from urinary incontinence or impotence?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 2,
        label: "Psychiatric symptoms",
        questions: [
          {
            question_num: "2.1",
            label: 'Have you noticed unusual changes in your personality, or have friends noticed that you are "not yourself", or do you think you are more annoyed than before?',
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "2.2",
            label: 'Are you unusually passive or depressed (including Postnatal depression)? Have you ever had suicidal tendencies?',
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "2.3",
            label: 'Have you ever had problems with hallucinations or delusions?',
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "2.4",
            label: 'Do you ever show violent behavior?',
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "2.5",
            label: 'Have you been diagnosed with any form of psychosis or mental illness, including schizophrenia or bipolar disorder?',
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "2.6",
            label: 'Do you think that you become more compulsive about the behavior or intentions of other people?',
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 3,
        label: "Hematological symptoms",
        questions: [
          {
            question_num: "3.1",
            label: "Has a doctor ever told you that you have abnormally small red blood cells, iron deficiency or anemia due to iron deficiency?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "3.2",
            label: "Has a doctor ever told you that you have anemia (low blood count or low hemoglobin)? Do you have few platelets or a low number of white blood cells?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "3.3",
            label: "Has a doctor ever told you that your red blood cells are abnormally large?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 4,
        label: "Gastric / intestinal / digestion risk factors",
        questions: [
          {
            question_num: "4.1",
            label: "Has it been established that you have inflammation or reduction of the gastric mucosa (atrophy)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.2",
            label: "Has it been established that you have a low stomach acid level?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.3",
            label: "Do you suffer from gastritis (gastric mucosal inflammation)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.4",
            label: "Do you suffer from ulcers in the stomach or intestine?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.5",
            label: "Have you been diagnosed with GERD (gastroesophageal reflux disease: rising from stomach acid in the esophagus)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.6",
            label: "Do you suffer from diverticulosis (presence of diverticula in the gut)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.7",
            label: "Has it been established that precancerous growth or cancer is present in the gastrointestinal tract?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.8",
            label: "Do you have a partial or total gastric resection, have you had stomach surgery because of severe obesity, or did you undergo surgery where the ileum has been removed?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.9",
            label: "Have you been diagnosed with Crohn's disease, inflammatory bowel disease such as colitis ulcerosis, IBS (irritable bowel syndrome) or celiac disease (gluten hypersensitivity)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.10",
            label: "Do you have family members who have or have had Pernicious anemia (a form of anemia)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.11",
            label: "Is there overgrowth of the intestinal flora in the small intestine (can only be determined with a stool test)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "4.12",
            label: "Has it been established that you have intestinal parasites?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 5,
        label: "General risk factors",
        questions: [
          {
            question_num: "5.1",
            label: "Are you over 60 years old?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "5.2",
            label: "Do you have a thyroid disorder? Do you have autoimmune diseases such as lupus, type 1 diabetes, rheumatoid arthritis, Graves' disease, Addison, Hypergammaglubilemia or ?- gammaglobilinaemia? (Measure your body temperature for 4 mornings with a digital thermometer under the armpit, after waking up, but before getting up. If this value is lower than 36.4, you can answer the question with 'yes'.)",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "5.3",
            label: "Have you had cancer? Did you undergo chemotherapy and / or radiation therapy?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "5.4",
            label: "Have you ever had an operation (including surgery by a dental surgeon) in which nitric oxide (nitrous oxide) has been used as a narcotic?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "5.5",
            label: "Do you ever use laughing gas as a drug?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "5.6",
            label: "Are you vegan or vegetarian, or do you mainly eat vegetarian?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "5.7",
            label: "Do you have an alcohol problem?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "5.8",
            label: "A lot of drugs cause a Vitamin B12 deficiency. Indicate which medicines you use and how long.",
            choices: [
              { title: "", selected: false }
            ]
          }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: 6,
        label: "Other signals / symptoms",
        questions: [
          {
            question_num: "6.1",
            label: "Are you suffering from fatigue, lack of energy or weakness?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.2",
            label: "Do you suffer from general weakness (exhaustion)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.3",
            label: "Have you lost a lot of weight or is there a loss of appetite?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.4",
            label: "Do you suffer from chest pain, shortness of breath with little effort?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.5",
            label: "Do you have parts of the skin that are characterized by Hypopigmentation (unusually light spots), have you been diagnosed with Vitiligo?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.6",
            label: "Are you unusually pale, or do you have a lemon-yellow skin color?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.7",
            label: "Do you have a painful inflamed or red tongue (reminiscent of raw beef meat)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.8",
            label: "Do you suffer from Tinnitus (ringing in the ears)?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.9",
            label: "Has a Pap smear ever shown that you have abnormal cells?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          },
          {
            question_num: "6.10",
            label: "Do you suffer from infertility?",
            choices: [
              { title: "Yes", selected: false },
              { title: "No", selected: false }
            ]
          }
        ],
        page: 1,
        type: "single"
      }
    ]
  },
  generalDecisionMakingStyle: {
    totalPage: 4,
    currentPage: 1,
    continueLater: null,
    questions: [
      {
        question_num: "1",
        label: "I make sure that I have all the facts before I make a decision.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: "2",
        label: "When I make a decision, I do what feels right.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: "3",
        label: "I often ask other people to help me make important decisions.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: "4",
        label: "I don't like making decisions, so I try to avoid it.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: "5",
        label: "I make decisions quickly.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: "6",
        label: "I make decisions in a slow, logical way.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 1,
        type: "single"
      },
      {
        question_num: "7",
        label: "When I make a decision, I rely on my instincts.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 2,
        type: "single"
      },
      {
        question_num: "8",
        label: "I don't make big decisions without talking to other people first.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 2,
        type: "single"
      },
      {
        question_num: "9",
        label: "I usually won't make an important decision until I'm forced to do so.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 2,
        type: "single"
      },
      {
        question_num: "10",
        label: "I don't think too much about the decisions that I make.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 2,
        type: "single"
      },
      {
        question_num: "11",
        label: "Making decisions requires careful thought.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 2,
        type: "single"
      },
      {
        question_num: "12",
        label: "A decision doesn't need to make sense - it just needs to feel right.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 2,
        type: "single"
      },
      {
        question_num: "13",
        label: "When I need to make an important decision, I like to have someone point me in the right direction.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 3,
        type: "single"
      },
      {
        question_num: "14",
        label: "I try to put off making important decisions because thinking about them makes me feel uneasy.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 3,
        type: "single"
      },
      {
        question_num: "15",
        label: "When I need to make an important decision, I just do what seems natural at the moment.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 3,
        type: "single"
      },
      {
        question_num: "16",
        label: "I consider all of my options before making a decision.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 3,
        type: "single"
      },
      {
        question_num: "17",
        label: "I rely on my inner feelings when making decisions.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 3,
        type: "single"
      },
      {
        question_num: "18",
        label: "When I make a decision, I rely on other people's advice.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 3,
        type: "single"
      },
      {
        question_num: "19",
        label: "I usually make important decisions at the last minute.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 4,
        type: "single"
      },
      {
        question_num: "20",
        label: "I often make impulsive decisions.",
        choices: [
          { title: "False", selected: false },
          { title: "Sometimes", selected: false },
          { title: "Yes", selected: false }
        ],
        page: 4,
        type: "single"
      }
    ]
  }
};

export const analysesDataInitialState = {
  allHma: []
};

export const professionalLogInitialState: ProfessionalLogState = {
  isOpen: false,
  currentTab: 'log',
  minimized: true,
  isEditLog: false,
  isEditTimeline: false,
  selectedClient: null,
  logs: {
    clientId: '',
    title: '',
    content: '',
    clientSummaryAndActions: '',
    mainHealthProblemVasScore: null,
    generalWellBeingVasScore: null,
    energyLevelVasScore: null,
    attachments: null
  },
  timeline: {
    id: '',
    clientId: '',
    age: 0,
    day: 0,
    year: 0,
    month: 0,
    content: ''
  }
}