import { Card } from 'antd'
import { AnalysesData, AnalysesDataContainer, CardTitle } from './style'
import { analysesLists } from '../../constants/analyses'

const Analyses = () => {
  return (
    <Card>
      <CardTitle>
        <p>Analyses</p>
      </CardTitle>
      <AnalysesDataContainer>
        {analysesLists.map((analyses) => (
          <AnalysesData>
            <p>{analyses.title}</p>
            <small>{analyses.status}</small>
          </AnalysesData>
        ))}
      </AnalysesDataContainer>
    </Card>
  )
}

export default Analyses