export const dashboardList = [
  {
    title: 'View all Admin Users',
    url: '/a/admins'
  },
  {
    title: 'Preview RAND-36 Questionnaire',
    url: '/a/admins'
  },
  {
    title: 'Create Professional Account',
    url: '/a/admins'
  },
  {
    title: 'List Professional Account',
    url: '/a/professionals'
  },
  {
    title: 'HIC calculation table',
    url: '/a/admins'
  },
  {
    title: 'Input HMA data',
    url: '/a/hma_imports'
  },
  {
    title: 'All HMA data',
    url: '/a/hma_imports'
  },
  {
    title: 'Input HRV data',
    url: '/a/admins'
  },
  {
    title: 'All HRV data',
    url: '/a/hrv_imports'
  },
  {
    title: 'All IR Combi data',
    url: '/a/ir_combis'
  },
  {
    title: 'All Iodine Test data',
    url: '/a/iodine_tests'
  },
  {
    title: 'All Downloadable Analysis Reports',
    url: '/a/admins'
  },
  {
    title: 'Community Sub-Groups',
    url: '/a/community_subgroups'
  }
];