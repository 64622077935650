import sc from 'styled-components';
import { theme } from '../../../styles/theme';

export const NameData = sc.span`
  font-size: .875rem;
  font-weight: 700;
  color: ${theme.color.primary};
  cursor: pointer;
`;

export const HeaderData = sc.span`
  font-size: .875rem;
  font-weight: 400;
  color: #718096;
`;

export const ModalContentWrapper = sc.div`
  padding: 1.5rem;
  padding-bottom: 0;
`;

export const StatusWrapper = sc.div`
  display: flex;
  gap: 10px;
`;