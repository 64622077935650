import React, { useMemo } from 'react'
import { Container, Footer, NewQuestionnaireModal, StyledCheckbox, StyledForm } from './style'
import MaterialIcon from '../../Icons/MaterialIcon'
import { Button } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useAssignQuestionnaireToClient, useGetClientQuestionnaires, useListQuestionnaires } from '../../../hooks/professionals/useQuestionnaires'
import { ClientQuestionnaireType, QuestionnaireType } from '../../../types'
import CustomLoading from '../../Common/Loading'

const NewQuestionnaireLists = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [form] = StyledForm.useForm();
  const { data: questionnairesList, loading: questionnairesListLoading } = useListQuestionnaires();
  const { data: clientQuestionnaires, refetch: refetchClientQuestionnaires, loading: clientQuestionnairesLoading } = useGetClientQuestionnaires(clientId as string);
  const { create: assignQuestionnaire, loading: loadingAssignQuestionnaire } = useAssignQuestionnaireToClient();
  const modifiedQuestionnairesList = useMemo(() => {
    const groupedData: { [key: string]: QuestionnaireType[] } = {};

    questionnairesList?.forEach((questionnaire: QuestionnaireType) => {
      const groupKey = questionnaire.groupI18nKey === 'body_texts.intake'
        ? 'intake'
        : 'addtlQuestionnaires';

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = [];
      }

      groupedData[groupKey].push({
        ...questionnaire,
        url: questionnaire.name.replace(/\s+/g, '').replace(/[^a-zA-Z0-9]/g, ''),
      });
    });

    return groupedData;
  }, [questionnairesList]);
  const existingQuestionnaireIds = useMemo(() => {
    return new Set(clientQuestionnaires.map((cq: ClientQuestionnaireType) => cq.questionnaireId));
  }, [clientQuestionnaires]);

  const onNavigate = (url: string, payload?: any) => {
    navigate(url, {
      state: {
        payload
      }
    });
  };

  const onFinish = async (values: any) => {
    try {
      const res = await assignQuestionnaire({ clientId: clientId as string, ...values });
      console.log('assignQuestionnaire', res);
    } catch (err) {
      console.error('onFinishAssignQuestionnaire', err);
    } finally {
      await refetchClientQuestionnaires();
    }
  };

  if (questionnairesListLoading || clientQuestionnairesLoading) {
    return <CustomLoading />;
  }

  console.log(clientQuestionnaires);

  return (
    <StyledForm
      form={form}
      name="hma_form"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Container>
        <StyledForm.Item
          labelAlign="left"
          name="ids"
        >
          <StyledCheckbox.Group>
            <NewQuestionnaireModal>
              <p>Intake</p>
              <div className='checkbox-wrapper'>
                {modifiedQuestionnairesList?.intake?.map((intake: QuestionnaireType, index: number) => {
                  const isExisting = existingQuestionnaireIds.has(intake.id);
                  return (
                    <StyledCheckbox
                      key={`${intake.title}_${index}`}
                      value={intake.id}
                      checked={isExisting}
                      disabled={isExisting}
                    >
                      <span>{intake.title}</span>
                      <span onClick={(e) => {
                        e.stopPropagation();
                        onNavigate(`/p/random/questionnaire_preview/${intake.url}`);
                      }}>
                        <MaterialIcon icon='open_in_new' />
                      </span>
                    </StyledCheckbox>
                  )
                })}
              </div>
              <p>Additional questionnaires</p>
              <div className='checkbox-wrapper'>
                {modifiedQuestionnairesList?.addtlQuestionnaires?.map((addtlQuestionnaire: QuestionnaireType, index: number) => {
                  const isExisting = existingQuestionnaireIds.has(addtlQuestionnaire.id);
                  return (
                    <StyledCheckbox
                      key={`${addtlQuestionnaire.title}_${index}`}
                      value={addtlQuestionnaire.id}
                      checked={isExisting}
                      disabled={isExisting}
                    >
                      <span>{addtlQuestionnaire.title}</span>
                      <span onClick={(e) => {
                        e.stopPropagation();
                        onNavigate(`/p/random/questionnaire_preview/${addtlQuestionnaire.url}`);
                      }}>
                        <MaterialIcon icon='open_in_new' />
                      </span>
                    </StyledCheckbox>
                  )
                })}
              </div>
            </NewQuestionnaireModal>
          </StyledCheckbox.Group>
        </StyledForm.Item>
        <Footer>
        </Footer>
        <StyledForm.Item>
          <Button type="primary" htmlType="submit" loading={loadingAssignQuestionnaire}>
            Save
          </Button>
        </StyledForm.Item>
      </Container>
    </StyledForm >
  )
}

export default NewQuestionnaireLists