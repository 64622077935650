import { configureStore } from '@reduxjs/toolkit';
import { authSlice, userSlice } from '../slices';
import { professionalSlice } from '../slices/professionalSlice';
import { timeZoneSlice } from '../slices/timezoneSlice';
import { countriesSlice } from '../slices/countriesSlice';
import { questionnairesSlice } from '../slices/questionnaireSlice';
import { professionalLogSlice } from '../slices/professionalLogSlice';

const store = configureStore({
  reducer: {
    timezone: timeZoneSlice.reducer,
    user: userSlice.reducer,
    auth: authSlice.reducer,
    professionals: professionalSlice.reducer,
    countries: countriesSlice.reducer,
    questionnaires: questionnairesSlice.reducer,
    professionalLog: professionalLogSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;