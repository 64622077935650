import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import sc from 'styled-components';
import { theme } from '../../../styles/theme';

const { Sider } = Layout;

export const NavText = sc(Link)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: .875rem;
  padding-left: 0;
`;

export const StyledSider = sc(Sider)`
  @media (max-width: 770px) {
    display: none;
  }
`;

export const SignOutBtn = sc.button`
  text-transform: uppercase;
  font-size: .875rem;
  padding-left: 0;
  background: transparent;
  border: 0;
  color: white;
  cursor: pointer;

  &:hover {
    color: ${theme.color.primary};
  }
`;

export const ModalContentWrapper = sc.div`
  padding: 1.5rem;
  padding-bottom: 0;
`;