import { Button, Card, Form } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { UserContainerProps } from './type';

export const GoToClientButton = styled(Button)`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.color.darkGray};
  border-radius: 50rem;
  padding: .25rem .75rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);

  .ant-btn-icon {
    margin-inline-end: 3px !important;
  }

  span {
    color: ${theme.color.darkGray};
    i {
      font-size: 18px;
    }
  }

  &:hover {
    border: 1px solid ${theme.color.black} !important;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  display: flex;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
  .ant-card-body {
    padding: 0 !important;
    display: flex;
    width: 100%;
  }
`;

export const MessagesList = styled.div`
  width: 360px;
  background-color: #f7fafc;
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: .5rem;
  height: 100%;
  border-bottom: 1px solid #e2e8f0;
`;

export const CreateMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: .25rem;

  > button {
    font-size: 1.5rem;
    padding: .5rem;
    color: #319795;
  }
`;

export const UserContainer = styled.div<UserContainerProps>`
  position: relative;
  padding: .75rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.userSelected ? '#bee3f8' : 'transparent'};

  > .pin {
    position: absolute;
    top: 0;
    right: 0;
    color: ${theme.bg.primary};
    font-size: 16px;
    margin: 2px 3px;

    &:hover {
      background: ${theme.bg.primary} !important;
      color: white !important;
    }
  }

  > .userinfo {
    padding-left: .5rem;
    color: #718096;
    .name {
      white-space: nowrap;
      color: black;
    }
    .message
      white-space: nowrap;
    }
  }
`;

export const ConversationContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: #fff;
  > .header {
    height: 110px;
    background-color: #fff;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;

    .clientname {
      padding-left: .5rem;
      font-size: 25px;
      color: #6a6a6a;
      font-weight: 400;
    }
  }
`;

export const InmailWrapper = styled.div`
  padding: .75rem;
  border-bottom: 1px solid #f0f0f0;
`;

export const RightChat = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row-reverse;
  margin-left: 31px;
  padding-right: 0;
  padding: .5rem;
  gap: 10px;

  > .message {
    border-color: #eeeefc;
    color: #000;

    > .content {
      border: 1px solid #f0f0f0;
      background-color: #f8f8fb;
      word-break: break-word;
      border-radius: 5px;
      padding: 2px 10px;
      margin: 0;

      > .title {
        font-size: 1.125rem;
        line-height: 2;
      }
    }

    > .datetime {
      font-size: 0.6rem;
      color: #718096;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const LeftChat = styled.div`
  padding-right: 55px;
  display: flex;
  padding: .25rem;
  gap: 10px;

  > .message {
    border-color: #eeeefc;
    color: #000;

    > .content {
      border: 1px solid #f0f0f0;
      background-color: #f8f8fb;
      word-break: break-word;
      border-radius: 5px;
      padding: 2px 10px;
      margin: 0;

      > .title {
        font-size: 1.125rem;
        line-height: 2;
      }
    }

    > .datetime {
      font-size: 0.6rem;
      color: #718096;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const InputMessageWrapper = styled.div`
  padding: 1.25rem;
`;

export const InputMessageSubWrapper = styled.div`
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  margin: .75rem;
  border-radius: .25rem;
  padding: 1.25rem;
`;

export const StyledForm = styled(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;