import sc from 'styled-components';
import { theme } from '../../../styles/theme';

export const AnalysesTitle = sc.div`
  p {
    font-size: 18px;
  }
`;

export const ItemContainer = sc.div`
  display: flex;
  flex-direction: column;
  gap: 11px;

  .ant-divider {
    margin-top: 20px;
    // margin-bottom: 20px;
  }
`;

export const ItemTitle = sc.p`
  font-weight: 700;
  font-size: 16px;

  span {
    font-weight: 400;
  }
`;

export const IconsContainer = sc.div`
  display: flex;
  gap: 10px;
  i {
    font-size: 20px;
    color: ${theme.color.primary};
  }
`;