import React from 'react'
import { useParams } from 'react-router-dom';
import CommonLayout from '../../../layouts/Common';
import { StyledCard } from './style';
import { useGetTerm } from '../../../hooks/common/useTerms';
import { Spin } from 'antd';

const LibraryOfTerms = () => {
  const { slug } = useParams();
  const { data, loading } = useGetTerm(slug as string, 'en');
  return (
    <CommonLayout title="Library of Terms" width="900px" style={{ padding: '0 2rem' }}>
      {loading ? <Spin /> :
        (
          <StyledCard>
            <h2>{data?.name}</h2>
            <div dangerouslySetInnerHTML={{ __html: data?.body }} />
          </StyledCard>

        )}
    </CommonLayout>
  )
}

export default LibraryOfTerms;