import React from 'react'

type Props = {
  icon: string
  style?: React.CSSProperties
  className?: string
}

const MaterialIcon: React.FC<Props> = ({
  icon,
  style,
  className,
  ...props
}) => {
  return (
    <i
      className={className ?? "material-icons-outlined"}
      style={{ fontSize: 'inherit', ...style }}
      {...props}
    >
      {icon}
    </i>
  )
}

export default MaterialIcon