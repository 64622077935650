import React from 'react'
import { StyledForm } from './style'
import { _FORM_ITEM_LAYOUT, _STRESS_STATE_OPTIONS } from '../../../../../constants';
import { Divider, Form, Input, InputNumber, Select, TimePicker } from 'antd';

interface Props {
  formNum: number;
}

const { Option } = Select;

const DayForm: React.FC<Props> = ({ formNum }) => {
// const [form] = StyledForm.useForm();
// const onFinish = (values: any) => {
//   console.log('Received values of form: ', values);
// };

  return (
    <>
      <Form.Item
        labelAlign="left"
        label="Measurement start time:"
        name={[`form${formNum}`, 'measurement_start_time']}
        rules={[
          {
            required: true,
            message: 'Measurement start time can\'t be blank',
          },
        ]}>
        <TimePicker format="hh:mm" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Duration:"
        name={[`form${formNum}`, 'duration']}
        rules={[
          {
            required: true,
            message: 'Duration can\'t be blank',
          },
        ]}>
        <TimePicker format="hh:mm" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Heart rate:"
      >
        <div>
          <Form.Item
            labelAlign="left"
            label="Low:"
            name={[`form${formNum}`, 'heart_rate_low']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Avg:"
            name={[`form${formNum}`, 'heart_rate_avg']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="High:"
            name={[`form${formNum}`, 'heart_rate_high']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Stress and recovery balance:"
        name={[`form${formNum}`, 'stress_and_recovery_balance']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="%" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Amount of stress reactions:"
        name={[`form${formNum}`, 'amount_of_stress_reactions']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="%" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Amount of recovery:"
        name={[`form${formNum}`, 'amount_of_recovery']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="%" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Recovery during daytime:"
        name={[`form${formNum}`, 'recovery_during_daytime']}
        rules={[
          {
            required: true,
            message: 'Recovery during daytime can\'t be blank',
          },
        ]}>
        <TimePicker format="hh:mm" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Restorative effect of sleep:"
        name={[`form${formNum}`, 'restorative_effect_of_sleep']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="%" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Length of sleep:"
        name={[`form${formNum}`, 'length_of_sleep']}
        rules={[
          {
            required: true,
            message: 'Recovery during daytime can\'t be blank',
          },
        ]}>
        <TimePicker format="hh:mm" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Amount of recovered sleep:"
        name={[`form${formNum}`, 'amount_of_recovered_sleep']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="%" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Quality of recovery (HRV):"
        name={[`form${formNum}`, 'quality_of_recovery']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="(MS)" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Self-reported sleep quality"
        name={[`form${formNum}`, 'self_reported_sleep_quality']}
        hasFeedback
        rules={[{ required: true, message: 'Please select a self-reported sleep quality!' }]}
      >
        <Select placeholder="Please select">
          <Option value="well">Well</Option>
          <Option value="fairly_well">Fairly Well</Option>
          <Option value="not_well_not_badly">Not Well Not Badly</Option>
          <Option value="fairly_badly">Fairly Badly</Option>
          <Option value="badly">Badly</Option>
        </Select>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Health effects of physical activity:"
        name={[`form${formNum}`, 'health_effects_of_physical_activity']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="%" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Duration of physical activity:"
      >
        <div>
          <Form.Item
            labelAlign="left"
            label="Light:"
            name={[`form${formNum}`, 'duration_of_physical_activity_light_time']}
          >
            <TimePicker format="hh:mm" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Moderate:"
            name={[`form${formNum}`, 'duration_of_physical_activity_moderate_time']}
          >
            <TimePicker format="hh:mm" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Vigorous:"
            name={[`form${formNum}`, 'duration_of_physical_activity_vigorous_time']}
          >
            <TimePicker format="hh:mm" style={{ width: '100%' }} />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Total expenditure:"
        name={[`form${formNum}`, 'total_expenditure']}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Expenditure by intensity:"
      >
        <div>
          <Form.Item
            labelAlign="left"
            label="Vigorous & moderate:"
            name={[`form${formNum}`, 'expenditure_by_intensity_vigorous_and_moderate']}
          >
            <InputNumber style={{ width: '100%' }} addonAfter="(KCAL)" />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Light:"
            name={[`form${formNum}`, 'expenditure_by_intensity_light']}
          >
            <InputNumber style={{ width: '100%' }} addonAfter="(KCAL)" />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Other:"
            name={[`form${formNum}`, 'expenditure_by_intensity_other']}
          >
            <InputNumber style={{ width: '100%' }} addonAfter="(KCAL)" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Number of steps:"
        name={[`form${formNum}`, 'number_of_steps']}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Lifestyle assessment score:"
        name={[`form${formNum}`, 'lifestyle_assessment_score']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="%" />
      </Form.Item>
      <Divider />
      <small>Stress state classification</small>
      <Form.Item
        labelAlign="left"
        label="Alcohol:"
        name={[`form${formNum}`, 'alcohol']}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Medication:"
        name={[`form${formNum}`, 'medication']}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name={[`form${formNum}`, 'stress_state']}
        labelAlign="left"
        label="Stress state"
        hasFeedback
        rules={[{ required: true, message: 'Please select a self-reported sleep quality!' }]}
      >
        <Select placeholder="Please select">
          {_STRESS_STATE_OPTIONS.map((stress_state) => (
            <Option key={stress_state.value} value={stress_state.value}>{stress_state.label}</Option>
          ))}
        </Select>
      </Form.Item>
      <Divider />
      <small>Heart Rate Variablity</small>
      <Form.Item
        labelAlign="left"
        label="Average RMSSD:"
      >
        <div>
          <Form.Item
            labelAlign="left"
            label="During awake time (ms):"
            name={[`form${formNum}`, 'average_rmssd_during_awake_time']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="During sleep time (ms):"
            name={[`form${formNum}`, 'average_rmssd_during_sleep_time']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Relative difference:"
            name={[`form${formNum}`, 'average_rmssd_relative_difference']}
          >
            <InputNumber style={{ width: '100%' }} disabled />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Age based minimal score (ms):"
            name={[`form${formNum}`, 'average_rmssd_age_based_minimal_score']}
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Av RMSSD night / Age based minimum:"
            name={[`form${formNum}`, 'average_rmssd_age_based_minimum']}
          >
            <Input />
          </Form.Item>
        </div>
      </Form.Item>
      <Divider />
      <small>Training effect report</small>
      <Form.Item
        labelAlign="left"
        label="Number of reported training events:"
        name={[`form${formNum}`, 'number_of_reported_training_events']}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Measurement start time of event:"
        name={[`form${formNum}`, 'measurement_start_time_of_event']}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Duration of event:"
        name={[`form${formNum}`, 'duration_of_event']}
        rules={[
          {
            required: true,
            message: 'Duration of event can\'t be blank',
          },
        ]}>
        <TimePicker format="hh:mm" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Heart rate for report:"
      >
        <div>
          <Form.Item
            labelAlign="left"
            label="Low:"
            name={[`form${formNum}`, 'heart_rate_for_report_low']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Avg:"
            name={[`form${formNum}`, 'heart_rate_for_report_avg']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="High:"
            name={[`form${formNum}`, 'heart_rate_for_report_high']}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </div>
      </Form.Item>
      <Divider />
      <small>Training effect event</small>
      <Form.Item
        labelAlign="left"
        label="Type of recovery:"
        name={[`form${formNum}`, 'type_of_recovery']}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="EPOC:"
        name={[`form${formNum}`, 'epoc']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="(ml/kg)" />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Energy expenditure:"
        name={[`form${formNum}`, 'energy_expenditure']}
      >
        <InputNumber style={{ width: '100%' }} addonAfter="(Kcal)" />
      </Form.Item>
    </>
  )
};

export default DayForm;
