import { Avatar, Col, Row, Tabs, TabsProps } from 'antd';
import ProfessionalSummary from '../../../components/Professional/Summary';
import MobileAnalyses from '../../../components/Analyses/Mobile';
import ProfessionalQuestionnaire from '../../../components/Questionnaire/Mobile';
import { ClientCardsContainer, MobileContainer, MobileProfessionalDetailsContainer } from './style';
import ClientsTable from '../../../components/Professional/ClientsTable';
// import Invitations from '../../../components/Professional/Invitations';
import { useEffect } from 'react';
// import useGetAllActionPlanStatuses from '../../../hooks/professionals/useGetAllActionPlanStatuses';
import { useGetAllActionPlanStatuses } from '../../../hooks/professionals/useActionPlanStatuses';

const Dashboard = () => {
  const { fetchData: fetchActionPlanStatuses } = useGetAllActionPlanStatuses();
  const tabItemStyle = {
    padding: 20,
    height: 500,
    overflow: 'auto',
    paddingBottom: 100
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Summary',
      children: <ProfessionalSummary />,
      style: tabItemStyle
    },
    {
      key: '2',
      label: 'Analyses',
      children: <MobileAnalyses />,
      style: tabItemStyle
    },
    {
      key: '3',
      label: 'Vragenlijsten',
      children: <ProfessionalQuestionnaire />,
      style: tabItemStyle
    },
  ];

  useEffect(() => {
    fetchActionPlanStatuses();
  }, [fetchActionPlanStatuses]);

  return (
    <>
      <ClientCardsContainer>
        <Row gutter={10}>
          <Col span={24}>
            <ClientsTable />
          </Col>
          {/* <Col>
            <Invitations />
          </Col> */}
        </Row>
      </ClientCardsContainer>
      <MobileContainer>
        <MobileProfessionalDetailsContainer>
          <Avatar
            size={{ xs: 146, sm: 146, md: 146, lg: 0, xl: 0, xxl: 0 }}
          />
          <h2>Hello Melvin</h2>
        </MobileProfessionalDetailsContainer>
        <Tabs
          tabBarStyle={{
            boxShadow: '1px 1px 1px 0px #0000001A'
          }}
          tabBarGutter={50}
          defaultActiveKey="1"
          items={items}
          centered={true}
        />
      </MobileContainer>
    </>
  )
}

export default Dashboard;