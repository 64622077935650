import { useQuery } from '@apollo/client';
import { GET_TERM, LIST_TERMS } from '../../services/graphql/queries/common';

export default function useTerms() {
  const { data, loading, refetch } = useQuery(LIST_TERMS, {
    variables: {
      language: 'en'
    }
  });

  return {
    data: data?.listTerms,
    loading,
    refetch
  };
}

export const useGetTerm = (slug: string, language: 'nl' | 'en') => {
  const { data, loading, refetch } = useQuery(GET_TERM, {
    variables: {
      slug,
      language: language ?? 'en'
    }
  });

  return {
    data: data?.findTerm,
    loading,
    refetch
  };
}
