import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }

  small {
    font-size: .875rem;
    margin-bottom: .25rem;
  }
`;