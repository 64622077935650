import { Button, Input } from 'antd';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../constants';
import { StyledForm } from './style';

const { TextArea } = Input;

const MedicationsForm = () => {
  const [form] = StyledForm.useForm();
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };
  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="allergies_and_others"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>Do you take medicines?</p>
      <small>Type of medicine, name and amount per day:</small>
      <StyledForm.Item name="other_medication">
        <TextArea rows={4} />
      </StyledForm.Item>
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  )
}

export default MedicationsForm;