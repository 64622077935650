import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { PROFESSIONAL_CREATE_LOG, PROFESSIONAL_REMOVE_LOG, PROFESSIONAL_UPDATE_LOG } from '../../services/graphql/mutations/professional';

export const useCreateProfessionalLog = () => {
  const [createProfessionalLog, { loading }] = useMutation(PROFESSIONAL_CREATE_LOG);

  const createData = useCallback(async (values: any) => {
    try {
      const { data } = await createProfessionalLog({
        variables: {
          input: {
            params: {
              content: values.content,
              clientSummaryAndActions: values.clientSummaryAndActions,
              mainHealthProblemVasScore: values.mainHealthProblemVasScore,
              generalWellBeingVasScore: values.generalWellBeingVasScore,
              energyLevelVasScore: values.energyLevelVasScore,
              clientId: values.clientId,
              title: values.title,
              attachments: values.attachments
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in createProfessionalLog: ', error);
    }
  }, [createProfessionalLog]);

  return {
    create: createData,
    loading
  };
};

export const useUpdateProfessionalLog = () => {
  const [updateProfessionalLog, { loading }] = useMutation(PROFESSIONAL_UPDATE_LOG);

  const updateData = useCallback(async (values: any) => {
    try {
      const { data } = await updateProfessionalLog({
        variables: {
          input: {
            params: {
              id: values.id,
              content: values.content,
              clientSummaryAndActions: values.clientSummaryAndActions,
              mainHealthProblemVasScore: values.mainHealthProblemVasScore,
              generalWellBeingVasScore: values.generalWellBeingVasScore,
              energyLevelVasScore: values.energyLevelVasScore,
              clientId: values.clientId,
              title: values.title,
              attachments: values.attachments
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in updateProfessionalLog: ', error);
    }
  }, [updateProfessionalLog]);

  return {
    update: updateData,
    loading
  };
};

export const useRemoveProfessionalLog = () => {
  const [removeProfessionalLog, { loading }] = useMutation(PROFESSIONAL_REMOVE_LOG);

  const removeData = useCallback(async (id: string) => {
    try {
      const { data } = await removeProfessionalLog({
        variables: {
          input: {
            id
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in removeProfessionalLog: ', error);
    }
  }, [removeProfessionalLog]);

  return {
    remove: removeData,
    loading
  };
};