import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROFESSIONAL_LIST_CLIENTS } from '../../services/graphql/queries/professional';
import { useDispatch } from 'react-redux';
import { professionalActions } from '../../redux/slices/professionalSlice';

export default function useGetProfessionalListClients(page?: number, perPage?: number, filter?: { name: string, status: string }) {
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(GET_PROFESSIONAL_LIST_CLIENTS, {
    variables: {
      page,
      perPage,
      filter
    }
  });

  if (data) {
    dispatch(
      professionalActions.setProfessionalListClients(data?.professionalListClients?.data)
    );
  }

  const fetchProfessionalListClients = useCallback(() => {
    if (data) {
      dispatch(
        professionalActions.setProfessionalListClients(data?.professionalListClients?.data)
      );
    }
  }, [data, dispatch]);

  return {
    data: data?.professionalListClients?.data,
    fetchData: fetchProfessionalListClients,
    page: data?.professionalListClients?.page,
    perPage: data?.professionalListClients?.perPage,
    total: data?.professionalListClients?.total,
    loading,
    refetch
  };
}
