import { useNavigate } from 'react-router-dom';
import { dashboardList } from '../../../constants/admin_dasboard';
import { CardContainer, DashboardContainer, MainContainer } from './style';

const AdminDashboard = () => {
  const navigate = useNavigate();

  const onVisitUrl = (url: string | undefined) => {
    navigate(url ?? '');
  };

  return (
    <MainContainer>
      <h2>Dashboard</h2>
      <DashboardContainer>
        {dashboardList.map((list) => (
          <CardContainer bordered={false} onClick={() => onVisitUrl(list?.url)}>
            <p>{list.title}</p>
          </CardContainer>
        ))}
      </DashboardContainer>
    </MainContainer>
  )

}

export default AdminDashboard