import { additionalQuestionnaires, intakeData } from '../../../constants/questionnaire';
import QuestionnaireItem from './QuestionnaireItem';
import { QuestionnaireContainer, StyledCollapse } from './style';

const Questionnaire = () => {
  return (
    <QuestionnaireContainer>
      <StyledCollapse
        expandIconPosition="end"
        size="small"
        ghost
        items={[
          {
            key: '1',
            label: 'Intake',
            children: intakeData.map((data, index) => (
              <QuestionnaireItem
                key={index}
                title={data.title}
                date={data.date}
                isDone={data.isDone}
              />
            ))
          }
        ]}
      />
      <StyledCollapse
        expandIconPosition="end"
        size="small"
        ghost
        items={[
          {
            key: '1',
            label: 'Aanvullende vragenlijsten',
            children: additionalQuestionnaires.map((data, index) => (
              <QuestionnaireItem
                key={index}
                title={data.title}
                date={data.date}
                isDone={data.isDone}
              />
            ))
          }
        ]}
      />
    </QuestionnaireContainer>
  )
}

export default Questionnaire;