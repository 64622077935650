import { MouseEvent, useState } from 'react'
import CommonLayout from '../../../layouts/Common'
import { ModalContentWrapper, MyInfoWrapper, StyledButton, StyledCard, StyledCollapse } from './style'
import BioInfo from '../../../components/Forms/Bio/Info'
import ContactDetailsInfo from '../../../components/Forms/ContactDetails/Info'
import CommonInfo from '../../../components/Forms/Common/Info'
import { allergiesAndOthers, bloodlineDiseases, medications, specialists, surgeries, vaccinations } from '../../../constants/dummy_data/client'
import ImportantNotesInfo from '../../../components/Forms/ImportantNotes/Info'
import { Modal } from 'antd'
import BioForm from '../../../components/Forms/Bio'
import ContactDetailsForm from '../../../components/Forms/ContactDetails'
import AllergiesAndOthersForm from '../../../components/Forms/AllergiesAndOthers'
import SpecialistsForm from '../../../components/Forms/Specialists'
import MedicationsForm from '../../../components/Forms/Medications'
import VaccinationsForm from '../../../components/Forms/Vaccinations'
import BloodlineDiseasesForm from '../../../components/Forms/BloodlineDiseases'
import SurgeriesForm from '../../../components/Forms/Surgeries'
import ImportantNotesForm from '../../../components/Forms/ImportantNotes'

const MyInfo = () => {
  const [modalContent, setModalContent] = useState({
    isOpen: false,
    component: <></>
  });
  const onEdit = (e: MouseEvent<HTMLButtonElement>, formTitle: string) => {
    e.stopPropagation();
    let modalContent = {
      component: <></>
    };
    switch (formTitle) {
      case 'bio':
        modalContent.component = <BioForm />;
        break;
      case 'contact_details':
        modalContent.component = <ContactDetailsForm />;
        break;
      case 'allergies_and_others':
        modalContent.component = <AllergiesAndOthersForm />;
        break;
      case 'specialists':
        modalContent.component = <SpecialistsForm />;
        break;
      case 'medications':
        modalContent.component = <MedicationsForm />;
        break;
      case 'vaccinations':
        modalContent.component = <VaccinationsForm />;
        break;
      case 'bloodline_diseases':
        modalContent.component = <BloodlineDiseasesForm />;
        break;
      case 'surgeries':
        modalContent.component = <SurgeriesForm />;
        break;
      case 'important_notes':
        modalContent.component = <ImportantNotesForm />;
        break;
      default:
    };
    setModalContent({
      isOpen: true,
      ...modalContent
    });
  };

  const handleBack = () => {
    setModalContent({
      isOpen: false,
      component: <></>
    });
  }

  return (
    <CommonLayout title="General client info" width="800px">
      <MyInfoWrapper>
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Bio',
            children: <BioInfo />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'bio')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCard>
          <p>Email</p>
          <span>andredemavivas+3@gmail.com</span>
        </StyledCard>
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Contact Details',
            children: <ContactDetailsInfo />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'contact_details')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Allergies and others',
            children: <CommonInfo items={allergiesAndOthers} />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'allergies_and_others')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Specialists',
            children: <CommonInfo items={specialists} />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'specialists')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Medications',
            children: <CommonInfo items={medications} />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'medications')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Vaccinations',
            children: <CommonInfo items={vaccinations} />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'vaccinations')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Bloodline Diseases',
            children: <CommonInfo items={bloodlineDiseases} />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'bloodline_diseases')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Surgeries',
            children: <CommonInfo items={surgeries} />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'surgeries')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
        <StyledCollapse
          ghost
          items={[{
            key: '1',
            label: 'Important notes',
            children: <ImportantNotesInfo />,
            extra: <StyledButton
              onClick={(e: MouseEvent<HTMLButtonElement>) => onEdit(e, 'important_notes')}
              shape="circle"
              type="primary"
              size="small"
              icon={<i className="material-icons-round" style={{ fontSize: '16px' }}>edit</i>}
            />
          }]}
        />
      </MyInfoWrapper>
      <Modal
        title="Edit General client info"
        open={modalContent.isOpen}
        onOk={handleBack}
        onCancel={handleBack}
        footer={null}
        width={900}
      >
        <ModalContentWrapper>
          {modalContent.component}
        </ModalContentWrapper>
      </Modal>
    </CommonLayout>
  )
}

export default MyInfo