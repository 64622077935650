import React from 'react'
import { StyledEmpty } from './style'

interface NoDataProps {
  description?: string
}

const NoData: React.FC<NoDataProps> = ({ description }) => {
  return (
    <StyledEmpty
      description={description ?? ''}
    />
  )
}

export default NoData