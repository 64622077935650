import { Collapse } from 'antd';
import sc from 'styled-components';

export const QuestionnaireContainer = sc.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledCollapse = sc(Collapse)`
  box-shadow: 1px 1px 1px 0px #0000001A;
  font-size: 18px;
  background: white;
`;