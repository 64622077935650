import { Card } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  > h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

   > p {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
   }

   .summary {
      display: flex;
      justify-content: center;

      > div {
        border: 2px solid #edf2f7;
        padding: 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;

        p {
          font-weight: 700;
          padding: 0;
          color: rgb(0, 102, 210);
          font-size: 1rem;
        }
      }
   }
`;

export const BulletChartWrapper = styled.div`
   height: 540px;
`;

export const StyledCard = styled(Card)`
  width: 100%;
`;

export const HRVHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 2rem;

  .hrv-details {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      gap: 10px;

      :nth-child(1) {
        width: 8rem;
      }

      :nth-child(2) {
        font-weight: 700;
      }
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  > div {
    border: 2px solid #edf2f7;
    margin-bottom: 2rem;
    text-align: center;
    padding: 1rem;
    .chart-title {
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
      > p {
        font-size: 1.25rem;
        border-bottom: 2px dashed #bbb;

        &:hover {
          border-bottom: 2px solid #ffd900;
        }

        span {
          color: rgb(0, 102, 210);
          font-weight: 700;
        }
      }
    }

    > div {
      text-align: left;
      > p {
        color: #718096;
        span {
          color: black;
          font-weight: 700;
        }
      }
    }
  }
`;