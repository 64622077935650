import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { Tabs, Upload } from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  border-radius: .5rem .5rem 0 0;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  padding: .5rem .75rem;
  position: fixed;
  bottom: 0;
  right: 30px;
  background-color: ${theme.bg.darkTeal};
  cursor: pointer;

  > div span {
    color: white;
    white-space: nowrap;
  }

  > .action-btns {
    display: flex;
  }
`;

export const MaximizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 620px;
  border-radius: .5rem .5rem 0 0;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  position: fixed;
  bottom: 0;
  right: 30px;
  background-color: white;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.bg.darkTeal};
  border-radius: .5rem .5rem 0 0;
  padding: .5rem .75rem;
  width: 100%;
  > div span {
    color: white;
  }
  > .action-btns {
    display: flex;
  }
`;

export const StyledTabs = styled(Tabs)`
  height: 100%;

  .ant-tabs-content-holder {
    height: inherit;

    .ant-tabs-content,
    .ant-tabs-content > div {
      height: 100%;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 4px;
    .ant-tabs-nav-wrap .ant-tabs-nav-list {
      width: 100%;
      > .ant-tabs-tab {
        width: 100%;
        > .ant-tabs-tab-btn {
          font-size: 12px;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
        }
      }
    }
  }
`;

export const LogBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UsernameWrapper = styled.div`
  padding: .25rem .5rem;
  border-bottom-width: 1px;
  border-bottom-color: #e2e8f0;
  border-bottom-style: solid;

  > p {
    font-size: 14px;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: .5rem;
  height: 460px;
`;

export const LogQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: .5rem 0;

  > p {
    font-weight: 700;
    color: ${theme.color.lightTeal};
    text-transform: uppercase;
  }

  > .label-wrapper {
    display: flex;
    justify-content: space-between;
    > label {
      color: #718096;
      margin-top: .25rem;
    }
  }
`;

export const RadiosContainer = styled.div`
  display: flex;
  width: 100%;
  padding: .75rem 0;
  > .radio-wrapper {
   flex: 1;
   display: flex;
   flex-direction: column;
   gap: .5rem;

    > div {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #e2e8f0;
      text-align: center;
      padding-bottom: .25rem;
    }
  }
`;

export const LogFooterContainer = styled.div`
  padding: .5rem .5rem 0 .5rem;
  display: flex;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #e2e8f0;

  > div {
    display: flex;
    align-items: center;
    > button {
      margin: 0 .5rem;
    }
  }

  > .delete-wrapper {

  }
`;

export const LogInputsContainer = styled.div`
  overflow: auto;
  > div {
    height: fit-content;
    > .header {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      background: rgb(251, 251, 251);
      text-align: center;
      font-weight: bold;
      color: #718096;
      padding: 1px 0;
      border-top: 1px solid #e2e8f0;
      border-bottom: 1px solid #e2e8f0;
      margin-bottom: .5rem;

      > i {
        margin-top: 3px;
      }
    }

    > .input-text {
      height: inherit;
    }
  }
`;

export const StyledFormUpload = styled(Upload.Dragger)`
  .ant-upload {
    width: 100% !important;
    min-height: 200px;
  }
`;

export const NoGeneralClientInfoWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  > div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    color: #718096;
    font-size: 1rem;
    gap: 5px;
  }
`;

export const TimelineWrapper = styled.div`
  > .date-container {
    > span {
      font-weight: bold;
      color: #718096;
    }
    display: flex;
    margin-bottom: 1rem;
    gap: 5px;
    > div {
      display: flex;
      gap: 5px;
      flex: 1;
      > span {
        width: fit-content;
      }
      > .ant-select {
        flex: 1;
      }

      > .ant-picker {
        width: 100%;
        height: 26px;
      }
    }
  }
`;

export const TimelineBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .5rem;
  flex: 1;
`;

export const TimelineFooterContainer = styled.div`
  margin-top: 1rem;
  padding: .5rem .5rem 0 .5rem;
  display: flex;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #e2e8f0;
`;

export const TimeLineEventBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`;