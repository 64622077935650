import React from 'react'
import { BioInfoContainer, FormInfoWrapper } from './style'
import { clientInfo } from '../../../../constants/dummy_data/client'

type Props = {}

const BioInfo = (props: Props) => {
  return (
    <BioInfoContainer>
      {clientInfo.map((info, index) => (
        <FormInfoWrapper key={index}>
          <div>{info.label}</div>
          <div>{info.value}</div>
        </FormInfoWrapper>
      ))}
    </BioInfoContainer>
  )
}

export default BioInfo