import React from 'react'
import { Card } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ResponsiveBullet } from '@nivo/bullet'
import CommonLayout from '../../../../../layouts/Common'
import Chart from 'react-apexcharts';
import { BulletChartWrapper, Container, FlexContainer, HRVHeader, StyledCard } from './style'
import { StyledButton } from '../../../../../components/Common/Button/style'
import { formatDateWithoutTime } from '../../../../../helpers'
import { _COMMON_BULLET_CHART_PROPS } from '../../../../../constants'

type Props = {}

type LegendType = "top" | "right" | "bottom" | "left" | undefined;

const HMAResult = (props: Props) => {
  const { hrvId } = useParams();
  const location = useLocation();
  const { payload } = location.state;
  const [client] = useSelector((state: any) => [state.professionals.selectedClient]);
  const navigate = useNavigate();

  const MINERALS = ['Ca', 'Mg', 'Na', 'K', 'Fe', 'Cu', 'Mn', 'Zn', 'Cr', 'Se', 'P'];

  const onNavigate = (url: string) => {
    navigate(url, {
      state: {
        payload
      }
    });
  };

  const formatTitle = (
    name: any,
    shortName: any,
    measure: any
  ) => {
    return (
      <text dy="-29">
        <tspan x="0" textAnchor="middle" style={{ fontSize: '18px' }}>{shortName}</tspan>
        <tspan x="0" textAnchor="middle" dy="1.4em" style={{ fontSize: '18px', fontWeight: 800, fill: '#0066D2' }}>{measure}</tspan>
        <tspan x="0" textAnchor="middle" dy="1.4em" style={{ fontSize: '14px' }}>{name}</tspan>
      </text>
    )
  }

  const data = [
    {
      "id": 1,
      "ranges": [
        0,
        31,
        65,
        120
      ],
      "measures": [
        2
      ],
      "markers": [
        40
      ],
      title: formatTitle('CALCIUM', 'Ca', 2)
    },
    {
      "id": 1,
      "ranges": [
        0,
        31,
        65,
        120
      ],
      "measures": [
        2
      ],
      "markers": [
        40
      ],
      title: formatTitle('TEST', 'TST', 2)
    },
    {
      "id": 1,
      "ranges": [
        0,
        31,
        65,
        120
      ],
      "measures": [
        2
      ],
      "markers": [
        40
      ],
      title: formatTitle('TEST', 'TST', 2)
    },
    {
      "id": 1,
      "ranges": [
        0,
        31,
        65,
        120
      ],
      "measures": [
        2
      ],
      "markers": [
        40
      ],
      title: formatTitle('TEST', 'TST', 2)
    },
    {
      "id": 1,
      "ranges": [
        0,
        31,
        65,
        120
      ],
      "measures": [
        2
      ],
      "markers": [
        40
      ],
      title: formatTitle('TEST', 'TST', 2)
    },
    {
      "id": 1,
      "ranges": [
        0,
        31,
        65,
        120
      ],
      "measures": [
        2
      ],
      "markers": [
        40
      ],
      title: formatTitle('TEST', 'TST', 2)
    },
  ];

  const importantMineralSeries = [44, 55, 13, 33];
  const importantMineral = {
    chart: {
      id: 'donut',
    },
    legend: {
      show: false
    },
    series: importantMineralSeries,
    labels: ['Apple', 'Mango', 'Orange', 'Watermelon'],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        customScale: 1,
        donut: {
          size: '50%'
        },
        labels: {
          show: false
        }
      },
      donut: {
        labels: {
          show: false
        }
      }
    },
  };

  return (
    <CommonLayout title="HRV Results" width="90%">
      <HRVHeader>
        <Card style={{ width: '100%' }}>
          <div className="hrv-details">
            <div>
              <p>Report number:</p>
              <p>{payload.reportNumber}</p>
            </div>
            <div>
              <p>Date of analysis:</p>
              <p>{formatDateWithoutTime(payload.dateOfAnalysis)}</p>
            </div>
            <div>
              <p>Client</p>
              <p>{`${client.firstName} ${client.familyName}`}</p>
            </div>
            <div>
              <p>Client ID</p>
              <p>{client.id}</p>
            </div>
          </div>
        </Card>
      </HRVHeader>
      <StyledCard>
        <Container>
          <p className="">Nutrients and Minerals</p>
          <BulletChartWrapper>
            <ResponsiveBullet
              data={data}
              layout="vertical"
              titleAlign="start"
              axisPosition="before"
              {..._COMMON_BULLET_CHART_PROPS}
            />
          </BulletChartWrapper>
        </Container>
        <Container style={{ marginTop: 80 }}>
          <p className="">Important mineral proportions</p>
          <FlexContainer>
            {[...Array(6)].map((val: any, index: number) => (
              <div key={index}>
                <Chart
                  width={200}
                  options={importantMineral}
                  series={importantMineralSeries}
                  type="donut"
                  hideSeries
                />
                <div className="chart-title">
                  <p>Na/K: <span>2</span></p>
                </div>
                <div>
                  <p>Ideal: <span>2.5-5</span></p>
                  <p>Optimal Ratio: <span>2.5</span></p>
                  <p>% Optimal Ratio: <span>80</span></p>
                </div>
              </div>
            ))}
          </FlexContainer>
          <div className='summary'>
            <div>
              <p>* Your body has a fast mixed oxidation rate</p>
              <p>* Temporarily fast oxidizer</p>
              <p>* Type of stress: Exhaustion phase</p>
            </div>
          </div>
        </Container>
      </StyledCard>
    </CommonLayout>
  )
}

export default HMAResult;