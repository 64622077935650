import { gql } from '@apollo/client';

export const USER_SIGNIN_MUTATION = gql`
  mutation userSignin($input: user_signinInput!) {
    userSignin(input: $input) {
      token
      user {
        ... on Professional {
          id
          firstName
          familyName
          code
          email
          # type
          # contactDetail {
          #   addressPlace
          #   addressStreet
          #   addressCountry
          #   telephoneNumber
          #   addressPostalCode
          # }
          # user {
          #   id
          #   isActive
          #   language
          #   timezone
          # }
          twoFactorEnabled
        }
        ... on Client {
          id
          gid
          firstName
          actionPlanStatus {
            sorting
            name
          }
          bio {
            id
            sex
            length
            weight
            isVegetarian
            __typename
          }
          specialists {
            address
            name
          }
          personalContactDetail {
            addressPlace
            telephoneNumber
            addressPostalCode
            telephoneCountryCode
            addressNumber
            addressStreet
            addressCountry
          }
          professionalLogs {
            mainHealthProblemVasScore
          }
          requestForHelp {
            __typename
          }
        }
      }
    }
  }
`;