import { Alert, Badge, Dropdown, Form, MenuProps } from 'antd';
import { FieldType, LanguageType } from './types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, ForgotPassword, FormLabel, LanguageSelect, LanguageSelectWrapper, RequiredMark, StyledButton, StyledForm, StyledInput, StyledInputPassword } from './style';
import { useNormalAuth } from '../../../hooks/auth/useAuth';

const Login = () => {
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const { signIn, loading } = useNormalAuth();
  const [language, setLanguage] = useState<LanguageType>({
    label: 'English',
    key: 'en'
  });

  const items: MenuProps['items'] = [
    {
      label: <p onClick={() => setLanguage({
        label: 'English',
        key: 'en'
      })}>English</p>,
      key: 'en',
    },
    {
      label: <p onClick={() => setLanguage({
        label: 'Dutch',
        key: 'nl'
      })}>Dutch</p>,
      key: 'nl',
    },
  ];

  const onFinish = async (values: any) => {
    // navigate(`/p/`); //temporary
    const { email, password } = values;
    try {
      const res = await signIn({ email, password });
      if (res.success) {
        navigate(res.link as string);
      } else {
        setError(res.message);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
      {label}
      {required && <RequiredMark>*</RequiredMark>}
    </>
  );

  return (
    <Container>
      <div>
        <LanguageSelectWrapper>
          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: [language.key]
            }}
            trigger={['click']}
          >
            <LanguageSelect onClick={(e) => e.preventDefault()}>Language</LanguageSelect>
          </Dropdown>
        </LanguageSelectWrapper>
        <Badge.Ribbon text={language.label} color="volcano">
          <StyledForm
            name="Login"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
            requiredMark={customizeRequiredMark}
          >
            {error && (
              <Alert message={error} type="error" />
            )}
            <Form.Item<FieldType>
              label={<FormLabel>Email</FormLabel>}
              name="email"
              rules={[{
                required: true,
                message: 'Please enter your email address!'
              }]}
            >
              <StyledInput
                placeholder="address@email.com"
              />
            </Form.Item>
            <Form.Item<FieldType>
              label={<FormLabel>Password</FormLabel>}
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <StyledInputPassword
                placeholder="yourpassword"
              />
            </Form.Item>
            <Form.Item>
              <StyledButton htmlType="submit" block loading={loading}>
                Sign In
              </StyledButton>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <ForgotPassword target="blank" href="/sessions/forgot_password">
                Forgot your password?
              </ForgotPassword>
            </Form.Item>
          </StyledForm>
        </Badge.Ribbon>
      </div>
    </Container>
  )
}

export default Login;