import React from 'react'
import styles from './CardTitle.module.scss';

type CardTitleProps = {
  title: string
  subTitle: string
}

const CardTitle: React.FC<CardTitleProps> = ({ title, subTitle }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <span className={styles.subTitle}>{subTitle}</span>
    </div>
  )
}

export default CardTitle