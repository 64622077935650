import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';

const OrientationToLife = () => {
  const dispatch = useDispatch();
  const orientationToLifeQuestionnaire = useSelector((state: any) => state.questionnaires.orientationToLife);
  const currentOrientationToLifeQuestionnaire = useMemo(() => {
    return orientationToLifeQuestionnaire?.questions.filter((q: any) => q.page === orientationToLifeQuestionnaire.currentPage)
  }, [orientationToLifeQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: orientationToLifeQuestionnaire.currentPage + 1,
      type: 'orientationToLife'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: orientationToLifeQuestionnaire.currentPage - 1,
      type: 'orientationToLife'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'orientationToLife' }));
  };

  const renderContent = (question: any) => {
    switch (question.direction) {
      case 'row':
        return (
          <SingleQuestion
            label={question.label}
            choices={question.choices}
            handleRadioToggle={handleRadioToggle}
            order={question.question_num}
            minMaxLabel
            maxLabel={question.maxLabel}
            minLabel={question.minLabel}
          />
        )
      default:
    }
  };

  return (
    <Container>
      <h1>Orientation to Life Questionnaire</h1>
      <p>
        The Questionnaire Sense of coherence refers to an enduring attitude and measures how people view life and in stressful situations identify, use, and reuse their general resistance resources to maintain and develop their health.
      </p>
      <p>
        It is a valid, a reliable, and a cross culturally applicable instrument for measuring health. The SOC scale consists of at least three dimensions: the comprehensibility, the manageability, and the meaningfulness components. The outcome of this questionnaire will be discussed with you in our meeting.
      </p>
      <div className="page-container">
        <div>
          <p>{`Page ${orientationToLifeQuestionnaire.currentPage} of ${orientationToLifeQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentOrientationToLifeQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                {renderContent(question)}
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {orientationToLifeQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {orientationToLifeQuestionnaire.currentPage === orientationToLifeQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default OrientationToLife