import sc from 'styled-components';

export const MainWrapper = sc.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;

  em {
    font-size: .875rem;
    color: #718096;
  }
`;