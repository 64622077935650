import { Card } from 'antd';
import sc from 'styled-components';
import { theme } from '../../../styles/theme';

export const MainContainer = sc.div`
  h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #000000;
    font-weight: inherit;
  }
`;

export const DashboardContainer = sc.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
`;

export const CardContainer = sc(Card)`
  background-color: ${theme.bg.secondary};
  cursor: pointer;
  text-align: center;
  width: 360px;

  & p {
    color: ${theme.color.primary};
    font-size: 1.25rem;
  }

  &:hover {
    background-color: #b2f5ea;
  }
`;