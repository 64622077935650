import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authActions, userActions } from '../../../redux/slices';
import useGetAllTimezones from '../../../hooks/common/useGetAllTimezones';
import useGetAllProfessionals, { useGetPaginatedProfessionals } from '../../../hooks/professionals/useGetAllProfessionals';
import useGetAllCountries from '../../../hooks/common/useGetAllCountries';
import Cookies from 'js-cookie';

type Props = {
  element?: React.ReactNode; // The type of element can be any React Node
  layout: React.ReactNode;
  [key: string]: any;
};

const AdminProtectedLayout: React.FC<Props> = ({ element: Element, layout: Layout, ...rest }) => {
  const dispatch = useDispatch();
  const user = Cookies.get('user');
  const token = Cookies.get('auth_token');
  const userData = user ? JSON.parse(user ?? '') : '';
  const { fetchData: fetchTimezones } = useGetAllTimezones();
  const { fetchData: fetchAllCountries } = useGetAllCountries();
  const { total: totalProfessionals } = useGetAllProfessionals();
  const { fetchData: fetchProfessionals } = useGetPaginatedProfessionals(1, totalProfessionals);

  useEffect(() => {
    if (token) {
      dispatch(authActions.storeAuth({ token, user: userData }));
      fetchTimezones();
      fetchAllCountries();
      fetchProfessionals();
    }
    if (user) {
      dispatch(userActions.storeUser(JSON.parse(user)));
    }
  }, [
    dispatch,
    fetchTimezones,
    fetchAllCountries,
    fetchProfessionals,
    token,
    user,
    userData
  ]);

  if (!userData) {
    return <Navigate to="/a/sessions" replace />;
  }

  return (
    <>
      <>{Layout}</>
    </>
  );
}

export default AdminProtectedLayout