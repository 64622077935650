import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import CodeBlock from '@tiptap/extension-code-block'
import Blockquote from '@tiptap/extension-blockquote'
import Link from '@tiptap/extension-link'
import React from 'react'
import Toolbar from './Toolbar'
import { TipTapWrapper } from './style'

type TipTapProps = {
  content: string
  onChange: (text: string) => void
  fullHeight?: boolean
}

const Tiptap: React.FC<TipTapProps> = ({
  content,
  onChange,
  fullHeight
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
      CodeBlock,
      Blockquote,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: 'https',
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content,
    editorProps: {
      attributes: {
        class: fullHeight ? 'tiptap-lg' : 'tiptap-md'
      }
    },
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    }
  })

  return (
    <TipTapWrapper>
      <Toolbar editor={editor!} />
      <EditorContent editor={editor} />
    </TipTapWrapper>
  )
}

export default Tiptap