import { Layout } from 'antd';
import CustomHeader from '../CustomHeader';
import AdminSidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import MobileSidebar from './Mobile/Sidebar';

const { Content } = Layout;

const AdminLayout: React.FC = () => {
  return (
    <Layout hasSider style={{ minHeight: '100dvh' }}>
      <AdminSidebar />
      <MobileSidebar />
      <Layout>
        <CustomHeader />
        <Content style={{ margin: '1rem 2.5rem' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default AdminLayout;