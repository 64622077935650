import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADMIN_INPUT_IR_COMBI } from '../../services/graphql/mutations/admin';

type ID = string;

interface CreateIRCombiInput {
  professionalId: string;
  clientMutationId?: string;
  irCombiData: {
    clientId: ID;
    professionalViewable: boolean;
    clientViewable: boolean;
    isSeenByPro: boolean;
    crpHs: number;
    ldlOxAntistoffen: number;
    glucose: number;
    insulin: number;
    cPeptide: number;
    homocysteine: number;
    reportNumber: string;
    isDiabetic: boolean;
    homa2CPeptide: number;
    homa2CPeptidePctB: number;
    homa2CPeptidePctS: number;
    homa2CInsulin: number;
    homa2CInsulinPctB: number;
    homa2CInsulinPctS: number;
    isSyntheticInsulinUse: boolean;
    dateOfAnalysis: Date;
  }
}

export function useCreateIRCombi() {
  const [adminCreateIRCombi, { loading }] = useMutation(ADMIN_INPUT_IR_COMBI);
  const [result, setResult] = useState<any>([]);

  const createData = useCallback(async (values: CreateIRCombiInput) => {
    try {
      const { data } = await adminCreateIRCombi({
        variables: {
          input: {
            ...values
          },
        }
      });
      console.log(data);
      setResult(data);
      return data;
    } catch (error) {
      console.error('Error in adminCreateIRCombi: ', error);
    }
  }, [adminCreateIRCombi]);

  return {
    data: result,
    create: createData,
    loading
  };
}
