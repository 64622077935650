import React, { useState } from 'react';
import CommonLayout from '../../../layouts/Common';
import CommonTable from '../../../components/Common/Table';
import { ActionBtn, ActionBtnWrapper, HeaderData, NameData } from './style';
import { ColumnsType } from 'antd/es/table';
import { DataType } from './type';
import useTags, { useUpdateTag } from '../../../hooks/common/useTags';
import TagsForm from '../../../components/Forms/Admin/Tags';
import MaterialIcon from '../../../components/Icons/MaterialIcon';
import { TagsType } from '../../../types';
import { notification, Spin } from 'antd';
import { NotificationTitle } from '../../../components/Common/Notification/style';
import { CheckCircleOutlined } from '@ant-design/icons';
import { theme } from '../../../styles/theme';

const Tags = () => {
  const [api, contextHolder] = notification.useNotification();
  const { data: tags, loading: loadingTags, refetch: refetchTags } = useTags();
  const { update: updateTag, loading: updateTagLoading } = useUpdateTag();
  const [openNewModal, setOpenNewNodal] = useState<boolean>(false);
  // const { data: selectedTag, loading: loadingSelectedTag, refetch: refetchSelectedTag } = useGetTag(selectedTagId);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [editTagValues, setEditTagValues] = useState<TagsType>({
    id: '',
    name: ''
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <HeaderData>Tag Name</HeaderData>,
      dataIndex: 'name',
      render: (text, record) => (
        <NameData>{text}</NameData>
      ),
    },
    {
      title: <HeaderData>Actions</HeaderData>,
      dataIndex: 'active',
      render: (text, record) => (
        <ActionBtnWrapper>
          <ActionBtn
            onClick={() => onEdit(record)}
            active={text}
            type="text"
            icon={<MaterialIcon icon="edit" className="material-icons-round" />}
          />
          <ActionBtn
            active={text}
            type="text"
            icon={<MaterialIcon icon="delete" className="material-icons-round" style={{ color: '#c53030' }} />}
          />
        </ActionBtnWrapper>
      ),
    }
  ];

  const onSubmit = async (record: any) => {
    console.log(record);
    //   const res = await updateTag(record);
    //   if (res.success) {
    //     refetchTags();
    //     api.success({
    //       message: <NotificationTitle>Successfully Updated Professional</NotificationTitle>,
    //       placement: 'topRight',
    //       icon:
    //         <CheckCircleOutlined
    //           style={{
    //             color: theme.color.primary,
    //           }}
    //         />,
    //       style: {
    //         background: `${theme.bg.secondary}`,
    //         borderRadius: 10,
    //         alignItems: 'center',
    //         display: 'flex'
    //       }
    //     });
    //     setOpenNewNodal(false);
    //     setOpenEditModal(false);
    //   }
    // }
  };

  const onEdit = (record: TagsType) => {
    console.log('onEdit', record);
    setEditTagValues({
      ...record
    });
    setOpenEditModal(true);
  };

  const handleBack = () => {
    setOpenNewNodal(false);
    setOpenEditModal(false);
  };

  return (
    <CommonLayout title="Tags" width="1000px">
      {contextHolder}
      {loadingTags ? <Spin size="large" /> :
        <CommonTable
          showNewModal={openNewModal}
          onNewData={setOpenNewNodal}
          showEditModal={openEditModal}
          onEditData={setOpenEditModal}
          handleBack={handleBack}
          modalForm={
            <TagsForm
              defaultValues={editTagValues}
              onBack={handleBack}
              onSubmit={onSubmit}
              loading={updateTagLoading || loadingTags}
              isEdit={openEditModal}
            />
          }
          modalWidth={800}
          columns={columns}
          dataSource={tags}
          tableType="Tags"
          total={`${tags?.length} rows`}
        />}
    </CommonLayout>
  )
};

export default Tags;