import { ApolloProvider } from '@apollo/client';
import { router } from './routes';
import { ConfigProvider } from 'antd';
import client from './apollo';
import {
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import NotificationProvider from './context/NotificationContext';

const App = () => {
  return (
    <Provider store={store}>
      <NotificationProvider>
        <ApolloProvider client={client}>
          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                // colorPrimary: '#84a8a2',
                colorPrimary: '#2B8EA1',
                colorPrimaryHover: '#5ABBA4',
                colorPrimaryTextHover: '#5ABBA4',
                colorPrimaryActive: '#5ABBA4',
                fontFamily: '"Barlow", "Helvetica Neue", Helvetica, Arial, sans-serif'
                // Alias Token
                // colorBgContainer: '#f6ffed',
              },
              components: {
                Form: {
                  labelColor: '#9A9A9A'
                },
                Menu: {
                  itemColor: "rgba(255, 255, 255, 0.88)",
                  itemBg: '#2B8EA1',
                  itemHoverBg: 'white',
                  itemHoverColor: '#5ABBA4',
                  itemSelectedColor: '#5ABBA4',
                  itemSelectedBg: 'white',
                  darkItemBg: '#2B8EA1',
                  fontSize: 20,
                  padding: 16
                },
                Layout: {
                  siderBg: '#2B8EA1',
                  headerBg: 'white',
                  headerHeight: 40,
                },
                Divider: {
                  margin: 8,
                  marginLG: 8
                },
                Tabs: {
                  titleFontSize: 18,
                  titleFontSizeSM: 18,
                  titleFontSizeLG: 18
                },
                Table: {
                  headerBg: 'transparent',
                  headerSplitColor: 'transparent'
                }
              }
            }}
          >
            <RouterProvider router={router} />
          </ConfigProvider>
        </ApolloProvider>
      </NotificationProvider>
    </Provider>

  );
};

export default App;
