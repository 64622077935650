import { Button, Card, Collapse } from 'antd';
import sc from 'styled-components';
import { theme } from '../../../styles/theme';

export const MyInfoWrapper = sc.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const StyledCard = sc(Card)`
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06) !important;
  .ant-card-body {
    padding: 14px;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.75rem;
  }

  p {
    font-size: 1.125rem;
    width: 12rem;
  }

  span {

  }
`;

export const StyledCollapse = sc(Collapse)`
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06) !important;
  font-size: 18px;
  background: white;
  width: 100%;
  .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
    margin-top: 3px;
  }
`;

export const StyledButton = sc(Button)`
  background-color: ${theme.bg.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border: 1px solid #e2e8f0;
  padding: 0.25rem;

  span {
    color: ${theme.color.primary};
  }

  &:hover {
    background: #b2f5ea !important;
  }
`;

export const ModalContentWrapper = sc.div`
  padding: 1.5rem;
  padding-bottom: 0;
`;