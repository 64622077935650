import { createSlice } from '@reduxjs/toolkit';
import { countriesInitialState } from './initial_states';

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: countriesInitialState,
  reducers: {
    setCountries(state, action) {
      const countries = action.payload;
      state.countries = countries;
    },
  }
});

export const countriesActions = countriesSlice.actions;