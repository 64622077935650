import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  margin-top: 1.75rem;
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;

export const TagsWrapper = styled.div`
  max-height: 390px;
  overflow: scroll;
`;