import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { AdminType } from '../../types';
import { ADMIN_CREATE_MUTATION, ADMIN_UPDATE_MUTATION } from '../../services/graphql/mutations/admin';
import { useSelector } from 'react-redux';

export default function useUpdateAdmin() {
  const [adminUpdateMutation, { loading }] = useMutation(ADMIN_UPDATE_MUTATION);
  const [result, setResult] = useState<AdminType[]>([]);

  const updateAdmin = useCallback(async (values: any) => {
    const { data } = await adminUpdateMutation({
      variables: {
        input: {
          id: values.id,
          adminParams: {
            firstName: values.firstName,
            familyName: values.familyName,
            email: values.email,
            userAttributes: {
              password: values.password,
              passwordConfirmation: values.passwordConfirmation,
              language: values.language,
              timezone: values.timezone
            }
          },
        },
      }
    });
    setResult(data);
    return data;
  }, [adminUpdateMutation]);

  return {
    data: result,
    update: updateAdmin,
    loading
  };
}
