import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADMIN_INPUT_HMA } from '../../services/graphql/mutations/admin';
import { useSelector } from 'react-redux';
import { LIST_ALL_HMA } from '../../services/graphql/queries/admin';

interface CreateHmaInput {
  professionalId: string;
  clientMutationId?: string;
  hmaData: any;
  // hmaData: {
  //   clientId: string;
  //   reportNumber: string;
  //   dateOfAnalysis: string;
  //   hairType: string;
  //   calcium: number;
  //   magnesium: number;
  //   sodium: number;
  //   potassium: number;
  //   iron: number;
  //   copper: number;
  //   manganese: number;
  //   zinc: number;
  //   chromium: number;
  //   selenium: number;
  //   phosphorus: number;
  //   lead: number;
  //   mercury: number;
  //   cadmium: number;
  //   arsenic: number;
  //   aluminum: number;
  //   nickel: number;
  //   cobalt: number;
  //   molybdenum: number;
  //   lithium: number;
  //   boron: number;
  //   attachment?: any;
  // };
}

export const useListAllHMA = () => {
  const { data, loading, refetch } = useQuery(LIST_ALL_HMA);

  return {
    data: data?.listAllHma,
    loading,
    refetch
  }
}

export function useCreateHMA() {
  const token = useSelector((state: any) => state.auth.token);
  const [adminCreateHMA, { loading }] = useMutation(ADMIN_INPUT_HMA);

  const createData = useCallback(async (values: CreateHmaInput) => {
    console.log('useCreateHMA', values);
    try {
      const { data } = await adminCreateHMA({
        variables: {
          input: {
            ...values
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return data;
    } catch (error) {
      console.error('Error in adminCreateHMA: ', error);
    }
  }, [adminCreateHMA, token]);

  return {
    create: createData,
    loading
  };
}
