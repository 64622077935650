import { Form } from 'antd';
import sc from 'styled-components';

export const StyledForm = sc(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;

export const Label = sc.p`
  color: #9A9A9A;
  font-size: .875rem !important;
  margin: 0 !important ;
`;

export const MineralsWrapper = sc.div`
`;

export const MineralsFormContainer = sc.div`
  display: flex;
  flex-wrap: wrap;
`;