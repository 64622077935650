import { gql } from '@apollo/client';

export const CREATE_ACTION_PLAN_STATUS = gql`
  mutation createActionPlanStatus($input:create_action_plan_statusInput!) {
    createActionPlanStatus(input:$input){
      id
      name
      color
      sorting
    }
  }
`;

export const UPDATE_ACTION_PLAN_STATUS = gql`
  mutation updateActionPlanStatus($input: update_action_plan_statusInput!) {
    updateActionPlanStatus(input: $input) {
      sorting
      name
      id
      color
    }
  }
`;

export const DELETE_ACTION_PLAN_STATUS = gql`
  mutation removeActionPlanStatus($input: remove_action_plan_statusInput!) {
    removeActionPlanStatus(input: $input) {
      actionPlan {
        name
        id
      }
    }
  }
`;

export const PROFESSIONAL_CREATE_CLIENT = gql`
  mutation professionalCreateClient ($input: professional_create_clientInput!) {
    professionalCreateClient (input: $input) {
      success,
      message
    }
  }
`;

export const PROFESSIONAL_DELETE_CLIENT = gql`
  mutation professionalDeleteClient($input: professional_delete_clientInput!) {
    professionalDeleteClient(input: $input) {
      message
      success
    }
  }
`;

export const PROFESSIONAL_UPDATE_CLIENT_ACTION_PLAN_STATUS = gql`
  mutation UpdateClientActionPlanStatus($clientId: ID!, $actionPlanStatusId: ID!) {
    updateClientActionPlanStatus(
      input: {clientId: $clientId, actionPlanStatusId: $actionPlanStatusId}
    ) {
      success
      message
    }
  }
`;

export const PROFESSIONAL_SEND_MESSAGE = gql`
  mutation professionalSendMessage ($input: inmail_professional_send_messageInput!) {
    professionalSendMessage(input:$input) {
      message
      success
    }
  }
`;

export const PROFESSIONAL_TWOFACTOR_QR = gql`
  mutation twoFactorQr($input: two_factor_qrInput!) {
    twoFactorQr(input: $input) {
      dataUrl
    }
  }
`;

export const PROFESSIONAL_CREATE_LOG = gql`
  mutation professionalCreateLogs ($input:professional_create_logsInput!) {
    professionalCreateLogs(input:$input) {
      id
      title
      content
      clientSummaryAndActions
      mainHealthProblemVasScore
      generalWellBeingVasScore
      energyLevelVasScore
    }
  }
`;

export const PROFESSIONAL_UPDATE_LOG = gql`
  mutation professionalUpdateLogs ($input:professional_update_logsInput!) {
    professionalUpdateLogs(input:$input) {
      id
      title
      content
      clientSummaryAndActions
      mainHealthProblemVasScore
      generalWellBeingVasScore
      energyLevelVasScore
    }
  }
`;

export const PROFESSIONAL_REMOVE_LOG = gql`
  mutation professionalDeleteLogs($input: professional_delete_logsInput!) {
    professionalDeleteLogs(input: $input) {
      success
      message
    }
  }
`;

export const PROFESSIONAL_CREATE_CLIENT_HEALTH_COMPLAINT_NOTES = gql`
  mutation createProfessionalClientHealthComplaintNotes($input: create_professional_client_health_complaint_notesInput!) {
    createProfessionalClientHealthComplaintNotes(input: $input) {
      id
      tags
      content
      clientId
      createdAt
      updatedAt
    }
  }
`;

export const PROFESSIONAL_UPDATE_CLIENT_HEALTH_COMPLAINT_NOTES = gql`
  mutation professionalUpdateClientHealthComplaintNotes($input: professional_update_client_health_complaint_notesInput!) {
    professionalUpdateClientHealthComplaintNotes(input: $input) {
      id
      tags
      content
      clientId
      createdAt
      updatedAt
    }
  }
`;

export const PROFESSIONAL_ASSIGN_QUESTIONNAIRE_TO_CLIENT = gql`
  mutation assignQuestionnaireToClient($input: questionnaire_assign_questionnaireInput!) {
    assignQuestionnaireToClient(input: $input) {
      message
      success
    }
  }
`;

export const PROFESSIONAL_CREATE_TIMELINE_EVENT = gql`
  mutation professionalCreateTimelineEvent($input: professional_create_timeline_eventInput!) {
    professionalCreateTimelineEvent(input: $input) {
      id
      day
      month
      year
      client {
        id
        firstName
        familyName
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const PROFESSIONAL_UPDATE_TIMELINE_EVENT = gql`
  mutation professionalUpdateTimelineEvent($input: professional_update_timeline_eventInput!) {
    professionalUpdateTimelineEvent(input: $input) {
      id
      day
      month
      year
      client {
        id
        firstName
        familyName
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const PROFESSIONAL_DELETE_TIMELINE_EVENT = gql`
  mutation professionalDeleteTimelineEvent($input: professional_delete_timeline_eventInput!) {
    professionalDeleteTimelineEvent(input: $input) {
      message
      success
    }
  }
`;