import { Layout } from 'antd';
import CustomHeader from '../CustomHeader';
import ProfessionalSidebar from './Sidebar';
import MobileSidebar from './Mobile/Sidebar';
import { Outlet } from 'react-router-dom';
import Logger from './Logger';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const { Content } = Layout;
const ProfessionalLayout: React.FC = () => {
  const { isOpen } = useSelector((state: RootState) => state.professionalLog);
  return (
    <Layout hasSider style={{ minHeight: '100dvh' }}>
      <ProfessionalSidebar />
      <MobileSidebar />
      <Layout>
        <CustomHeader />
        <Content style={{ overflow: 'initial' }}>
          <Outlet />
          {isOpen && <Logger />}
        </Content>
      </Layout>
    </Layout>
  )
}

export default ProfessionalLayout;