import React from 'react'
import { PopOverWrapper, StyledTextButton } from './style'
import MaterialIcon from '../../Icons/MaterialIcon'

interface CommonPopoverProps {
  onEdit?: (event: React.MouseEvent) => void
  onRemove?: (event: React.MouseEvent) => void
  onView?: (event: React.MouseEvent) => void
}

const CommonPopover: React.FC<CommonPopoverProps> = ({
  onEdit,
  onRemove,
  onView
}) => {
  return (
    <PopOverWrapper>
      {onEdit && (
        <StyledTextButton
          onClick={onEdit}
          type="text"
          icon={
            <MaterialIcon
              icon="edit"
              className="material-icons-sharp"
            />
          }
        >
          Edit
        </StyledTextButton>
      )}
      {onView && (
        <StyledTextButton
          onClick={onView}
          type="text"
          icon={
            <MaterialIcon
              icon="visibility"
              className="material-icons-sharp"
            />
          }
        >
          View
        </StyledTextButton>
      )}
      {onRemove && (
        <StyledTextButton
          onClick={onRemove}
          type="text"
          isDelete
          icon={
            <MaterialIcon
              icon="delete"
              className="material-icons-sharp"
            />
          }
        >
          Delete
        </StyledTextButton>
      )}
    </PopOverWrapper>
  )
}

export default CommonPopover