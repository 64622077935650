import { Table } from 'antd';
import sc from 'styled-components';

export const StyledTable = sc(Table)`
  width: 100%;
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    background: transparent;
  }
  table {
    border-collapse: collapse;
    thead tr .ant-table-cell {
      border-bottom: 1px solid;
      span {
        color:
      }
    }
    tbody tr .ant-table-cell {
      padding: 0.2rem !important;
      &:last-child {
      }
    }
  }
`;

export const NameData = sc.span`
  font-size: .875rem;
  font-weight: 700;
`;

export const HeaderData = sc.span`
  font-size: .875rem;
  font-weight: 400;
  color: #718096;
`;

export const ModalContentWrapper = sc.div`
  padding: 1.5rem;
  padding-bottom: 0;
`;