import { Avatar, Button, Card, Input, Modal, notification, Select, Spin, Table, Tag } from 'antd'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CardTitle from '../../Card/CardTitle/CardTitle'
import { ClientEmail, ClientName, FilterContainer, LoadingWrapper, ModalContentWrapper, StyledPopover, StyledSelect, clientStyle } from './style'
import type { ColumnsType, TableProps } from 'antd/es/table';
import { useSelector } from 'react-redux'
import { ClientType } from '../../../types'
import ClientUserForm from '../../Forms/Client/User'
import { useCreateProfessionalClient, useDeleteProfessionalClient } from '../../../hooks/professionals/useProfessionalClient'
import { useUpdateClientActionPlanStatus } from '../../../hooks/professionals/useActionPlanStatuses'
import useGetProfessionalListClients from '../../../hooks/professionals/useGetProfessionalListClients';
import { theme } from '../../../styles/theme';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { NotificationErrorTitle, NotificationTitle } from '../../Common/Notification/style';
import CommonPopover from '../../Common/Popover';

interface ActionPlanStatusType {
  id: string;
  color: string;
  name: string;
}

const ClientsTable = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  // const professionalListClients = useSelector((state: any) => state.professionals.listClients);
  const actionPlanStatuses = useSelector((state: any) => state.professionals.actionPlanStatuses);
  const actionPlanStatusesOptions = actionPlanStatuses?.map((actionPlanStatus: ActionPlanStatusType) => ({
    value: actionPlanStatus.id,
    label: actionPlanStatus.name
  }));
  const {
    data: professionalListClients,
    fetchData: fetchProfessionalListClients,
    total,
    loading,
    refetch
  } = useGetProfessionalListClients();
  const { create: createProfessionalClient, loading: loadingCreateClient } = useCreateProfessionalClient();
  const { delete: deleteProfessionalClient, loading: loadingDeleteClient } = useDeleteProfessionalClient();
  const { update: updateClientActionPlanStatus } = useUpdateClientActionPlanStatus();
  const [openNewClient, setOpenNewClient] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<any>({
    open: false,
    id: null
  });
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const professionalListClientsWithKey = useMemo(() => (
    professionalListClients?.map((client: ClientType) => ({ ...client, key: client.id }))
  ), [professionalListClients])

  const columns: ColumnsType<ClientType> = [
    {
      title: '',
      dataIndex: 'notification',
    },
    {
      title: '',
      render: (client: ClientType) => (
        <Avatar
          style={{ color: 'white', fontSize: 20 }}
          size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 50 }}
        >
          {`${client?.firstName.charAt(0)}${client?.familyName.charAt(0)}`}
        </Avatar>
      )
    },
    {
      title: 'Client',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      render: (client: ClientType) => (
        <>
          <ClientName>{`${client?.firstName} ${client?.familyName}`}</ClientName>
          <ClientEmail>{client?.email}</ClientEmail>
        </>
      )
    },
    {
      title: 'Validated',
      render: (client: ClientType) => (
        <>
          {client.accountStatus === 'active' ? (
            <Tag color="success">Active</Tag>
          ) : (
            <Tag color="error">Not Active</Tag>
          )}
        </>
      )
    },
    {
      title: 'Status',
      responsive: ['lg'],
      render: (client: any) => (
        <>
          <StyledSelect
            value={client?.actionPlanStatus?.id ?? 'No Status'}
            options={actionPlanStatusesOptions}
            onChange={(_, option: any) => onActionPlanStatusUpdate(client.id, option.value)}
            style={{ width: 169 }}
            bgColor={client?.actionPlanStatus?.color}
            onClick={(e) => e.stopPropagation()}
          />
        </>
      )
    },
    {
      title: '',
      render: (client: ClientType) => (
        <StyledPopover
          open={openPopover}
          trigger="click"
          placement="bottomRight"
          overlayInnerStyle={{ padding: 0 }}
          content={
            <CommonPopover
              onRemove={(e: React.MouseEvent) => {
                e.stopPropagation();
                setDeleteModal({
                  open: true,
                  id: client?.id
                })
                setOpenPopover(false);
              }}
            />
          }
          arrow={false}
          onOpenChange={(isOpen) => {
            if (isOpen) {
              return
            }
            setOpenPopover(false);
          }}
        >
          <Button onClick={(e: any) => {
            e.stopPropagation();
            setOpenPopover(true);
          }} type="text">
            <i className="material-icons-sharp">more_vert</i>
          </Button>
        </StyledPopover>
      )
    },
  ];

  const onChange: TableProps<ClientType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const onDeleteClient = async (clientId: string) => {
    console.log('onDeleteClient', clientId);
    try {
      const res = await deleteProfessionalClient(clientId);
      console.log('onDeleteClient', res);
    } catch (error: any) {
      console.error(error);
    } finally {
      refetch();
      setDeleteModal({
        open: false,
        id: null
      });
    }
  };

  const handleBack = () => {
    setOpenNewClient(false);
    setDeleteModal({
      open: false,
      id: null
    })
  };

  const onCreateClient = async (values: any) => {
    try {
      const result = await createProfessionalClient(values);
      if (result && result.professionalCreateClient.success) {
        api.info({
          message: <NotificationTitle>{result.professionalCreateClient?.message}</NotificationTitle>,
          placement: 'topRight',
          icon:
            <CheckCircleOutlined
              style={{
                color: theme.color.primary,
              }}
            />,
          style: {
            background: `${theme.bg.secondary}`,
            borderRadius: 10,
            alignItems: 'center',
            display: 'flex'
          }
        });
        fetchProfessionalListClients();
        setOpenNewClient(false);
        return;
      }
      api.error({
        message: <NotificationErrorTitle>{result?.professionalCreateClient?.message}</NotificationErrorTitle>,
        placement: 'topRight',
        icon:
          <CloseCircleOutlined
            style={{
              color: theme.color.error,
            }}
          />,
        style: {
          background: `${theme.bg.error}`,
          borderRadius: 10,
          alignItems: 'center',
          display: 'flex'
        }
      });
    } catch (error: any) {
      api.error({
        message: <NotificationTitle>{error?.graphQLErrors[0].message}</NotificationTitle>,
        placement: 'topRight',
        icon:
          <CloseCircleOutlined
            style={{
              color: theme.color.error,
            }}
          />,
        style: {
          background: `${theme.bg.error}`,
          borderRadius: 10,
          alignItems: 'center',
          display: 'flex'
        }
      });
    }
  };

  const onActionPlanStatusUpdate = async (clientId: string, actionPlanStatusId: any) => {
    try {
      const res = await updateClientActionPlanStatus(clientId.toString(), actionPlanStatusId);
      console.log('onActionPlanStatusUpdate', res)
    } catch (error: any) {
      console.error('onActionPlanStatusUpdateError', error);
    } finally {
      refetch();
    }
  };

  const onVisitClient = (clientId: string) => {
    navigate(`clients/${clientId}`)
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <Spin size="large" />
      </LoadingWrapper>
    );
  }

  return (
    <Card
      title={<CardTitle title='Clients' subTitle={`Showing ${total} items`} />}
      extra={<Button onClick={() => setOpenNewClient(true)} type="primary" icon={<i className="material-icons-sharp">add</i>} />}
      styles={{ header: { borderBottom: 0, alignItems: 'flex-start' } }}
      style={clientStyle}
    >
      {contextHolder}
      <FilterContainer>
        <Input
          size="large"
          placeholder="Name"
          prefix={<i className="material-icons-sharp">filter_list</i>}
        />
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Status"
          options={actionPlanStatusesOptions}
        />
      </FilterContainer>
      <Table
        size="small"
        columns={columns}
        dataSource={professionalListClientsWithKey}
        onChange={onChange}
        scroll={{ x: 'max-content' }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e: any) => {
              e.stopPropagation();
              onVisitClient(record.id);
            },
          };
        }}
      />
      <Modal
        title={`New Client`}
        open={openNewClient}
        onOk={onCreateClient}
        onCancel={handleBack}
        footer={null}
        width={900}
      >
        <ModalContentWrapper>
          <ClientUserForm
            onSubmit={onCreateClient}
            onBack={handleBack}
            loading={loadingCreateClient}
          />
        </ModalContentWrapper>
      </Modal>
      <Modal
        title={`Delete Client`}
        open={deleteModal.open}
        onOk={() => onDeleteClient(deleteModal?.id)}
        loading={loadingDeleteClient}
        onCancel={handleBack}
      // footer={null}
      >
        <ModalContentWrapper>
          <span>Are you sure you want to delete this client?</span>
        </ModalContentWrapper>
      </Modal>
    </Card>
  );
}

export default ClientsTable