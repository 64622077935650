import { useSelector } from 'react-redux';
import { ADMIN_TOGGLE_PROFESSIONAL_VIEWABLE } from '../../services/graphql/mutations/admin';
import { LIST_ANALYSES_VIEW } from '../../services/graphql/queries/admin';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { ToggleProfessionalViewInputType } from '../../types';

export default function useListAnalysesView(viewType: string, currentPage?: number) {
  const { data, loading, refetch } = useQuery(LIST_ANALYSES_VIEW, {
    variables: {
      viewType,
      page: currentPage
    }
  });

  // const { page, perPage, total, data: listAnalysesView } = data?.listAnalysesView

  return {
    data: data?.listAnalysesView?.data,
    refetch,
    loading,
    page: data?.listAnalysesView?.page,
    perPage: data?.listAnalysesView?.perPage,
    total: data?.listAnalysesView?.total
  };
}

export const useToggleProfessionalViewable = () => {
  const token = useSelector((state: any) => state.auth.token);
  const [adminCreateHMA, { loading }] = useMutation(ADMIN_TOGGLE_PROFESSIONAL_VIEWABLE);

  const toggle = useCallback(async (values: ToggleProfessionalViewInputType) => {
    console.log('useToggleProfessionalViewable', values);
    try {
      const { data } = await adminCreateHMA({
        variables: {
          input: {
            ...values
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return data;
    } catch (error) {
      console.error('Error in adminCreateHMA: ', error);
    }
  }, [adminCreateHMA, token]);

  return {
    toggle,
    loading
  }
}
