import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { countriesActions } from '../../redux/slices/countriesSlice';

export default function useGetAllCountries() {
  const dispatch = useDispatch();

  const fetchAllCountries = useCallback(async () => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then((data) => {
        const countriesOpt = data.map((country: any) => ({
          label: country.name.common,
          value: country.cca2.toLowerCase()
        })).sort((a: any, b: any) => a.label.localeCompare(b.label));
        dispatch(countriesActions.setCountries(countriesOpt));
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  }, [dispatch]);

  return {
    fetchData: fetchAllCountries,
  };
}
