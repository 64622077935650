import { Avatar, Col, Row, Tabs, TabsProps } from 'antd';
import ProfessionalSummary from '../../../components/Professional/Summary';
import ProfessionalAnalyses from '../../../components/Analyses/Mobile';
import ProfessionalQuestionnaire from '../../../components/Questionnaire/Mobile';
import { ClientAvatarContainer, ClientCardsContainer, MobileContainer, MobileProfessionalDetailsContainer } from './style';
import { UserOutlined } from '@ant-design/icons';
import Analyses from '../../../components/Analyses';
import Questionnaire from '../../../components/Questionnaire';

const Dashboard = () => {
  const tabItemStyle = {
    padding: 20,
    height: '100%',
    overflow: 'auto',
    paddingBottom: 100
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Summary',
      children: <ProfessionalSummary />,
      style: tabItemStyle
    },
    {
      key: '2',
      label: 'Analyses',
      children: <ProfessionalAnalyses />,
      style: tabItemStyle
    },
    {
      key: '3',
      label: 'Vragenlijsten',
      children: <ProfessionalQuestionnaire />,
      style: tabItemStyle
    },
  ];

  return (
    <>
      <ClientCardsContainer>
        <ClientAvatarContainer>
          <Avatar
            style={{ backgroundColor: '#e2e8f0' }}
            size={{ xs: 134, sm: 134, md: 134, lg: 134, xl: 134, xxl: 134 }}
            icon={<UserOutlined />}
          />
        </ClientAvatarContainer>
        <Row gutter={20}>
          <Col span={12}>
            <Analyses />
          </Col>
          <Col span={12}>
            <Questionnaire />
          </Col>
        </Row>
      </ClientCardsContainer>
      <MobileContainer>
        <MobileProfessionalDetailsContainer>
          <Avatar
            size={{ xs: 146, sm: 146, md: 146, lg: 0, xl: 0, xxl: 0 }}
          />
          <h2>Hello Melvin</h2>
        </MobileProfessionalDetailsContainer>
        <Tabs
          tabBarStyle={{
            boxShadow: '1px 1px 1px 0px #0000001A'
          }}
          tabBarGutter={50}
          defaultActiveKey="1"
          items={items}
          centered={true}
        />
      </MobileContainer>
    </>
  );
}

export default Dashboard;