import React, { useState } from 'react'
import { AddMoreBtnContainer, NewTestContainer, NewTestFormWrapper, NewTestOrder, StyledForm } from './style';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../../constants';
import UserLookup from '../../../Common/Form/UserLookup';
import { Button, Col, DatePicker, Input, Row } from 'antd';
import { useCreateIodineTest } from '../../../../hooks/admin/useIodineTest';

type Props = {}

const IodineNewTest = () => {
  return (
    <StyledForm.List name="iodineTest">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <>
              <NewTestOrder>
                <p>{`${++index})`}</p>
                <Button
                  size="small"
                  onClick={() => remove(field.name)}
                  disabled={index === 1}
                >
                  {'Remove'}
                </Button>
              </NewTestOrder>
              <Row gutter={16} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <StyledForm.Item
                    name={[field.name, 'dateOfAnalysis']}
                    label="Date of analysis"
                    labelAlign="left"
                    rules={[{ required: true, message: 'Date of analysis can\'t be blank' }]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </StyledForm.Item>
                  <StyledForm.Item
                    labelAlign="left"
                    name={[field.name, 'reportNumber']}
                    label="Report number"
                    rules={[
                      {
                        required: true,
                        message: 'Report number can\'t be blank',
                      },
                    ]}
                  >
                    <Input />
                  </StyledForm.Item>
                </Col>
                <Col span={12}>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Iodide</th>
                        <th>Bromide</th>
                        <th>Chloride</th>
                        <th>Fluoride</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}>Spot value</td>
                        <td>
                          <StyledForm.Item
                            style={{ margin: 0 }}
                            name={[field.name, 'iodideSpotValue']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                        <td>
                          <StyledForm.Item
                            name={[field.name, 'bromideSpotValue']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                        <td>
                          <StyledForm.Item
                            name={[field.name, 'chlorideSpotValue']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                        <td>
                          <StyledForm.Item
                            name={[field.name, 'fluorideSpotValue']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>24h excretion</td>
                        <td>
                          <StyledForm.Item
                            name={[field.name, 'iodide24hExcretion']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                        <td>
                          <StyledForm.Item
                            name={[field.name, 'bromide24hExcretion']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                        <td>
                          <StyledForm.Item
                            name={[field.name, 'chloride24hExcretion']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                        <td>
                          <StyledForm.Item
                            name={[field.name, 'fluoride24hExcretion']}
                          >
                            <Input type="number" />
                          </StyledForm.Item>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </>
          ))}
        </>
      )}
    </StyledForm.List>
  );
};

const IodineTestForm = (props: Props) => {
  const { create: createIodineTest } = useCreateIodineTest();
  const [form] = StyledForm.useForm();
  const [iodineTestData, setIodineTestData] = useState([
    {
      dateOfAnalysis: '',
      reportNumber: '',
      iodideSpotValue: '',
      iodide24hExcretion: '',
      bromideSpotValue: '',
      bromide24hExcretion: '',
      chlorideSpotValue: '',
      chloride24hExcretion: '',
      fluorideSpotValue: '',
      fluoride24hExcretion: '',
    }
  ]);

  const onFinish = async (values: any) => {
    console.log('Received values of form: ', values);
    // await createIodineTest(values);
  };

  const onAdd = () => {
    const prevIodineTestVal = form.getFieldValue('iodineTest');
    form.setFieldValue('iodineTest', [
      ...prevIodineTestVal,
      {
        dateOfAnalysis: '',
        reportNumber: '',
        iodideSpotValue: '',
        iodide24hExcretion: '',
        bromideSpotValue: '',
        bromide24hExcretion: '',
        chlorideSpotValue: '',
        chloride24hExcretion: '',
        fluorideSpotValue: '',
        fluoride24hExcretion: '',
      }
    ]);
  };

  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="iodine_test_form"
      onFinish={onFinish}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
      initialValues={{ iodineTest: iodineTestData }}
    >
      <p>Import Iodine Test</p>
      <UserLookup />
      <NewTestContainer>
        <h4>New Test</h4>
        <Button
          onClick={onAdd}
          type='text'
          icon={
            <i className="material-icons-sharp" style={{ fontSize: 'inherit' }}>add</i>
          }
        />
      </NewTestContainer>
      <IodineNewTest />
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  );
};

export default IodineTestForm;
