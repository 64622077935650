import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { professionalInitialState } from './initial_states';
import { ProfessionalStoreType } from './initial_states/type';
import { ActionPlanStatusType } from '../../types';
import { arrayMove } from '@dnd-kit/sortable';

export const professionalSlice = createSlice({
  name: 'professionals',
  initialState: professionalInitialState,
  reducers: {
    setProfessionals(state: ProfessionalStoreType, action) {
      const professionals = action.payload;
      state.professionals = professionals;
    },
    setProfessionalListClients(state: ProfessionalStoreType, action) {
      const listClients = action.payload;
      state.listClients = listClients;
    },
    setActionPlanStatuses(state: ProfessionalStoreType, action) {
      const actionPlanStatuses = action.payload;
      state.actionPlanStatuses = actionPlanStatuses;
    },
    addActionPlanStatus(state: ProfessionalStoreType, action: PayloadAction<ActionPlanStatusType>) {
      if (state.actionPlanStatuses) {
        state.actionPlanStatuses = [
          ...state.actionPlanStatuses,
          action.payload
        ];
      } else {
        state.actionPlanStatuses = [action.payload]
      }
    },
    updateNameActionPlanStatus(state: ProfessionalStoreType, action: PayloadAction<{ index: number, name: string }>) {
      const { index, name } = action.payload;
      if (state.actionPlanStatuses) {
        const updatedItems = state.actionPlanStatuses.map((item: any, i: number) => {
          if (index === i) {
            return { ...item, name, type: item.id ? 'update' : 'add' };
          }
          return item;
        });
        state.actionPlanStatuses = updatedItems;
      }
    },
    updateColorActionPlanStatus(state: ProfessionalStoreType, action: PayloadAction<{ index: number, color: string }>) {
      const { index, color } = action.payload;
      if (state.actionPlanStatuses) {
        const updatedItems = state.actionPlanStatuses.map((item: any, i: number) => {
          if (index === i) {
            return { ...item, color, type: item.id ? 'update' : 'add' };
          }
          return item;
        });
        state.actionPlanStatuses = updatedItems;
      }
    },
    updateSortActionPlanStatus(state: ProfessionalStoreType, action: PayloadAction<{ event: any, getItemPos: any }>) {
      const { event, getItemPos } = action.payload;
      const { active, over } = event;
      if (state.actionPlanStatuses) {
        if (active.id === over.id) return;
        const originalPos = getItemPos(active.id);
        const newPos = getItemPos(over.id);

        state.actionPlanStatuses = arrayMove(state.actionPlanStatuses, originalPos, newPos);
        state.actionPlanStatuses[newPos] = {
          ...state.actionPlanStatuses[newPos],
          type: 'update',
        };
      }
    },
    removeActionPlanStatus(state: ProfessionalStoreType, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload;

      if (state.actionPlanStatuses) {
        const updatedItems = state.actionPlanStatuses.map((item: any, i: number) => {
          if (index === i) {
            return { ...item, type: 'remove' };
          }
          return item;
        });
        state.actionPlanStatuses = updatedItems;
      }
    },
    setSelectedClient(state: ProfessionalStoreType, action) {
      const selectedClient = action.payload;
      state.selectedClient = selectedClient;
    },
    setProfessionalMessages(state: ProfessionalStoreType, action) {
      const messages = action.payload;
      state.messages = messages;
    },
    setCurrentProfessional(state: ProfessionalStoreType, action) {
      const currentProfessional = action.payload;
      state.currentProfessional = currentProfessional;
    }
  }
});

export const professionalActions = professionalSlice.actions;