import React from 'react'
import CommonLayout from '../../../../layouts/Common'
import { useParams } from 'react-router-dom';
import YourHealth from '../YourHealth';
import { StyledCard } from './style';
import GeneralWellBeing from '../GeneralWellBeing';
import FourDimensionalComplaintList from '../FourDimensionalComplaintList';
import OrientationToLife from '../OrientationToLife';
import BehavioralAvoidanceInhibition from '../BehavioralAvoidanceInhibition';
import ConditionsDuringPregnancy from '../ConditionsDuringPregnancy';
import EmotionRegulation from '../EmotionRegulation';
import Expressivity from '../Expressivity';
import MentalEmotionalFunctioning from '../MentalEmotionalFunctioning';
import StressIndicators from '../StressIndicators';
import SocialSupport from '../SocialSupport';
import Neurotransmitters from '../NeuroTransmitters';
import FoodAndIntakeDigestion from '../FoodAndIntakeDigestion';
import VitaminB12Deficiency from '../VitaminB12Deficiency';
import GeneralDecisionMakingStyle from '../GeneralDecisionMakingStyle';

type Props = {}

const QuestionnairePreview = (props: Props) => {
  const { code, questionnaireType } = useParams();
  const renderContent = () => {
    switch (questionnaireType) {
      case 'RAND36':
        return <YourHealth />
      case 'GeneralWellBeingScale':
        return <GeneralWellBeing />
      case 'FourDimensionalComplaintList':
        return <FourDimensionalComplaintList />
      case 'SenseOfCoherence':
        return <OrientationToLife />
      case 'BISBASScale':
        return <BehavioralAvoidanceInhibition />
      case 'ConditionsofMotherduringPregnancy':
        return <ConditionsDuringPregnancy />
      case 'ERQCCG':
        return <EmotionRegulation />
      case 'BEQCCG':
        return <Expressivity />
      case 'MentalEmotionalFunctioning':
        return <MentalEmotionalFunctioning />
      case 'SocialSupportQuestionnaire':
        return <SocialSupport />
      case 'StressIndicators':
        return <StressIndicators />
      case 'Neurotransmitters':
        return <Neurotransmitters />
      case 'FoodAndIntakeDigestion':
        return <FoodAndIntakeDigestion />
      case 'VitaminB12Deficiency':
        return <VitaminB12Deficiency />
      case 'GeneralDecisionMakingStyle':
        return <GeneralDecisionMakingStyle />
      default:
    }
  }
  return (
    <CommonLayout title='Questionnaire Preview' width='940px'>
      <StyledCard>
        {renderContent()}
      </StyledCard>
    </CommonLayout>
  )
}

export default QuestionnairePreview