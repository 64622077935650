import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 2rem;

  h2 {
   font-size: 1.125rem;
   font-weight: inherit;
   margin-bottom: 1.5rem;
  }
`;