import React, { useEffect, useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import MultiRowQuestion from '../../../../components/Questionnaire/MultiRowQuestion';

const FourDimensionalComplaintList = () => {
  const dispatch = useDispatch();
  const fourDimensionalComplaintList = useSelector((state: any) => state.questionnaires.fourDimensionalComplaintList);
  const currentFourDimensionalComplaintListQuestionnaire = useMemo(() => {
    return fourDimensionalComplaintList?.questions.filter((q: any) => q.page === fourDimensionalComplaintList.currentPage)
  }, [fourDimensionalComplaintList]);

  useEffect(() => {
    console.log(currentFourDimensionalComplaintListQuestionnaire);
  }, [currentFourDimensionalComplaintListQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: fourDimensionalComplaintList.currentPage + 1,
      type: 'fourDimensionalComplaintList'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: fourDimensionalComplaintList.currentPage - 1,
      type: 'fourDimensionalComplaintList'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'yourHealth' }));
  };

  const handleRadioToggleMulti = (choicesIndex: number, subQuestionsIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleMultiChoice({ choicesIndex, subQuestionsIndex, questionsIndex }));
  };

  const renderContent = (question: any) => {
    switch (question.type) {
      case 'multi':
        return <MultiRowQuestion
          label={question.label}
          subQuestions={question.subQuestions}
          handleRadioToggleMulti={handleRadioToggleMulti}
          order={question.question_num}
          choicesLabelType='alphabet'
        />
      default:
    }
  }
  return (
    <Container>
      <h1>Four Dimensional Complaint List</h1>
      <p>
        The following questionnaire concerns various complaints and symptoms that you may have. It always concerns complaints and symptoms that you have experienced in the past week (the past 7 days including today). Complaints that you had before, but no longer have in the past week, do not count. Please indicate for each complaint how often you have noticed this in yourself in the past week, by ticking the box that represents the most appropriate answer.
      </p>
      <div className="page-container">
        <div>
          <p>{`Page ${fourDimensionalComplaintList.currentPage} of ${fourDimensionalComplaintList.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentFourDimensionalComplaintListQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                {renderContent(question)}
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {fourDimensionalComplaintList.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {fourDimensionalComplaintList.currentPage === fourDimensionalComplaintList.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default FourDimensionalComplaintList