import { useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Tooltip } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import waistCircumference from '../../../assets/images/waist-circumference.png';
import { StyledModal, WaistCircumferenceImage, WaistCircumferenceTipInfo } from './style';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../constants';

const BioForm = () => {
  const [form] = Form.useForm();
  const [waistCircumferenceModal, setWaistCircumferenceModal] = useState<boolean>(false);
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  const handleWaistCircumferenceModal = () => {
    setWaistCircumferenceModal(false);
  };

  return (
    <Form
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="bio"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <Form.Item
        name="first_name"
        label="First name"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: 'Please input your First name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="family_name"
        label="Family name"
        labelAlign="left"
        rules={[
          {
            required: true,
            message: 'Please input your Family name!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="sex"
        label="Sex"
        labelAlign="left"
        rules={[{ required: true, message: 'Please input your sex!' }]}
      >
        <Radio.Group>
          <Radio value="male"> Male </Radio>
          <Radio value="female"> Female </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="date_of_birth"
        label="Date of birth"
        labelAlign="left"
        rules={[{ required: true, message: 'Please input your date of birth!' }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        name="weight"
        label="Weight"
        labelAlign="left"
        rules={[{ required: true, message: 'Please input your weight!' }]}
      >
        <InputNumber
          addonAfter="kg"
        />
      </Form.Item>
      <Form.Item
        name="waist_circumference"
        label="Waist circumference"
        labelAlign="left"
      >
        <InputNumber
          addonAfter="cm"
        />
        <Tooltip title="How to measure?">
          <Button
            type="primary"
            shape="circle"
            size="small"
            onClick={() => setWaistCircumferenceModal(true)}
            icon={<QuestionOutlined />}
            style={{
              margin: '5px 10px',
            }}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="height"
        label="Height"
        labelAlign="left"
        rules={[{ required: true, message: 'Please input your height!' }]}
      >
        <InputNumber
          addonAfter="cm"
        />
      </Form.Item>
      <Form.Item
        name="vegetarian"
        label="Vegetarian?"
        labelAlign="left"
        rules={[{ required: true, message: 'Please answer!' }]}
      >
        <Radio.Group>
          <Radio value="yes"> Yes </Radio>
          <Radio value="no"> No </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
      <StyledModal
        open={waistCircumferenceModal}
        onOk={handleWaistCircumferenceModal}
        onCancel={handleWaistCircumferenceModal}
        footer={null}
        width={650}
      >
        <Row>
          <Col span={12}>
            <WaistCircumferenceImage src={waistCircumference} />
          </Col>
          <Col span={12}>
            <WaistCircumferenceTipInfo>
              <h3>How to measure your Waist Circumference?</h3>
              <ul>
                <li>Wrap a tape measure just above your hip bones and below the lowest rib.</li>
                <li>Hold the tape measure flat against body, not too tight.</li>
                <li>Stand up straight, exhale and take a reading.</li>
              </ul>
            </WaistCircumferenceTipInfo>
          </Col>
        </Row>
      </StyledModal>
    </Form>
  )
}

export default BioForm