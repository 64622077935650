import {
  ActionBtn,
  ActionPlanStatusCard,
  AnalysesItems,
  AnalysesListWrapper,
  CardExtraStyle,
  CommonCardContent,
  Container,
  CustomModal,
  DashboardWrapper,
  GenderText,
  HeaderWrapper,
  LoadingWrapper,
  ModalBody,
  ModalHeader,
  ModalLogContent,
  ModalLogScoreContainer,
  QuestionnaireData,
  QuestionnaireGroups,
  StyledAvatar,
  StyledPopover,
  StyledSelect,
  StyledTag,
  TimelineData,
  TimelineWrapper,
  TimelineWrapperButton,
  UserDetails,
  UserInfo,
  UserInfoWrapper,
  UserName
} from './style'
import { UserOutlined } from '@ant-design/icons'
import MaterialIcon from '../../../components/Icons/MaterialIcon'
import { Badge, Button, Card, Divider, Empty, Modal, Popover, Spin, Tag } from 'antd'
import { StyledButton } from '../../../components/Common/Button/style'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetProfessionalClient } from '../../../hooks/professionals/useProfessionalClient'
import { formatDate, formatDateWithoutTime, getAge, isAgeGreater, isAgeInRange, vasScoreIndicator } from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { ActionPlanStatusType, ClientQuestionnaireType, LogType, ProfessionalHealthComplaintNotesType, TimelineEventsType } from '../../../types'
import { useUpdateClientActionPlanStatus } from '../../../hooks/professionals/useActionPlanStatuses'
import { useGetClientIrCombiItems, useGetHMAItems, useGetHRVItems, useGetIodineTestItems } from '../../../hooks/professionals/useAnalyses'
import { useDeleteClientTimeline, useGetClientTimelines } from '../../../hooks/professionals/useClientTimelines'
import { _AGE_TIMELINE } from '../../../constants'
import { useMemo, useState } from 'react'
import { useGetClientQuestionnaires } from '../../../hooks/professionals/useQuestionnaires'
import NewQuestionnaireLists from '../../../components/Questionnaire/New'
import { professionalLogActions } from '../../../redux/slices/professionalLogSlice'
import CommonPopover from '../../../components/Common/Popover'
import { theme } from '../../../styles/theme'
import { useRemoveProfessionalLog } from '../../../hooks/professionals/useProfessionalLog'
import HealthComplaintNotesForm from '../../../components/Forms/HealthComplaintNotes'
import NoData from '../../../components/Common/NoData'
import dayjs from 'dayjs'

const ProfessionalClientView = () => {
  const { code, clientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    setOpen,
    setCurrentTab,
    setMaximized,
    setLogData,
    setSelectedClient,
    setEditLog,
    setEditTimeline,
    resetLogData,
    setTimelineData,
    resetTimelineData,
    setSelectedAge,
    setSelectedDateOfEvent
  } = professionalLogActions;
  const { refetch: fetchProfessionalClient, loading, refetch: refetchProfessionalClient } = useGetProfessionalClient(parseInt(clientId as string));
  const { update: updateClientActionPlanStatus, loading: loadingUpdateClientActionPlanStatus } = useUpdateClientActionPlanStatus();
  const { data: irCombiItems, refetch: refetchIrCombiItems } = useGetClientIrCombiItems(clientId as string);
  const { data: hrvItems, refetch: refetchHRVItems } = useGetHRVItems(clientId as string);
  const { data: hmaItems, refetch: refetchHMAItems } = useGetHMAItems(clientId as string);
  const { data: iodineTestItems, refetch: refetchIodineTestItems } = useGetIodineTestItems(clientId as string);
  const { data: clientTimeline, refetch: refetchClientTimeline } = useGetClientTimelines(clientId as string);
  const { data: clientQuestionnaires, refetch: refetchClientQuestionnaires } = useGetClientQuestionnaires(clientId as string);
  const { remove: removeProfessionalLog, loading: loadingRemoveProfessionalLog } = useRemoveProfessionalLog();
  const { delete: deleteClientTimeline, loading: loadingDeleteClientTimeline } = useDeleteClientTimeline();
  const actionPlanStatuses = useSelector((state: any) => state.professionals.actionPlanStatuses);
  const actionPlanStatusesOptions = actionPlanStatuses?.map((actionPlanStatus: ActionPlanStatusType) => ({
    value: actionPlanStatus.id,
    label: actionPlanStatus.name
  }));
  const client = useSelector((state: any) => state.professionals.selectedClient);
  const clientFullname = useMemo(() => (
    `${client?.firstName} ${client?.familyName}`
  ), [client]);
  const clientAge = useMemo(() => {
    return getAge(client?.bio?.dateOfBirth);
  }, [client]);
  const clientPhonenumbers = useMemo(() => {
    const phoneNumbers = [];
    client?.personalContactDetail?.telephoneNumber && phoneNumbers.push(client?.personalContactDetail?.telephoneNumber);
    return phoneNumbers;
  }, [client]);
  const intakeNotEmpty = useMemo(() => {
    return clientQuestionnaires?.some(
      (assignment: any) => assignment.questionnaire.group === "intake"
    );
  }, [clientQuestionnaires]);
  const addtlQuestionnairesNotEmpty = useMemo(() => {
    return clientQuestionnaires?.some(
      (assignment: any) => assignment.questionnaire.group === ""
    );
  }, [clientQuestionnaires]);
  const [timelineModal, setTimelineModal] = useState<any>({
    title: '',
    content: '',
    open: false
  });
  const [deleteModal, setDeleteModal] = useState<any>({
    id: null,
    type: '',
    open: false,
    title: '',
    message: '',
    loading: false
  });
  const [logModal, setLogModal] = useState<any>({
    title: '',
    content: '',
    open: false
  });
  const [healthComplaintModal, setHealthComplaintModal] = useState<any>({
    title: '',
    content: '',
    open: false,
    type: ''
  });
  const [viewHealthComplaintModal, setViewHealthComplaintModal] = useState<any>({
    content: '',
    open: false,
  });
  const [showNewQuestionnaire, setShowNewQuestionnaire] = useState<boolean>(false);
  const [showNoGeneralClientInfo, setShowNoGeneralClientInfo] = useState<boolean>(false);

  const onActionPlanStatusUpdate = async (clientId: any, actionPlanStatusId: any) => {
    const res = await updateClientActionPlanStatus(clientId, actionPlanStatusId);
    console.log('STATUS ', res.success);
    if (res.success) {
      fetchProfessionalClient();
    }
  };

  const onNavigate = (url: string, payload?: any) => {
    navigate(url, {
      state: {
        payload
      }
    });
  };

  const onClickTimeline = (type: string) => {
    if (type !== 'default') {
      const timelineData = clientTimeline?.filter((timeline: any) => {
        if (isAgeInRange(timeline.age, type)) {
          return timeline;
        }
        return null;
      });
      setTimelineModal({
        title: `Age ${type}`,
        content: timelineData[0] !== undefined ? timelineData : 'No events yet',
        open: true
      });
    } else {
      setTimelineModal({
        title: 'Full timeline',
        content: clientTimeline[0] !== undefined ? clientTimeline : 'No events yet',
        open: true
      });
    }
  };

  const onNewQuestionnaire = () => {
    setShowNewQuestionnaire(true);
  };

  const handleCancel = () => {
    setTimelineModal({
      title: '',
      content: '',
      open: false
    });
    setLogModal({
      title: '',
      content: '',
      open: false
    });
    setDeleteModal({
      id: null,
      type: '',
      open: false,
      title: '',
      message: '',
      onDelete: () => { },
      loading: false
    });
    setHealthComplaintModal({
      open: false,
      type: '',
      title: '',
      content: ''
    });
    setViewHealthComplaintModal({
      open: false,
      content: ''
    });
    setShowNewQuestionnaire(false);
    setShowNoGeneralClientInfo(false);
  };

  const onClickGeneralClientInfo = () => {
    if (client?.bio) {
      return;
    }
    setShowNoGeneralClientInfo(true);
  };

  const onAddTimelineEvent = () => {
    dispatch(resetTimelineData());
    dispatch(setCurrentTab('timeline'));
    dispatch(setMaximized());
    dispatch(setLogData({ key: 'clientId', value: clientId }));
    dispatch(setTimelineData({ key: 'clientId', value: clientId }));
    dispatch(setSelectedClient(client));
    dispatch(setOpen());
    setTimelineModal({
      title: '',
      content: '',
      open: false
    });
  };

  const onEditTimelineEvent = (timelineEvent: Partial<TimelineEventsType>) => {
    dispatch(setSelectedClient(client));
    dispatch(setSelectedAge(timelineEvent?.age as number));

    const timelineYear = timelineEvent?.year;
    const timelineMonth = timelineEvent?.month || 1; // Default to January if month is not available
    const timelineDay = timelineEvent?.day || 1; // Default to 1st of the month if day is not available

    const eventDate = dayjs(`${timelineYear}-${timelineMonth}-${timelineDay}`, 'YYYY-M-D');

    dispatch(setSelectedDateOfEvent(eventDate));
    Object.keys(timelineEvent).forEach((key) => {
      if (key in timelineEvent) {
        const typedKey = key as keyof TimelineEventsType;
        dispatch(
          setTimelineData({
            key: typedKey,
            value: timelineEvent[typedKey],
          })
        );
      } else {
        console.error(`Key "${key}" is not a valid field in timeline.`);
      }
    });
    setTimelineModal({
      open: false,
      title: '',
      content: ''
    });
    dispatch(setTimelineData({ key: 'clientId', value: clientId }));
    dispatch(setEditTimeline());
    dispatch(setCurrentTab('timeline'));
    dispatch(setMaximized());
    dispatch(setOpen());
  };

  const onDeleteTimelineEvent = async () => {
    try {
      console.log('onDeleteTimelineEvent', deleteModal);
      const res = await deleteClientTimeline(deleteModal.id);
      console.log(res);
    } catch (err) {
      console.error(err);
    } finally {
      refetchClientTimeline();
      // setDeleteModal({
      //   id: null,
      //   type: '',
      //   open: false,
      //   title: '',
      //   message: '',
      //   onDelete: () => { },
      //   loading: false
      // });
    }
  };

  const onRemoveTimelineEvent = (id: string) => {
    setDeleteModal({
      open: true,
      onDelete: onDeleteTimelineEvent,
      id,
      type: 'timeline',
      title: 'Delete timeline',
      message: 'Are you sure you wnat to delete this timeline event?',
      loading: loadingDeleteClientTimeline
    });
  };

  const onAddLog = () => {
    dispatch(resetLogData());
    dispatch(setCurrentTab('log'));
    dispatch(setMaximized());
    dispatch(setLogData({ key: 'clientId', value: clientId }));
    dispatch(setSelectedClient(client));
    dispatch(setOpen());
  };

  const onEditLog = (professionalLog: Partial<LogType>) => {
    dispatch(setSelectedClient(client));
    Object.keys(professionalLog).forEach((key) => {
      if (key in professionalLog) {
        const typedKey = key as keyof LogType;
        dispatch(
          professionalLogActions.setLogData({
            key: typedKey,
            value: professionalLog[typedKey],
          })
        );
      } else {
        console.error(`Key "${key}" is not a valid field in logs.`);
      }
    });
    setLogModal({
      open: false,
      title: '',
      content: ''
    });
    dispatch(setLogData({ key: 'clientId', value: clientId }));
    dispatch(setEditLog());
    dispatch(setMaximized());
    dispatch(setOpen());
  };

  const onViewLog = (professionalLog: LogType, id: string) => {
    setLogModal({
      content: { ...professionalLog, id },
      open: true
    });
  };

  const onDeleteLog = async () => {
    try {
      const res = await removeProfessionalLog(deleteModal.id);
      console.log(res);
    } catch (err) {
      console.error(err);
    } finally {
      fetchProfessionalClient();
      setDeleteModal({
        id: null,
        type: '',
        open: false,
        title: '',
        message: '',
        onDelete: () => { },
        loading: false
      });
    }
  };

  const onRemoveLog = (id: string) => {
    setDeleteModal({
      open: true,
      onDelete: onDeleteLog,
      id,
      type: 'log',
      title: 'Delete Log',
      message: 'Are you sure you wnat to delete this log?',
      loading: loadingRemoveProfessionalLog
    });
  };

  const onAddHealthComplaintNote = () => {
    setHealthComplaintModal({
      open: true,
      title: 'New Health Complaint Note',
      type: 'new',
    });
  };

  const onEditHealthComplaintNote = (healthComplaintNote: Partial<ProfessionalHealthComplaintNotesType>) => {
    console.log('onEditHealthComplaintNote', healthComplaintNote);
    setHealthComplaintModal({
      open: true,
      type: 'edit',
      title: 'Edit Health Complaint Note',
      content: healthComplaintNote
    });
  };

  const onViewHealthComplaintNote = (healthComplaintNote: Partial<ProfessionalHealthComplaintNotesType>) => {
    console.log('onViewHealthComplaintNote', healthComplaintNote)
    setViewHealthComplaintModal({
      content: healthComplaintNote,
      open: true
    });
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <Spin size="large" />
      </LoadingWrapper>
    );
  }

  return (
    <Container>
      <HeaderWrapper>
        <div style={{ flex: 1 }} />
        <UserInfoWrapper>
          <StyledAvatar
            size={{
              xs: 24, sm: 32, md: 40, lg: 160, xl: 160, xxl: 160
            }}
            icon={<UserOutlined />}
          />
          <UserInfo>
            <UserName>{clientFullname ?? 'Bobby Test'}</UserName>
            <UserDetails>
              {client?.bio && (
                <div>
                  <MaterialIcon icon='person' />
                  <p>
                    <GenderText>{client?.bio?.sex}</GenderText>
                    • Age: {getAge(client?.bio?.dateOfBirth)}
                  </p>
                </div>
              )}
              <div>
                <span>ID</span>
                {/* should be client.gid  */}
                <p>{client?.gid}</p>
              </div>
              <div>
                <MaterialIcon icon='email' />
                <p>{client?.email ?? 'example@mail.com'}</p>
              </div>
              {clientPhonenumbers?.map((phoneNumber, index) => (
                <div key={`phoneNumber_${index++}`}>
                  <MaterialIcon icon='phone' />
                  <p>{phoneNumber}</p>
                </div>
              ))}
            </UserDetails>
            <button onClick={onClickGeneralClientInfo} className="general-client-info-btn-container">
              <MaterialIcon icon='visibility' className='material-icons-round' />
              <p>General client info</p>
            </button>
          </UserInfo>
        </UserInfoWrapper>
        <div style={{ flex: 1 }} />
      </HeaderWrapper>
      <TimelineWrapper>
        <TimelineWrapperButton onClick={() => onClickTimeline('default')}>
          <MaterialIcon icon='timeline' className='material-icons-sharp' />
        </TimelineWrapperButton>
        {_AGE_TIMELINE.map((ageTimeline: { label: string, value: string }, index: number) => {
          const hasAsterisk = clientTimeline?.some((timeline: any) => (
            isAgeInRange(timeline.age, ageTimeline.value)
          ));
          return (
            <TimelineWrapperButton
              onClick={() => onClickTimeline(ageTimeline.value)}
              key={`${ageTimeline.value}_${index}`}
              // lastindex={(index === _AGE_TIMELINE.length - 1).toString()}
              disabled={client?.bio === null || isAgeGreater(clientAge, ageTimeline.value)}
            >
              {ageTimeline.label}
              {hasAsterisk && <span> *</span>}
            </TimelineWrapperButton>
          )
        })}
      </TimelineWrapper>
      <DashboardWrapper>
        <div>
          <Card title="Request for help">
            {client?.requestForHelp ? (
              <span>{client?.requestForHelp?.description}</span>
            ) : (
              <NoData description="No request" />
            )}
          </Card>
          <Card title="Health complaint notes"
            extra={
              <CardExtraStyle>
                {!client?.professionalHealthComplaintNote && (
                  <StyledButton onClick={onAddHealthComplaintNote} icon={<MaterialIcon icon="add" />} />
                )}
              </CardExtraStyle>
            }>
            {client?.professionalHealthComplaintNote ? (
              <CommonCardContent>
                <div>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: client?.professionalHealthComplaintNote?.content }} />
                  </div>
                  <div className="tags">
                    {client?.professionalHealthComplaintNote?.tags.map((tag: string, index: number) => (
                      <Tag key={`${index}_${tag}`} color="#fefcbf" style={{ color: '#2d3748' }}>{tag}</Tag>
                    ))}
                  </div>
                </div>
                <StyledPopover
                  trigger="click"
                  placement="bottomRight"
                  overlayInnerStyle={{ padding: 0 }}
                  content={
                    <CommonPopover
                      onEdit={() => onEditHealthComplaintNote(client?.professionalHealthComplaintNote)}
                      onView={() => onViewHealthComplaintNote(client?.professionalHealthComplaintNote)}
                    />
                  }
                  arrow={false}
                >
                  <Button
                    type="text"
                    icon={<MaterialIcon icon='more_vert' style={{ color: '#718096' }} />}
                  />
                </StyledPopover>
              </CommonCardContent>
            ) : (
              <NoData description="No health complaint" />
            )}
          </Card>
          <Card title="Logs"
            extra={
              <CardExtraStyle>
                <StyledButton icon={<MaterialIcon icon="show_chart" />}>
                  Wellbeing VAS Scores chart
                  <MaterialIcon icon="arrow_drop_down" />
                </StyledButton>
                <StyledButton onClick={onAddLog} icon={<MaterialIcon icon="add" />} />
              </CardExtraStyle>
            }
          >
            {client?.professionalLogs && (
              client?.professionalLogs.map((professionalLog: any, index: number) => (
                <CommonCardContent key={`professional_logs_${index}`} showbottommargin>
                  <div>
                    <p>{professionalLog?.title}</p>
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: professionalLog?.content }} />
                    </div>
                    <div>
                      <div>
                        {professionalLog.mainHealthProblemVasScore && <Badge color={vasScoreIndicator(professionalLog.mainHealthProblemVasScore, true)} />}
                        {professionalLog.generalWellBeingVasScore && <Badge color={vasScoreIndicator(professionalLog.mainHealthProblemVasScore)} />}
                        {professionalLog.energyLevelVasScore && <Badge color={vasScoreIndicator(professionalLog.mainHealthProblemVasScore)} />}
                        <small>{formatDate(professionalLog?.createdAt)}</small>
                      </div>
                    </div>
                  </div>
                  <StyledPopover
                    trigger="click"
                    placement="bottomRight"
                    overlayInnerStyle={{ padding: 0 }}
                    content={
                      <CommonPopover
                        onEdit={() => onEditLog(professionalLog)}
                        onRemove={() => onRemoveLog(professionalLog.id)}
                        onView={() => onViewLog(professionalLog, professionalLog.id)}
                      />
                    }
                    arrow={false}
                  >
                    <Button
                      type="text"
                      icon={<MaterialIcon icon='more_vert' style={{ color: '#718096' }} />}
                    />
                  </StyledPopover>
                </CommonCardContent>
              ))
            )}
            {!client?.professionalLogs && (
              <NoData description="No logs" />
            )}
          </Card>
        </div>
        <div>
          <Card>
            <ActionPlanStatusCard>
              <p>Status</p>
              <StyledSelect
                value={client?.actionPlanStatus?.id ?? 'No Status'}
                options={actionPlanStatusesOptions}
                onChange={(_, option: any) => onActionPlanStatusUpdate(client.id, option.value)}
                style={{ width: 270 }}
                bgColor={client?.actionPlanStatus?.color}
              />
            </ActionPlanStatusCard>
          </Card>
          <Card title="Analyses">
            <AnalysesListWrapper>
              <div>
                {hmaItems?.length > 0 ? (
                  <div style={{ flex: 1 }}>
                    <Link to={`/p/${code}/terms/hma-analysis`}>Hair and Mineral Analysis</Link>
                    <AnalysesItems>
                      {hmaItems.map((hma: any, index: number) => (
                        <div key={`hma${hma.id}_${index}`}>
                          <p>#{hma.reportNumber}</p>
                          <p>{formatDateWithoutTime(hma.dateOfAnalysis)}</p>
                          <div className="actionBtns">
                            <ActionBtn
                              active={false}
                              type="text"
                              icon={
                                <i className="material-icons-round" style={{ fontSize: 'inherit' }}>visibility</i>
                              }
                              onClick={() => onNavigate(`/p/${code}/hma_results/${hma.id}`, hma)}
                            />
                            <ActionBtn
                              active={hma.clientViewable}
                              type="text"
                              icon={
                                <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>policy</i>
                              }
                            >
                              {hma.clientViewable ? 'Disable' : 'Enable'}
                            </ActionBtn>
                          </div>
                        </div>
                      ))}
                    </AnalysesItems>
                  </div>
                ) : (
                  <>
                    <Link to={`/p/${code}/terms/hma-analysis`}>Heart and Mineral Analysis</Link>
                    <small>Not available yet</small>
                  </>
                )}
              </div>
              <div>
                {hrvItems?.length > 0 ? (
                  <div style={{ flex: 1 }}>
                    <Link to={`/p/${code}/terms/hrv-analysis`}>Heart Rate Variability</Link>
                    <AnalysesItems>
                      {hrvItems.map((hrv: any, index: number) => (
                        <div key={`hrv${hrv.id}_${index}`}>
                          <p>#{hrv.reportNumber}</p>
                          <p>{formatDateWithoutTime(hrv.dateOfAnalysis)}</p>
                          <div className="actionBtns">
                            <ActionBtn
                              active={false}
                              type="text"
                              icon={
                                <i className="material-icons-round" style={{ fontSize: 'inherit' }}>visibility</i>
                              }
                              onClick={() => onNavigate(`/p/${code}/hrv_reports/${hrv.id}`, hrv)}
                            />
                            <ActionBtn
                              active={hrv.clientViewable}
                              type="text"
                              icon={
                                <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>policy</i>
                              }
                            >
                              {hrv.clientViewable ? 'Disable' : 'Enable'}
                            </ActionBtn>
                          </div>
                        </div>
                      ))}
                    </AnalysesItems>
                  </div>
                ) : (
                  <>
                    <Link to={`/p/${code}/terms/hrv-analysis`}>Heart Rate Variability</Link>
                    <small>Not available yet</small>
                  </>
                )}
              </div>
              <div>
                {irCombiItems?.length > 0 ? (
                  <div style={{ flex: 1 }}>
                    <Link to={`/p/${code}/terms/insuline-resistance-combi-analysis`}>Insulin Resistance Combi Analysis (IR Combi)</Link>
                    <AnalysesItems>
                      {irCombiItems.map((irCombi: any, index: number) => (
                        <div key={`irCombi${irCombi.id}_${index}`}>
                          <p>#{irCombi.reportNumber}</p>
                          <p>{formatDateWithoutTime(irCombi.createdAt)}</p>
                          <div className="actionBtns">
                            <ActionBtn
                              active={false}
                              type="text"
                              icon={
                                <i className="material-icons-round" style={{ fontSize: 'inherit' }}>visibility</i>
                              }
                              onClick={() => onNavigate(`/p/${code}/ir_combis/${irCombi.id}`, irCombi)}
                            />
                            <ActionBtn
                              active={irCombi.clientViewable}
                              type="text"
                              icon={
                                <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>policy</i>
                              }
                            >
                              {irCombi.clientViewable ? 'Disable' : 'Enable'}
                            </ActionBtn>
                          </div>
                        </div>
                      ))}
                    </AnalysesItems>
                  </div>
                ) : (
                  <>
                    <Link to={`/p/${code}/terms/insuline-resistance-combi-analysis`}>Insulin Resistance Combi Analysis (IR Combi)</Link>
                    <small>Not available yet</small>
                  </>
                )}

              </div>
              <div>
                {iodineTestItems?.length > 0 ? (
                  <div style={{ flex: 1 }}>
                    <Link to={`/p/${code}/terms/iodine-analysis`}>Iodine Test</Link>
                    <AnalysesItems>
                      {iodineTestItems.map((iodineTest: any, index: number) => (
                        <div key={`iodineTest${iodineTest.id}_${index}`}>
                          <p>&nbsp;</p>
                          <p style={{ marginLeft: 40 }}>{formatDateWithoutTime(iodineTest.createdAt)}</p>
                          <div className="actionBtns">
                            <ActionBtn
                              active={false}
                              type="text"
                              icon={
                                <i className="material-icons-round" style={{ fontSize: 'inherit' }}>visibility</i>
                              }
                              onClick={() => onNavigate(`/p/${code}/iodine_tests/${iodineTest.id}`, iodineTest)}
                            />
                            <ActionBtn
                              active={iodineTest.clientViewable}
                              type="text"
                              icon={
                                <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>policy</i>
                              }
                            >
                              {iodineTest.clientViewable ? 'Disable' : 'Enable'}
                            </ActionBtn>
                          </div>
                        </div>
                      ))}
                    </AnalysesItems>
                  </div>
                ) : (
                  <>
                    <Link to={`/p/${code}/terms/iodine-analysis`}>Iodine Test</Link>
                    <small>Not available yet</small>
                  </>
                )}

              </div>
            </AnalysesListWrapper>
          </Card>
          <Card title="Treatment Advice"
            extra={<StyledButton icon={<MaterialIcon icon="add" />} />}
          >
            {!client?.prescribedMedications.length && (
              <NoData description="No treatment advice" />
            )}
            {client?.prescribedMedications && (
              client?.prescribedMedications.map((prescribedMedication: any, index: number) => (
                <CommonCardContent key={`prescribedMedication_${index}_${prescribedMedication.id}`}>
                  <div>
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: prescribedMedication?.content }} />
                    </div>
                    <div>
                      <small>{formatDate(prescribedMedication?.createdAt)}</small>
                    </div>
                  </div>
                  <Button type="text" icon={<MaterialIcon icon='more_vert' style={{ color: '#718096' }} />} />
                </CommonCardContent>
              ))
            )}
          </Card>
          <Card title="Questionnaires" extra={<StyledButton onClick={onNewQuestionnaire} icon={<MaterialIcon icon="add" />} />}>
            {!clientQuestionnaires?.length && (
              <NoData description="No questionnaires" />
            )}
            {clientQuestionnaires && (
              <QuestionnaireGroups>
                {intakeNotEmpty && (
                  <div className="questionnaire-header">
                    <p>Intake</p>
                  </div>
                )}
                {clientQuestionnaires
                  ?.filter((data: ClientQuestionnaireType) => data.questionnaire.group === "intake")
                  .reverse()
                  .map((data: ClientQuestionnaireType, index: number) => (
                    <QuestionnaireData key={`client_questionnaire_intake_${index}`}>
                      <div>
                        <div>
                          <div>
                            <a>{data.questionnaire.title}</a>
                            <a onClick={() => onNavigate(`/p/${code}/questionnaire_preview/RAND-36`)}>
                              <MaterialIcon icon='open_in_new' />
                            </a>
                          </div>
                          <p>Assigned: {formatDate(data.updatedAt)}</p>
                        </div>
                      </div>
                      <div>
                        <small>Not answered yet</small>
                      </div>
                    </QuestionnaireData>
                  ))}
                {addtlQuestionnairesNotEmpty && (
                  <div className="questionnaire-header">
                    <p>Additional questionnaires</p>
                  </div>
                )}
                {clientQuestionnaires
                  ?.filter((data: ClientQuestionnaireType) => data.questionnaire.group === "")
                  .reverse()
                  .map((data: ClientQuestionnaireType, index: number) => (
                    <QuestionnaireData key={`client_questionnaire_group_${index}`}>
                      <div>
                        <div>
                          <div>
                            <a>{data.questionnaire.title}</a>
                            <div onClick={() => onNavigate('')}>
                              <MaterialIcon icon='open_in_new' />
                            </div>
                          </div>
                          <p>Assigned: {formatDate(data.updatedAt)}</p>
                        </div>
                      </div>
                      <div>
                        <small>Not answered yet</small>
                      </div>
                    </QuestionnaireData>
                  ))}
              </QuestionnaireGroups>
            )}
          </Card>
        </div >
      </DashboardWrapper>
      <Modal
        title={timelineModal.title}
        open={timelineModal.open}
        onCancel={handleCancel}
        footer={null}
        style={{
          pointerEvents: 'auto'
        }}
        modalRender={() =>
          <CustomModal>
            <ModalHeader lightbackground>
              <p>{timelineModal.title}</p>
              <div>
                <Button onClick={onAddTimelineEvent} type="text" icon={<MaterialIcon icon='add' className='material-icons-sharp' />} />
                <Button type="text" icon={<MaterialIcon icon='settings' className='material-icons-sharp' />} />
                <Button onClick={handleCancel} type="text" icon={<MaterialIcon icon='close' className='material-icons-sharp' />} />
              </div>
            </ModalHeader>
            <ModalBody>
              {typeof timelineModal.content === 'object' ? (
                <div>
                  {timelineModal.content.map((data: any, index: number) => (
                    <TimelineData key={`timelineContent_${index}`}>
                      <div className="header">
                        <div>{data.age}</div>
                        <div>
                          <Button onClick={() => onEditTimelineEvent(data)} type="text">
                            <MaterialIcon icon='edit' className='material-icons-sharp' style={{ color: theme.color.primary }} />
                          </Button>
                          <Button onClick={() => onRemoveTimelineEvent(data.id)} type="text">
                            <MaterialIcon icon='delete' className='material-icons-sharp' />
                          </Button>
                        </div>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                      <div className="footer">
                        <p>
                          Updated: {formatDate(data.updatedAt)} |
                          Created: {formatDate(data.createdAt)}
                        </p>
                      </div>
                    </TimelineData>
                  ))}
                </div>
              ) : (
                <p>{timelineModal.content}</p>
              )}
            </ModalBody>
          </CustomModal>
        }
      />
      <Modal
        title="New questionnaire assignment"
        open={showNewQuestionnaire}
        onCancel={handleCancel}
        footer={null}
      >
        <NewQuestionnaireLists />
      </Modal>
      <Modal
        title="Log"
        open={logModal.open}
        onCancel={handleCancel}
        footer={null}
        style={{
          pointerEvents: 'auto'
        }}
        modalRender={() =>
          <CustomModal>
            <ModalHeader backgroundcolor={theme.bg.primary}>
              <p>Log</p>
              <div>
                <Button onClick={handleCancel} type="text" icon={<MaterialIcon icon='close' className='material-icons-sharp' />} />
              </div>
            </ModalHeader>
            <ModalBody>
              <ModalLogScoreContainer>
                <div>
                  <small>Updated: {formatDate(logModal.content.updatedAt)}</small>
                  <Divider type="vertical" />
                  <small>Created: {formatDate(logModal.content.createdAt)}</small>
                  <div className="action-btns">
                    <Button
                      onClick={() => onEditLog(logModal.content)}
                      type="text"
                      icon={<MaterialIcon
                        className="material-icons-sharp"
                        icon='edit'
                        style={{ color: theme?.color?.primary }}
                      />}
                      size="small"
                    />
                    <Button
                      type="text"
                      icon={<MaterialIcon
                        className="material-icons-sharp"
                        icon='delete'
                        style={{ color: '#718096' }}
                      />}
                      size="small"
                    />
                  </div>
                </div>
                <div className="badge-container">
                  <StyledTag color={vasScoreIndicator(logModal.content.mainHealthProblemVasScore, true)}>
                    Presence of Main Health Complaint:{' '}
                    <span>{logModal.content.mainHealthProblemVasScore}</span>
                  </StyledTag>
                  <StyledTag color={vasScoreIndicator(logModal.content.generalWellBeingVasScore)}>
                    Wellbeing:{' '}
                    <span>{logModal.content.generalWellBeingVasScore}</span>
                  </StyledTag>
                  <StyledTag color={vasScoreIndicator(logModal.content.energyLevelVasScore)}>
                    Energy:{' '}
                    <span>{logModal.content.energyLevelVasScore}</span>
                  </StyledTag>
                </div>
              </ModalLogScoreContainer>
              <ModalLogContent>
                {typeof logModal.content === 'object' ? (
                  <div>
                    <p className="title">{logModal.content.title}</p>
                    <div dangerouslySetInnerHTML={{ __html: logModal.content.content }} />
                    <h3>Client summary and actions</h3>
                    <div dangerouslySetInnerHTML={{ __html: logModal.content.clientSummaryAndActions }} />
                    <div className="attachments-container">
                      {logModal.content.attachmentUrls.map((attachment: string) => (
                        <a href={attachment} target="_blank" rel="noreferrer" title="View">
                          <span>
                            <MaterialIcon
                              icon="attachment"
                              className="material-icons-sharp"
                            />
                            {attachment.split('/').pop()}
                          </span>
                          <MaterialIcon
                            icon="open_in_new"
                            className="material-icons-sharp"
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>{logModal.content}</p>
                )}
              </ModalLogContent>
            </ModalBody>
          </CustomModal>
        }
      />
      <Modal
        title="Health complaint notes"
        open={viewHealthComplaintModal.open}
        onCancel={handleCancel}
        footer={null}
        style={{
          pointerEvents: 'auto'
        }}
        modalRender={() =>
          <CustomModal>
            <ModalHeader backgroundcolor={theme.bg.primary}>
              <p>Health complaint notes</p>
              <div>
                <Button onClick={handleCancel} type="text" icon={<MaterialIcon icon='close' className='material-icons-sharp' />} />
              </div>
            </ModalHeader>
            <ModalBody>
              <ModalLogScoreContainer>
                <div>
                  <small>Updated: {formatDate(viewHealthComplaintModal.content.updatedAt)}</small>
                  <Divider type="vertical" />
                  <small>Created: {formatDate(viewHealthComplaintModal.content.createdAt)}</small>
                  <div className="action-btns">
                    <Button
                      onClick={() => onEditHealthComplaintNote(viewHealthComplaintModal.content)}
                      type="text"
                      icon={<MaterialIcon
                        className="material-icons-sharp"
                        icon='edit'
                        style={{ color: theme?.color?.primary }}
                      />}
                      size="small"
                    />
                  </div>
                </div>
              </ModalLogScoreContainer>
              <ModalLogContent>
                {typeof viewHealthComplaintModal.content === 'object' ? (
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: viewHealthComplaintModal.content.content }} />
                    <div className="attachments-container">
                      {viewHealthComplaintModal.content.attachmentUrls.map((attachment: string) => (
                        <a href={attachment} target="_blank" rel="noreferrer" title="View">
                          <span>
                            <MaterialIcon
                              icon="attachment"
                              className="material-icons-sharp"
                            />
                            {attachment.split('/').pop()}
                          </span>
                          <MaterialIcon
                            icon="open_in_new"
                            className="material-icons-sharp"
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>{logModal.content}</p>
                )}
              </ModalLogContent>
            </ModalBody>
          </CustomModal>
        }
      />
      <Modal
        title="No client info"
        open={showNoGeneralClientInfo}
        onCancel={handleCancel}
        footer={null}
      >
        <span>Client has not yet completed the General Client Info form</span>
      </Modal>
      <Modal
        title={deleteModal.title}
        open={deleteModal.open}
        onCancel={handleCancel}
        onOk={deleteModal.type === 'log' ? onDeleteLog : onDeleteTimelineEvent}
        confirmLoading={deleteModal.loading === 'log' ? loadingRemoveProfessionalLog : loadingDeleteClientTimeline}
      >
        <span>{deleteModal.message}</span>
      </Modal>
      <Modal
        title={healthComplaintModal.title}
        open={healthComplaintModal.open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <HealthComplaintNotesForm
          type={healthComplaintModal.type}
          defaultValues={healthComplaintModal.content}
          onReset={healthComplaintModal.type === 'new'}
        />
      </Modal>
    </Container>
  )
}

export default ProfessionalClientView