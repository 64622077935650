export const intakeData = [
  {
    title: 'My Integrated Health',
    date: '10 nov. 2023 11:01',
    isDone: true
  },
  {
    title: 'Vierdimensionale klachtenlijst',
    date: '17 jan. 2022 17:16',
    isDone: true
  },
  {
    title: 'Algemeen welbevinden',
    date: '08 feb. 2021 12:32',
    isDone: true
  },
];

export const additionalQuestionnaires = [
  {
    title: 'Algemene Beslissingsstijlen',
    date: '01 jun. 2022 11:18',
    isDone: false
  },
  {
    title: 'Vitamine B12 tekort',
    date: '15 mrt. 2022 15:57',
    isDone: false
  },
  {
    title: 'Vragenlijst Sociale Ondersteuning',
    date: '06 apr. 2021 13:22',
    isDone: true
  },
];