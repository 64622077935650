import React, { useMemo } from 'react'
import { CardFooter, Container, InputContainer, QuestionMainContainer, QuestionWrapper } from './style'
import { Button, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion, { SingleQuestionCol } from '../../../../components/Questionnaire/SingleQuestion';
import MultiRowQuestion from '../../../../components/Questionnaire/MultiRowQuestion';
import MultiSelectQuestion from '../../../../components/Questionnaire/MultiSelectQuestion';

const FoodAndIntakeDigestion = () => {
  const dispatch = useDispatch();
  const foodAndIntakeDigestionQuestionnaire = useSelector((state: any) => state.questionnaires.foodAndIntakeDigestion);
  const currentFoodAndIntakeDigestionQuestionnaire = useMemo(() => {
    return foodAndIntakeDigestionQuestionnaire?.questions.filter((q: any) => q.page === foodAndIntakeDigestionQuestionnaire.currentPage)
  }, [foodAndIntakeDigestionQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: foodAndIntakeDigestionQuestionnaire.currentPage + 1,
      type: 'foodAndIntakeDigestion'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: foodAndIntakeDigestionQuestionnaire.currentPage - 1,
      type: 'foodAndIntakeDigestion'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'foodAndIntakeDigestion' }));
  };

  const handleRadioToggleMulti = (choicesIndex: number, subQuestionsIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleMultiChoice({ choicesIndex, subQuestionsIndex, questionsIndex, type: 'foodAndIntakeDigestion' }));
  };

  const handleMultiSelect = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleMultiSelect({ choicesIndex, questionsIndex, type: 'mentalEmotionalFunctioning' }));
  };

  const renderContent = (question: any) => {
    switch (question.type) {
      case 'row':
        return (
          <SingleQuestion
            label={question.label}
            choices={question.choices}
            handleRadioToggle={handleRadioToggle}
            order={question.question_num}
            minMaxLabel
            maxLabel={question.maxLabel}
            minLabel={question.minLabel}
          />
        )
      case 'col':
        return (
          <SingleQuestionCol
            label={question.label}
            choices={question.choices}
            handleRadioToggle={handleRadioToggle}
            order={question.question_num}
          />
        )
      case 'multi':
        return (
          <MultiRowQuestion
            label={question.label}
            subQuestions={question.subQuestions}
            handleRadioToggleMulti={handleRadioToggleMulti}
            order={question.question_num}
            choicesLabelType='alphabet'
          />
        )
      case 'checkbox':
        return (
          <MultiSelectQuestion
            label={question.label}
            choices={question.choices}
            handleMultiSelect={handleMultiSelect}
            order={question.question_num}
            other={question.other}
          />
        )
      default:
        return (
          <InputContainer>
            <p>{`${question.question_num}${question.question_num ? '.' : ''} ${question.label}`}</p>
            <Input type="text" placeholder={question.placeholder} onClick={(e) => e.stopPropagation()} />
          </InputContainer>
        )
    }
  };

  return (
    <Container>
      <h1>Food Intake and Digestion</h1>
      <div className='paragraphs'>
        <h3>
          <u>
            <b>
              Your food
            </b>
          </u>
        </h3>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${foodAndIntakeDigestionQuestionnaire.currentPage} of ${foodAndIntakeDigestionQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentFoodAndIntakeDigestionQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                {renderContent(question)}
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {foodAndIntakeDigestionQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {foodAndIntakeDigestionQuestionnaire.currentPage === foodAndIntakeDigestionQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
};

export default FoodAndIntakeDigestion;

