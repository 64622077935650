import { Collapse, Modal } from 'antd';
import sc from 'styled-components';

export const StyledCollapse = sc(Collapse)`
  box-shadow: 1px 1px 1px 0px #0000001A;
  font-size: 18px;
  background: white;
`;

export const WaistCircumferenceImage = sc.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
`;

export const StyledModal = sc(Modal)`
  .ant-modal-content {
    padding: 0;
  }
`;

export const WaistCircumferenceTipInfo = sc.div`
  margin-top: 3rem;
  padding: 0 2rem;
  font-size: 16px;

  h3 {
    font-weight: 700;
    margin-bottom: 0.75rem;
  }

  ul {
    list-style: none;
    margin-left: 1rem;
    padding: 0;
    list-style-type: decimal;
  }
`;