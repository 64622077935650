import React from 'react'
import CommonLayout from '../../../../../layouts/Common'
import {
  AdvanceSupplementProtocolWrapper,
  AnalysisResultsWrapper,
  BackgroundInformationWrapper,
  Container,
  Footer,
  InterpretationResultWrapper,
  OrderStyle,
  StyledCard,
  StyledTable,
  UnorderedStyle,
} from './style'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatDateWithoutTime } from '../../../../../helpers'

type Props = {}

const IodineTestResult = (props: Props) => {
  const { iodineTestId } = useParams();
  const location = useLocation();
  const { payload } = location.state;
  const [client, professional] = useSelector((state: any) => [state.professionals.selectedClient, state.auth.user]);

  return (
    <CommonLayout title="Iodine test results" width="800px">
      <StyledCard>
        <Container>
          <h2>Iodine analysis with the 24 hours loading and spot test</h2>
          <StyledTable>
            <tbody>
              <tr>
                <td>Client:</td>
                <td className="bold">{`${client.firstName} ${client.familyName}`}</td>
                <td>Client ID:</td>
                <td className="bold">{client.id}</td>
              </tr>
              <tr>
                <td>Date:</td>
                <td className="bold">{formatDateWithoutTime(payload.createdAt)}</td>
              </tr>
            </tbody>
          </StyledTable>
          <BackgroundInformationWrapper>
            <div>
              <h3>Background Information</h3>
              <p>Iodine is present in all body cells, not just in the thyroid gland. There is a complex transport system to get iodine into the cells, also called the NIS (Sodium Iodide Symporter). Iodide must attach to a specific NIS site before being able to be absorbed. Goitrogens (substances that disrupt the production of the thyroid hormone) enter into a competition with iodide for binding to this location. Goitrogens such as fluoride and perchlorates bind at the same spot where iodide does, without being transported into the cell. Therefore, they are seen as iodide transport inhibitors. Bromide and thiocyanates also adhere to that binding site, but these substances are transported into the cell and prevent the oxidation and organifation of iodine.</p>
            </div>
            <div>
              <h4>The Iodine-spot test</h4>
              <p>The iodine spot test is performed before the test with iodine supplementation is started. This test is used to determine if there is a serious iodine deficiency. This test is a good indicator for the iodine intake via the daily diet.</p>
            </div>
            <div>
              <h4>The 24-hours urine Iodine ‘loading’ test</h4>
              <p>This test has been developed to determine the availability of iodine throughout the body. This test is based on research that has shown that excess iodine is excreted in the urine as soon as all tissues are saturated with iodine.</p>
            </div>
            <div>
              <h4>Interpretation of the results:</h4>
              <p>Sometimes people have symptoms of an iodine deficiency while the 24-hour iodide 'loading' test indicates that there is enough iodine in the body. This can be caused by the presence of chemicals (goitrogens) in the body that block the uptake of iodine in the cell, as a result of which the excretion of iodine via the urine is artificially too high. We speak of a 'false negative' result. One of the ways to determine if there is a defective iodine transport system is to make a comparison between the iodine spot test and the iodine 24-hour urine 'loading' test.
                If there is a very high excretion of iodine in the 24-hour urine {`(> 90% of the ingested 50 mg iodine / iodide)`} but a very low score of the iodine spot test, you can conclude that iodine is absorbed in the intestines but is not (enough) absorbed into the cells. The intake of iodine will then have little effect, it is better to first focus on the recovery of the NIS function.</p>
            </div>
            <div>
              <h4>Toxic halogens:</h4>
              <p>For the above reasons, we can measure the so-called 'toxic halogens' bromide, fluoride and chloride in addition to iodine. By default, the bromide measurement is included in the test. The analysis can be extended on request with the fluoride and chloride measurement.</p>
            </div>
          </BackgroundInformationWrapper>
          <AnalysisResultsWrapper>
            <h3>Analysis results of 01 Apr. 2021</h3>
            <StyledTable collapsed border='1px'>
              <thead>
                <tr className='bluebackground'>
                  <th style={{ width: '35%' }}></th>
                  <th>Iodide</th>
                  <th>Bromide</th>
                  <th>Fluoride</th>
                  <th>Chloride</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='bluebackground'>24 hours test (%)</td>
                  <td>12.0</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className='bluebackground'>24 hours excretion (mg)</td>
                  <td>6.0</td>
                  <td>6.0</td>
                  <td>6.0</td>
                </tr>
                <tr>
                  <td className='bluebackground'>Spot test (mg/L)</td>
                  <td>2.0</td>
                  <td>3.0</td>
                  <td>3.0</td>
                  <td>3.0</td>
                </tr>
              </tbody>
            </StyledTable>
            <h3>Analysis results of 02 Mar. 2021</h3>
            <StyledTable collapsed border='1px'>
              <thead>
                <tr className='bluebackground'>
                  <th style={{ width: '35%' }}></th>
                  <th>Iodide</th>
                  <th>Bromide</th>
                  <th>Fluoride</th>
                  <th>Chloride</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='bluebackground'>24 hours test (%)</td>
                  <td>12.0</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td className='bluebackground'>24 hours excretion (mg)</td>
                  <td>6.0</td>
                  <td>6.0</td>
                  <td>6.0</td>
                </tr>
                <tr>
                  <td className='bluebackground'>Spot test (mg/L)</td>
                  <td>2.0</td>
                  <td>3.0</td>
                  <td>3.0</td>
                  <td>3.0</td>
                </tr>
              </tbody>
            </StyledTable>
          </AnalysisResultsWrapper>
          <InterpretationResultWrapper>
            <h3>Interpretation / references of the results:</h3>
            <div>
              <h4>Iodine/Iodide</h4>
              <p>
                The body shows an average or slightly increased daily Iodine intake. This might be caused by using Iodine containing supplements or a diet rich in iodine containing foods.
                The Iodine concentration in the whole body is very low. If Bromide and Fluoride are also present in concentrations higher dan the accepted range, then this value in daily practice is lower than shown, as these molecules compete with Iodine during Iodine metabolism.
              </p>
              <StyledTable collapsed border='1px'>
                <tbody>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Spot test</td>
                    <td className="text-left">
                      Reference: {`<`} 5 mg/L
                      A serum bromide concentration that may produce adverse effects on Iodine utilization is 10 mg/L. Serum Bromide levels are generally double urinary levels
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Excretion/24 hours</td>
                    <td className="text-left">Reference: 0-50 mg/24 hour</td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>% excretion/24 hours</td>
                    <td className="text-left">Iodine body sufficiency is achieved when the 24-hour urine collection contains 90% or more of the amount of Iodine / Iodide ingested *{`)`}</td>
                  </tr>
                </tbody>
              </StyledTable>
              <p>*{')'} However, if you excrete 90% or more, and are not taking Iodine supplementation, this may be caused by:</p>
              <UnorderedStyle>
                <li>A symporter (NIS) defect in which Iodine is absorbed but not taken into the cells properly. (Highly likely with a high 24-hour excretion and a low score in the spot test.)</li>
                <li>Iodine organification problem where Iodine gets into the cell but does not attach to the lipid complex for activation.</li>
                <li>Bromide, Fluoride and/or heavy metals may be interfering with the body’s utilization of Iodine.</li>
              </UnorderedStyle>
            </div>
            <div>
              <h4>Bromine/Bromide</h4>
              <p>
                The daily bromide intake is relatively high but is still a value often found in humans.
                The body excretes a relatively large amount of bromide in 24 hours.
                The difference between the bromide spot test and the 24-hour urine concentration is the extra secretion of bromide indicated by iodine intake. High doses of an iodine / iodide combi actively detoxify (secrete) the toxic halogens in the body.
              </p>
              <StyledTable collapsed border='1px'>
                <tbody>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Spot test</td>
                    <td className="text-left">
                      Reference: {`<`} 5 mg/L
                      A serum bromide concentration that may produce adverse effects on Iodine utilization is 10 mg/L. Serum Bromide levels are generally double urinary levels
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Excretion/24 hours</td>
                    <td className="text-left">
                      Reference: {'<'} 5 mg/24 hour.
                      The national average urinary Bromide is 1-2 mg per 24 hours *{')'}
                    </td>
                  </tr>
                </tbody>
              </StyledTable>
              <p>*{')'}Any level of Bromide should be addressed; it competes with Iodine for receptor sites and inhibits iodine absorption.</p>
            </div>
            <div>
              <h4>Fluor/Fluoride</h4>
              <p>
                The daily fluoride intake is much too high.
                The body excretes a relatively large amount of fluoride.
                The difference between the Fluoride spot test and the 24-hour urine concentration is the extra secretion of Fluoride indicated by iodine intake. High doses of an iodine / iodide combi actively detoxify (secrete) the toxic halogens in the body.
              </p>
              <StyledTable collapsed border='1px'>
                <tbody>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Spot test</td>
                    <td className="text-left">Reference: {'<'} 1 mg/L</td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Excretion/24 hours</td>
                    <td className="text-left">Reference: {'<'} 1,3 mg/24 hour **{')'}</td>
                  </tr>
                </tbody>
              </StyledTable>
              <p>**{')'}Fluoride is neurotoxic, has a negative effect on bone tissue and interferes with the Iodine metabolism.</p>
            </div>
            <div>
              <h4>Chlorine/Chloride</h4>
              <p>
                The daily chloride intake from salt is much too high.
                The body excretes a normal amount of chloride.
              </p>
              <StyledTable collapsed border='1px'>
                <tbody>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Spot test</td>
                    <td className="text-left">Reference: {'<'} 0,888-1,42 mg/L</td>
                  </tr>
                  <tr>
                    <td className="text-left" style={{ width: '12rem' }}>Excretion/24 hours</td>
                    <td className="text-left">Reference: {'<'} 3,9-8,9 mg/24 hour (110-50 mEq/24 hour) ***{')'}</td>
                  </tr>
                </tbody>
              </StyledTable>
              <p>***{')'} The amount of chloride excreted in 24 hours is a good estimate for the actual salt consumption in the previous 24 hours. This amount can be calculated by multiplying the value with 1.6.</p>
            </div>
          </InterpretationResultWrapper>
          <AdvanceSupplementProtocolWrapper>
            <h3>Advised Supplement Protocol</h3>
            <div>
              <h4>Introduction</h4>
              <p>
                The recovery of the iodine status of the body is of great importance for health, since all body cells have a great dependency on iodine. Iodine is a very safe mineral and it has enormous therapeutic potential, but it must be used with knowledge. The problems that are linked to iodine use, such as a disordered thyroid gland etc. are due to an improper use of iodine and not to the use as such. Often it has to do with an unbalanced intake of various co-factors, other minerals and vitamins that are necessary for a good use of iodine in the metabolism (including uptake in the cells).
                Before describing the optimal iodine protocol, we will first discuss a few things in more detail.
              </p>
            </div>
            <div>
              <h4>Some reservations about the use of high doses of iodine</h4>
              <OrderStyle>
                <li>
                  Allergy to iodine: an allergy to inorganic iodide / iodine is very rare. An allergy to iodine-containing contrast fluid as used in certain medical diagnostic methods does not imply that there is an allergy to inorganic iodide / iodine
                </li>
                <li>
                  Autoimmune thyroid disorders: Many doctors and specialists believe that autoimmune thyroid disorders can be caused by the use of additional iodine. However, this is not correct. If it already occurs due to iodine intake, this is usually due to insufficient consideration being given to the simultaneous use of sufficient co-factors in the correct doses. Of course, it is and remains important that thyroid problems are always treated within the total context of the body (system approach).
                </li>
                <li>
                  Detoxification reactions: Due to the competition with the other (toxic) halogens, bromine, fluorine and chlorine and the frequent overload of these substances in the body, iodine will also partly act as a detox substance at the start of treatment. The toxic halogens are displaced and excreted by the increasing dose of iodine. In certain cases, this may be accompanied by detox symptoms. Think of fatigue, muscle pain, possibly fever, diarrhea, a foggy feeling in the head and the like. The iodine protocol we use and optimized provides as much as possible in guiding this detoxification, so that usually only mild symptoms are observed. One of the best ways to minimize the chance of detoxification reactions is the use of unrefined sea salt (Celtic sea salt or Himalayan salt) together with supplementation of magnesium bicarbonate and vitamin C.
                </li>
                <li>
                  Hypothyroidism and goiter induced by iodine use: When people and animals that are iodine deficient are given a high dose of iodine, there is a temporary decrease in thyroid hormone production (estimated 24-40 hours). The normal hormone values then recover. Again, if any of the aforementioned symptoms occur during iodine use, it is generally due to an incomplete supplementation program.
                </li>
                <li>
                  Hyperthyroidism induced by iodine use: the use of iodine by people who are iodine deficient can lead to a temporary increase in thyroid hormone production with sometimes the accompanying symptoms such as a slight pressure in the region of the thyroid gland, palpitations, nervousness etc. It is always a case in this kind of cases to match the build-up of iodine intake. Wait until the symptoms have subsided and then continue to calm down with the build-up or first go back half a tablet / day in the dose. If the symptoms persist, which is rare, it may be indicated to discontinue the supplementation and to have a serum TSH and FT4 analysis first.
                </li>
                <li>
                  Iodine intoxication: This can occur if the dose of iodine is too high. It manifests itself in a metallic taste in the mouth, an increase in saliva production, sneezing, headache and acne. Pain in the sinuses can also occur. It only affects a very small group of people and can be easily addressed by adjusting the dosage of iodine downwards. The correct use of the iodine protocol also greatly reduces the chance of such reactions.
                </li>
              </OrderStyle>
            </div>
            <div>
              <h4>How much iodine should we take?</h4>
              <p>
                There is no universal dose for everyone. The best way to determine the individual dose of iodine is to test the iodine status of the body, to determine the load with toxic halogens and to always pay close attention to the body's reactions during the build-up and to take higher doses of iodine.
                For most adults, the dose will be between 6 and 50 mg / day.
              </p>
            </div>
            <div>
              <h4>Iodine protocol for adults:</h4>
              <OrderStyle>
                <li>
                  Iodine 12,5 mg. Start with 1/2 tablet per day during or after the meal and increase in a month (or slower) to 2 tablets 2 times per day. Your Bromide excretion is above 5 mg/L. Therefore, signs of Bromide detoxification might occur. Use Keltic sea salt in diluted water (see notes), and do not using Iodine supplements on day 6 and 7 in the week, during the first weeks.
                </li>
                <li>
                  Biomore - Vitamin C Ascorbate with Aspartate, 1 tablespoon per day mixed with water or juice.
                </li>
                <li>The combination:</li>
                <OrderStyle type="a" className="ml-2">
                  <li>
                    Biomore - Relaxxx (Concentrated solution of Magnesium bicarbonate), One measuring cup (30 ml) diluted in 1 L water per day during the day.
                  </li>
                  <li>
                    Biomore - Fulvic acid (drink). Add 15 ml concentrate (with measuring cup) to the 1 L drink of Relaxxx.
                  </li>
                </OrderStyle>
                <li>
                  Vitamin B-complex (Biomore), 2 times per day 1 tablet during or after the meal.
                </li>
                <li>
                  Basic Essential Fatty Acids; YES Basic Essential acids, 2x per day, 2 caps.
                </li>
                <li>
                  Vitamin Life (Biomore), 2 capsules per day
                </li>
              </OrderStyle>
            </div>
            <div>
              <h4>PLEASE NOTE:</h4>
              <OrderStyle>
                <li>
                  If you experience symptoms of Bromine detoxification or want to prevent this symptoms1: Take 1/4 - 1/2 teaspoon Celtic Sea salt2 in half a cup of warm water. Drink it, followed by 350-450 cc water. You can do this more times per day until urine production is starting.
                </li>
                <li>
                  Certain substances from food such as soy (in particular non-fermented soy products) can bind to and even destroy the iodine transport protein, thereby counteracting the effective uptake of iodine in body cells.
                </li>
                <li>
                  If there is a deviation from the protocol and no Fulvic Acid is used, you must use Selenium 100 mcg separately. 2 times per day 1 capsule. The chloride intake is high. Please consider the use of Keltic sea salt or Himalaya salt instead of regular salt.
                </li>
              </OrderStyle>
            </div>
            <div>
              <h4>Too strong detox reactions</h4>
              <p>
                In the event that the detox reactions are still perceived as too strong and Celtic sea salt is already taken, an effective method is to stop using iodine for 48 hours in a week (2 consecutive days). The other parts of the protocol do not change.
              </p>
            </div>
            <div>
              <h4>Monitoring</h4>
              <p>
                After 3 months of using the iodine protocol, it is strongly recommended to repeat the iodine 24-hour spot (with or without additional halogen determination) and, if someone uses thyroid medication, to also regularly determine TSH, FT4 and, if possible, FT3 strongly recommended.
              </p>
            </div>
            <div>
              <h4>Please note</h4>
              <p>
                Unlike doctors think, TSH is not only responsible for controlling thyroid function, but TSH is also important for controlling the production of the iodine transport system (NIS). As a result, at the start of the iodine protocol, sometimes up to 6 months after the start, the TSH serum value may go up (sometimes strongly up) without there being any signs of a clear hypothyroidism. Therefore, it is always important to inform doctors about this effect of iodine intake i.g.v. clients who have to have their thyroid values determined periodically.
              </p>
            </div>
          </AdvanceSupplementProtocolWrapper>
          <Footer>
            <div className="disclaimer">
              <h5>Disclaimer</h5>
              <p>
                This advice is provided with the greatest possible care. The statements and information that is strengthened has not been approved by official bodies and should therefore not be adopted as advice from a doctor or physician. The recipient acknowledges full responsibility for using this information and for following advice. We exclude all liability for any direct or indirect damage, of any nature whatsoever, arising from or in any way related to the use of the vitamins, minerals, treatments or otherwise mentioned by us.
              </p>
            </div>
            <div className="info">
              <div className="sup">
                <p>
                  <sup>1</sup>
                  There is a wide variety of complaints that can be associated with bromine detoxification. The signals from Bromide detoxification can be very diverse:
                </p>
                <p># feeling depressed</p>
                <p># (Extra) fatigue</p>
                <p># foggy feeling in the head, unable to think clearly</p>
                <p># constipation</p>
                <p># fpain in the legs and hips</p>
                <p># acne</p>
                <p># eczema</p>
                <p># ea symptoms.</p>
                <p>Take the Celtic sea salt as standard once a day and, in the case of detoxification more often. Time of intake does not matter.</p>
              </div>
              <div className="sup">
                <p>
                  <sup>2</sup>
                  Also replace the standard kitchen salt in the kitchen with Celtic Sea Salt!
                </p>
              </div>
            </div>
          </Footer>
        </Container>
      </StyledCard>
    </CommonLayout>
  )
}

export default IodineTestResult;