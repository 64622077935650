import { Radio } from 'antd'
import { ChoicesColContainer, ChoicesContainer } from './style'
import React from 'react'

type SingleQuestionProps = {
  label: string
  choices: any
  order: number
  handleRadioToggle: (choicesIndex: number, questionsIndex: number) => void
  minMaxLabel?: boolean
  maxLabel?: string
  minLabel?: string
}

const SingleQuestion: React.FC<SingleQuestionProps> = ({ label, choices, order, handleRadioToggle, minMaxLabel, maxLabel, minLabel }) => {
  return (
    <ChoicesContainer>
      <h3>{`${order} ${order ? '.' : ''} ${label}`}</h3>
      <Radio.Group>
        <table>
          <thead>
            <tr>
              {choices.map((choice: any, index: number) => (
                <th key={`title_${index}`}>{choice.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {choices.map((choice: any, index: number) => {
                return (
                  <td key={`choice_${index}`}>
                    <div onClick={(e) => {
                      e.stopPropagation();
                      handleRadioToggle(index, order);
                    }}>
                      <input
                        type="radio"
                        onChange={() => handleRadioToggle(index, order)}
                        value={choice.title}
                        name={`radios_question_${order}`}
                        checked={choice.selected}
                      />
                    </div>
                  </td>
                )
              })}
            </tr>
            {minMaxLabel && (
              <tr>
                <td>{`[${minLabel}]`}</td>
                <td colSpan={choices.length - 2}></td>
                <td>{`[${maxLabel}]`}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Radio.Group>
    </ChoicesContainer >
  );
};

export const SingleQuestionCol: React.FC<SingleQuestionProps> = ({ label, choices, order, handleRadioToggle }) => {
  return (
    <ChoicesColContainer>
      <h3>{`${order} ${order ? '.' : ''} ${label}`}</h3>
      {choices.map((choice: any, index: number) => {
        console.log(choice, index);
        return (
          <div key={`choice_${index}`} onClick={(e) => {
            e.stopPropagation();
            handleRadioToggle(index, order);
          }}>
            <input
              type="radio"
              onChange={() => handleRadioToggle(index, order)}
              value={choice.title}
              name={`radios_question_${order}`}
              checked={choice.selected}
            />
            <label>{choice.title}</label>
          </div>
        )
      })}
    </ChoicesColContainer>
  );
};

export default SingleQuestion;