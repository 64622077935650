import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

type ProtectedRouteProps = {
  element: React.ReactElement;
  redirectPath?: string;
};

const RedirectIfLoggedIn: React.FC<ProtectedRouteProps> = ({ element, redirectPath }) => {
  const token = Cookies.get('auth_token');
  const user = Cookies.get('user');

  if (token && user) {
    const userData = JSON.parse(user);
    let link = '';
    switch (userData.__typename) {
      case 'Admin':
        link = `/a`;
        break;
      case 'Professional':
        link = `/p/${userData?.code}`;
        break;
      case 'Client':
        link = `/c/${userData?.code}`;
        break;
      default:
    }
    return <Navigate to={link} replace />;
  }

  return element;
};

export default RedirectIfLoggedIn;
