import { Button } from 'antd';
import styled from 'styled-components';

export const CancelDeleteContainer = styled.div`
  display: flex;
  gap: 2px;
`;

export const SaveBtnContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

export const StyledSaveBtn = styled(Button)`
  width: 100%;
`;


export const ModalContentWrapper = styled.div`
  padding: 1.5rem;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;