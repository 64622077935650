import { Card, Divider } from 'antd';
import { AnalysesTitle, IconsContainer, ItemContainer, ItemTitle } from './style';

const MobileAnalyses = () => {
  return (
    <Card>
      <AnalysesTitle>
        <p>Analyses</p>
        <Divider />
      </AnalysesTitle>
      <ItemContainer>
        <ItemTitle>Haar Mineraal Analyse{' '}
          <span>(#123456)</span>
        </ItemTitle>
        <p>15 jan. 2021</p>
        <IconsContainer>
          <i className="material-icons-sharp">visibility</i>
          <i className="material-icons-sharp">attachments</i>
        </IconsContainer>
        <Divider />
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>Heart Rate Variability{' '}
          <span></span>
        </ItemTitle>
        <p>nog niet verkrijgbaar</p>
        <IconsContainer>
        </IconsContainer>
        <Divider />
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>IR Combi{' '}
          <span></span>
        </ItemTitle>
        <p>14 jan. 2021</p>
        <IconsContainer>
          <i className="material-icons-sharp">visibility</i>
        </IconsContainer>
        <Divider />
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>Jodium Analyse{' '}
          <span></span>
        </ItemTitle>
        <p>nog niet verkrijgbaar</p>
        <IconsContainer>
        </IconsContainer>
      </ItemContainer>
    </Card>
  )
}

export default MobileAnalyses;