import React from 'react'
import { MainWrapper } from './style'
import { TableHeaderProps } from './type'

const TableHeader: React.FC<TableHeaderProps> = ({ children }) => {
  return (
    <MainWrapper>
      {children}
    </MainWrapper>
  )
}

export default TableHeader