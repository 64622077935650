import styled from 'styled-components';
import { theme } from '../../../styles/theme';
import { Popover, Select } from 'antd';
import { StyledSelectProps } from './type';

export const ClientCardsContainer = styled.div`
  margin: 2.5rem;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 1.25rem;
`;

export const ClientName = styled.div`
  font-weight: 700;
  color: ${theme.color.primary}
`;

export const ClientEmail = styled.div`
  color: #2d3748;
`;

export const ModalContentWrapper = styled.div`
  padding: 1.5rem;
  padding-bottom: 0;
`;

export const StyledSelect = styled(Select) <StyledSelectProps>`
  .ant-select-selector {
    background-color: ${(props) => props.bgColor ?? 'white'}!important;
  }
`;

export const clientStyle = {
  paddingTop: '24px',
  paddingBottom: '24px'
};

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPopover = styled(Popover)`
  padding: 0;
`;