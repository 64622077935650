import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ACTION_PLAN_STATUSES } from '../../services/graphql/queries/common';
import { useDispatch } from 'react-redux';
import { professionalActions } from '../../redux/slices/professionalSlice';

export default function useGetAllActionPlanStatuses() {
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(GET_ACTION_PLAN_STATUSES);

  const fetchAllActionPlanStatuses = useCallback(() => {
    dispatch(
      professionalActions.setActionPlanStatuses(data?.getActionPlanStatuses)
    )
    return data;
  }, [data, dispatch]);

  return {
    data: data?.getActionPlanStatuses,
    fetchData: fetchAllActionPlanStatuses,
    refetch,
    loading
  };
}
