import React from 'react'
import { MainContainer, Title, backButtonStyle } from './style'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CommonLayoutProps } from './type'

const CommonLayout: React.FC<CommonLayoutProps> = ({
  children,
  title,
  width,
  customButton
}) => {
  const navigate = useNavigate();
  return (
    <MainContainer width={width}>
      <Title>
        <h1>{title}</h1>
        {customButton ||
          <Button
            onClick={() => navigate(-1)}
            shape="circle"
            icon={<i className="material-icons-sharp" style={{ fontSize: 20, color: '#718096' }}>arrow_back</i>}
            style={backButtonStyle}
          />
        }
      </Title>
      {children}
    </MainContainer>
  )
}

export default CommonLayout;
