import { useCallback } from 'react';
import { LIST_ADMINS_QUERY } from '../../services/graphql/queries/admin';
import { useQuery } from '@apollo/client';

export default function useGetAllAdmins(page?: number, perPage?: number) {
  const { data, loading, refetch } = useQuery(LIST_ADMINS_QUERY, {
    variables: {
      page,
      perPage
    }
  });

  const fetchAdmins = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    data: data?.listAdmins?.data,
    fetchData: fetchAdmins,
    loading,
    page: data?.listAdmins?.page,
    perPage: data?.listAdmins?.perPage,
    total: data?.listAdmins?.total,
  };
}
