import { useEffect, useState } from 'react';
import CommonLayout from '../../../layouts/Common'
import { AdminUserListWrapper, HeaderData, ModalContentWrapper, NameData, StyledButton, StyledTable } from './style';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { AdminValues } from './type';
import { Modal, Spin, notification } from 'antd';
import UserAdminForm from '../../../components/Forms/Admin/UserAdmin';
import useGetAllAdmins from '../../../hooks/admin/useGetAllAdmins';
import { AdminType } from '../../../types';
import useCreateAdmin from '../../../hooks/admin/useCreateAdmin';
import { NotificationTitle } from '../../../components/Common/Notification/style';
import { theme } from '../../../styles/theme';
import { CheckCircleOutlined } from '@ant-design/icons';
import useUpdateAdmin from '../../../hooks/admin/useUpdateAdmin';
import CommonTable from '../../../components/Common/Table';

const AdminUsers = () => {
  const [api, contextHolder] = notification.useNotification();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: admins, fetchData: fetchAdmins, loading, page, perPage, total } = useGetAllAdmins(currentPage);
  const { create: createAdmin, loading: createAdminLoading } = useCreateAdmin();
  const { update: updateAdmin, loading: updateAdminLoading } = useUpdateAdmin();
  const [openNewAdmin, setOpenNewAdmin] = useState<boolean>(false);
  const [editAdminId, setEditAdminId] = useState<string>('');
  const [openEditAdmin, setOpenEditAdmin] = useState<boolean>(false);
  const [editAdminValues, setEditAdminValues] = useState<AdminValues>({
    email: '',
    firstName: '',
    familyName: '',
    password: '',
    confirmPassword: '',
    language: '',
    timezone: '',
  });

  const columns: ColumnsType<AdminType> = [
    {
      title: <HeaderData>Name</HeaderData>,
      render: (text, record) => (
        <NameData>{record.firstName} {record.familyName}</NameData>
      ),
    },
    {
      title: <HeaderData>Email</HeaderData>,
      render: (text, record) => (
        <span>{record.email}</span>
      ),
    },
    {
      title: '',
      render: (text, record) => (
        <StyledButton onClick={() => onEdit(record)} icon={<i className="material-icons-sharp">edit</i>} />
      ),
    }
  ];

  const handleNewAdmin = () => {
    setOpenNewAdmin(true);
  };

  const onEdit = (record: AdminType) => {
    console.log(record);
    setEditAdminId(record.id);
    setEditAdminValues({
      email: record.email,
      firstName: record.firstName,
      familyName: record.familyName,
      password: '',
      confirmPassword: '',
      language: '',
      timezone: '',
    });
    setOpenEditAdmin(true);
  };

  const handleBack = () => {
    setEditAdminValues({
      email: '',
      firstName: '',
      familyName: '',
      password: '',
      confirmPassword: '',
      language: '',
      timezone: '',
    });
    setOpenNewAdmin(false);
    setOpenEditAdmin(false);
  };

  const onCreateNewAdmin = async (values: any) => {
    const result = await createAdmin(values);
    if (result && result.createAdmin) {
      setOpenNewAdmin(false);
      api.info({
        message: <NotificationTitle>Successfully Created Admin</NotificationTitle>,
        placement: 'topRight',
        icon:
          <CheckCircleOutlined
            style={{
              color: theme.color.primary,
            }}
          />,
        style: {
          background: `${theme.bg.secondary}`,
          borderRadius: 10,
          alignItems: 'center',
          display: 'flex'
        }
      });
      fetchAdmins();
    }
  };

  const onUpdateNewAdmin = async (values: any) => {
    const result = await updateAdmin({ ...values, id: editAdminId });
    if (result && result.updateAdmin) {
      setOpenEditAdmin(false);
      setEditAdminId('');
      api.info({
        message: <NotificationTitle>Successfully Updated Admin</NotificationTitle>,
        placement: 'topRight',
        icon:
          <CheckCircleOutlined
            style={{
              color: theme.color.primary,
            }}
          />,
        style: {
          background: `${theme.bg.secondary}`,
          borderRadius: 10,
          alignItems: 'center',
          display: 'flex'
        }
      });
      fetchAdmins();
    }
  }

  useEffect(() => {
    fetchAdmins();
  }, [fetchAdmins]);

  return (
    <CommonLayout title="Admins" width="600px">
      {contextHolder}
      {loading ? <Spin size="large" /> : (
        <CommonTable
          showNewModal={openNewAdmin}
          onNewData={setOpenNewAdmin}
          showEditModal={openEditAdmin}
          onEditData={setOpenEditAdmin}
          handleBack={handleBack}
          modalForm={
            <UserAdminForm
              defaultValues={editAdminValues}
              onBack={handleBack}
              onSubmit={(values: any) => onCreateNewAdmin(values)}
              onReset={openNewAdmin}
              loading={createAdminLoading}
              isEdit={openEditAdmin}
            />
          }
          columns={columns}
          dataSource={admins ?? []}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          totalData={total}
          tableType="Admins"
          total={`${total} users`}
        />
      )}
    </CommonLayout>
  )
}

export default AdminUsers