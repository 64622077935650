import sc from 'styled-components';

export const ContactDetailsInfoContainer = sc.div`
  margin-bottom: 1.5rem;
  p {
    margin: 0.75rem 0;
  }
`;

export const FormInfoWrapper = sc.div`
  display: flex;
  align-items: center;
  font-size: .875rem;
  color: #2d3748;
  margin-bottom: 0.5rem;

  div:nth-child(1) {
    width: 12rem;
    margin-right: .75rem;
  }

  div:nth-child(2) {
    flex-grow: 1;
    width: 100%;
    padding: 0.25rem;
    border-bottom: 1px solid #718096;
  }
`;