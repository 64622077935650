import React from 'react'
import CommonLayout from '../../../layouts/Common'
import { MainSection, OTPWrapper, StyledCard, SwitchWrapper, Title, TooltipWrapper } from './style'
import { Input, Switch, Tooltip } from 'antd'
import { StyledButton } from '../../../components/Common/Button/style'

const TwoFactorAuthentication = () => {

  const onToggleSwitch = () => {

  };

  return (
    <CommonLayout title="Security" width="540px">
      <StyledCard>
        <MainSection>
          <Title>Additional security verification (2-factor authenticator)</Title>
          <Tooltip placement="bottom" trigger="click" title={<span>test</span>}>
            <TooltipWrapper>
              <p>
                Add extra security to your account by using the external Autenthincator App. Don't you have the Microsoft Authenticator app yet? Download it first and create an account. Need help downloading? Click here
              </p>
            </TooltipWrapper>
          </Tooltip>
          <SwitchWrapper>
            <p>Enable 2-factor authentication</p>
            <Switch onChange={onToggleSwitch} />
          </SwitchWrapper>
        </MainSection>
        <OTPWrapper>
          <p>Enter your one time password to save your changes</p>
          <Input.OTP size="large" />
        </OTPWrapper>
        <StyledButton>
          Save
        </StyledButton>
      </StyledCard>
    </CommonLayout>
  )
}

export default TwoFactorAuthentication