export const theme = {
  bg: {
    primary: '#409F9D',
    secondary: '#e7fffa',
    orange: '#d69e2e',
    black: '#000000',
    darkGray: '#718096',
    error: '#ffcdd2',
    darkTeal: '#2c7a7b',
    lightTeal: '#4fd1c5'
  },
  color: {
    primary: '#409F9D',
    secondary: '#e7fffa',
    gray: '#e2e8f0',
    orange: '#d69e2e',
    black: '#000000',
    darkGray: '#718096',
    error: '#ef5350',
    darkTeal: '#2c7a7b',
    lightTeal: '#4fd1c5'
  }
};