import React, { useRef, useState } from 'react'
import { _FORM_ITEM_LAYOUT, _MINERALS, _TAIL_FORM_ITEM_LAYOUT } from '../../../../constants';
import { StyledForm, StyledFormUpload, StyledTabs } from './style';
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, UploadProps, message } from 'antd';
import UserLookup from '../../../Common/Form/UserLookup';
import { InboxOutlined } from '@ant-design/icons';
import DayForm from './DayForm';
import { useCreateHRV } from '../../../../hooks/admin/useHrv';

const { Option } = Select;

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const initialItems = [
  { label: 'Day 1', children: <DayForm formNum={1} />, key: '1' },
  { label: 'Day 2', children: <DayForm formNum={2} />, key: '2' },
];

const HRVForm = () => {
  const { create: createHRV } = useCreateHRV();
  const [form] = StyledForm.useForm();
  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  const [items, setItems] = useState(initialItems);
  const newTabIndex = useRef(0);

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    // const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...items];
    const newActiveKey = items.length + 1;
    console.log(newActiveKey);
    newPanes.push({ label: `Day ${newActiveKey}`, children: <DayForm formNum={newActiveKey} />, key: `newTab${newActiveKey.toString()}` });
    setItems(newPanes);
    setActiveKey(`newTab${newActiveKey.toString()}`);
  };

  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove',
  ) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  const props: UploadProps = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="hrv_form"
      onFinish={onFinish}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>Import HRV Data</p>
      <Divider style={{ margin: '.75rem 0' }} />
      <Row gutter={50}>
        <Col lg={12}>
          <UserLookup />
        </Col>
        <Col lg={12}>
          <Form.Item
            labelAlign="left"
            name="report_number"
            label="Report number"
            rules={[
              {
                required: true,
                message: 'Report number can\'t be blank',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="date_of_analysis"
            label="Date of analysis"
            labelAlign="left"
            rules={[{ required: true, message: 'Date of analysis can\'t be blank' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="duration_of_analysis"
            labelAlign="left"
            label="Duration of Analysis (days)"
            rules={[{ required: true, message: 'Duration of Analysis (days) can\'t be blank' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: '.75rem 0' }} />
      <Form.Item
        labelAlign="left"
        label="Attachment:"
        name="attachment"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        style={{ width: '100%' }}
      >
        <StyledFormUpload name="attachment" action="/upload.do">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </StyledFormUpload>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Specialist Report:"
        name="specialist_report"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        style={{ width: '100%' }}
      >
        <StyledFormUpload name="specialist_report" action="/upload.do">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </StyledFormUpload>
      </Form.Item>
      <StyledTabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
      />
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  )
}

export default HRVForm;
