import { Form } from 'antd';
import sc from 'styled-components';

export const StyledForm = sc(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;

export const Footer = sc.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const cardStyle = {
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column' as 'column',
};

export const cardBody = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
};