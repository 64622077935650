import { Button, Table } from 'antd';
import sc from 'styled-components';
import { theme } from '../../../styles/theme';

export const AdminUserListWrapper = sc.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;

  em {
    font-size: .875rem;
    color: #718096;
  }
`;

export const StyledButton = sc(Button)`
  background-color: ${theme.bg.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 5px;
  box-shadow: none;

  .ant-btn-icon {
    margin-inline-end: 3px !important;
  }

  span {
    color: ${theme.color.primary};
    i {
      font-size: 18px;
    }
  }

  &:hover {
    background: #b2f5ea !important;
  }
`;

export const StyledTable = sc(Table)`
  width: 100%;
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    background: transparent;
  }
  table {
    border-collapse: collapse;
    thead tr .ant-table-cell {
      border-bottom: 1px solid;
    }
    tbody tr .ant-table-cell {
      padding: 0.2rem !important;
    }
  }
`;

export const NameData = sc.span`
  font-size: .875rem;
  font-weight: 700;
`;

export const HeaderData = sc.span`
  font-size: .875rem;
  font-weight: 400;
  color: #718096;
`;

export const ModalContentWrapper = sc.div`
  padding: 1.5rem;
  padding-bottom: 0;
`;