import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADMIN_INPUT_IODINE_TEST } from '../../services/graphql/mutations/admin';

type ID = string;

type IRCombiDataType = {
  dateOfAnalysis: string; // ISO 8601 date format
  reportNumber: string;
  iodideSpotValue: number;
  iodide24hExcretion: number;
  bromideSpotValue: number;
  bromide24hExcretion: number;
  chlorideSpotValue: number;
  chloride24hExcretion: number;
  fluorideSpotValue: number;
  fluoride24hExcretion: number;
}

interface CreateIodineTestInput {
  professionalId: ID;
  clientId: ID;
  clientMutationId?: string;
  irCombiData: IRCombiDataType[]
}

export function useCreateIodineTest() {
  const [adminCreateIodineTest, { loading }] = useMutation(ADMIN_INPUT_IODINE_TEST);
  const [result, setResult] = useState<any>([]);

  const createData = useCallback(async (values: CreateIodineTestInput) => {
    try {
      const { data } = await adminCreateIodineTest({
        variables: {
          input: {
            ...values
          },
        }
      });
      console.log(data);
      setResult(data);
      return data;
    } catch (error) {
      console.error('Error in adminCreateIodineTest: ', error);
    }
  }, [adminCreateIodineTest]);

  return {
    data: result,
    create: createData,
    loading
  };
}
