import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';

const GeneralDecisionMakingStyle = () => {
  const dispatch = useDispatch();
  const generalDecisionMakingStyleQuestionnaire = useSelector((state: any) => state.questionnaires.generalDecisionMakingStyle);
  const currentGeneralDecisionMakingStyleQuestionnaire = useMemo(() => {
    return generalDecisionMakingStyleQuestionnaire?.questions.filter((q: any) => q.page === generalDecisionMakingStyleQuestionnaire.currentPage)
  }, [generalDecisionMakingStyleQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: generalDecisionMakingStyleQuestionnaire.currentPage + 1,
      type: 'generalDecisionMakingStyle'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: generalDecisionMakingStyleQuestionnaire.currentPage - 1,
      type: 'generalDecisionMakingStyle'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'generalDecisionMakingStyle' }));
  };

  return (
    <Container>
      <h1>General Decision-Making Style</h1>
      <div className='paragraphs'>
        <p>You receive this questionnaire from your health professional because from the conversation with you, or from answers given in other questionnaires, it is important to know how you make certain decisions.
        </p>
        <p>
          Would you like to complete this questionnaire? You can choose form 3 answers:
        </p>
        <div>
          <p>1 - The claim is false.</p>
          <p>2 - The statement is sometimes true, it depends on the situation.</p>
          <p>3 - The statement is correct.</p>
        </div>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${generalDecisionMakingStyleQuestionnaire.currentPage} of ${generalDecisionMakingStyleQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentGeneralDecisionMakingStyleQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <SingleQuestion
                  label={question.label}
                  choices={question.choices}
                  handleRadioToggle={handleRadioToggle}
                  order={question.question_num}
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {generalDecisionMakingStyleQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {generalDecisionMakingStyleQuestionnaire.currentPage === generalDecisionMakingStyleQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
};

export default GeneralDecisionMakingStyle;

