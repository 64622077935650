import { useEffect, useState } from 'react';
import { Button, Input, Spin, Tag, notification } from 'antd';
import CommonLayout from '../../../layouts/Common';
import { StyledButton } from '../../../components/Common/Button/style';
import { ColumnsType } from 'antd/es/table';
import { HeaderData, NameData, StatusWrapper } from './style';
import CommonTable from '../../../components/Common/Table';
import useGetAllProfessionals from '../../../hooks/professionals/useGetAllProfessionals';
import { ProfessionalType } from '../../../types';
import { formatDate } from '../../../helpers';
import ProfessionalUserForm from '../../../components/Forms/Admin/User/Professional';
import { useCreateProfessionalUser, useUpdateProfessionalUser } from '../../../hooks/admin/useAdminProfessional';
import { CheckCircleOutlined } from '@ant-design/icons';
import { theme } from '../../../styles/theme';
import { NotificationTitle } from '../../../components/Common/Notification/style';
import { useSelector } from 'react-redux';

const Professionals = () => {
  const [api, contextHolder] = notification.useNotification();
  const professionals = useSelector((state: any) => state?.professionals?.professionals);

  const { update: createProfessionalUser, loading: createLoading } = useCreateProfessionalUser();
  const { update: updateProfessionalUser, loading: updateLoading } = useUpdateProfessionalUser();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { refetch: refetchProfessionals, loading, page, perPage, total } = useGetAllProfessionals(currentPage);
  const [openNewProfessional, setOpenNewProfessional] = useState<boolean>(false);
  const [openEditProfessional, setOpenEditProfessional] = useState<boolean>(false);
  const [editProfessionalValues, setEditProfessionalValues] = useState<any>({
    email: '',
    firstName: '',
    familyName: '',
    password: '',
    confirmPassword: '',
    language: '',
    timezone: '',
  });

  console.log(professionals);

  const columns: ColumnsType<ProfessionalType> = [
    {
      title: <HeaderData>Name</HeaderData>,
      render: (text, record) => (
        <NameData onClick={() => onEdit(record)}>{record.firstName} {record.familyName}</NameData>
      ),
    },
    {
      title: <HeaderData>Email</HeaderData>,
      render: (text, record) => (
        <span>{record.email}</span>
      ),
    },
    {
      title: <HeaderData>Type</HeaderData>,
      dataIndex: 'type',
      render: (text) => {
        let type = '';
        switch (text.replace('ProfessionalType', '')) {
          case 'QuestionnaireOnly':
            type = 'Questionnaire-only';
            break;
          case 'A':
            type = 'Type A';
            break;
          default:
            type = text.replace('ProfessionalType', '');
        }
        return (
          <span>{type}</span>
        )
      }
    },
    {
      title: <HeaderData>Code</HeaderData>,
      dataIndex: 'code',
    },
    {
      title: <HeaderData>Created</HeaderData>,
      dataIndex: 'createdAt',
      render: (text) => (
        <>
          {formatDate(text)}
        </>
      )
    },
    {
      title: <HeaderData>Status</HeaderData>,
      dataIndex: 'twoFactorEnabled',
      render: (text, record) => (
        <>
          {record?.accountStatus === 'active' ?
            <Tag color="green">active</Tag>
            : (
              <StatusWrapper>
                <Input
                  type="text"
                  readOnly
                  disabled
                />
                <Button icon={<i className="material-icons-sharp" style={{ fontSize: 'inherit' }}>content_copy</i>} />
              </StatusWrapper>
            )
          }
        </>
      ),
    },
    {
      title: <HeaderData>Login as</HeaderData>,
      dataIndex: 'action',
      render: (text, record) => (
        <StyledButton onClick={() => onEdit(record)} icon={<i className="material-icons-round">login</i>} />
      ),
    }
  ];

  const onEdit = (record: any) => {
    setEditProfessionalValues({
      ...record
    });
    setOpenEditProfessional(true);
  };

  const onSubmit = async (record: any) => {
    if (openEditProfessional) {
      const res = await updateProfessionalUser(record);
      if (res.success) {
        refetchProfessionals();
        api.success({
          message: <NotificationTitle>Successfully Updated Professional</NotificationTitle>,
          placement: 'topRight',
          icon:
            <CheckCircleOutlined
              style={{
                color: theme.color.primary,
              }}
            />,
          style: {
            background: `${theme.bg.secondary}`,
            borderRadius: 10,
            alignItems: 'center',
            display: 'flex'
          }
        });
        setOpenNewProfessional(false);
        setOpenEditProfessional(false);
      }
    } else {
      const res = await createProfessionalUser(record);
      if (res.success) {
        refetchProfessionals();
        api.success({
          message: <NotificationTitle>Successfully Created Professional</NotificationTitle>,
          placement: 'topRight',
          icon:
            <CheckCircleOutlined
              style={{
                color: theme.color.primary,
              }}
            />,
          style: {
            background: `${theme.bg.secondary}`,
            borderRadius: 10,
            alignItems: 'center',
            display: 'flex'
          }
        });
        setOpenNewProfessional(false);
      }
    }
  };

  const handleBack = () => {
    setEditProfessionalValues({
      email: '',
      firstName: '',
      familyName: '',
      password: '',
      confirmPassword: '',
      language: '',
      timezone: '',
    });
    setOpenNewProfessional(false);
    setOpenEditProfessional(false);
  };

  return (
    <CommonLayout title="Professionals" width="1000px">
      {contextHolder}
      {loading ? <Spin size="large" /> : (
        <CommonTable
          showNewModal={openNewProfessional}
          onNewData={setOpenNewProfessional}
          showEditModal={openEditProfessional}
          onEditData={setOpenEditProfessional}
          handleBack={handleBack}
          modalForm={
            <ProfessionalUserForm
              defaultValues={editProfessionalValues}
              onBack={handleBack}
              onSubmit={onSubmit}
              loading={updateLoading || loading}
              isEdit={openEditProfessional}
              onReset={openNewProfessional}
            />
          }
          columns={columns}
          dataSource={professionals ?? []}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          totalData={total}
          tableType="Professional"
          total={`${professionals?.length} professionals`}
        />
      )}
    </CommonLayout>
  )
};

export default Professionals;