import sc from 'styled-components';
import { theme } from '../../styles/theme';

export const CardTitle = sc.div`
  margin-bottom: 10px;
  p {
    font-size: 1.125rem;
  }
`;

export const AnalysesDataContainer = sc.div`
  display: flex;
  flex-direction: column;

  tbody tr {
    border-top: 1px solid ${theme.color.gray};
    td:nth-child(1) {
      font-size: .875rem;
      font-weight: 700;
    }

    td:nth-child(2) {
      font-size: .75rem;
      color: #718096;
    }
  }
`;

export const AnalysesData = sc.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.color.gray};
  padding: 0.25rem 0;

  p {
    width: 60%;
    font-weight: 700;
    font-size: .875rem;
  }

  small {
    font-size: .75rem;
    flex-grow: 1;
  }

  &:hover {
    background-color: #f7fafc;
  }
`;