import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PROFESSIONAL_CREATE_CLIENT, PROFESSIONAL_DELETE_CLIENT } from '../../services/graphql/mutations/professional';
import { GET_PROFESSIONAL_CLIENT } from '../../services/graphql/queries/professional';
import { useDispatch, useSelector } from 'react-redux';
import { professionalActions } from '../../redux/slices/professionalSlice';

export const useGetProfessionalClient = (id: number) => {
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(GET_PROFESSIONAL_CLIENT, {
    variables: {
      id
    }
  });

  const fetchProfessionalClient = useCallback(() => {
    if (data) {
      dispatch(
        professionalActions.setSelectedClient(data?.getClient)
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    fetchProfessionalClient();
  }, [id, fetchProfessionalClient]);

  return {
    data: data?.getClient,
    fetchData: fetchProfessionalClient,
    loading,
    refetch
  };
}

export const useCreateProfessionalClient = () => {
  const token = useSelector((state: any) => state.auth.token);
  const [createProfessionalClient, { loading }] = useMutation(PROFESSIONAL_CREATE_CLIENT);

  const createData = useCallback(async (values: any) => {
    try {
      const { data } = await createProfessionalClient({
        variables: {
          input: {
            params: {
              email: values?.email,
              firstName: values?.firstName,
              familyName: values?.familyName,
              type: values?.type ?? 'ClientTypeFull',
              userAttributes: {
                language: values?.language ?? 'en',
                timezone: values?.timezone ?? 'Europe/Amsterdam'
              }
            },
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return data;
    } catch (error: any) {
      console.log('Error in createProfessionalClient: ', error?.graphQLErrors[0].message);
    }
  }, [createProfessionalClient, token]);

  return {
    create: createData,
    loading
  };
};

export const useDeleteProfessionalClient = () => {
  const [deleteProfessionalClient, { loading }] = useMutation(PROFESSIONAL_DELETE_CLIENT);

  const deleteData = useCallback(async (id: string) => {
    try {
      const { data } = await deleteProfessionalClient({
        variables: {
          input: {
            id
          },
        }
      });

      return data;
    } catch (error) {
      console.log('Error in deleteProfessionalClient: ', error);
    }
  }, [deleteProfessionalClient]);

  return {
    delete: deleteData,
    loading
  };
};