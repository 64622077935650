import { useMutation, useQuery } from '@apollo/client';
import { GET_TAG, LIST_TAGS } from '../../services/graphql/queries/common';
import { useCallback } from 'react';
import { ADMIN_DELETE_TAG, ADMIN_UPDATE_TAG } from '../../services/graphql/mutations/admin';

export default function useTags() {
  const { data, loading, refetch } = useQuery(LIST_TAGS);

  return {
    data: data?.listTags,
    loading,
    refetch
  };
}

export function useGetTag(id: string | undefined) {
  const { data, loading, refetch } = useQuery(GET_TAG, {
    variables: {
      id
    }
  });

  return {
    data: data?.getTag,
    loading,
    refetch
  };
}

export const useUpdateTag = () => {
  const [tagUpdate, { loading }] = useMutation(ADMIN_UPDATE_TAG);

  const updateTag = useCallback(async (values: any) => {
    const { data } = await tagUpdate({
      variables: {
        input: {
          id: values.id,
          name: values.name
        },
      }
    });
    return data?.updateTag;
  }, [tagUpdate]);

  return {
    update: updateTag,
    loading
  };
}

export const useDeleteTag = () => {
  const [tagDelete, { loading }] = useMutation(ADMIN_DELETE_TAG);

  const deleteTag = useCallback(async (values: any) => {
    const { data } = await tagDelete({
      variables: {
        id: {
          id: values.id
        }
      }
    });
    return data;
  }, [tagDelete]);

  return {
    delete: deleteTag,
    loading
  };
}