export const analysesLists = [
  {
    id: 1,
    title: 'Hair and Mineral analysis',
    status: 'Not available yet'
  },
  {
    id: 2,
    title: 'Heart Rate Variability',
    status: 'Not available yet'
  },
  {
    id: 3,
    title: 'IR Combi',
    status: 'Not available yet'
  },
  {
    id: 4,
    title: 'Iodine Test',
    status: 'Not available yet'
  },
];