import { Button } from 'antd';
import RadioBtn from '../../Common/Form/RadioBtn';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../constants';
import { StyledForm } from './style';

const SurgeriesForm = () => {
  const [form] = StyledForm.useForm();
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };
  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="surgeries_form"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>Have you had any surgeries?</p>
      <RadioBtn
        name="client_surgeries"
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
      />
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  )
}

export default SurgeriesForm;