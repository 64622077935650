import { useMutation } from '@apollo/client';
import { PROFESSIONAL_CREATE_CLIENT_HEALTH_COMPLAINT_NOTES, PROFESSIONAL_UPDATE_CLIENT_HEALTH_COMPLAINT_NOTES } from '../../services/graphql/mutations/professional';
import { useCallback } from 'react';

export const useCreateProfessionalClientHealthComplaintNotes = () => {
  const [createProfessionalClientHealthComplaintNotes, { loading }] = useMutation(PROFESSIONAL_CREATE_CLIENT_HEALTH_COMPLAINT_NOTES);

  const createData = useCallback(async (values: any) => {
    console.log('createDAta', values);
    try {
      const { data } = await createProfessionalClientHealthComplaintNotes({
        variables: {
          input: {
            professionalComplaintData: {
              content: values.content,
              tags: values.tags,
              clientId: values.clientId,
              attachments: values.attachments
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in createProfessionalClientHealthComplaintNotes: ', error);
    }
  }, [createProfessionalClientHealthComplaintNotes]);

  return {
    create: createData,
    loading
  };
};

export const useUpdateProfessionalClientHealthComplaintNotes = () => {
  const [updateProfessionalClientHealthComplaintNotes, { loading }] = useMutation(PROFESSIONAL_UPDATE_CLIENT_HEALTH_COMPLAINT_NOTES);

  const updateData = useCallback(async (values: any) => {
    console.log('updateData', values.tags);
    try {
      const { data } = await updateProfessionalClientHealthComplaintNotes({
        variables: {
          input: {
            params: {
              id: values.id,
              content: values.content,
              tags: values.tags,
              attachments: values.attachments
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in updateProfessionalClientHealthComplaintNotes: ', error);
    }
  }, [updateProfessionalClientHealthComplaintNotes]);

  return {
    update: updateData,
    loading
  };
};