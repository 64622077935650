import { Radio } from 'antd';
import { ChoicesContainer } from './style';

type MultiRowQuestionProps = {
  label: string
  subQuestions: any
  order: number
  choicesLabelType: 'number' | 'alphabet'
  handleRadioToggleMulti: (choicesIndex: number, subQuestionIndex: number, questionsIndex: number) => void
};

const MultiRowQuestion: React.FC<MultiRowQuestionProps> = ({ label, subQuestions, order, choicesLabelType, handleRadioToggleMulti }) => {
  return (
    <ChoicesContainer>
      <h3>{`${order} ${order ? '.' : ''} ${label}`}</h3>
      <Radio.Group>
        <table>
          <thead>
            <tr>
              <th style={{ width: '5%' }}></th>
              <th style={{ width: '40%' }}></th>
              {subQuestions[0].choices.map((choice: any, index: number) => (
                <th key={`title_${index}`}>{choice.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {subQuestions.map((subQuestion: any, subQuestionIndex: number) => (
              <tr key={`row_${subQuestionIndex}`}>
                <td style={{ whiteSpace: 'noWrap' }}>{(choicesLabelType === 'alphabet' ? String.fromCharCode(97 + subQuestionIndex) : subQuestionIndex + 1) + '.'}</td>
                <td style={{ textAlign: 'left', paddingLeft: '10px', width: '40%' }}>{subQuestion.label}</td>
                {subQuestion.choices.map((choice: any, choiceIndex: number) => {
                  // const radioRef: any = useRef();
                  return (
                    <td key={`choice_${choiceIndex}`}>
                      <div onClick={(e) => {
                        e.stopPropagation();
                        handleRadioToggleMulti(choiceIndex, subQuestionIndex, order);
                        // radioRef.current.click();
                      }}>
                        <input
                          type="radio"
                          // ref={radioRef}
                          value={choice.title}
                          onChange={() => handleRadioToggleMulti(choiceIndex, subQuestionIndex, order)}
                          name={`radios_multi_question_${subQuestionIndex}`}
                          checked={choice.selected}
                        />
                      </div>
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Radio.Group>
    </ChoicesContainer>
  );
};

export default MultiRowQuestion;