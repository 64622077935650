import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADMIN_INPUT_HMA, ADMIN_INPUT_HRV } from '../../services/graphql/mutations/admin';
import { useSelector } from 'react-redux';

interface CreateHmaInput {
  professionalId: string;
  clientMutationId?: string;
  // hrvData: any;
  hrvData: {
    clientId: number,
    reportNumber: string,
    dateOfAnalysis: Date,
    durationOfAnalysis: number,
    specialistReport: string
  }
}

export function useCreateHRV() {
  const [adminCreateHRV, { loading }] = useMutation(ADMIN_INPUT_HRV);
  const [result, setResult] = useState<any>([]);

  const createData = useCallback(async (values: CreateHmaInput) => {
    try {
      const { data } = await adminCreateHRV({
        variables: {
          input: {
            ...values
          },
        }
      });
      setResult(data);
      return data;
    } catch (error) {
      console.error('Error in adminCreateHRV: ', error);
    }
  }, [adminCreateHRV]);

  return {
    data: result,
    create: createData,
    loading
  };
}
