function Heart() {
  return (
    <i className="material-icons-outlined ant-menu-item-icon icon-font">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        className="fill-current"
        viewBox="0 0 195.296 195.296"
        fill="white"
      >
        <path d="M6.168 98.509h62.714l9.86-46.453a4.981 4.981 0 015.025-3.96 5 5 0 014.806 4.223l8.212 52.346 5.126-16.338a5 5 0 019.126-.959l7.196 12.765 70.343-.545c2.813-5.474 4.885-11.471 5.908-18.094 2.852-18.445-1.915-37.002-12.749-49.641-9.598-11.196-23.243-17.113-39.459-17.113-25.163 0-39.46 16.897-44.629 24.519-5.168-7.622-19.466-24.519-44.629-24.519-16.216 0-29.861 5.917-39.459 17.113C2.726 44.491-2.041 63.049.811 81.494a58.995 58.995 0 005.357 17.015z"></path>
        <path d="M115.364 110.155c-1.798-.029-3.501-.96-4.394-2.544l-2.974-5.275-8.068 25.714a5 5 0 01-9.71-.722l-7.323-46.676-5.072 23.896a5 5 0 01-4.891 3.962H12.253c11.886 16.212 30.28 27.131 44.996 35.856 7.213 4.277 14.026 8.317 18.764 12.182 17.495 14.272 19.096 21.473 19.155 21.773.154 1.249 1.21 2.236 2.476 2.236 1.292 0 2.367-.94 2.484-2.227.012-.071 1.37-7.272 19.157-21.782 4.738-3.865 11.551-7.905 18.764-12.182 14.372-8.522 32.255-19.134 44.155-34.728l-66.84.517z"></path>
      </svg>
    </i>

  );
}

export default Heart;