import { Card, Divider } from 'antd'
import styles from './Summary.module.scss';

const ProfessionalSummary = () => {
  return (
    <Card>
      <div className={styles.mainTitle}>
        <p>Gesprekssamenvatting en acties</p>
        <Divider className={styles.divider} />
      </div>
      <div>
        <h4>22 feb. 2022 11:27</h4>
        <p>lakjsdkfjalskdjflaksjldfjlkajslkdjflkasdf</p>
        <Divider className={styles.divider} />
      </div>
      <div>
        <h4>01 nov. 2021 16:05</h4>
        <p>Dit is de samenvatting van de lancering van deze nieuwe funcitonaliteit. Dit is de samenvatting van de lancering van deze nieuwe functionaliteit. D...</p>
        {/* <Divider className={styles.divider} /> */}
      </div>
    </Card>
  )
}

export default ProfessionalSummary