import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';
import MultiRowQuestion from '../../../../components/Questionnaire/MultiRowQuestion';

const StressIndicators = () => {
  const dispatch = useDispatch();
  const stressIndicatorQuestionnaire = useSelector((state: any) => state.questionnaires.stressIndicator);
  const currentStressIndicatorQuestionnaire = useMemo(() => {
    return stressIndicatorQuestionnaire?.questions.filter((q: any) => q.page === stressIndicatorQuestionnaire.currentPage)
  }, [stressIndicatorQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: stressIndicatorQuestionnaire.currentPage + 1,
      type: 'stressIndicator'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: stressIndicatorQuestionnaire.currentPage - 1,
      type: 'stressIndicator'
    }));
  };

  const handleRadioToggleMulti = (choicesIndex: number, subQuestionsIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleMultiChoice({ choicesIndex, subQuestionsIndex, questionsIndex }));
  };

  return (
    <Container>
      <h1>Stress Indicators</h1>
      <div className='paragraphs'>
        <p>This questionnaire will show how stress affects different parts of your life. No single question in this questionnaire proves you are experiencing stress, but by looking at the results of groups of questions, it may be possible to define what areas of your life stress affects the most. Answer the question which best indicates how often you experience each stress indicator during a typical week.
        </p>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${stressIndicatorQuestionnaire.currentPage} of ${stressIndicatorQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentStressIndicatorQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <MultiRowQuestion
                  label={question.label}
                  subQuestions={question.subQuestions}
                  handleRadioToggleMulti={handleRadioToggleMulti}
                  order={question.question_num}
                  choicesLabelType='number'
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {stressIndicatorQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {stressIndicatorQuestionnaire.currentPage === stressIndicatorQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
};

export default StressIndicators;

