import { Checkbox, Form } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const NewQuestionnaireModal = styled.div`
  margin-top: 2rem;
  > p {
    font-weight: 700;
    font-size: 1rem;
  }

  .checkbox-wrapper {
    margin: .5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  > span:nth-child(2) {
    span {
      border-bottom: 2px dashed #bbb;
      margin-right: 0.25rem;
      &:hover {
        border-bottom: 2px dashed #ffd900;
      }

      &:nth-child(2) {
        color: ${theme.color.primary};
      }
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledForm = styled(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;