import { ReactNode, createContext, useMemo } from 'react';

interface Props {
  children: ReactNode
}

export const NotificationContext = createContext<{ name: string } | undefined>(undefined);

const NotificationProvider: React.FC<Props> = ({ children }) => {
  const contextValue = useMemo(() => ({ name: 'Notification' }), []);
  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
};

export default NotificationProvider;