import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion, { SingleQuestionCol } from '../../../../components/Questionnaire/SingleQuestion';

const GeneralWellBeing = () => {
  const dispatch = useDispatch();
  const generalWellBeingQuestionnaire = useSelector((state: any) => state.questionnaires.generalWellBeing);
  const currentGeneralWellBeingQuestionnaire = useMemo(() => {
    return generalWellBeingQuestionnaire?.questions.filter((q: any) => q.page === generalWellBeingQuestionnaire.currentPage)
  }, [generalWellBeingQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: generalWellBeingQuestionnaire.currentPage + 1,
      type: 'generalWellBeing'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: generalWellBeingQuestionnaire.currentPage - 1,
      type: 'generalWellBeing'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'generalWellBeing' }));
  };

  const renderContent = (question: any) => {
    switch (question.direction) {
      case 'row':
        return (
          <SingleQuestion
            label={question.label}
            choices={question.choices}
            handleRadioToggle={handleRadioToggle}
            order={question.question_num}
            minMaxLabel
            maxLabel={question.maxLabel}
            minLabel={question.minLabel}
          />
        )
      default:
        return (
          <SingleQuestionCol
            label={question.label}
            choices={question.choices}
            handleRadioToggle={handleRadioToggle}
            order={question.question_num}
          />
        )
    }
  };

  return (
    <Container>
      <h1>General Well-Being</h1>
      <p>
        The General well-being questionnaire is a self-administered questionnaire that focuses on one’s subjective feelings of psychological well-being and distress. The scale assesses how the individual feels about his/ her “inner personal state”. It consists of 18 items covering six dimensions of anxiety, depression, general health, positive well-being, self-control and vitality. The scale includes both positive and negative questions and each item has the time frame “during the last month”. The first 14 questions use six-point response scales representing intensity or frequency. The remaining four questions use 0-to-10 rating scales defined by adjectives at each end.
      </p>
      <div className="page-container">
        <div>
          <p>{`Page ${generalWellBeingQuestionnaire.currentPage} of ${generalWellBeingQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentGeneralWellBeingQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                {renderContent(question)}
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {generalWellBeingQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {generalWellBeingQuestionnaire.currentPage === generalWellBeingQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default GeneralWellBeing