import { Card } from 'antd'
import { CardTitle, IntakeDataContainer, IntakeTitleContainer, ResultContainer } from './style'
import { intakeData } from '../../constants/questionnaire'

const Questionnaire = () => {
  return (
    <Card>
      <CardTitle>
        <p>Questionnaires</p>
      </CardTitle>
      <IntakeTitleContainer>
        <p>Intake</p>
      </IntakeTitleContainer>
      <IntakeDataContainer>
        <tbody>
          {intakeData.map((intake) => (
            <tr>
              <td>{intake.title}</td>
              <td>{intake.date}</td>
              <td>
                <ResultContainer>
                  {intake.isDone ? (
                    <>
                      <i className="material-icons-sharp">visibility</i>
                      <p>Resultaten</p>
                    </>
                  ) : (
                    <>
                      <i className="material-icons-sharp">play_arrow</i>
                      <p>Start</p>
                    </>
                  )}
                </ResultContainer>

              </td>
            </tr>
          ))}
        </tbody>
      </IntakeDataContainer>
    </Card>
  )
}

export default Questionnaire