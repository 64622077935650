import { gql } from '@apollo/client';

export const ADMIN_SIGNIN_MUTATION = gql`
  mutation adminSignIn($input: admin_siginInput!) {
    adminSignin(input: $input) {
      user {
        id
        email
        firstName
        familyName
        user {
          id
          timezone
          language
        }
        __typename
      },
      token
    }
  }
`;

export const ADMIN_CREATE_MUTATION = gql`
  mutation createAdmin($input: create_adminInput!) {
    createAdmin(input: $input) {
      email
      firstName
      familyName
      createdAt
      id
    }
  }
`;

export const ADMIN_UPDATE_MUTATION = gql`
mutation updateAdmin($input: update_adminInput!) {
  updateAdmin(input: $input) {
    email
    firstName
    familyName
    createdAt
    id
  }
}
`;

export const ADMIN_DELETE_MUTATION = gql`
  mutation deleteAdmin($input: delete_adminInput!) {
  deleteAdmin(input: $input) {
    email
    firstName
    familyName
    createdAt
    id
  }
}
`;

export const ADMIN_INPUT_HMA = gql`
  mutation inputHma($input: InputHmaInput!) {
    inputHma(input: $input) {
      id
    }
  }
`;

export const ADMIN_INPUT_HRV = gql`
  mutation inputHrvData($input: InputHrvDataInput!) {
    inputHrvData (input: $input) {
      hrv {
        data
      }
    }
  }
`;

export const ADMIN_INPUT_IR_COMBI = gql`
  mutation inputIrCombi($input: InputIrCombiInput!) {
    inputIrCombi(input: $input) {
      irCombi {
        id
        createdAt
      }
    }
  }
`;

export const ADMIN_INPUT_IODINE_TEST = gql`
  mutation inputIodineTest($input: InputIodineTestInput!) {
    inputIodineTest(input: $input) {
      iodineTest {
        id
        createdAt
      }
    }
  }
`;

export const ADMIN_CREATE_PROFESSIONAL_MUTATION = gql`
  mutation createProfessional($input: create_professionalInput!) {
    createProfessional(input: $input) {
      message
      success
    }
  }
`;

export const ADMIN_UPDATE_PROFESSIONAL_MUTATION = gql`
  mutation updateProfessional($input: update_professionalInput!) {
    updateProfessional(input: $input) {
      clientMutationId
      message
      success
    }
  }
`;

export const ADMIN_DELETE_PROFESSIONAL_MUTATION = gql`
  mutation deleteProfessional ($id: delete_professionalInput!) {
    deleteProfessional (input: $id) {
      success,
      message
    }
  }
`;

export const ADMIN_UPDATE_HMA = gql`
  mutation updateHmaData ($input: UpdateHmaInput!) {
    updateHma(input: $input) {
      hma	{
        id
      }
    }
  }
`;

export const ADMIN_UPDATE_HRV = gql`
  mutation updateHrvData ($input: UpdateHrvInput!) {
    updateHrv(input: $input) {
      hrv	{
        id
      }
    }
  }
`;

export const ADMIN_UPDATE_IRCOMBI = gql`
  mutation updateIRCombi ($input: UpdateIrCombiInput!) {
    updateIrCombi (input: $input) {
      irCombiData {
        crpHs
      }
    }
  }
`;

export const ADMIN_UPDATE_IODINE_TEST = gql`
  mutation updateIodineTest ($input: UpdateIodineTestInput!) {
    updateIodineTest (input: $input) {
      iodineTest {
        testData {
          iodineTestId
        }
      }
    }
  }
`;

export const ADMIN_SIGNIN_AS_PROFESSIONAL = gql`
    mutation signInAsProfessional($input: sign_in_as_professionalInput!) {
    signInAsProfessional (input: $input) {
      token
      clientMutationId
      user {
        ... on Professional {
          id
          firstName
          familyName
          code
          type
          email
          contactDetail {
            addressPlace
            addressStreet
            addressCountry
            telephoneNumber
            addressPostalCode
          }
          twoFactorEnabled
          user {
            id
            isActive
            language
            timezone
          }
        }
      }
    }
  }
`;

export const ADMIN_UPDATE_TAG = gql`
  mutation updateTag($input: terms_update_tagInput!) {
    updateTag(input: $input) {
      success
      message
    }
  }
`;

export const ADMIN_DELETE_TAG = gql`
  mutation deleteTag($input: terms_delete_tagInput!) {
    deleteTag(input: $input) {
      success
      message
    }
  }
`;

export const ADMIN_CREATE_COMMUNITY_SUBGROUP = gql`
  mutation createCommunitySubgroup ($input:community_create_subgroupInput!) {
    createCommunitySubgroup(input:$input) {
      message
      success
    }
  }
`;

export const ADMIN_UPDATE_COMMUNITY_SUBGROUP = gql`
  mutation updateCommunitySubgroup ($input: community_update_subgroupInput!) {
    updateCommunitySubgroup(input:$input) {
      message
      success
    }
  }
`;

export const ADMIN_DELETE_COMMUNITY_SUBGROUP = gql`
  mutation deleteCommunitySubgroup ($input:community_delete_subgroupInput!) {
    deleteCommunitySubgroup(input:$input) {
      message
      success
    }
  }
`;

export const ADMIN_TOGGLE_PROFESSIONAL_VIEWABLE = gql`
  mutation toggleProfessionalViewable($input: analyses_toggle_professional_viewableInput!) {
    toggleProfessionalViewable(input: $input) {
      analysesId
      isViewable
      analysesType
    }
  }
`;