import { Divider, Layout, Popover } from 'antd';
import styles from './CustomHeader.module.scss';
import { Link } from 'react-router-dom';
import { LinkContainer } from './style';
import { useState } from 'react';
import MaterialIcon from '../../components/Icons/MaterialIcon';
import Cookies from 'js-cookie';

const { Header } = Layout;

const CustomHeader = () => {
  const user = Cookies.get('user');
  const userData = user ? JSON.parse(user ?? '') : '';
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const handleOpenChange = (open: boolean) => {
    setPopoverVisible(open);
  };

  const text = <span className={styles.username}>Account</span>;
  const content = (
    <div>
      <Divider />
      <LinkContainer>
        <Link onClick={() => handleOpenChange(false)} to="account/edit" className={styles.link}>Edit account info
          <MaterialIcon className={`material-icons-outlined ${styles.linkIcon}`} icon="account_circle" />
        </Link>
      </LinkContainer>
      {userData.__typename !== 'Admin' && (
        <>
          <Divider />
          <LinkContainer>
            <Link onClick={() => handleOpenChange(false)} to="action_plan_statuses" className={styles.link}>Client statuses
              <MaterialIcon className={`material-icons-outlined ${styles.linkIcon}`} icon="label" />
            </Link>
          </LinkContainer>
          <Divider />
          <LinkContainer>
            <Link onClick={() => handleOpenChange(false)} to="two_factor_authentication/edit" className={styles.link}>Security
              <MaterialIcon className={`material-icons-outlined ${styles.linkIcon}`} icon="settings" />
            </Link>
          </LinkContainer>
        </>
      )}
    </div>
  );

  return (
    <Header className={styles.headerContainer} style={{
      overflow: 'hidden',
      position: 'sticky',
      top: 0
    }}>
      <Popover
        placement="bottomRight"
        title={text}
        content={content}
        arrow={false}
        trigger="click"
        open={popoverVisible}
        onOpenChange={handleOpenChange}
      >
        <span className={styles.username}>
          {`${userData.firstName} ${userData.familyName}`}
          <MaterialIcon className={`material-icons-sharp ${styles.linkIcon}`} icon="settings" />
        </span>
      </Popover>
    </Header>

  )
}

export default CustomHeader;