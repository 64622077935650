import { createSlice } from '@reduxjs/toolkit';
import { timezoneInitialState } from './initial_states';

export const timeZoneSlice = createSlice({
  name: 'timezone',
  initialState: timezoneInitialState,
  reducers: {
    setTimezones(state, action) {
      const timezones = action.payload;
      state.timezones = timezones;
    },
  }
});

export const timeZoneActions = timeZoneSlice.actions;