import { useMutation } from '@apollo/client';
import { useCallback } from 'react'
import Cookies from 'js-cookie';
import { USER_SIGNIN_MUTATION } from '../../services/graphql/mutations/auth';
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux/slices';
import { ADMIN_SIGNIN_MUTATION } from '../../services/graphql/mutations/admin';

export const useNormalAuth = () => {
  const dispatch = useDispatch();
  const { storeAuth } = authActions;
  const [userSignInMutation, { loading }] = useMutation(USER_SIGNIN_MUTATION);

  const signIn = useCallback(async (values: { email: string, password: string }) => {
    try {
      const { data } = await userSignInMutation({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      });
      const user = data?.userSignin?.user;
      const token = data?.userSignin?.token;
      const userType = data?.userSignin.user.__typename;

      Cookies.set('user', JSON.stringify(user), { expires: 7 }); // Expires in 7 days
      Cookies.set('auth_token', token, { expires: 7 });

      dispatch(storeAuth({
        token,
        user
      }));

      let link = '';
      switch (userType) {
        case 'Professional':
          link = `/p/${data.userSignin?.user?.code}`;
          break;
        case 'Client':
          link = `/c/${data.userSignin?.user?.code}`;
          break;
        default:
      }
      return {
        success: true,
        message: 'Succesfully logged in.',
        link
      }
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        message: error?.graphQLErrors[0].message || 'An error occurred during sign-in.',
      };
    }
  }, [userSignInMutation, dispatch, storeAuth]);

  return {
    signIn,
    loading
  }
};

export const useAdminAuth = () => {
  const dispatch = useDispatch();
  const { storeAuth } = authActions;
  const [adminSignInMutation, { loading }] = useMutation(ADMIN_SIGNIN_MUTATION);

  const signIn = useCallback(async (values: { email: string, password: string }) => {
    try {
      const { data } = await adminSignInMutation({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      });
      const user = data?.adminSignin?.user;
      const token = data?.adminSignin?.token;

      Cookies.set('user', JSON.stringify(user), { expires: 7 }); // Expires in 7 days
      Cookies.set('auth_token', token, { expires: 7 });

      dispatch(storeAuth({
        token,
        user
      }));

      return {
        success: true,
        message: 'Succesfully logged in.',
        link: '/a'
      }
    } catch (error: any) {
      console.error(error);
      return {
        success: false,
        message: error?.graphQLErrors[0].message || 'useAdminAuth: An error occurred during sign-in.',
      };
    }
  }, [adminSignInMutation, dispatch, storeAuth]);

  return {
    signIn,
    loading
  }
};