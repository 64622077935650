import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';

const Expressivity = () => {
  const dispatch = useDispatch();
  const expressivityQuestionnaire = useSelector((state: any) => state.questionnaires.expressivity);
  const currentExpressivityQuestionnaire = useMemo(() => {
    return expressivityQuestionnaire?.questions.filter((q: any) => q.page === expressivityQuestionnaire.currentPage)
  }, [expressivityQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: expressivityQuestionnaire.currentPage + 1,
      type: 'expressivity'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: expressivityQuestionnaire.currentPage - 1,
      type: 'expressivity'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'expressivity' }));
  };

  return (
    <Container>
      <h1>Expressivity</h1>
      <div className='paragraphs'>
        <p>A 16-item scale designed to measure your individual emotional expressivity. The scale is separated into 3 facets: Negative Expressivity, Positive Expressivity, and Impulse Strength.</p>
        <p>Each item is answered on a 7-point Likert-type ranging from 1 (strongly disagree) to 7 (strongly agree).</p>
        <p>This questionnaire is designed to assess the following:</p>
        <ol type='a'>
          <li>the general strength of emotion-response tendencies, as measured by the Impulse Strength sub-scale and</li>
          <li>the degree to which such tendencies are typically expressed as manifest behavior.</li>
        </ol>
        <p>The outcome of this questionnaire will be discussed with you in our meeting.</p>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${expressivityQuestionnaire.currentPage} of ${expressivityQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentExpressivityQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <SingleQuestion
                  label={question.label}
                  choices={question.choices}
                  handleRadioToggle={handleRadioToggle}
                  order={question.question_num}
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {expressivityQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {expressivityQuestionnaire.currentPage === expressivityQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default Expressivity;

