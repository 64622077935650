import React, { useEffect, useState } from 'react'
import { StyledForm, TagsWrapper } from './style';
import { Button, Checkbox, Col, Input, message, Row, Upload, UploadProps } from 'antd';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../constants';
import useTags from '../../../hooks/common/useTags';
import Tiptap from '../../Common/Form/Tiptap';
import { UploadOutlined } from '@ant-design/icons';
import { ProfessionalHealthComplaintNotesType, TagsType } from '../../../types';
import { useParams } from 'react-router-dom';
import { useCreateProfessionalClientHealthComplaintNotes, useUpdateProfessionalClientHealthComplaintNotes } from '../../../hooks/professionals/useHealthComplaintNotes';
import { useGetProfessionalClient } from '../../../hooks/professionals/useProfessionalClient';

interface HealthComplaintNotesFormProps {
  type?: 'new' | 'edit'
  defaultValues?: Partial<ProfessionalHealthComplaintNotesType>
  onReset?: boolean
}
const HealthComplaintNotesForm: React.FC<HealthComplaintNotesFormProps> = ({
  type,
  defaultValues,
  onReset
}) => {
  const { clientId } = useParams();
  const [form] = StyledForm.useForm();
  const { data: tags } = useTags();
  const { create: createHealthComplaintNotes, loading: loadingCreateHealthComplaintNote } = useCreateProfessionalClientHealthComplaintNotes();
  const { update: updateHealthComplaintNotes, loading: loadingUpdateHealthComplaintNote } = useUpdateProfessionalClientHealthComplaintNotes();
  const { refetch: refetchProfessionalClient } = useGetProfessionalClient(parseInt(clientId as string));
  const [description, setDescription] = useState<string>(defaultValues?.content ?? '');

  const props: UploadProps = {
    name: 'attachments',
    multiple: true,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file, fileList) => {
      return false;
    },
    defaultFileList: defaultValues?.attachmentUrls ? defaultValues.attachmentUrls.map((attachment: any, index: number) => ({
      uid: String(index + 1),
      name: attachment.split('/').pop(),
      status: 'done',
      url: attachment
    })) : []
  };

  const onChangeDescription = (value: string) => {
    setDescription(value);
  };

  const onFinish = async (values: any) => {
    if (type === 'edit') {
      try {
        const res = await updateHealthComplaintNotes({
          ...values,
          attachments: values?.attachments?.file ?? null
        });
        console.log('UpdateHealthComplaintForm', res);
      } catch (err) {
        console.error(err);
      } finally {
        refetchProfessionalClient();
      }
      return;
    }
    try {
      const res = await createHealthComplaintNotes({
        ...values,
        attachments: values.attachments.file,
        clientId
      });
      console.log('NewHealthComplaintNotesForm', res);
    } catch (err) {
      console.error(err);
    } finally {
      refetchProfessionalClient();
    }
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     id: defaultValues?.id,
  //     content: defaultValues?.content,
  //     tags: defaultValues?.tags,
  //     attachments: defaultValues?.attachemnts
  //   });
  // }, [defaultValues, form]);
  useEffect(() => {
    if (!onReset) {
      form.resetFields();
    }
  }, [onReset, form]);

  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="health_complaint_notes_form"
      onFinish={onFinish}
      initialValues={{ ...defaultValues }}
      layout="vertical"
      scrollToFirstError
    >
      <StyledForm.Item hidden name='id'>
        <Input />
      </StyledForm.Item>
      <StyledForm.Item
        labelAlign="left"
        name="content"
        label=""
        rules={[
          {
            required: true,
            message: 'Content can\'t be blank',
          },
        ]}
      >
        <Tiptap
          content={description}
          onChange={onChangeDescription}
        />
      </StyledForm.Item>
      <StyledForm.Item
        labelAlign="left"
        name="tags"
        label="Tags"
        rules={[
          {
            required: true,
            message: 'Tags can\'t be blank',
          },
        ]}
      >
        <Checkbox.Group>
          <TagsWrapper>
            <Row>
              {(tags ?? []).map((tag: TagsType) => (
                <Col key={tag.id} span={8}>
                  <Checkbox value={tag.name} style={{ lineHeight: '32px' }}>
                    {tag.name}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </TagsWrapper>
        </Checkbox.Group>
      </StyledForm.Item>
      <StyledForm.Item labelAlign="left" label="" name="attachments"  >
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </StyledForm.Item>
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit" loading={loadingCreateHealthComplaintNote || loadingUpdateHealthComplaintNote}>
          {type === 'edit' ? 'Update' : 'Save'}
        </Button>
      </StyledForm.Item>
    </StyledForm>
  )
}

export default HealthComplaintNotesForm