import React, { useState } from 'react';
import CommonLayout from '../../../layouts/Common';
import CommonTable from '../../../components/Common/Table';
import { ActionBtn, HeaderData, NameData, UrlData } from './style';
import { ColumnsType } from 'antd/es/table';
import { DataType } from './type';
import HMAForm from '../../../components/Forms/Admin/HMA';
import { formatDate } from '../../../helpers';
import useListAnalysesView, { useToggleProfessionalViewable } from '../../../hooks/admin/useListAnalysesView';
import { Spin } from 'antd';

const HMA = () => {
  // const { data: hmaData } = useListAllHMA();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: hmaData, total, loading, perPage } = useListAnalysesView('hma', currentPage as number);
  const { toggle, loading: toggleLoading } = useToggleProfessionalViewable();
  const [openNewHMA, setOpenNewHMA] = useState<boolean>(false);
  const [openEditHMA, setOpenEditHMA] = useState<boolean>(false);

  const columns: ColumnsType<DataType> = [
    {
      title: <HeaderData>Client</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.client}`}</NameData>
      ),
    },
    {
      title: '',
      render: (text, record) => (
        <NameData>{`${record.clientGid}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Professional</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.professional}`}</NameData>
      ),
    },
    {
      title: '',
      render: (text, record) => (
        <NameData>{`${record.professionalCode}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Added by</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.addedBy}`}</NameData>
      )
    },
    {
      title: <HeaderData>Date</HeaderData>,
      render: (text, record) => (
        <UrlData>{`${formatDate(record.date)}`}</UrlData>
      ),
    },
    {
      title: <HeaderData>Actions</HeaderData>,
      render: (text, record) => (
        <ActionBtn
          onClick={() => onToggle(record)}
          active={record.professionalViewable}
          type="text"
          icon={
            <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>policy</i>
          }
        >
          {record.professionalViewable ? 'Disable' : 'Enable'}
        </ActionBtn>
      ),
    }
  ];

  const onToggle = async (record: DataType) => {
    try {
      const result = await toggle({
        analysesId: record.id,
        analysesType: 'hma',
        professionalCode: record.professionalCode
      });

      if (result) {
        console.log(result);
      }

    } catch (err) {
      console.error('onToggle Error: ', err);
    }
  }

  const handleBack = () => {
    setOpenNewHMA(false);
    setOpenEditHMA(false);
  };

  return (
    <CommonLayout title="HMA" width="1000px">
      {loading ? <Spin size="large" /> :
        <CommonTable
          showNewModal={openNewHMA}
          onNewData={setOpenNewHMA}
          showEditModal={openEditHMA}
          onEditData={setOpenEditHMA}
          handleBack={handleBack}
          modalForm={
            <HMAForm
              onReset={openNewHMA}
            />
          }
          columns={columns}
          dataSource={hmaData}
          tableType="HMA"
          modalWidth={1300}
          total={`${total} rows`}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          totalData={total}
        />
      }
    </CommonLayout>
  )
};

export default HMA;