import sc from 'styled-components';
import { theme } from '../../styles/theme';

export const CardTitle = sc.div`
  margin-bottom: 10px;
  p {
    font-size: 1.125rem;
  }
`;

export const IntakeTitleContainer = sc.div`
  padding: 0.25rem;
  background-color: rgba(247,250,252);
  border-bottom: solid 2px ${theme.color.gray};
  border-top: solid 2px ${theme.color.gray};
  text-align: center;

  p {
    font-size: 1rem;
  }
`;

export const IntakeDataContainer = sc.table`
  width: 100%;
  border-collapse: collapse;

  tbody tr {
    border-bottom: 1px solid ${theme.color.gray};
    td:nth-child(1) {
      width: 60%;
    }

    td:nth-child(2) {
      font-size: .75rem;
    }
  }
`;

export const ResultContainer = sc.div`
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    font-size: 16px;
    color: ${theme.color.primary};
  }

  i {
    font-size: 20px;
    color: ${theme.color.primary};
    margin-top: 3px;
  }
`;