import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROFESSIONAL } from '../../services/graphql/queries/professional';
import { professionalActions } from '../../redux/slices/professionalSlice';
import { GetProfessionalType } from '../../services/graphql/queries/types';

export default function useGetProfessional(professionalId: string) {
  const { data, loading, refetch } = useQuery<GetProfessionalType>(GET_PROFESSIONAL, {
    variables: {
      id: professionalId
    }
  });

  const fetchProfessional = useCallback(() => {
    professionalActions.setCurrentProfessional(data?.getProfessional);
  }, [data]);

  return {
    data: data?.getProfessional,
    refetch,
    fetchData: fetchProfessional,
    loading
  };
}
