import React, { useMemo } from 'react'
import { CardFooter, Container, MainQuestionWrapper, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion, { SingleQuestionCol } from '../../../../components/Questionnaire/SingleQuestion';

const VitaminB12Deficiency = () => {
  const dispatch = useDispatch();
  const vitaminB12DeficiencyQuestionnaire = useSelector((state: any) => state.questionnaires.vitaminB12Deficiency);
  const currentVitaminB12DeficiencyQuestionnaire = useMemo(() => {
    return vitaminB12DeficiencyQuestionnaire?.questions.filter((q: any) => q.page === vitaminB12DeficiencyQuestionnaire.currentPage)
  }, [vitaminB12DeficiencyQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: vitaminB12DeficiencyQuestionnaire.currentPage + 1,
      type: 'vitaminB12Deficiency'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: vitaminB12DeficiencyQuestionnaire.currentPage - 1,
      type: 'vitaminB12Deficiency'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number, mainQuestionNum: number) => {
    dispatch(questionnairesActions.toggleSubChoice({ choicesIndex, questionsIndex, mainQuestionNum, type: 'vitaminB12Deficiency' }));
  };

  const renderContent = (question: any, mainQuestionNum: number) => {
    return (
      <SingleQuestionCol
        label={question.label}
        choices={question.choices}
        handleRadioToggle={(choicesIndex: number, questionsIndex: number) => handleRadioToggle(choicesIndex, questionsIndex, mainQuestionNum)}
        order={question.question_num}
      />
    )
  };

  return (
    <Container>
      <h1>Vitamin B12 Deficiency</h1>
      <div className='paragraphs'>
        <p>Vitamin B12 is produced by microorganisms. Humans can make vitamin B12 by the microorganisms in the gut, but this happens in a part of the gut where it can no longer be absorbed. We are therefore dependent on vitamin B12 in our diet. Vitamin B12 is only found in meat, fish, eggs, and dairy. People who do not eat animal products therefore run the risk of a vitamin B12 deficiency.
        </p>
        <p>
          The purpose of this questionnaire is to estimate whether you have a Vitamin B12 deficiency. The list consists of 50 questions. The answers will be processed and discussed by your Professional during your consultation.
        </p>
      </div>
      {/* <div className="page-container">
        <div>
          <p>{`Page ${vitaminB12DeficiencyQuestionnaire.currentPage} of ${vitaminB12DeficiencyQuestionnaire.totalPage}`}</p>
        </div>
      </div> */}
      <QuestionMainContainer>
        {(currentVitaminB12DeficiencyQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <MainQuestionWrapper>
                <p>{`${question.question_num}. ${question.label}`}</p>
                {question?.questions.map((q: any, indexQ: number) => (
                  <QuestionWrapper key={`question_${indexQ}`}>
                    {renderContent(q, question.question_num)}
                  </QuestionWrapper>
                ))}
              </MainQuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {vitaminB12DeficiencyQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {vitaminB12DeficiencyQuestionnaire.currentPage === vitaminB12DeficiencyQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
};

export default VitaminB12Deficiency;

