import { message } from 'antd';
import { RcFile } from 'antd/es/upload';

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  return formattedDate;
};

export const formatDateWithoutTime = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  return formattedDate;
};

export const getAge = (birthDateString: string) => {
  const birthDate = new Date(birthDateString);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const isAgeInRange = (age: number, range: string) => {
  const [min, max] = range.split('-').map(Number);
  return age >= min && age <= max;
};

export const isAgeGreater = (age: number, range: string) => {
  const [min, max] = range.split('-').map(Number);
  return age < min && age < max;
};

export const vasScoreIndicator = (score: number, isHighLowReversed?: boolean) => {
  const [lowColor, highColor] = isHighLowReversed ? ['#9ae6b4', '#feb2b2'] : ['#feb2b2', '#9ae6b4'];
  if (score >= 1 && score <= 4) return lowColor;
  if (score >= 5 && score <= 6) return '#faf089';
  if (score >= 7 && score <= 10) return highColor;
}

