import React from 'react'
import { Card } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CommonLayout from '../../../../../layouts/Common'
import Chart from 'react-apexcharts';
import { Container, HRVHeader, StyledCard } from './style'
import { StyledButton } from '../../../../../components/Common/Button/style'
import { formatDateWithoutTime } from '../../../../../helpers'

type Props = {}

type LegendType = "top" | "right" | "bottom" | "left" | undefined;

const HRVResult = (props: Props) => {
  const { hrvId } = useParams();
  const location = useLocation();
  const { payload } = location.state;
  const [client] = useSelector((state: any) => [state.professionals.selectedClient]);
  const navigate = useNavigate();

  const onNavigate = (url: string) => {
    navigate(url, {
      state: {
        payload
      }
    });
  };

  const stressAndRecoverySeries = [
    {
      name: 'Day 1',
      type: 'column',
      data: [100, 50, 100, 23, 50, 100, 23, 54, 23, 62, 35]
    },
    {
      name: 'Day 2',
      type: 'column',
      data: [54, 76, 24, 27]
    },
    {
      name: 'Good balance',
      type: 'line',
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
    },
  ];

  const stressAndRecoveryOptions = {
    chart: {
      id: 'line',
    },
    legend: {
      position: 'top' as LegendType,
      offsetY: -20,
      // markers: {
      //   width: 40,
      //   height: 15,
      //   radius: 0
      // }
    },
    stroke: {
      width: [0, 5],
      // curve: 'smooth'
    },
    title: {
      text: 'Stress and Recovery Profile'
    },
    xaxis: {
      categories: [
        'Self-reported sleep quality',
        'Amount of stress reactions (% >60%)',
        'Energy Expenditure (% kcal)',
        'Time of recovery Day / Night (% >30%)',
        'Length of sleep (% > 7 hours)',
        'Amount of recovery during sleep (% > 75%)',
        'Age based Quality of Good recovery (% ms)',
        'RMSSD night / Age Based Minimum RMSSD',
        'Biocoherence Recovery Index (%)'
      ]
    },
    yaxis: {
      title: {
        text: 'Relative amount (%)',
      },
      min: 0,
      max: 100,
      tickAmount: 10
    }
  };

  const rmssdSeries = [
    {
      name: 'RMSSD awake time (ms)',
      type: 'column',
      data: [54, 76]
    },
    {
      name: 'RMSSD during sleep (ms)',
      type: 'column',
      data: [23, 42],
    },
    {
      name: 'Age Based minimum RMSSD during sleep (ms)',
      type: 'column',
      data: [23, 42],
    },
    {
      name: 'Age Based minimal Good Recovery RMSSD (ms)',
      type: 'column',
      data: [23, 42],
    },
    {
      name: 'Relative RMSSD difference (%)',
      type: 'line',
      data: [100, 0]
    },
  ];

  const rmssdOptions = {
    chart: {
      id: 'line',
    },
    legend: {
      position: 'top' as LegendType,
      offsetY: 0,
      // markers: {
      //   width: 40,
      //   height: 15,
      //   radius: 0
      // }
    },
    stroke: {
      width: [0, 5],
      // curve: 'smooth'
    },
    markers: {
      size: 5
    },
    title: {
      text: 'RMSSD Profile'
    },
    xaxis: {
      categories: [
        'Day 1',
        'Day 2',
      ]
    },
    yaxis: {
      title: {
        text: 'RMSSD (ms)',
      },
      min: 0,
      max: 100,
      tickAmount: 10
    }
  };

  return (
    <CommonLayout title="HRV Results" width="900px">
      <HRVHeader>
        <Card>
          <div className="hrv-details">
            <div>
              <p>Report number:</p>
              <p>{payload.reportNumber}</p>
            </div>
            <div>
              <p>Date of analysis:</p>
              <p>{formatDateWithoutTime(payload.dateOfAnalysis)}</p>
            </div>
            <div>
              <p>Client</p>
              <p>{`${client.firstName} ${client.familyName}`}</p>
            </div>
            <div>
              <p>Client ID</p>
              <p>{client.id}</p>
            </div>
          </div>
        </Card>
        <StyledButton onClick={() => onNavigate('show_data')}>
          Show HRV data
        </StyledButton>
      </HRVHeader>
      <StyledCard>
        <Container>
          <Chart options={stressAndRecoveryOptions} series={stressAndRecoverySeries} type="line" height={350} />
        </Container>
        <Container>
          <Chart options={rmssdOptions} series={rmssdSeries} type="line" height={350} />
        </Container>
      </StyledCard>
    </CommonLayout>
  )
}

export default HRVResult