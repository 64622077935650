import { Card } from 'antd';
import styled from 'styled-components';
import { StyledTableProps } from './type';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
`;

export const StyledTable = styled.table<StyledTableProps>`
  margin-bottom: 2rem;
  width: 100%;
  border-collapse: ${(props) => props.collapsed ? 'collapse' : ''};
  border: ${(props) => `${props.border} solid` ?? ''};
  border-color: #e2e8f04d;
  break-inside: avoid;

  font-size: 16px;

  thead tr th {
    padding: .25rem .5rem;
  }

  tbody tr td {
    padding: .25rem .5rem;
    text-align: center;
  }

  .bluebackground {
    background-color: #edf2f7;
    font-weight: 700;
    text-align: left;
  }

  .text-left {
    text-align: left;
  }

  .bold {
    font-weight: 700;
  }

  .capitalize {
    text-transform: capitalize;
  }
`;

export const OrderStyle = styled.ol`
  padding: 0;

  .ml-2 {
    margin-left: 2.5rem;
  }

  li {
    list-style-position: inside;
    &::before {
      content: "";
      width: 1rem;
      display: inline-block;
    }
  }
`;

export const UnorderedStyle = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  padding: 0;
  margin: 0;
`;

export const BackgroundInformationWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > div {
    > h3 {
      margin-bottom: 0.75rem;
      font-size: 1.125rem;
      font-weight: 700;
    }

    > h4 {
      font-size: 1rem;
      font-weight: 700;
    }

    > P {
      font-size: 1rem;
    }
  }
`;

export const AnalysisResultsWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  > h3 {
    margin-bottom: 0.75rem;
    text-decoration: underline;
    font-size: inherit;
    font-weight: 700;
  }
`;

export const InterpretationResultWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > h3 {
    margin-bottom: 0.75rem;
    text-decoration: underline;
    font-size: 1.125rem;
    font-weight: 700;
  }

  > div {
    > h4 {
      font-size: 1rem;
      font-weight: 700;
    }

    > P {
      font-size: 1rem;
    }
  }
`;

export const AdvanceSupplementProtocolWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > h3 {
    font-size: 1.125rem;
    font-weight: 700;
  }

  > div {
    > h4 {
      font-size: 1rem;
      font-weight: 700;
    }

    > P {
      font-size: 1rem;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .disclaimer {
    font-style: italic;
    font-size: .75rem;
    h5 {
      font-size: .75rem;
      font-weight: 700;
    }
  }

  .info {
    border-top: 2px solid #2d3748;
    padding-top: 1.5rem;
    font-size: .75rem;

    .sup {
      margin-bottom: 1.25rem;
    }
  }
`;