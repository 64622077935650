import React, { useState } from 'react'
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../../constants';
import { StyledForm } from './style';
import { Button, Form, Input } from 'antd';
import Tiptap from '../../../Common/Form/Tiptap';

const TermsForm = () => {
  const [form] = StyledForm.useForm();
  const [description, setDescription] = useState<string>('');

  const onChangeDescription = (value: string) => {
    setDescription(value);
  }

  const onFinish = async (values: any) => {

  };


  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="terms_form"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <Form.Item
        labelAlign="left"
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Name can\'t be blank',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: 'Description can\'t be blank',
          },
        ]}
      >
        <Tiptap
          content={description}
          onChange={onChangeDescription}
        />
      </Form.Item>
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  );
};

export default TermsForm;