import { createSlice } from '@reduxjs/toolkit';
import { authInitialState, userInitialState } from './initial_states';

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    storeUser(state, action) {
      const user = action.payload;
      state.user = user;
    }
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    storeAuth(state, action) {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;
      state.isAuthenticated = true;
    },
    removeAuth(state) {
      state = {
        ...authInitialState
      };
    }
  }
});


export const userActions = userSlice.actions;
export const authActions = authSlice.actions;