import React from 'react'
import CommonLayout from '../../../../../layouts/Common'
import { AnalysisResultsWrapper, BackgroundInformationWrapper, Container, HOMA2Wrapper, HOMAWrapper, HomocysteineWrapper, HsCRPWrapper, LDLoxWrapper, OrderStyle, StyledCard, StyledTable } from './style'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatDateWithoutTime } from '../../../../../helpers'

type Props = {}

const IRCombiResult = (props: Props) => {
  const { irCombiId } = useParams();
  const location = useLocation();
  const { payload } = location.state;
  const [client, professional] = useSelector((state: any) => [state.professionals.selectedClient, state.auth.user])
  console.log(irCombiId, payload);
  return (
    <CommonLayout title="Results of Insulin Resistance Combi Analysis" width="800px">
      <StyledCard>
        <Container>
          <h2>Insulin Resistence Combi analysis</h2>
          <StyledTable>
            <tbody>
              <tr>
                <td>Client:</td>
                <td className="bold">{`${client.firstName} ${client.familyName}`}</td>
                <td>Client ID:</td>
                <td className="bold">{client.id}</td>
              </tr>
              <tr>
                <td>Date of birth:</td>
                <td className="bold">{formatDateWithoutTime(client.bio.dateOfBirth)}</td>
                <td>Sex:</td>
                <td className="bold capitalize">{client.bio.sex}</td>
              </tr>
              <tr>
                <td>Professional:</td>
                <td className="bold">{`${professional.firstName} ${professional.familyName}`}</td>
                <td>Diabetes known:</td>
                <td className="bold">{payload.isDiabetic ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Date:</td>
                <td className="bold">{formatDateWithoutTime(payload.createdAt)}</td>
                <td>Insulin use:</td>
                <td className="bold">{payload.isSyntheticInsulinUse ? 'Yes' : 'No'}</td>
              </tr>
            </tbody>
          </StyledTable>
          <BackgroundInformationWrapper>
            <h3>1. Background Information</h3>
            <p>An increased Ca/Mg ratio (Lifestyle ratio) and the sugar sensitivity in the Hair Mineral Analysis, but also your BMI, are reasons to have a series of blood values measured that determine the presence, extent and degree of insulin resistance and its consequences (in terms of low-grade inflammation and cardiovascular disease risk). The combined determination of insulin, C-peptide and glucose in the blood makes it possible to determine the so-called HOMA index. The HOMA index is an index that indicates the presence and degree of insulin resistance.</p>
          </BackgroundInformationWrapper>
          <AnalysisResultsWrapper>
            <h3>2. Analysis results</h3>
            <StyledTable collapsed border='1px'>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Unit</th>
                  <th>Reference value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={99}>
                    Liver, pancreas, heart, muscle
                  </td>
                </tr>
                <tr>
                  <td>CRP-HS</td>
                  <td>{payload.crpHs.toFixed(1)}</td>
                  <td>mg / l</td>
                  <td>0.00 - 1.00</td>
                </tr>
                <tr>
                  <td colSpan={99}>
                    Lipid metabolism
                  </td>
                </tr>
                <tr>
                  <td>LDL-ox antibodies</td>
                  <td>{payload.ldlOxAntistoffen.toFixed(1)}</td>
                  <td>miU / ml</td>
                  <td>{'<= 800'}</td>
                </tr>
                <tr>
                  <td colSpan={99}>
                    Glucose metabolism
                  </td>
                </tr>
                <tr>
                  <td>Glucose (fasting)</td>
                  <td>{payload.glucose.toFixed(1)}</td>
                  <td>mmol / ml</td>
                  <td>{'4.5 – 6.5'}</td>
                </tr>
                <tr>
                  <td>Insulin</td>
                  <td>{payload.insuline.toFixed(1)}</td>
                  <td>pmol / l</td>
                  <td>{'15-90'}</td>
                </tr>
                <tr>
                  <td>C-Peptide</td>
                  <td>{payload.insuline.toFixed(1)}</td>
                  <td>nmol / l</td>
                  <td>{'0.25 – 0.90'}</td>
                </tr>
                <tr>
                  <td>Homocysteine (Plasma)</td>
                  <td>{payload.homocysteine.toFixed(1)}</td>
                  <td>umol / l</td>
                  <td>{'< 6.3'}</td>
                </tr>
              </tbody>
            </StyledTable>
            <h3>HOMA 2 values (Calculated)</h3>
            <StyledTable collapsed border='1px'>
              <thead>
                <tr>
                  <th></th>
                  <th>IR value</th>
                  <th>% ß cel activity</th>
                  <th>% insulin sensitivity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>HOMA 2 – diabetes / no insulin use</td>
                  <td>{payload.homa2CInsulin.toFixed(1)}</td>
                  <td>{payload.homa2CInsulinPctB.toFixed(1)}</td>
                  <td>{payload.homa2CInsulinPctS.toFixed(1)}</td>
                </tr>
              </tbody>
            </StyledTable>
            <p>Healthy HOMA IR range Glucose: 1.0 (0.5–1.4)</p>
            <p>Less than 1.0 means you are insulin sensitive, that's optimal.</p>
            <p>Above 1.9 means early insulin resistance</p>
            <p>Above 2.9 means significant insulin resistance.</p>
            <br />
            <OrderStyle>
              <li>Insulin resistance is often accompanied by a systemic inflammatory activity that we call 'low-grade inflammation'. High-sensitive CRP is a good parameter to determine this. Your HS-CRP value is 1.0 mg/l. This means you have an intermediate level of low-grade inflammation, and an intermediate risk for cardiovascular disease, and chronic disease.</li>
              <li>Antibodies against oxidized LDL cholesterol are an important indicator of cardiovascular disease (a common manifestation of insulin resistance). Your LDL-ox antibody value is 1.0 miU/ml. This means that based on this value, you do not run an increased risk of cardiovascular disease.</li>
              <li>Homocysteine: major risk factor for insulin resistance, diabetes, cardiovascular disease, cancer, Alzheimer's, susceptibility to hip fractures and various psychiatric disorders. Your homocysteine value is 2.0 umol/l. This means that you are not at an increased risk for chronic diseases.</li>
              <li>Fasting Glucose concentration together with insulin (in case of diabetes) and glucose and C-Peptide (in case no diabetes has yet been diagnosed) allows to determine the HOMA-Insulin resistance index, a scientifically documented measure for determining the degree of insulin resistance.</li>
              <li>C-Peptide: In the evaluation of the endogenous insulin reserve, the determination of C-peptide in the peripheral blood has several advantages over that of insulin. C-peptide is (not like insulin) largely removed from the blood on first passage through the liver. Therefore, this value in peripheral blood gives a more reliable impression about beta cell activity than the insulin level itself. Furthermore, when using exogenous insulin, there is a high risk of the development of antibodies that complicate insulin determination. Moreover, it is impossible to distinguish exogenous insulin from endogenous insulin. The C-peptide assay can do this and is not affected by antibody formation.</li>
            </OrderStyle>
          </AnalysisResultsWrapper>
          <HsCRPWrapper>
            <h3>Explanation of the hs-CRP value</h3>
            <p>
              In 1943, Gunnar Lofstrom, a Swedish scientist, described elevated blood levels of CRP in patients having a heart attack (acute myocardial infarction).
              Studies in the 1990s further confirmed the link between CRP and atherosclerotic coronary artery disease and acute cardiovascular events such as heart attacks and strokes. However, although CRP levels were found to be elevated in patients with these conditions, the question remained whether CRP levels in healthy individuals could predict future cardiovascular events.
              With the publication of the Physician's Health Study (PHS) in 1997, there was a paradigm shift in the use of hs-CRP as a marker of cardiovascular risk.
              The study was a prospective evaluation of 22,000 initially healthy men. Baseline levels of hs-CRP were significantly higher in those who subsequently had a heart attack or stroke compared with those who didn't. The results indicated that the predictive value of hs-CRP was independent of other risk factors such as blood cholesterol and smoking. The results also suggested that hs-CRP was a better predictor of cardiovascular events than several other inflammatory biomarkers.
              The WHS (Women's Health Study) compared LDL-C with hs-CRP in nearly 28,000 healthy women followed for eight years. After adjusting for other risk factors, hs-CRP was found to be a stronger predictor of cardiovascular events than LDL-C. Women in the high hs-CRP and low LDL-C group were at greater absolute risk than the low hs-CRP and high LDL-C subgroup. However, screening for both biological markers showed a better predictive value than either test alone.
            </p>
            <br />
            <p className="italic">What is the ideal level of hs-CRP?</p>
            <p>
              Large-scale clinical trials have used an hs-CRP cutpoint of 2 mg/mL to define an increased risk of cardiovascular disease.
              This would imply that people with hs-CRP above 2 mg/mL are at increased risk.
              However, a desired value is likely to be less than 1 mg/mL. An hs-CRP level above 3 mg/mL is associated with an increased risk in most studies
            </p>
            <p>Summarizing:</p>
            <ul>
              <li>Low Risk: Less than 1.0mg/L</li>
              <li>Medium risk: 1.0 to 3.0 mg/l</li>
              <li>High risk: above 3.0mg/l</li>
              <li>Above 10mg/ml usually indicates acute inflammation</li>
            </ul>
            <p className="italic">Footnote:</p>
            <p>
              It is important to remember that both CRP and hs-CRP are elevated in a wide variety of acute and chronic inflammatory conditions such as infections, rheumatoid arthritis, many other inflammatory diseases, and many cancers. These conditions cause the release of interleukin-6 and other cytokines that mediate the synthesis of CRP by the liver.
              Because there are many different conditions that can increase CRP and hs-CRP, an elevated CRP level does not indicate a specific disease. Due to poor sensitivity and low negative predictive value, measurements of hs-CRP cannot be used to rule out disease.
              Most clinical guidelines have emphasized that data from experimental studies, epidemiological studies and large clinical trials do not provide conclusive evidence for the routine use of hs-CRP measures for risk prediction.
              At present, there is insufficient evidence to recommend widespread use of hs-CRP in clinical practice.
            </p>
          </HsCRPWrapper>
          <LDLoxWrapper>
            <h3>Explanation of the LDL-ox antibody value</h3>
            <p>
              Antibodies to oxidized LDL cholesterol is not a clear marker for cardiovascular disease (CVD) risk, but research shows that oxidation of LDL and the interaction of ox-LDL with the immune system plays a role in pathogenesis of cardiovascular disease. The significance of this marker increases in importance when there are also other parameters that indicate CVD risk, such as insulin resistance, increased HS-CRP and increased homocysteine.
            </p>
          </LDLoxWrapper>
          <HomocysteineWrapper>
            <h3>Explanation of the Homocysteine value</h3>
            <p>
              Homocysteine, a non-protein sulfuric acid amino acid, is an intermediate of the methionine cycle, also referred to as the transulfuration-methylation cycle that closely links the metabolism of sulfur amino acids to the major methylation reactions. Homocysteine is formed by the catabolism of S-adenosylmethionine or by the conversion of toxic homocysteine thiolactone to homocysteine by paraoxonase 1 (PON1), the same enzyme that detoxifies organophosphate pesticides. Eliminating homocysteine requires the presence of methyltetrahydrofolate, methylcobalamin, betaine, or pyridoxal-5-phosphate. Methylcobalamin is such a potent methylating coenzyme that it can methylate homocysteine directly to methionine without an associated enzyme.
            </p>
            <p>
              Kilmer McCully was one of the first researchers to explore the link between the toxic effects of homocysteine as a major risk factor in atherosclerosis. After examining cases of classical homocystinuria in patients in whom total plasma homocysteine levels and severe atherosclerosis, and even in teenagers, were abnormally elevated, McCully initiated moderate elevations of homocysteine as a cause of atherosclerosis in much of the entire population. population to investigate. Individuals with the severe genetic deficiency of the enzyme cystathionine b synthase had plasma homocysteine concentrations as high as 300 mmol/L. High homocysteine is also associated with other cardiovascular risk factors, such as insulin resistance, metabolic syndrome and type 2 diabetes mellitus. The optimal plasma homocysteine has been found to be 6.3 micromol/liter.
            </p>
          </HomocysteineWrapper>
          <HOMAWrapper>
            <h3>Explanation of the HOMA calculation</h3>
            <p>
              There is no absolute value for HOMA indices. The HOMA index depends on the specific assays used for glucose, insulin and C-peptide. Therefore, there are no defined thresholds for 'normal' versus 'abnormal' values.
              The HOMA calculation was derived from RIA insulin and C-peptide in a UK population of 299 and 383 non-diabetic men and women with a mean age of approximately 50 years, using insulin and C-peptide assays performed on the University of Oxford have been used between 1996 and 1998.
              The data in this report is therefore for informational purposes only and should be quoted in any publication as “personal communication, Dr JC Levy, Oxford Center for Diabetes, Endocrinology and Metabolism.”
            </p>
            <p>
              This data from the HOMA analysis should therefore only be used with the following caveats:
              HOMA indices, whether derived from insulin or C-peptide concentrations, will be highly dependent on the assays used to derive those data. The variation between the calibration of insulin assays is significant and there is no external quality assurance scheme to reconcile it (1). The situation is probably even more variable for C-peptide assays. For example, a plasma or serum sample could yield insulin concentrations by various assays that vary by as much as 200%. Glucose testing, on the other hand, has been comparable across labs for at least the last three decades. However, any difference between insulin and C-peptide assays will be reflected in the derived HOMA indices. This has two implications:
            </p>
            <br />
            <ul>
              <li>Interpretation of HOMA indices should be interpreted with reference to a local 'normal' or 'reference' population in which the same assays were used as in the particular population.</li>
              <li>HOMA indices are best suited for comparisons between populations or within populations, where the same assays have been used.</li>
            </ul>
            <p>
              There is no external criterion to categorize HOMA indices as 'normal' or 'abnormal'. They form a continuous range with an approximately normal distribution. We use the interpretation used in Oxford on the assumption that data obtained in the UK is applicable to populations in the rest of Europe.
            </p>
            <br />
            <p>References:</p>
            <p className="italic">Comparison of 11 Human Insulin Assays: Implications for Clinical Investigation and Research. Susan E., Manley, Irene M. Stratton, Penelope M. Clark, and Stephen D. Luzio. Clinical Chemistry 53:5 922–932 (2007).</p>
          </HOMAWrapper>
          <HOMA2Wrapper>
            <h3>The HOMA 2 calculation</h3>
            <p>
              The Oxford University computer model can be used to determine insulin sensitivity (%S) and beta cell function (%B) based on paired fasting plasma glucose and RIA insulin, specific insulin or C-peptide concentrations over a range of 1–2,200 pmol / l for insulin and 1–25 mmol / l for glucose.
              Clinical judgment is required when entering data: For example, a plasma glucose of {'<'} 2.5 mmol/L represents hypoglycemia, which is a non-steady-state situation, or an assay problem. In either case, such values should not be used in the model. If both C-peptide and insulin data are available, there is a logic to use C-peptide data to calculate beta cell function (since C-peptide is a marker of secretion) and to use insulin data to calculate %S (because the HOMA-%S is derived from glucose removal as a function of insulin concentration). In practice, however, insulin and glucose are commonly used to achieve both functions, as the theoretical benefit of using C-peptide must be weighed against the additional cost and practicalities of analyzing and storing the additional C-peptide samples.
              In case of a diagnosed Diabetes type II, we use the HOMA2 calculator with the glucose and insulin value. In non-diabetics we use glucose and C-peptide.
            </p>
          </HOMA2Wrapper>
        </Container>
      </StyledCard>
    </CommonLayout>
  )
}

export default IRCombiResult