import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const ClientCardsContainer = styled.div`
  margin: 2.5rem;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1023px) {
    display: none;
  }
`;

export const MobileProfessionalDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  background-color: ${theme.bg.primary};
  height: 271px;
  width: 100%;

  h2 {
    color: white;
  }

  .tabsStyle {
    font-size: 20px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 1.25rem;
`;

export const ClientName = styled.div`
  font-weight: 700;
  color: ${theme.color.primary}
`;

export const ClientEmail = styled.div`
  color: #2d3748;
`;

export const clientStyle = {
  paddingTop: '24px',
  paddingBottom: '24px'
};