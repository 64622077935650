import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { authActions, userActions } from '../../redux/slices';
import useGetAllTimezones from '../../hooks/common/useGetAllTimezones';
import useGetAllActionPlanStatuses from '../../hooks/professionals/useGetAllActionPlanStatuses';
import useGetAllCountries from '../../hooks/common/useGetAllCountries';
import useGetProfessional from '../../hooks/professionals/useGetProfessional';
import useGetProfessionalListClients from '../../hooks/professionals/useGetProfessionalListClients';

type Props = {
  element?: React.ReactNode;
  layout: React.ReactNode;
  [key: string]: any;
};

const ProtectedLayout: React.FC<Props> = ({ element: Element, layout: Layout, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Retrieve user and token from cookies
  const user = Cookies.get('user');
  const token = Cookies.get('auth_token');
  const userData = user ? JSON.parse(user) : null;

  const { fetchData: fetchTimezones } = useGetAllTimezones();
  const { fetchData: fetchAllCountries } = useGetAllCountries();
  const { fetchData: fetchActionPlanStatuses } = useGetAllActionPlanStatuses();
  const { fetchData: fetchProfessional } = useGetProfessional(userData?.id);
  const { fetchData: fetchProfessionalListClients } = useGetProfessionalListClients();

  useEffect(() => {
    if (token && userData) {
      dispatch(authActions.storeAuth({ token, user: userData }));

      (async () => {
        await Promise.all([
          fetchTimezones(),
          fetchAllCountries(),
          fetchActionPlanStatuses(),
        ]);
      })();

      if (userData.code) {
        (async () => {
          await Promise.all([
            fetchProfessional(),
            fetchProfessionalListClients(),
          ]);
        })();
      }
    }

    if (userData) {
      dispatch(userActions.storeUser(userData));
    } else {
      // If no user data, redirect to sign-in
      navigate('/signin');
    }
  }, [
    dispatch,
    fetchProfessional,
    fetchTimezones,
    fetchAllCountries,
    fetchActionPlanStatuses,
    fetchProfessionalListClients,
    token,
    userData,
    navigate,
  ]);

  if (!userData) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      <>{Layout}</>
    </>
  );
};

export default ProtectedLayout;
