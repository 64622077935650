import { useMutation, useQuery } from '@apollo/client';
import { LIST_COMMUNITY_SUBGROUPS } from '../../services/graphql/queries/admin';
import { ADMIN_CREATE_COMMUNITY_SUBGROUP, ADMIN_DELETE_COMMUNITY_SUBGROUP, ADMIN_UPDATE_COMMUNITY_SUBGROUP } from '../../services/graphql/mutations/admin';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function useListCommunitySubgroups(currentPage?: number) {
  const { data, loading, refetch } = useQuery(LIST_COMMUNITY_SUBGROUPS, {
    variables: {
      page: currentPage
    }
  });

  return {
    data: data?.listCommunitySubgroups?.data,
    refetch,
    loading,
    page: data?.listCommunitySubgroups?.page,
    perPage: data?.listCommunitySubgroups?.perPage,
    total: data?.listCommunitySubgroups?.total
  };
}

export const useCreateCommunitySubGroup = () => {
  const token = useSelector((state: any) => state.auth.token);
  const [comSubCreateMutation, { loading }] = useMutation(ADMIN_CREATE_COMMUNITY_SUBGROUP);

  const createComSub = useCallback(async (values: any) => {
    try {
      const { data } = await comSubCreateMutation({
        variables: {
          input: {
            params: {
              name: values.name,
              description: values.description,
              members: values.professional
            },
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return data;
    } catch (err) {
      console.error('useCreateCommunitySubGroup Error: ', err);
    }
  }, [comSubCreateMutation, token]);

  return {
    create: createComSub,
    loading
  };
}

export const useUpdateCommunitySubGroup = () => {
  const token = useSelector((state: any) => state.auth.token);
  const [comSubUpdateMutation, { loading }] = useMutation(ADMIN_UPDATE_COMMUNITY_SUBGROUP);

  const updateComSub = useCallback(async (values: any) => {
    try {
      const { data } = await comSubUpdateMutation({
        variables: {
          input: {
            params: {
              id: values.id,
              name: values.name,
              description: values.description,
              members: values.professional
            },
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return data;
    } catch (err) {
      console.error('useCreateCommunitySubGroup Error: ', err);
    }
  }, [comSubUpdateMutation, token]);

  return {
    update: updateComSub,
    loading
  };
}

export const useDeleteCommunitySubGroup = () => {
  const token = useSelector((state: any) => state.auth.token);
  const [comSubDeleteMutation, { loading }] = useMutation(ADMIN_DELETE_COMMUNITY_SUBGROUP);

  const deleteComSub = useCallback(async (id: any) => {
    try {
      const { data } = await comSubDeleteMutation({
        variables: {
          input: {
            id,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });
      return data;
    } catch (err) {
      console.error('useDeleteCommunitySubGroup Error: ', err);
    }
  }, [comSubDeleteMutation, token]);

  return {
    delete: deleteComSub,
    loading
  };
}