import React from 'react'
import { Form, Radio } from 'antd'
import { RadioBtnProps } from './type'

const RadioBtn: React.FC<RadioBtnProps> = ({
  name,
  label,
  required,
  options
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{
        required: required,
        message: 'Please choose an answer.'
      }]}
    >
      <Radio.Group>
        {options.map((data) => (
          <Radio.Button value={data.value}>{data.label}</Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item >
  )
}

export default RadioBtn