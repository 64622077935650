import React, { useEffect, useState } from 'react'
import CommonLayout from '../../../layouts/Common';
import { Card } from 'antd';
import { Footer, cardBody, cardStyle } from './style';
import { StyledButton } from '../../../components/Common/Button/style';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import DraggableItem from './DraggableItem';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateActionPlanStatus, useDeleteActionPlanStatus, useUpdateActionPlanStatus } from '../../../hooks/professionals/useActionPlanStatuses';
import useGetAllActionPlanStatuses from '../../../hooks/professionals/useGetAllActionPlanStatuses';
import CustomLoading from '../../../components/Common/Loading';
import { RootState } from '../../../redux/store';
import { professionalActions } from '../../../redux/slices/professionalSlice';

const ActionPlanStatus = () => {
  const dispatch = useDispatch();
  const {
    addActionPlanStatus,
    updateNameActionPlanStatus,
    removeActionPlanStatus,
    updateColorActionPlanStatus,
    updateSortActionPlanStatus
  } = professionalActions;
  const { fetchData: fetchActionPlanStatuses, refetch: refetchAllActionPlanStatuses, loading } = useGetAllActionPlanStatuses();
  const { create: createActionPlanStatus, loading: loadingCreateActionPlanStatus } = useCreateActionPlanStatus();
  const { update: updateActionPlanStatus, loading: loadingUpdateActionPlanStatus } = useUpdateActionPlanStatus();
  const { delete: deleteActionPlanStatus, loading: loadingDeleteActionPlanStatus } = useDeleteActionPlanStatus();
  const actionPlanStatuses = useSelector((state: RootState) => state.professionals.actionPlanStatuses);

  const [items, setItems] = useState(actionPlanStatuses ?? []);

  const onAdd = async () => {
    dispatch(
      addActionPlanStatus({
        name: '',
        sorting: actionPlanStatuses?.length as number,
        color: "#FFEB3B",
        type: 'add'
      })
    );
    // setItems((prev: any) => ([
    //   ...prev,
    //   {
    //     name: '',
    //     sorting: prev.length,
    //     color: "#FFEB3B",
    //     type: 'add'
    //   },
    // ]));
  };

  const onRemove = (index: number) => {
    dispatch(
      removeActionPlanStatus({ index })
    );
    setItems((prev: any) => {
      const updatedItems = prev.map((item: any, i: number) => {
        if (index === i) {
          return { ...item, type: 'remove' };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const onChangeName = (index: number, name: string) => {
    dispatch(
      updateNameActionPlanStatus({ index, name })
    );
    setItems((prev: any) => {
      const updatedItems = prev.map((item: any, i: number) => {
        if (index === i) {
          return { ...item, name, type: item.id ? 'update' : 'add' };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const onChangeColor = (index: number, color: string) => {
    dispatch(
      updateColorActionPlanStatus({ index, color })
    );
    setItems((prev: any) => {
      const updatedItems = prev.map((item: any, i: number) => {
        if (index === i) {
          return { ...item, color, type: item.id ? 'update' : 'add' };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const onSubmit = async () => {
    try {
      await Promise.all((actionPlanStatuses ?? []).map(async (item: any) => {
        switch (item.type) {
          case 'add':
            const resAdd = await createActionPlanStatus(item);
            console.log('add', resAdd);
            break;
          case 'update':
            const resUpdate = await updateActionPlanStatus(item);
            console.log('update', resUpdate);
            break;
          case 'remove':
            const resDelete = await deleteActionPlanStatus(parseInt(item.id, 10));
            console.log('delete', resDelete);
            break;
          default:
        }
      }));
    } catch (error: any) {
      console.error('onSubmitActionPlanStatus', error);
    } finally {
      await refetchAllActionPlanStatuses();
    }
  };

  const getItemPos = (id: string) => (actionPlanStatuses ?? []).findIndex((item: any) => item.id === id);

  const handleDragEnd = (event: any) => {
    dispatch(
      updateSortActionPlanStatus({
        event,
        getItemPos
      })
    );
    const { active, over } = event;
    if (active.i === over.id) return;
    setItems((prev: any) => {
      const originalPos = getItemPos(active.id);
      const newPos = getItemPos(over.id);

      return arrayMove(prev, originalPos, newPos);
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5
      }
    }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    }),
  );

  useEffect(() => {
    fetchActionPlanStatuses();
  }, [fetchActionPlanStatuses]);

  console.log('actionPlanStatuses', actionPlanStatuses)

  if (loading) {
    return <CustomLoading />
  }

  return (
    <CommonLayout title="Client statuses" width="900px">
      <Card style={cardStyle} bodyStyle={cardBody}>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          collisionDetection={closestCorners}
        >
          <SortableContext items={actionPlanStatuses ?? [] as any} strategy={verticalListSortingStrategy}>
            {(actionPlanStatuses ?? [])
              .filter((item: any) => item.type !== 'remove')
              .map((item: any, index: number) => (
                <DraggableItem
                  key={`${item.sorting}_${index}`}
                  id={item.id}
                  index={index}
                  sorting={item.sorting}
                  name={item.name}
                  color={item.color}
                  onRemove={onRemove}
                  onChangeName={onChangeName}
                  onChangeColor={onChangeColor}
                />
              ))}
          </SortableContext>
        </DndContext>
        <StyledButton
          icon={<i className='material-icons-sharp' style={{ fontSize: 'inherit' }}>add</i>}
          onClick={onAdd}
        >
          Add more
        </StyledButton>
        <Footer>
          <StyledButton
            onClick={onSubmit}
            loading={
              loadingCreateActionPlanStatus ||
              loadingUpdateActionPlanStatus ||
              loadingDeleteActionPlanStatus
            }
          >
            Save
          </StyledButton>
        </Footer>
      </Card>
    </CommonLayout>
  )
}

export default ActionPlanStatus;