import React, { useState } from 'react';
import CommonLayout from '../../../layouts/Common';
import CommonTable from '../../../components/Common/Table';
import { ActionBtn, ActionBtnWrapper, HeaderData, NameData, UrlData } from './style';
import { ColumnsType } from 'antd/es/table';
import { DataType } from './type';
import useTerms from '../../../hooks/common/useTerms';
import TermsForm from '../../../components/Forms/Admin/Terms';
import { Spin } from 'antd';

const Terms = () => {
  const { data: terms, loading: loadingTerms, refetch: refetchTerms } = useTerms();
  const [openNewModal, setOpenNewNodal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const columns: ColumnsType<DataType> = [
    {
      title: <HeaderData>Term</HeaderData>,
      dataIndex: 'name',
      render: (text, record) => (
        <NameData>{record.name}</NameData>
      ),
    },
    {
      title: <HeaderData>Translations</HeaderData>,
      render: (text, record) => (
        <NameData>{`${'EN'}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Actions</HeaderData>,
      dataIndex: 'active',
      render: (text, record) => (
        <ActionBtnWrapper>
          <ActionBtn
            active={text}
            type="text"
            icon={
              <i className="material-icons-round" style={{ fontSize: 'inherit' }}>visibility</i>
            }
          />
          <ActionBtn
            active={text}
            type="text"
            icon={
              <i className="material-icons-round" style={{ fontSize: 'inherit' }}>edit</i>
            }
          />
        </ActionBtnWrapper>
      ),
    }
  ];

  const handleBack = () => {
    setOpenNewNodal(false);
    setOpenEditModal(false);
  };

  return (
    <CommonLayout title="Terms" width="1000px">
      {loadingTerms ? <Spin size="large" /> :
        <CommonTable
          showNewModal={openNewModal}
          onNewData={setOpenNewNodal}
          showEditModal={openEditModal}
          onEditData={setOpenEditModal}
          handleBack={handleBack}
          modalForm={<TermsForm />}
          modalWidth={800}
          columns={columns}
          dataSource={terms}
          tableType="Terms"
          total={`${terms?.length} rows`}
        />
      }
    </CommonLayout>
  )
};

export default Terms;