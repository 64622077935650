import { Button, Divider, Form, Input, Select } from 'antd';
import { StyledForm } from './style';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../constants';

type ContactDetailsFormInputsType = {
  type: string
};

const ContactDetailsFormInputs: React.FC<ContactDetailsFormInputsType> = ({ type }) => (
  <>
    <Form.Item
      name={`${type}_relationship_with_child`}
      label="Relationship with child"
      labelAlign="left"
      rules={[
        {
          required: type === 'primary',
          message: 'Caregiver contact details relationship can\'t be blank.',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={`${type}_name`}
      label="Name"
      labelAlign="left"
      rules={[
        {
          required: type === 'primary',
          message: 'Caregiver contact details name can\'t be blank.',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={`${type}_telephone_number`}
      label="Telephone number"
      labelAlign="left"
      rules={[{
        required: type === 'primary',
        message: 'Caregiver contact details telephone number can\'t be blank'
      }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={`${type}_country`}
      label="Country"
      labelAlign="left"
      rules={[{
        required: type === 'primary',
        message: 'Country can\'t be blank'
      }]}
    >
      <Select>
        <Select.Option value="demo">Demo</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item
      name={`${type}_street`}
      label="Street"
      labelAlign="left"
      rules={[
        {
          required: type === 'primary',
          message: 'Street can\'t be blank.',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={`${type}_additional_number`}
      label="Number (+addition)"
      labelAlign="left"
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={`${type}_place`}
      label="Place"
      labelAlign="left"
      rules={[
        {
          required: type === 'primary',
          message: 'Place can\'t be blank.',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={`${type}_postal_code`}
      label="Postal Code"
      labelAlign="left"
      rules={[
        {
          required: type === 'primary',
          message: 'Postal code can\'t be blank.',
        },
      ]}
    >
      <Input />
    </Form.Item>
  </>
);

const FamilyFormInputs = () => (
  <>
    <Form.Item
      name={`parents_marital_status`}
      label="Parents marital status"
      labelAlign="left"
      rules={[{
        required: true,
        message: 'Parents marital status can\'t be blank'
      }]}
    >
      <Select>
        <Select.Option value="demo">Demo</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item
      name={`num_of_brothers`}
      label="Number of brothers"
      labelAlign="left"
      rules={[{
        required: true,
        message: 'Number of brothers can\'t be blank'
      }]}
    >
      <Select>
        <Select.Option value="demo">Demo</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item
      name={`num_of_sisters`}
      label="Number of sisters"
      labelAlign="left"
      rules={[{
        required: true,
        message: 'Number of sisters can\'t be blank'
      }]}
    >
      <Select>
        <Select.Option value="demo">Demo</Select.Option>
      </Select>
    </Form.Item>
  </>
);

const ContactDetailsForm = () => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="contact_details"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>Contact details of Primary Caregiver</p>
      <ContactDetailsFormInputs type="primary" />
      <Divider />
      <p>Contact details of Secondary Caregiver</p>
      <ContactDetailsFormInputs type="secondary" />
      <Divider />
      <p>Family</p>
      <FamilyFormInputs />
      <Form.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </StyledForm>
  )
};

export default ContactDetailsForm;