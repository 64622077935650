import React, { useEffect } from 'react'
import { _FORM_ITEM_LAYOUT, TWO_BTN_TAIL_FORM_ITEM_LAYOUT } from '../../../../constants';
import { SaveBtnContainer, StyledForm, StyledSaveBtn } from './style';
import { Button, Form, Input } from 'antd';
import { CommunitySubGroupFormProps } from './type';
import UserLookup from '../../../Common/Form/UserLookup';

const CommunitySubGroupForm: React.FC<CommunitySubGroupFormProps> = ({
  onBack,
  onSubmit,
  onReset,
  defaultValues,
  loading,
  isEdit
}) => {
  const [form] = StyledForm.useForm();

  const onFinish = async (values: any) => {
    onSubmit(values);
  };

  const handleOnBack = () => {
    onBack();
  };

  useEffect(() => {
    if (!onReset) {
      form.resetFields();
    }
  }, [onReset, form]);

  return (
    <>
      <StyledForm
        {..._FORM_ITEM_LAYOUT}
        form={form}
        name="tags_form"
        onFinish={onFinish}
        initialValues={{ ...defaultValues, professional: defaultValues?.members, }}
        style={{ marginTop: '1rem' }}
        scrollToFirstError
      >
        <Form.Item hidden name='id'>
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Name can\'t be blank',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Description can\'t be blank',
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <UserLookup
          professionalOnly
          multiSelect
        />
        <StyledForm.Item {...TWO_BTN_TAIL_FORM_ITEM_LAYOUT}>
          <SaveBtnContainer>
            {/* {!isEdit && (
            )} */}
            <Button onClick={handleOnBack} type="text">
              Back
            </Button>
            <StyledSaveBtn type="primary" htmlType="submit" loading={loading}>
              Save
            </StyledSaveBtn>
          </SaveBtnContainer>
          {/* {isEdit && (
            <CancelDeleteContainer>
              <Button onClick={() => setConfirmDelete(true)} type="primary" style={{ background: 'red' }}>
                Delete
              </Button>
              <Button onClick={handleOnBack} type="text">
                Back
              </Button>
            </CancelDeleteContainer>
          )} */}
        </StyledForm.Item>
      </StyledForm>
      {/* <Modal
        title='Delete Tag'
        open={confirmDelete}
        onOk={handleOnDelete}
        onCancel={() => setConfirmDelete(false)}
        width={400}
      >
        <ModalContentWrapper>
          {deleteTagLoading ? (
            <Spin />
          ) : (
            <p>Are you sure you want to delete the tag?</p>
          )}
        </ModalContentWrapper>
      </Modal> */}
    </>
  );
};

export default CommunitySubGroupForm;