import styled from 'styled-components';

export const FilterActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
`;

export const PopOverWrapper = styled.div`
  min-width: 250px;
`;

export const PopOverItem = styled.div`
  padding: .25rem .75rem;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 5px;

    > span {
      font-size: 1.125rem;
    }

    > button {
      border: 0;
      background-color: transparent;
      background-image: none;
      cursor: pointer;
    }
  }
`;

export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 10px;
`;