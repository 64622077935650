import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';

const SocialSupport = () => {
  const dispatch = useDispatch();
  const socialSupportQuestionnaire = useSelector((state: any) => state.questionnaires.socialSupport);
  const currentSocialSupportQuestionnaire = useMemo(() => {
    return socialSupportQuestionnaire?.questions.filter((q: any) => q.page === socialSupportQuestionnaire.currentPage)
  }, [socialSupportQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: socialSupportQuestionnaire.currentPage + 1,
      type: 'socialSupport'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: socialSupportQuestionnaire.currentPage - 1,
      type: 'socialSupport'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'socialSupport' }));
  };

  return (
    <Container>
      <h1>Social Support Questionnaire</h1>
      <div className='paragraphs'>
        <p>A system diagnosis according to the Biomoatrix model is not limited to physical aspects of being. Man is also a social being and is part of a social network. The importance is beautifully reflected in the work of Prof. A. Antonovsky, the developer of the Sense of Coherence questionnaire. He developed the concept of General Resistance Resources (GRRs) [“Health, Stress, and Coping”] what he defines as “…any characteristic of the person, the group, or the environment that can facilitate effective tension management.”
        </p>
        <p>
          The GGRs can consist of all kinds of aspects of human functioning (e.g. physical, biochemical, cognitive, emotional, etc.), including the relational and social aspect.
          This questionnaire investigates how you feel in a relationship network
        </p>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${socialSupportQuestionnaire.currentPage} of ${socialSupportQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentSocialSupportQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <SingleQuestion
                  label={question.label}
                  choices={question.choices}
                  handleRadioToggle={handleRadioToggle}
                  order={question.question_num}
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {socialSupportQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {socialSupportQuestionnaire.currentPage === socialSupportQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
};

export default SocialSupport;

