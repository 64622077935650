import { gql } from '@apollo/client';

export const LIST_PROFESSIONALS_QUERY = gql`
  query listProfessionals($page: Int, $perPage: Int) {
    listProfessionals(page: $page, perPage: $perPage) {
      page
      perPage
      total
      data {
        id
        email
        firstName
        familyName
        code
        twoFactorEnabled
        accountStatus
        type
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ACTION_PLAN_STATUSES = gql`
  query {
    getActionPlanStatuses {
      id
      name
      sorting
      color
    }
  }
`;

export const LIST_TIMEZONES = gql`
  query {
    listTimezones
  }
`;

export const LIST_TERMS = gql`
  query listTerms($language: String) {
    listTerms(language: $language) {
      name
      admin {
        firstName
        familyName
        id
      }
      adminId
      slug
      id
      body
      updatedAt
      createdAt
    }
  }
`;

export const GET_TERM = gql`
  query findTerm($slug: String!, $language: String) {
    findTerm(slug: $slug, language: $language) {
      name
      admin {
        firstName
        familyName
        id
      }
      adminId
      slug
      id
      body
      updatedAt
      createdAt
    }
  }
`;

export const LIST_TAGS = gql`
  query listTags {
    listTags {
      name
      id
    }
  }
`;

export const GET_TAG = gql`
  query getTag($id: String!) {
    getTag(id: $id) {
      id
      name
    }
  }
`;