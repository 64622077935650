import { Avatar, Menu, MenuProps, Modal } from 'antd';
import { useMemo, useState } from 'react';
import { ModalContentWrapper, NavText, ProfilePhotoContainer, SignOutBtn, StyledSider } from './style';
import { UserOutlined } from '@ant-design/icons';
import { authActions } from '../../../redux/slices';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const ProfessionalSidebar = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);
  const auth = authActions;
  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/');
  const cleanedPath = segments[segments.length - 1];
  const [showSignoutModal, setShowSignoutModal] = useState<boolean>(false);

  const onSignOut = () => {
    setShowSignoutModal(false);

    Cookies.remove('user');
    Cookies.remove('auth_token');

    auth.removeAuth();
    navigate('/');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navItems = [
    {
      icon: <i className="material-icons-outlined icon-font">dashboard</i>,
      label: <NavText href={`/p/${user?.code}`}>Dashboard</NavText>,
      key: `${user?.code}`
    },
    {
      icon: <i className="material-icons-outlined icon-font">forum</i>,
      label: <NavText href={`/p/${user?.code}/forums`}>Community</NavText>,
      key: 'forums'
    },
    {
      icon: <i className="material-icons-outlined icon-font">message</i>,
      label: <NavText href={`/p/${user?.code}/client_messages`}>Inmail</NavText>,
      key: 'client_messages'
    },
    {
      icon: <i className="material-icons-outlined icon-font">power_settings_new</i>,
      label: <SignOutBtn onClick={() => setShowSignoutModal(true)}>Sign Out</SignOutBtn>
    }
  ];

  const activeIndex = useMemo(() => {
    const index = navItems?.findIndex(item => cleanedPath.startsWith(item?.key as string));
    return index !== -1 ? (index + 1).toString() : null;
  }, [cleanedPath, navItems]);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const items: MenuProps['items'] = navItems.map((item, index) => ({
    key: String(index + 1),
    icon: item.icon,
    label: item.label,
  }));

  const handleBack = () => {
    setShowSignoutModal(false);
  };

  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      breakpoint="xs"
      // collapsedWidth="0"
      onCollapse={(value) => setCollapsed(value)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        left: 0,
        top: 0
      }}
    >
      <ProfilePhotoContainer>
        {!collapsed ? (
          <Avatar
            style={{ backgroundColor: '#e2e8f0' }}
            size={{ xs: 134, sm: 134, md: 134, lg: 134, xl: 134, xxl: 134 }}
            icon={<UserOutlined />}
          />
        ) : (
          <Avatar
            shape="square"
            style={{ backgroundColor: '#e2e8f0' }}
            size={{ xs: 64, sm: 64, md: 64, lg: 64, xl: 64, xxl: 64 }}
            icon={<UserOutlined />}
          />
        )}
      </ProfilePhotoContainer>
      <Modal
        title={`Signout`}
        open={showSignoutModal}
        onOk={onSignOut}
        onCancel={handleBack}
        // footer={null}
        width={350}
      >
        <ModalContentWrapper>
          <span>Are you sure you want to sign out?</span>
        </ModalContentWrapper>
      </Modal>
      <Menu mode="inline" defaultSelectedKeys={['1']} items={items} selectedKeys={[activeIndex!]} />
    </StyledSider>
  )
};

export default ProfessionalSidebar;