import { useSortable } from '@dnd-kit/sortable'
import React from 'react'
import { CSS } from '@dnd-kit/utilities';
import { DraggableContainer, DraggableIcon } from './style';
import { Button, ColorPicker, Input } from 'antd';

type Props = {
  id: string
  index: number
  name: string
  color: string
  sorting: number
  onRemove: (index: number) => void;
  onChangeName: (index: number, name: string) => void;
  onChangeColor: (index: number, hex: string) => void;
}

const DraggableItem: React.FC<Props> = ({ id, index, name, color, sorting, onRemove, onChangeName, onChangeColor }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };
  return (
    <DraggableContainer
      ref={(node) => {
        setNodeRef(node);
      }}
      {...attributes}
      {...listeners}
      style={style}
    >
      <DraggableIcon>
        <i className='material-icons-sharp' style={{ fontSize: 'inherit' }}>drag_handle</i>
      </DraggableIcon>
      <p>{`${++sorting}.`}</p>
      <Input value={name} onChange={(e) => onChangeName(index, e.target.value)} />
      <ColorPicker defaultValue={color} onChange={(value, hex: string) => onChangeColor(index, hex)} />
      <Button
        type='text'
        onClick={() => onRemove(index)}
        icon={<i className='material-icons-sharp' style={{ fontSize: 'inherit' }}>delete_outline</i>}
      />
    </DraggableContainer>
  )
}

export default DraggableItem