import React from 'react'
import { LoadingWrapper } from './style'
import { Spin, SpinProps } from 'antd'

const CustomLoading: React.FC<SpinProps> = ({
  size,
  fullscreen
}) => {
  return (
    <LoadingWrapper>
      <Spin
        size={size}
        fullscreen={fullscreen}
      />
    </LoadingWrapper>
  )
}

export default CustomLoading