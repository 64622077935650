import React, { useEffect } from 'react'
import { _FORM_ITEM_LAYOUT, _MINERALS, _TAIL_FORM_ITEM_LAYOUT } from '../../../../constants';
import { Label, MineralsFormContainer, MineralsWrapper, StyledForm, StyledFormUpload } from './style';
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Upload, UploadProps, message } from 'antd';
import UserLookup from '../../../Common/Form/UserLookup';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useCreateHMA } from '../../../../hooks/admin/useHma';

interface HMAFormProps {
  onReset: boolean
}

const { Option } = Select;

const HMAForm: React.FC<HMAFormProps> = ({ onReset }) => {
  const { create: createHMA } = useCreateHMA();
  const [form] = StyledForm.useForm();

  const onFinish = async (values: any) => {
    const {
      client,
      professional,
      reportNumber,
      dateOfAnalysis,
      attachment,
      hairType,
      ...hmaData
    } = values;
    const intHmaData: { [key: string]: number | string | null } = {};
    for (const key in hmaData) {
      if (hmaData.hasOwnProperty(key)) {
        const value = hmaData[key];
        intHmaData[key] = typeof value === 'string' ? parseInt(value, 10) : value;
      }
    }
    console.log('attachment', attachment.file.originFileObj, professional);
    const file = attachment.file.originFileObj;
    try {
      const res = await createHMA({
        professionalId: professional,
        hmaData: {
          clientId: 1,
          reportNumber,
          attachment: file,
          dateOfAnalysis,
          hairType,
          ...intHmaData
        }
      });
      console.log('result', res);
    } catch (err) {
      console.error('onSubmitHMA Error', err);
    }
  };

  const props: UploadProps = {
    name: 'file',
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };


  useEffect(() => {
    if (!onReset) {
      form.resetFields();
    }
  }, [onReset, form]);

  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="hma_form"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>Import HMA Data</p>
      <Divider style={{ margin: '.75rem 0' }} />
      <Row gutter={50}>
        <Col lg={12}>
          <UserLookup professionalOnly />
        </Col>
        <Col lg={12}>
          <Form.Item
            labelAlign="left"
            name="reportNumber"
            label="Report number"
            rules={[
              {
                required: true,
                message: 'Report number can\'t be blank',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dateOfAnalysis"
            label="Date of analysis"
            labelAlign="left"
            rules={[{ required: true, message: 'Date of analysis can\'t be blank' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="hairType"
            labelAlign="left"
            label="Hair Type"
            hasFeedback
            rules={[{ required: true, message: 'Please select a hair type!' }]}
          >
            <Select placeholder="Please select a hair type">
              <Option value="Head">Head</Option>
              <Option value="Pubic">Pubic</Option>
              <Option value="Head and pubic">Head and Pubic</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: '.75rem 0' }} />
      {/* <Form.Item labelAlign="left" label="Attachment:" name="attachment" valuePropName="fileList" getValueFromEvent={normFile} style={{ width: '100%' }}>
        <StyledFormUpload name="attachments">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </StyledFormUpload>
      </Form.Item> */}
      <Form.Item labelAlign="left" label="Attachment:" name="attachment"  >
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
      <MineralsWrapper>
        <p>Minerals:</p>
        <MineralsFormContainer>
          {_MINERALS.map((mineral, index) => (
            <div>
              <Label>{mineral.label}</Label>
              <Form.Item
                key={`${mineral.label}_${index}`}
                labelAlign="left"
                name={mineral.name}
                rules={[
                  {
                    required: true,
                    message: `${mineral.label} can\'t be blank`,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </div>
          ))}
        </MineralsFormContainer>
      </MineralsWrapper>
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  )
}

export default HMAForm