import React, { useState } from 'react';
import CommonLayout from '../../../layouts/Common';
import CommonTable from '../../../components/Common/Table';
import { ActionBtn, HeaderData, ModalContentWrapper, NameData, UrlData } from './style';
import { ColumnsType } from 'antd/es/table';
import { DataType } from './type';
import { Modal, notification, Spin } from 'antd';
import useListCommunitySubgroups, { useCreateCommunitySubGroup, useDeleteCommunitySubGroup, useUpdateCommunitySubGroup } from '../../../hooks/admin/useCommunitySubgroups';
import CommunitySubGroupForm from '../../../components/Forms/Admin/CommunitySubGroup';
import { CommunitySubGroupType, UUID } from '../../../types';
import { NotificationTitle } from '../../../components/Common/Notification/style';
import { CheckCircleOutlined } from '@ant-design/icons';
import { theme } from '../../../styles/theme';

const CommunitySubgroup = () => {
  const [api, contextHolder] = notification.useNotification();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, total, loading, perPage, refetch } = useListCommunitySubgroups(currentPage as number);
  const { create: createComSubGroup, loading: loadingCreate } = useCreateCommunitySubGroup();
  const { update: updateComSubGroup, loading: loadingUpdate } = useUpdateCommunitySubGroup();
  const { delete: deleteComSubGroup, loading: loadingDelete } = useDeleteCommunitySubGroup();
  const [comSubGroupId, setComSubGroupId] = useState<UUID>('');
  const [openNewComSub, setOpenNewComSub] = useState<boolean>(false);
  const [openEditComSub, setOpenEditComSub] = useState<boolean>(false);
  const [openDeleteComSub, setOpenDeleteComSub] = useState<boolean>(false);

  const [editCommunitySubGroupValues, setEditCommunitySubGroupValues] = useState<CommunitySubGroupType>({
    id: '',
    name: '',
    description: '',
    members: []
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <HeaderData>Name</HeaderData>,
      render: (text, record) => (
        <UrlData onClick={() => onEdit(record)}>{`${record.attributes.name}`}</UrlData>
      ),
    },
    {
      title: <HeaderData>Slug</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.attributes.slug}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Description</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.attributes.description}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Actions</HeaderData>,
      render: (text, record) => (
        <ActionBtn
          onClick={() => handleDelete(record.attributes.id)}
          type="text"
          icon={
            <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>delete</i>
          }
        >
        </ActionBtn>
      ),
    }
  ];

  const onSubmit = async (values: any) => {
    try {
      if (openEditComSub) {
        const result = await updateComSubGroup(values);
        if (result && result.updateCommunitySubgroup) {
          api.info({
            message: <NotificationTitle>Successfully Updated Community Subgroup</NotificationTitle>,
            placement: 'topRight',
            icon:
              <CheckCircleOutlined
                style={{
                  color: theme.color.primary,
                }}
              />,
            style: {
              background: `${theme.bg.secondary}`,
              borderRadius: 10,
              alignItems: 'center',
              display: 'flex'
            }
          });
        }
      } else {
        const result = await createComSubGroup(values);
        if (result && result.createCommunitySubgroup) {
          api.info({
            message: <NotificationTitle>Successfully Created Community Subgroup</NotificationTitle>,
            placement: 'topRight',
            icon:
              <CheckCircleOutlined
                style={{
                  color: theme.color.primary,
                }}
              />,
            style: {
              background: `${theme.bg.secondary}`,
              borderRadius: 10,
              alignItems: 'center',
              display: 'flex'
            }
          });
          refetch();
        }
      }

      refetch();
    } catch (err) {
      console.error('onSubmitCreateComSubGroup', err);
    } finally {
      setOpenNewComSub(false);
      setOpenEditComSub(false);
    }
  };

  const onEdit = (record: DataType) => {
    setEditCommunitySubGroupValues({
      id: record.attributes.id,
      name: record.attributes.name,
      description: record.attributes.description,
      members: record.attributes.members.map((member) => member.id) as string[]
    });
    setOpenEditComSub(true);
  };

  const onDelete = async () => {
    try {
      const result = await deleteComSubGroup(comSubGroupId);
      if (result && result.deleteCommunitySubgroup.success) {
        api.info({
          message: <NotificationTitle>Successfully Deleted Community Subgroup</NotificationTitle>,
          placement: 'topRight',
          icon:
            <CheckCircleOutlined
              style={{
                color: theme.color.primary,
              }}
            />,
          style: {
            background: `${theme.bg.secondary}`,
            borderRadius: 10,
            alignItems: 'center',
            display: 'flex'
          }
        });
      }
      refetch();
    } catch (err) {
      console.error('onDelete', err);
    } finally {
      setOpenDeleteComSub(false);
    }
  };

  const handleDelete = (id: UUID) => {
    setComSubGroupId(id)
    setOpenDeleteComSub(true);
  };

  const handleBack = () => {
    setOpenNewComSub(false);
    setOpenEditComSub(false);
    setEditCommunitySubGroupValues({
      id: '',
      name: '',
      description: '',
      members: []
    });
  };

  return (
    <CommonLayout title="Community Sub-Groups" width="1000px">
      {contextHolder}
      {loading ? <Spin size="large" /> :
        <CommonTable
          showNewModal={openNewComSub}
          onNewData={setOpenNewComSub}
          showEditModal={openEditComSub}
          onEditData={setOpenEditComSub}
          handleBack={handleBack}
          modalForm={
            <CommunitySubGroupForm
              defaultValues={editCommunitySubGroupValues}
              onBack={handleBack}
              onSubmit={onSubmit}
              loading={loadingCreate || loadingUpdate || loadingDelete}
              isEdit={openEditComSub}
              onReset={openNewComSub}
            />
          }
          modalWidth={1200}
          columns={columns}
          dataSource={data}
          tableType="Community Sub-Group"
          total={`${total} items`}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          totalData={total}
        />
      }
      <Modal
        title='Delete Subgroup'
        open={openDeleteComSub}
        onOk={onDelete}
        onCancel={() => setOpenDeleteComSub(false)}
        width={400}
      >
        <ModalContentWrapper>
          {loadingDelete ? (
            <Spin />
          ) : (
            <p>Are you sure you want to deactivate this subgroup?</p>
          )}
        </ModalContentWrapper>
      </Modal>
    </CommonLayout>
  )
};

export default CommunitySubgroup;