import { Button, Form, Input } from 'antd';
import RadioBtn from '../../Common/Form/RadioBtn';
import { StyledForm } from './style';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../constants';

const { TextArea } = Input;

const AllergiesAndOthersForm = () => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };
  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="allergies_and_others"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>General Health Question of the child</p>
      <Form.Item name="request_for_help_attributes">
        <TextArea rows={4} />
      </Form.Item>
      <p>Do you use food supplements?</p>
      <RadioBtn
        name="food_supplements"
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
      />
      <p>Do you have allergies?</p>
      <RadioBtn
        name="allergies"
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
      />
      <p>Do you have food intolerances?</p>
      <RadioBtn
        name="food_intolerances"
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
      />
      <p>Do you have any addicitons?</p>
      <RadioBtn
        name="addictions"
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
      />
      <Form.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </StyledForm>
  )
};

export default AllergiesAndOthersForm;