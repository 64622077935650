import { Button } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../styles/theme';

type StyledTextButtonProps = {
  isDelete?: boolean
}

export const PopOverWrapper = styled.div`
  min-width: 150px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
`;

export const StyledTextButton = styled(Button) <StyledTextButtonProps>`
  width: 100%;
  justify-content: flex-start;
  > span {
    color: ${(props) => props?.isDelete ? theme?.color?.darkGray : theme?.color?.primary};
  }
`;