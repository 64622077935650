import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';

const BehavioralAvoidanceInhibition = () => {
  const dispatch = useDispatch();
  const behavioralAvoidanceInhibitionQuestionnaire = useSelector((state: any) => state.questionnaires.behavioralAvoidanceInhibition);
  const currentBehavioralAvoidanceInhibitionQuestionnaire = useMemo(() => {
    return behavioralAvoidanceInhibitionQuestionnaire?.questions.filter((q: any) => q.page === behavioralAvoidanceInhibitionQuestionnaire.currentPage)
  }, [behavioralAvoidanceInhibitionQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: behavioralAvoidanceInhibitionQuestionnaire.currentPage + 1,
      type: 'behavioralAvoidanceInhibition'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: behavioralAvoidanceInhibitionQuestionnaire.currentPage - 1,
      type: 'behavioralAvoidanceInhibition'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'behavioralAvoidanceInhibition' }));
  };

  return (
    <Container>
      <h1>Behavioral avoidance / inhibition</h1>
      <div>
        <p>The BIS/BAS Scale is a 24-item self-report questionnaire designed to measure two motivational systems:</p>
        <ol>
          <li>The behavioral inhibition system (BIS), which corresponds to motivation to avoid aversive outcomes, and</li>
          <li>The behavioral activation (or avoidance) system (BAS), which corresponds to motivation to approach goal-oriented outcomes and to regulate aversive motives, in which the goal is to move away from something unpleasant.</li>
        </ol>
      </div>
      <p>
        You are asked to respond to each item using a 4-point Likert scale: 1 (very true for me), 2 (somewhat true for me), 3 (somewhat false for me), and 4 (very false for me).
      </p>
      <p>The outcome of this questionnaire will be discussed with you in our meeting.</p>
      <div className="page-container">
        <div>
          <p>{`Page ${behavioralAvoidanceInhibitionQuestionnaire.currentPage} of ${behavioralAvoidanceInhibitionQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentBehavioralAvoidanceInhibitionQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <SingleQuestion
                  label={question.label}
                  choices={question.choices}
                  handleRadioToggle={handleRadioToggle}
                  order={question.question_num}
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {behavioralAvoidanceInhibitionQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {behavioralAvoidanceInhibitionQuestionnaire.currentPage === behavioralAvoidanceInhibitionQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default BehavioralAvoidanceInhibition;
