import { Avatar, Button, Divider } from 'antd'
import { useState } from 'react';
import styles from './MobileSidebar.module.scss';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

type Props = {}

const MobileSidebar = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen((prev) => (!prev));
  };

  return (
    <div className={styles.container}>
      <Button
        type="text"
        onClick={toggleMenu}
        className={styles.hamburgerMenuBtn}
        icon={
          <MenuOutlined className={styles.hamburgerMenuIcon} />
        }
      >
      </Button>
      <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}>
        <Button
          type="text"
          onClick={toggleMenu}
          className={styles.closeBtn}
          icon={
            <CloseOutlined className={styles.closeMenuBtn} />
          }
        >
        </Button>
        <div className={styles.sidebarContent}>
          <div className={styles.professionalDetailsContainer}>
            <Avatar
              size={{ xs: 62, sm: 62, md: 62, lg: 0, xl: 0, xxl: 0 }}
            />
            <h2>Hello Melvin</h2>
          </div>
          <div className={styles.dividerContainer}>
            <Divider className={styles.divider} />
            <p>Dashboard</p>
          </div>
          <ul>
            <li>Summary</li>
            <li>Analyses</li>
            <li>Vragenlijsten</li>
          </ul>
          <div className={styles.dividerContainer}>
            <Divider className={styles.divider} />
            <p>Mijn Info</p>
          </div>
          <ul>
            <li>My Integrated Health</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MobileSidebar