export const _CLIENT_STATUS = [
  {
    label: 'No Status',
    value: 'No Status'
  },
  {
    label: 'Awaiting test results',
    value: 'Awaiting test results'
  },
  {
    label: 'Awaiting intake',
    value: 'Awaiting intake'
  },
  {
    label: 'Make a treatment plan',
    value: 'Make a treatment plan'
  },
  {
    label: 'Awaiting response treatment plan',
    value: 'Awaiting response treatment plan'
  },
  {
    label: 'Start/continue treatment',
    value: 'Start/continue treatment'
  },
  {
    label: 'No evaluation planned yet',
    value: 'No evaluation planned yet'
  },
  {
    label: 'Evaluation planned',
    value: 'Evaluation planned'
  },
  {
    label: 'Awaiting evaluation appointment',
    value: 'Awaiting evaluation appointment'
  },
  {
    label: 'Proactive monitoring',
    value: 'Proactive monitoring'
  }
];

export const _FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 22 },
    xxl: { span: 24 },
  },
  labelWrap: true
};

export const _TAIL_FORM_ITEM_LAYOUT = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
    lg: {
      span: 2,
      offset: 22
    },
    xl: {
      span: 2,
      offset: 22
    },
    xxl: {
      span: 2,
      offset: 22
    }
  },
};

export const TWO_BTN_TAIL_FORM_ITEM_LAYOUT = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
    lg: {
      span: 4,
      offset: 20
    },
    xl: {
      span: 4,
      offset: 20
    },
    xxl: {
      span: 4,
      offset: 20
    }
  },
};

export const _MINERALS = [
  {
    label: 'Calcium',
    name: 'calcium'
  },
  {
    label: 'Magnesium',
    name: 'magnesium'
  },
  {
    label: 'Sodium',
    name: 'sodium'
  },
  {
    label: 'Potassium',
    name: 'potassium'
  },
  {
    label: 'Iron',
    name: 'iron'
  },
  {
    label: 'Copper',
    name: 'copper'
  },
  {
    label: 'Manganese',
    name: 'manganese'
  },
  {
    label: 'Zinc',
    name: 'zinc'
  },
  {
    label: 'Chromium',
    name: 'chromium'
  },
  {
    label: 'Selenium',
    name: 'selenium'
  },
  {
    label: 'Phosphorus',
    name: 'phosphorus'
  },
  {
    label: 'Lead',
    name: 'lead'
  },
  {
    label: 'Mercury',
    name: 'mercury'
  },
  {
    label: 'Cadmium',
    name: 'cadmium'
  },
  {
    label: 'Arsenic',
    name: 'arsenic'
  },
  {
    label: 'Aluminum',
    name: 'aluminum'
  },
  {
    label: 'Nickel',
    name: 'nickel'
  },
  {
    label: 'Cobalt',
    name: 'cobalt'
  },
  {
    label: 'Molybdenum',
    name: 'molybdenum'
  },
  {
    label: 'Lithium',
    name: 'lithium'
  },
  {
    label: 'Boron',
    name: 'boron'
  }
];

export const _IR_COMBI = [
  {
    label: 'CRP-HS',
    name: 'crpHs'
  },
  {
    label: 'LDL -ox antistoffen',
    name: 'ldlOxAntistoffen'
  },
  {
    label: 'Glucose (nuchter)',
    name: 'glucose'
  },
  {
    label: 'Insuline',
    name: 'insulin'
  },
  {
    label: 'C-Peptide',
    name: 'cPeptide'
  },
  {
    label: 'Homocysteïne (Plasma)',
    name: 'homocysteine'
  },
  {
    label: 'HOMA 2 (C-peptide)',
    name: 'homa2CPeptide'
  },
  {
    label: '%ß C-Peptide',
    name: 'homa2CPeptidePctB'
  },
  {
    label: '%S C-Peptide',
    name: 'homa2CPeptidePctS'
  },
  {
    label: 'HOMA 2 (Insulin)',
    name: 'homa2CInsulin'
  },
  {
    label: '%ß Insulin',
    name: 'homa2CInsulinPctB'
  },
  {
    label: '%S Insulin',
    name: 'homa2CInsulinPctS'
  }
];

export const _STRESS_STATE_OPTIONS = [
  { value: '1', label: 'Good Recovery' },
  { value: '2', label: 'Good recovery, but no recovery during the day' },
  { value: '3', label: 'Moderate recovery, but sleep duration is too short' },
  { value: '4', label: 'Delayed nighttime recovery' },
  { value: '5', label: 'Weak recovery' },
  { value: '6', label: 'Overload' },
  { value: '7', label: 'Physical overload' },
  { value: '8', label: 'Physiologically irregular state' },
  { value: '9', label: 'Non-identifiable' }
];

export const _COMMON_BULLET_CHART_PROPS = {
  margin: { top: 50, right: 120, bottom: 100, left: 120 },
  spacing: 80,
  titleOffsetX: 0,
  titleOffsetY: 450,
  measureSize: 0.25,
  markerSize: 1.1,
  animate: false,
  motionStiffness: 90,
  motionDamping: 12,
  rangeColors: ["#FFFFFF", "#EEEEEE", "#FFFFFF"],
  measureColors: "#9cb8d7",
  markerColors: "#444444"
};

export const _AGE_TIMELINE = [
  {
    label: '0',
    value: '0'
  },
  {
    label: '1-5',
    value: '1-5'
  },
  {
    label: '6-12',
    value: '6-12'
  },
  {
    label: '13-18',
    value: '13-18'
  },
  {
    label: '19-30',
    value: '19-30'
  },
  {
    label: '31-50',
    value: '31-50'
  },
  {
    label: '51-60',
    value: '51-60'
  },
  {
    label: '81+',
    value: '81-999'
  },
];

export const _QUESTIONNAIRES = {
  intake: [
    {
      title: 'Your Health',
      url: 'RAND-36'
    },
    {
      title: 'Your Main Health Complaint',
      url: 'RAND-36'
    },
    {
      title: 'Orientation to Life Questionnaire',
      url: 'SenseOfCoherence'
    },
    {
      title: 'General Well-Being',
      url: 'GeneralWellBeingScale'
    },
    {
      title: 'Four Dimensional Complaint List',
      url: 'FourDimensionalComplaintList'
    }
  ],
  addtlQuestionnaires: [
    {
      title: 'Behavioral avoidance / inhibition',
      url: 'BIS-BASScale'
    },
    {
      title: 'Conditions during pregnancy of your mother',
      url: 'ConditionsofMotherduringPregnancy'
    },
    {
      title: 'Emotion Regulation',
      url: 'ERQ-CCG'
    },
    {
      title: 'Expressivity',
      url: 'BEQ-CCG'
    },
    {
      title: 'Mental / emotional functioning – Your Personality',
      url: 'MentalEmotionalFunctioning'
    },
    {
      title: 'Social Support Questionnaire',
      url: 'SocialSupportQuestionnaire'
    },
    {
      title: 'Stress Indicators',
      url: 'StressIndicators'
    },
    {
      title: 'Neurotransmitters',
      url: 'Neurotransmitters '
    },
    {
      title: 'Food Intake and Digestion',
      url: 'FoodAndIntakeDigestion'
    },
    {
      title: 'Vitamin B12 Deficiency',
      url: 'VitaminB12Deficiency'
    },
    {
      title: 'General Decision-Making Style',
      url: 'GeneralDecisionMakingStyle'
    }
  ]
}