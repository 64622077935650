export const clientInfo = [
  {
    label: 'First name',
    value: 'Cath'
  },
  {
    label: 'Family name',
    value: 'Liwag'
  },
  {
    label: 'Sex',
    value: 'Female'
  },
  {
    label: 'Date of birth',
    value: '02 Feb. 2024'
  },
  {
    label: 'Weight (kg)',
    value: '65.0'
  },
  {
    label: 'Waist circumference (cm)',
    value: '67.0'
  },
  {
    label: 'Your Body Mass Index (BMI)',
    value: '18055.56'
  },
  {
    label: 'Waist to height ratio',
    value: '11.17'
  },
  {
    label: 'Height (cm)',
    value: '6.0'
  },
  {
    label: 'Vegetarian',
    value: 'No'
  },
];

export const clientContactDetails = {
  label: [
    'Relationship with child',
    'Name',
    'Telephone number',
    'Country',
    'Street',
    'Number(+Additional)',
    'Place',
    'Postal Code',
  ],
  primaryCaregiver: [
    'Mother',
    'Cole Demavivas',
    '09166187723',
    'Netherlands',
    'Sampaguita',
    '62',
    'test',
    '1234AB'
  ],
  secondaryCaregiver: [
    'test',
    'test',
    '09166187723',
    'Andorra',
    'Sampaguita',
    '62',
    'haha',
    '1300'
  ],
  familyDetails: [
    {
      label: 'Parents marital status',
      value: 'Married'
    },
    {
      label: 'Number of brothers',
      value: 0
    },
    {
      label: 'Number of sisters',
      value: 0
    },
  ]
};

export const allergiesAndOthers = [
  {
    label: 'General Health Question of the child',
    value: 'yery'
  },
  {
    label: 'Food supplements',
    value: 'None'
  },
  {
    label: 'Allergies',
    value: 'None'
  },
  {
    label: 'Food intolerances',
    value: 'None'
  },
  {
    label: 'Addictions',
    value: 'None'
  },
];

export const specialists = [
  {
    label: 'Specialists',
    value: 'None'
  },
  {
    label: 'Other health professionals',
    value: 'None'
  },
];

export const medications = [
  {
    label: 'Medications',
    value: 'test'
  },
];

export const vaccinations = [
  {
    label: 'Vaccinations',
    value: 'None'
  },
];

export const bloodlineDiseases = [
  {
    label: 'In my family bloodline there are specific diseases',
    value: 'None'
  }
];

export const surgeries = [
  {
    label: 'Surgeries',
    value: 'None'
  }
];

export const importantNotes = [
  {
    label: 'Important things about you to know during the duration of the consulting process',
    value: {
      description: 'test',
      eventOccured: '2016'
    }
  }
];