import { createBrowserRouter } from 'react-router-dom';
import AdminDashboard from '../pages/Admin/Dashboard';
import Login from '../pages/Auth/Login';
import ProfessionalDashboard from '../pages/Professional/Dashboard';
import ClientDashboard from '../pages/Client/Dashboard';
import EditProfile from '../pages/Professional/EditProfile';
import ProfessionalLayout from '../layouts/Professional';
import AdminLayout from '../layouts/Admin';
import ClientLayout from '../layouts/Client';
import MyInfo from '../pages/Client/MyInfo';
import AdminUsers from '../pages/Admin/AdminUsers';
import Professionals from '../pages/Admin/Professionals';
import HMA from '../pages/Admin/HMA';
import HRV from '../pages/Admin/HRV';
import IRCombi from '../pages/Admin/IRCombi';
import AdminLogin from '../pages/Auth/AdminLogin';
import ProtectedLayout from '../layouts/Protected';
import AdminProtectedLayout from '../layouts/Protected/Admin';
import IodineTest from '../pages/Admin/IodineTest';
import ActionPlanStatus from '../pages/Professional/ActionPlanStatus';
import ProfessionalClientView from '../pages/Professional/Client';
import IRCombiResult from '../pages/Professional/Analyses/IRCombi/Result';
import HRVResult from '../pages/Professional/Analyses/HRV/Result';
import HRVShowResult from '../pages/Professional/Analyses/HRV/Show';
import HMAResult from '../pages/Professional/Analyses/HMA/Result';
import IodineTestResult from '../pages/Professional/Analyses/IodineTest/Result';
import Terms from '../pages/Admin/Terms';
import QuestionnairePreview from '../pages/Professional/Questionnaires/Preview';
import Tags from '../pages/Admin/Tags';
import InMail from '../pages/Professional/InMail';
import LibraryOfTerms from '../pages/public/LibraryOfTerms';
import CommunitySubgroup from '../pages/Admin/CommunitySubgroup';
import TwoFactorAuthentication from '../pages/Professional/TwoFactorAuthentication';
import AdminCommunity from '../pages/Admin/Community';
import ProfessionalCommunity from '../pages/Professional/Community';
import RedirectIfLoggedIn from '../layouts/Redirect';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RedirectIfLoggedIn
        element={<Login />}
      />
    ),
  },
  {
    path: '/signin',
    element: (
      <RedirectIfLoggedIn
        element={<Login />}
      />
    ),
  },
  {
    path: '/a/sessions',
    element: (
      <RedirectIfLoggedIn
        element={<AdminLogin />}
      />
    ),
  },
  { // ADMIN ROUTES
    path: '/a',
    element: <AdminProtectedLayout layout={<AdminLayout />} />,
    children: [
      {
        path: '/a',
        element: <AdminDashboard />,
      },
      {
        path: 'account/edit',
        element: <EditProfile />
      },
      {
        path: 'admins',
        element: <AdminUsers />
      },
      {
        path: 'professionals',
        element: <Professionals />
      },
      {
        path: 'hma_imports',
        element: <HMA />
      },
      {
        path: 'hrv_imports',
        element: <HRV />
      },
      {
        path: 'ir_combis',
        element: <IRCombi />
      },
      {
        path: 'iodine_tests',
        element: <IodineTest />
      },
      {
        path: 'terms',
        element: <Terms />
      },
      {
        path: 'tags',
        element: <Tags />
      },
      {
        path: 'forums',
        element: <AdminCommunity />
      },
      {
        path: 'community_subgroups',
        element: <CommunitySubgroup />
      }
    ]
  },
  { // CLIENT ROUTES
    path: '/c',
    element: <ClientLayout />,
    children: [
      {
        path: '/c',
        element: <ClientDashboard />,
      },
      {
        path: 'general_client_info',
        element: <MyInfo />,
      },
      {
        path: 'account/edit',
        element: <EditProfile />
      }
    ]
  },
  { // PROFESSIONAL ROUTES
    path: '/p/:code',
    element: <ProtectedLayout layout={<ProfessionalLayout />} />,
    children: [
      {
        path: '/p/:code',
        element: <ProfessionalDashboard />,
      },
      {
        path: 'action_plan_statuses',
        element: <ActionPlanStatus />
      },
      {
        path: 'account/edit',
        element: <EditProfile />
      },
      {
        path: 'clients/:clientId',
        element: <ProfessionalClientView />
      },
      {
        path: 'ir_combis/:irCombiId',
        element: <IRCombiResult />
      },
      {
        path: 'hrv_reports/:hrvId',
        element: <HRVResult />
      },
      {
        path: 'hrv_reports/:hrvId/show_data',
        element: <HRVShowResult />
      },
      {
        path: 'hma_results/:hmaId',
        element: <HMAResult />
      },
      {
        path: 'iodine_tests/:iodineTestId',
        element: <IodineTestResult />
      },
      {
        path: 'questionnaire_preview/:questionnaireType',
        element: <QuestionnairePreview />
      },
      {
        path: 'terms/:slug',
        element: <LibraryOfTerms />
      },
      {
        path: 'client_messages',
        element: <InMail />,
        children: [
          {
            path: ':clientId',
            element: <InMail />
          }
        ]
      },
      {
        path: 'two_factor_authentication/edit',
        element: <TwoFactorAuthentication />
      },
      {
        path: 'forums',
        element: <ProfessionalCommunity />,
      },
    ]
  },
  {
    path: '/account/edit',
    element: <EditProfile />
  }
]);
