import { gql } from '@apollo/client';

export const GET_PROFESSIONAL = gql`
  query getProfessional($id: ID!) {
    getProfessional(id: $id) {
      accountStatus
      code
      contactDetail {
        addressPlace
        addressStreet
        addressCountry
        addressPostalCode
        telephoneNumber
      }
      createdAt
      creatorId
      email
      familyName
      firstName
      id
      lastOtpAt
      otpSecret
      twoFactorEnabled
      type
      updatedAt
      user {
        id
        isActive
        language
        timezone
      }
    }
  }
`;

export const GET_PROFESSIONAL_LOGO = gql`
  query get_professional_logo($variant: String!) {
    getProfessionalLogo(variant: $variant)
  }
`;

export const GET_PROFESSIONAL_LIST_CLIENTS = gql`
  query professionalListClients($page: Int, $perPage: Int, $filter: ProfessionalListClientFilter) {
    professionalListClients(page: $page, perPage: $perPage, filter: $filter) {
      total
      page
      perPage
      data {
        id
        accountStatus
        actionPlanStatus {
          id
          name
          color
          sorting
        }
        allergies
        allergyMisc {
          id
          other
          clientId
          createdAt
          updatedAt
        }
        bio {
          id
          sex
          length
          weight
          clientId
          bioFemale {
            id
            createdAt
            updatedAt
            clientBioId
            firstMensAge
            isInMenopause
          }
          createdAt
          updatedAt
          dateOfBirth
          isVegetarian
          waistCircumference
        }
        bloodlineDiseases {
          id
          disease
          clientId
          createdAt
          updatedAt
          familyMembers
        }
        careerAndEducationDetail {
          id
          clientId
          createdAt
          updatedAt
          profession
          educationLevel
        }
        caregiverContactDetails {
          id
          name
          clientId
          createdAt
          updatedAt
          isPrimary
          addressPlace
          addressNumber
          addressStreet
          addressCountry
          addressPostalCode
          relationship
          telephoneNumber
          telephoneCountryCode
        }
        clientGid
        colleagueNotes {
          id
          title
          client {
            id
            familyName
            firstName
            clientGid
          }
          content
          createdAt
          updatedAt
          professional {
            id
            familyName
            firstName
          }
        }
        createdAt
        email
        familyDetail {
          id
          type
          clientId
          fatherName
          motherName
          maritalStatus
          numberOfSisters
          numberOfBrothers
          numberOfMaleChildren
          numberOfFemaleChildren
        }
        familyName
        firstName
        foodIntoleranceMisc {
          id
          other
          clientId
          createdAt
          updatedAt
        }
        gid
        hairAndMineralAnalyses {
          id
          iron
          lead
          zinc
          boron
          cobalt
          copper
          nickel
          adminId
          arsenic
          cadmium
          calcium
          lithium
          mercury
          aluminum
          chromium
          clientId
          hairType
          selenium
          createdAt
          magnesium
          manganese
          potassium
          updatedAt
          molybdenum
          phosphorus
          isSeenByPro
          reportNumber
          attachmentUrl
          clientViewable
          dateOfAnalysis
          professionalViewable
        }
        personalContactDetail {
          id
          clientId
          createdAt
          updatedAt
          addressPlace
          addressNumber
          addressStreet
          addressCountry
          addressPostalCode
        }
        professionalHealthComplaintNote {
          id
          tags
          content
          attachmentUrls
          clientId
          createdAt
          updatedAt
        }
        professionalLogs {
          id
          title
          content
          clientSummaryAndActions
          client {
            id
          }
          mainHealthProblemVasScore
          generalWellBeingVasScore
          energyLevelVasScore
          attachmentUrls
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_PROFESSIONAL_CLIENT = gql`
  query getClient($id: ID!) {
    getClient(id: $id) {
      id
      accountStatus
      actionPlanStatus {
        id
        name
        color
        sorting
      }
      allergies
      allergyMisc {
        id
        other
        clientId
        createdAt
        updatedAt
      }
      bio {
        id
        sex
        length
        weight
        clientId
        bioFemale {
          id
          createdAt
          updatedAt
          clientBioId
          firstMensAge
          isInMenopause
        }
        createdAt
        updatedAt
        dateOfBirth
        isVegetarian
        waistCircumference
      }
      bloodlineDiseases {
        id
        disease
        clientId
        createdAt
        updatedAt
        familyMembers
      }
      careerAndEducationDetail {
        id
        clientId
        createdAt
        updatedAt
        profession
        educationLevel
      }
      caregiverContactDetails {
        id
        name
        clientId
        createdAt
        updatedAt
        isPrimary
        addressPlace
        addressNumber
        addressStreet
        addressCountry
        addressPostalCode
        relationship
        telephoneNumber
        telephoneCountryCode
      }
      clientGid
      colleagueNotes {
        id
        title
        client {
          id
          familyName
          firstName
          clientGid
        }
        content
        createdAt
        updatedAt
        professional {
          id
          familyName
          firstName
        }
      }
      createdAt
      email
      familyDetail {
        id
        type
        clientId
        fatherName
        motherName
        maritalStatus
        numberOfSisters
        numberOfBrothers
        numberOfMaleChildren
        numberOfFemaleChildren
      }
      familyName
      firstName
      foodIntoleranceMisc {
        id
        other
        clientId
        createdAt
        updatedAt
      }
      gid
      hairAndMineralAnalyses {
        id
        iron
        lead
        zinc
        boron
        cobalt
        copper
        nickel
        adminId
        arsenic
        cadmium
        calcium
        lithium
        mercury
        aluminum
        chromium
        clientId
        hairType
        selenium
        createdAt
        magnesium
        manganese
        potassium
        updatedAt
        molybdenum
        phosphorus
        isSeenByPro
        reportNumber
        attachmentUrl
        clientViewable
        dateOfAnalysis
        professionalViewable
      }
      personalContactDetail {
          id
          clientId
          createdAt
          updatedAt
          addressPlace
          addressNumber
          addressStreet
          addressCountry
          addressPostalCode
          telephoneNumber
          telephoneCountryCode
        }
      professionalHealthComplaintNote {
        id
        tags
        content
        attachmentUrls
        clientId
        createdAt
        updatedAt
      }
      professionalLogs {
        id
        title
        content
        clientSummaryAndActions
        client {
          id
        }
        mainHealthProblemVasScore
        generalWellBeingVasScore
        energyLevelVasScore
        attachmentUrls
        createdAt
        updatedAt
      }
      prescribedMedications {
        id
        content
        clientId
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_IRCOMBI_ITEMS = gql`
  query clientIrCombiItems ($clientId: ID!) {
    clientIrCombiItems (clientId:$clientId) {
      createdAt
      id
      reportNumber
      crpHs
      # cPepTide
      glucose
      homa2CInsulin
      homa2CInsulinPctB
      homa2CInsulinPctS
      homa2CPeptide
      homa2CPeptidePctB
      homa2CPeptidePctS
      homocysteine
      insuline
      isDiabetic
      isSeenByPro
      ldlOxAntistoffen
      professionalViewable
      isSyntheticInsulinUse
      clientViewable
    }
  }
`;

export const GET_HRV_ITEMS = gql`
  query clientHrvItems ($clientId:ID!) {
    clientHrvItems (clientId:$clientId) {
      id
      adminId
      clientId
      clientViewable
      data
      dateOfAnalysis
      durationOfAnalysis
      isSeenByPro
      professionalViewable
      reportNumber
      createdAt
      updatedAt
    }
  }
`;

export const GET_HMA_ITEMS = gql`
  query clientHmaItems ($clientId: ID!) {
    clientHmaItems (clientId:$clientId) {
      id
      adminId
      aluminum
      arsenic
      boron
      cadmium
      calcium
      chromium
      clientId
      clientViewable
      cobalt
      copper
      createdAt
      dateOfAnalysis
      hairType
      iron
      isSeenByPro
      lead
      lithium
      magnesium
      manganese
      mercury
      molybdenum
      nickel
      # phosporus
      potassium
      professionalViewable
      reportNumber
      selenium
      updatedAt
      zinc
    }
  }
`;

export const GET_IODINE_TEST_ITEMS = gql`
  query clientIodineTestItems($clientId: ID!) {
    clientIodineTestItems(clientId: $clientId) {
      id
      createdAt
      updatedAt
      testData {
        iodineTestId
        adminId
        id
        reportNumber
        dateOfAnalysis
        iodideSpotValue
        bromideSpotValue
        chlorideSpotValue
        iodide24hExcretion
        bromide24hExcretion
        chloride24hExcretion
      }
    }
  }
`;

export const GET_CLIENT_TIMELINE_EVENTS = gql`
  query clientTimelineEvents($clientId: ID!) {
    clientTimelineEvents(clientId: $clientId) {
      id
      age
      id
      day
      year
      month
      client {
        firstName
        familyName
      }
      content
      createdAt
      updatedAt
    }
  }
`;

export const GET_CLIENT_QUESTIONNAIRE_LISTS = gql`
  query clientQuestionnaireAssignments ($clientId:ID!) {
    clientQuestionnaireAssignments (clientId:$clientId) {
      questionnaireId
    	id
    	clientId
      updatedAt
      questionnaire {
        id
        user {
          id
          isActive
          language
          timezone
        }
        group
        title
        userId
        description
        deactivatedOn
        closingRemarks
        # content
      }
    }
  }
`;

export const LIST_PROFESSIONAL_MESSAGES = gql`
  query listProfessionalMessages($clientId: ID!) {
    listProfessionalMessages(clientId: $clientId) {
      page
      perPage
      data {
        content
        isFromClient
        title
        viewed
        id
        clientName
        clientId
        updatedAt
        attachments {
          id
          url
        }
        createdAt
      }
      total
    }
  }
`;

export const GET_PROFESSIONAL_NOTIFICATION = gql`
  query getProfessionalNotifications($professionalCode: ID!) {
    getProfessionalNotifications(professionalCode:$professionalCode) {
      unreadComments
      unreadClientMessages
    }
  }
`;

export const GET_CLIENT_ANALYSIS_REPORT_DOCUMENTS = gql`
  query clientAnalysisReportDocuments($clientId:ID!){
    clientAnalysisReportDocuments (clientId:$clientId) {
      id
      clientId
    }
  }
`;

export const LIST_QUESTIONNAIRES = gql`
  query listQuestionnaires {
    listQuestionnaires {
      id
      name
      title
      groupI18nKey
    }
  }
`;