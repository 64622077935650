import { Card } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: 2rem;
`;

export const HRVHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 2rem;

  .hrv-details {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      gap: 10px;

      :nth-child(1) {
        width: 8rem;
      }

      :nth-child(2) {
        font-weight: 700;
      }
    }
  }
`;

export const FixedContainer = styled.div`
  border: 1px solid black;
  padding: 1rem;
  display: inline-block;

  .mb-16 {
    width: 16rem;
    margin-bottom: 4rem;
  }

  .mb-5 {
    margin-bottom: 1.25rem;
  }

  .mb-24-5 {
    margin-bottom: 6.5rem;
  }

  p {
    font-weight: 700;
  }
`;

export const ScrollableContainer = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: auto;
`;

export const HRVItem = styled.div`
  flex-shrink: 0;
  padding: 1rem;
  display: inline-block;
  border: 1px solid #2f855a;

  .mb-16 {
    width: 16rem;
    margin-bottom: 4rem;
  }

  .mb-5 {
    margin-bottom: 1.25rem;
  }

  .mb-24-5 {
    margin-bottom: 6.5rem;
  }
`;