import { Form, Input, Modal, Select, SelectProps } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { ModalContentWrapper, StyledButtonInput } from './style'
import { UserLookupProps } from './type';
import { useSelector } from 'react-redux';
import { ProfessionalType } from '../../../../types';

const UserLookup: React.FC<UserLookupProps> = ({
  multiSelect,
  professionalOnly,
  clientOnly,
}) => {
  const professionals = useSelector((state: any) => state.professionals.professionals);
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [selectedProfessional, setSelectedProfessional] = useState<string>();
  const [selectedClient, setSelectedClient] = useState<string>();
  const optionProfessionalData = useMemo(() => {
    return professionals.map((professional: ProfessionalType) => ({
      value: professional.id,
      label: `${professional.firstName} ${professional.familyName}`
    }))
  }, [professionals]);
  const optionClientData = useMemo(() => {
    return professionals.map((professional: ProfessionalType) => ({
      value: professional.id,
      label: `${professional.firstName} ${professional.familyName}`
    }))
  }, [professionals]);

  const handleSelectProfessional = (newValue: string) => {
    setSelectedProfessional(newValue);
  };
  const handleSelectClient = (newValue: string) => {
    setSelectedClient(newValue);
  };

  return (
    <>
      {!clientOnly && (
        <Form.Item
          labelAlign="left"
          name="professional"
          label="Professional"
          rules={[
            {
              required: true,
              message: 'Professional can\'t be blank',
            },
          ]}
        >
          {/* <StyledButtonInput
          onClick={() => setOpenUserModal(true)}
        >
          <span>{placeholder}</span>
        </StyledButtonInput> */}
          <Select
            mode={multiSelect ? 'multiple' : undefined}
            showSearch
            value={selectedProfessional}
            placeholder={'Find Professional ...'}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            optionFilterProp="children"
            filterOption={(input: string, option: any) => option.label?.includes(input)}
            onChange={handleSelectProfessional}
            notFoundContent={null}
            options={(optionProfessionalData || []).map((d: any) => ({
              value: d.value,
              label: d.label,
            }))}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
      {!professionalOnly && (
        <Form.Item
          labelAlign="left"
          name="client"
          label="Client"
          rules={[
            {
              required: true,
              message: 'Client can\'t be blank',
            },
          ]}
        >
          <Select
            mode={multiSelect ? 'multiple' : undefined}
            showSearch
            value={selectedClient}
            placeholder={'Find Client ...'}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            optionFilterProp="children"
            filterOption={(input: string, option: any) => option.label?.includes(input)}
            onChange={handleSelectClient}
            notFoundContent={null}
            options={(optionClientData || []).map((d: any) => ({
              value: d.value,
              label: d.label,
            }))}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
      {/* <Modal
        title={`${userType} lookup`}
        open={openUserModal}
        onOk={() => setOpenUserModal(false)}
        onCancel={() => setOpenUserModal(false)}
        footer={null}
        width={900}
      >
        <ModalContentWrapper>
          <Select
            showSearch
            value={value}
            placeholder={'Find Professional ...'}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            optionFilterProp="children"
            filterOption={(input: string, option: any) => option.label?.includes(input)}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={(optionData || []).map((d: any) => ({
              value: d.value,
              label: d.label,
            }))}
            style={{ width: '100%' }}
          />
        </ModalContentWrapper>
      </Modal> */}
    </>
  )
}

export default UserLookup