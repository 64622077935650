import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import Cookies from 'js-cookie';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const csrfToken = 'key';
  const token = Cookies.get('auth_token');
  return {
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken,
      authorization: token ? `Bearer ${token}` : ''
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink as any),
  cache: new InMemoryCache(),
});

export default client;
