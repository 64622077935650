import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { AdminType } from '../../types';
import { ADMIN_CREATE_MUTATION } from '../../services/graphql/mutations/admin';
import { useSelector } from 'react-redux';

export default function useCreateAdmin() {
  const token = useSelector((state: any) => state.auth.token);
  const [adminCreateMutation, { loading }] = useMutation(ADMIN_CREATE_MUTATION);
  const [result, setResult] = useState<AdminType[]>([]);

  const createAdmin = useCallback(async (values: any) => {
    const { data } = await adminCreateMutation({
      variables: {
        input: {
          adminParams: {
            firstName: values.firstName,
            familyName: values.familyName,
            email: values.email,
            userAttributes: {
              password: values.password,
              passwordConfirmation: values.passwordConfirmation,
              language: values.language,
              timezone: values.timezone
            }
          },
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    });
    setResult(data);
    return data;
  }, [adminCreateMutation, token]);

  return {
    data: result,
    create: createAdmin,
    loading
  };
}
