import { Form, Tabs, Upload } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;

export const Label = styled.p`
  color: #9A9A9A;
  font-size: .875rem !important;
  margin: 0 !important ;
`;

export const MineralsWrapper = styled.div`
`;

export const MineralsFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledFormUpload = styled(Upload.Dragger)`
  .ant-upload {
    width: 100% !important;
    min-height: 200px;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 12px;
  }
`;