import React from 'react'
import { ContactDetailsInfoContainer, FormInfoWrapper } from './style'
import { clientContactDetails } from '../../../../constants/dummy_data/client'
import { Divider } from 'antd'

type Props = {}

const ContactDetailsInfo = (props: Props) => {
  return (
    <ContactDetailsInfoContainer>
      <p>Primary Caregiver</p>
      <Divider />
      {clientContactDetails.label.map((value, index) => (
        <FormInfoWrapper key={index}>
          <div>{value}</div>
          <div>{clientContactDetails.primaryCaregiver[index]}</div>
        </FormInfoWrapper>
      ))}
      <p>Secondary Caregiver</p>
      <Divider />
      {clientContactDetails.label.map((value, index) => (
        <FormInfoWrapper key={index}>
          <div>{value}</div>
          <div>{clientContactDetails.secondaryCaregiver[index]}</div>
        </FormInfoWrapper>
      ))}
      <p>Family Details</p>
      <Divider />
      {clientContactDetails.familyDetails.map((familyDetails, index) => (
        <FormInfoWrapper key={index}>
          <div>{familyDetails.label}</div>
          <div>{familyDetails.value}</div>
        </FormInfoWrapper>
      ))}
    </ContactDetailsInfoContainer>
  )
}

export default ContactDetailsInfo