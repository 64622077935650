import { createSlice } from '@reduxjs/toolkit';
import { questionnairesInitialState } from './initial_states';

export const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState: questionnairesInitialState,
  reducers: {
    setAnswerQuestionnaire(state, action) {
      const { questions, type } = action.payload;
      if (state[type]) {
        state[type].questions = questions;
      }
    },
    toggleChoice(state, action) {
      const { choicesIndex, questionsIndex, type } = action.payload;
      const questionnaire = state[type];
      const question = questionnaire.questions.find((q) => q.question_num === questionsIndex);

      let updatedQuestions;
      const choices = question.choices.map((choice: any, index: number) => ({
        ...choice,
        selected: index === choicesIndex,
      }));
      updatedQuestions = questionnaire.questions.map((q) =>
        q.question_num === questionsIndex ? { ...q, choices } : q
      );

      state[type] = {
        ...questionnaire,
        questions: updatedQuestions,
      };
    },
    toggleSubChoice(state, action) {
      const { choicesIndex, questionsIndex, mainQuestionNum, type } = action.payload;
      const questionnaire = state[type];
      const mainQuestion = questionnaire.questions.find((q: any) => q.question_num === mainQuestionNum);

      if (mainQuestion) {
        const subQuestion = mainQuestion.questions.find((q: any) => q.question_num === questionsIndex);

        if (subQuestion) {
          const updatedChoices = subQuestion.choices.map((choice: any, index: number) => ({
            ...choice,
            selected: index === choicesIndex,
          }));

          const updatedSubQuestions = mainQuestion.questions.map((q: any) =>
            q.question_num === questionsIndex ? { ...q, choices: updatedChoices } : q
          );

          const updatedQuestions = questionnaire.questions.map((q) =>
            q.question_num === mainQuestionNum ? { ...q, questions: updatedSubQuestions } : q
          );

          state[type] = {
            ...questionnaire,
            questions: updatedQuestions,
          };
        }
      }
    },
    toggleMultiSelect(state, action) {
      const { choicesIndex, questionsIndex, type } = action.payload;
      const questionnaire = state[type];
      const question = questionnaire.questions.find((q) => q.question_num === questionsIndex);

      let updatedQuestions;
      const choices = question.choices.map((choice: any, index: number) => ({
        ...choice,
        selected: index === choicesIndex ? !choice.selected : choice.selected,
      }));
      updatedQuestions = questionnaire.questions.map((q) =>
        q.question_num === questionsIndex ? { ...q, choices } : q
      );

      state[type] = {
        ...questionnaire,
        questions: updatedQuestions,
      };
    },
    toggleMultiChoice(state, action) {
      const { choicesIndex, questionsIndex, subQuestionsIndex, type } = action.payload;
      const questionnaire = state[type];
      const question = questionnaire.questions.find((q) => q.question_num === questionsIndex);
      const subQuestion = question.subQuestions[subQuestionsIndex];
      const choices = subQuestion.choices.map((choice: any, index: number) => ({
        ...choice,
        selected: index === choicesIndex,
      }));
      const updatedSubQuestions = question.subQuestions.map((sq: any, index: number) =>
        index === subQuestionsIndex ? { ...sq, choices } : sq
      );
      const updatedQuestions = questionnaire.questions.map((q) =>
        q.question_num === questionsIndex ? { ...q, subQuestions: updatedSubQuestions } : q
      );

      state[type] = {
        ...questionnaire,
        questions: updatedQuestions,
      };
    },
    setPage(state, action) {
      const { page, type } = action.payload;
      if (state[type]) {
        state[type].currentPage = page;
      }
    },
    setContinueLater(state, action) {
      const { type } = action.payload;
      if (state[type]) {
        state[type].continueLater = true;
      }
    },
  }
});

export const questionnairesActions = questionnairesSlice.actions;