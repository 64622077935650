import { useEffect, useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';
import MultiRowQuestion from '../../../../components/Questionnaire/MultiRowQuestion';

const YourHealth = () => {
  const dispatch = useDispatch();
  const yourHealthQuestionnaire = useSelector((state: any) => state.questionnaires.yourHealth);
  const currentYourHealthQuestionnaire = useMemo(() => {
    return yourHealthQuestionnaire?.questions.filter((q: any) => q.page === yourHealthQuestionnaire.currentPage)
  }, [yourHealthQuestionnaire]);

  useEffect(() => {
    console.log(currentYourHealthQuestionnaire);
  }, [currentYourHealthQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: yourHealthQuestionnaire.currentPage + 1,
      type: 'yourHealth'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: yourHealthQuestionnaire.currentPage - 1,
      type: 'yourHealth'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'yourHealth' }));
  };

  const handleRadioToggleMulti = (choicesIndex: number, subQuestionsIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleMultiChoice({ choicesIndex, subQuestionsIndex, questionsIndex }));
  };

  const renderContent = (question: any) => {
    switch (question.type) {
      case 'multi':
        return <MultiRowQuestion
          label={question.label}
          subQuestions={question.subQuestions}
          handleRadioToggleMulti={handleRadioToggleMulti}
          order={question.question_num}
          choicesLabelType='alphabet'
        />
      case 'single':
        return <SingleQuestion
          label={question.label}
          choices={question.choices}
          handleRadioToggle={handleRadioToggle}
          order={question.question_num}
        />
      default:
    }
  }

  return (
    <Container>
      <h1>Your Health</h1>
      <p>
        The questionnaire consists of 36 closed questions questioning limitations in functioning or cognitions about health. A higher score translates into a better level of functioning. The negatively formulated items are mirrored. The outcome of this questionnaire will be discussed with you in our meeting.
      </p>
      <div className="page-container">
        <div>
          <p>{`Page ${yourHealthQuestionnaire.currentPage} of ${yourHealthQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentYourHealthQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                {renderContent(question)}
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      {yourHealthQuestionnaire.currentPage === yourHealthQuestionnaire.totalPage &&
        <p>Thank you for completing these questions!</p>
      }
      <CardFooter>
        <div>
          <Button>Coninue later</Button>
          {yourHealthQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {yourHealthQuestionnaire.currentPage === yourHealthQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default YourHealth;
