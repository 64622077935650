import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { LIST_TIMEZONES } from '../../services/graphql/queries/common';
import { useDispatch } from 'react-redux';
import { timeZoneActions } from '../../redux/slices/timezoneSlice';

export default function useGetAllTimezones() {
  const dispatch = useDispatch();
  const { data, loading } = useQuery(LIST_TIMEZONES);

  const fetchAllTimezones = useCallback(() => {
    if (data) {
      dispatch(
        timeZoneActions.setTimezones(data?.listTimezones)
      )
    }
  }, [data, dispatch]);

  return {
    data: data?.listTimezones,
    fetchData: fetchAllTimezones,
    loading
  };
}
