import { type Editor } from '@tiptap/react'
import React, { useCallback } from 'react'
import MaterialIcon from '../../../Icons/MaterialIcon'

type ToolbarProps = {
  editor: Editor
}

const Toolbar: React.FC<ToolbarProps> = ({ editor }) => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="tiptap-toolbar">
        <div className="btn-group-texts">
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .toggleBold()
                .run()
            }
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-icons-sharp"
              icon="format_bold"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .toggleItalic()
                .run()
            }
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-icons-sharp"
              icon="format_italic"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .toggleStrike()
                .run()
            }
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-icons-sharp"
              icon="strikethrough_s"
              style={{ fontSize: 20 }}
            />
          </button>
        </div>
        <div className="btn-group-align">
          <button
            type="button"
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_align_left"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_align_center"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_align_right"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().setTextAlign('justify').run()}
            className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_align_justify"
              style={{ fontSize: 20 }}
            />
          </button>
        </div>
      </div>
      <div className='tiptap-toolbar'>
        <div className="btn-group-elements">
          <button
            type="button"
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={editor.isActive('paragraph') ? 'is-active' : ''}
          >
            p
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_h1"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_h2"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_h3"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_h4"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
            className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_h5"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
            className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="format_h6"
              style={{ fontSize: 20 }}
            />
          </button>
        </div>
      </div>
      <div className="tiptap-toolbar bottom">
        <div className="btn-group-list">
          {/* code */}
          <div>
            <button
              type="button"
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
              <MaterialIcon
                className="material-symbols-outlined"
                icon="format_list_bulleted"
                style={{ fontSize: 20 }}
              />
            </button>
            <button
              type="button"
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
              <MaterialIcon
                className="material-symbols-outlined"
                icon="format_list_numbered"
                style={{ fontSize: 20 }}
              />
            </button>
          </div>

          {/* quote */}
          <div>
            <button
              type="button"
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              className={editor.isActive('codeBlock') ? 'is-active' : ''}
            >
              <MaterialIcon
                className="material-symbols-outlined"
                icon="code"
                style={{ fontSize: 20 }}
              />
            </button>
            <button
              type="button"
              onClick={() => editor.chain().focus().setBlockquote().run()}
              className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
              <MaterialIcon
                className="material-symbols-outlined"
                icon="format_quote"
                style={{ fontSize: 20 }}
              />
            </button>
            <button
              type="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
              <MaterialIcon
                className="material-symbols-outlined"
                icon="horizontal_rule"
                style={{ fontSize: 20 }}
              />
            </button>
            <button
              type="button" onClick={() => editor.chain().focus().setHardBreak().run()}>
              br
            </button>
          </div>

          {/* link */}
          <div>
            <button type="button" onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
              <MaterialIcon
                className="material-symbols-outlined"
                icon="link"
                style={{ fontSize: 20 }}
              />
            </button>
            <button
              type="button"
              onClick={() => editor.chain().focus().unsetLink().run()}
              disabled={!editor.isActive('link')}
            >
              <MaterialIcon
                className="material-symbols-outlined"
                icon="link_off"
                style={{ fontSize: 20 }}
              />
            </button>
          </div>
        </div>
        <div className="btn-group-history">
          <button
            type="button"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .undo()
                .run()
            }
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="undo"
              style={{ fontSize: 20 }}
            />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .redo()
                .run()
            }
          >
            <MaterialIcon
              className="material-symbols-outlined"
              icon="redo"
              style={{ fontSize: 20 }}
            />
          </button>
        </div>
      </div>
    </>
  )
}

export default Toolbar