import { Layout } from 'antd';
import CustomHeader from '../CustomHeader';
import ClientSidebar from './Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import MobileSidebar from './Mobile/Sidebar';
import { StyledLayout } from './style';

const { Content } = Layout;
const ClientLayout: React.FC = () => {
  const location = useLocation();
  const isClientDashboard = location.pathname === '/c';

  return (
    <Layout hasSider style={{ height: '100%', minHeight: '100dvh' }}>
      <ClientSidebar />
      <MobileSidebar />
      <StyledLayout withBackground={isClientDashboard}>
        <CustomHeader />
        <Content>
          <Outlet />
        </Content>
      </StyledLayout>
    </Layout >
  )
}

export default ClientLayout;