import { useMutation } from '@apollo/client';
import { ADMIN_CREATE_PROFESSIONAL_MUTATION, ADMIN_DELETE_PROFESSIONAL_MUTATION, ADMIN_UPDATE_PROFESSIONAL_MUTATION } from '../../services/graphql/mutations/admin';
import { useCallback, useState } from 'react';

export const useCreateProfessionalUser = () => {
  const [professionalUserCreate, { loading }] = useMutation(ADMIN_CREATE_PROFESSIONAL_MUTATION);
  const [result, setResult] = useState<any>();

  const createProfessionalUser = useCallback(async (values: any) => {
    const { data } = await professionalUserCreate({
      variables: {
        input: {
          professionalParams: {
            type: values?.type,
            email: values?.email,
            firstName: values?.firstName,
            familyName: values?.familyName,
            contactDetailAttributes: {
              telephoneNumber: values?.telephoneNumber,
              addressCountry: values?.addressCountry,
              addressStreet: values?.addressStreet,
              addressPlace: values?.addressPlace,
              addressPostalCode: values?.addressPostalCode,
            },
            userAttributes: {
              language: values?.language,
              timezone: values?.timezone,
              password: values?.password ?? 'password',
              passwordConfirmation: values?.confirmPassword ?? 'password'
            }
          }
        },
      }
    });
    setResult(data?.updateProfessional);
    return data?.updateProfessional;
  }, [professionalUserCreate]);

  return {
    data: result,
    update: createProfessionalUser,
    loading
  };
};

export const useUpdateProfessionalUser = () => {
  const [professionalUserUpdate, { loading }] = useMutation(ADMIN_UPDATE_PROFESSIONAL_MUTATION);
  const [result, setResult] = useState<any>();

  const updateProfessionalUser = useCallback(async (values: any) => {
    const { data } = await professionalUserUpdate({
      variables: {
        input: {
          id: values.id,
          professionalParams: {
            type: values?.type,
            email: values?.email,
            firstName: values?.firstName,
            familyName: values?.familyName,
            contactDetailAttributes: {
              telephoneNumber: values?.telephoneNumber,
              addressCountry: values?.addressCountry,
              addressStreet: values?.addressStreet,
              addressPlace: values?.addressPlace,
              addressPostalCode: values?.addressPostalCode,
            },
            userAttributes: {
              language: values?.language,
              timezone: values?.timezone,
              password: values?.password,
              confirmPassword: values?.confirmPassword
            }
          }
        },
      }
    });
    setResult(data?.updateProfessional);
    return data?.updateProfessional;
  }, [professionalUserUpdate]);

  return {
    data: result,
    update: updateProfessionalUser,
    loading
  };
};

export const useDeleteProfessionalUser = () => {
  const [professionalUserDelete, { loading }] = useMutation(ADMIN_DELETE_PROFESSIONAL_MUTATION);
  const deleteProfessionalUser = useCallback(async (values: any) => {
    const { data } = await professionalUserDelete({
      variables: {
        id: {
          id: values.id
        }
      }
    });
    return data;
  }, [professionalUserDelete]);

  return {
    delete: deleteProfessionalUser,
    loading
  }
};
