import { Layout } from 'antd';
import sc from 'styled-components';
import spiderWeb from '../../assets/images/spiderweb-background.jpeg';
import { StyledLayoutProps } from './type';

export const StyledLayout = sc(Layout) <StyledLayoutProps>`
  @media (min-width: 992px) {
    background-image: ${(props) => props.withBackground ? `url(${spiderWeb})` : 'none'};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
`;