import styled from 'styled-components';

export const EditProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  width: 540px;
`;

export const EditProfileTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const cardStyle = {
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
};

export const cardBodyStyle = {
  width: '100%'
}

export const backButtonStyle = {
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderColor: '#718096'
};