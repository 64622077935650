import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { ProfessionalFormProps } from './type';
import { TWO_BTN_TAIL_FORM_ITEM_LAYOUT, _FORM_ITEM_LAYOUT } from '../../../../../constants';
import { CancelDeleteContainer, ModalContentWrapper, SaveBtnContainer, StyledSaveBtn } from './style';
import { useDeleteProfessionalUser } from '../../../../../hooks/admin/useAdminProfessional';

const { Option } = Select;

const ProfessionalUserForm: React.FC<ProfessionalFormProps> = ({ onBack, onSubmit, onReset, defaultValues, loading, isEdit }) => {
  const [countries] = useSelector((state: any) => [state?.countries.countries]);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const { delete: deleteProfessionalUser, loading: deleteLoading } = useDeleteProfessionalUser();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onSubmit(values);
  };

  const handleOnBack = () => {
    onBack();
  };

  const handleOnDelete = async () => {
    // const res = await deleteProfessionalUser(defaultValues?.id);
    console.log(defaultValues?.id);
  }

  useEffect(() => {
    if (!onReset) {
      form.resetFields();
    }
  }, [onReset, form]);

  return (
    <>
      <Form
        {..._FORM_ITEM_LAYOUT}
        form={form}
        name="user_professional_form"
        onFinish={onFinish}
        initialValues={{ ...defaultValues }}
        style={{ marginTop: '1rem' }}
        scrollToFirstError
      >
        <Form.Item hidden name='id'>
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          labelAlign="left"
          label="Type"
          hasFeedback
          rules={[{ required: true, message: 'Please select a type!' }]}
        >
          <Select placeholder="Please select a type">
            <Option value="ProfessionalTypeFull">Full</Option>
            <Option value="ProfessionalTypeA">Type A</Option>
            <Option value="ProfessionalTypeQuestionnaireOnly">Questionnaire-only</Option>
          </Select>
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'Email must be a valid email format',
            },
            {
              required: true,
              message: 'Email can\'t be blank.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="firstName"
          label="First name"
          rules={[
            {
              required: true,
              message: 'First name can\'t be blank',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="familyName"
          label="Family name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="telephoneNumber"
          label="Telephone number"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="addressCountry"
          labelAlign="left"
          label="Country"
          hasFeedback
          rules={[{ required: true, message: 'Please select a country!' }]}
        >
          <Select
            options={countries}
            placeholder="Please select a country"
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="addressStreet"
          label="Street"
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="addressPlace"
          label="Place"
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="addressPostalCode"
          label="Postal Code"
        >
          <Input />
        </Form.Item>
        {isEdit && (
          <>
            <Form.Item
              labelAlign="left"
              name="password"
              label="Password"
              rules={[
                // {
                //   validator: (rule, value) => {
                //     if (!value && value.length >= 6) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject('Password length must be at least be 6 characters long.');
                //   },
                // },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name="passwordConfirmation"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password confirmation doesn\'t match Password'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}
        <Form.Item {...TWO_BTN_TAIL_FORM_ITEM_LAYOUT}>
          <SaveBtnContainer>
            {!isEdit && (
              <Button onClick={handleOnBack} type="text">
                Back
              </Button>
            )}
            <StyledSaveBtn type="primary" htmlType="submit" loading={loading}>
              Save
            </StyledSaveBtn>
          </SaveBtnContainer>
          {isEdit && (
            <CancelDeleteContainer>
              <Button onClick={() => setConfirmDelete(true)} type="primary" style={{ background: 'red' }}>
                Delete
              </Button>
              <Button onClick={handleOnBack} type="text">
                Back
              </Button>
            </CancelDeleteContainer>
          )}
        </Form.Item>
      </Form>
      <Modal
        title='Delete Professional'
        open={confirmDelete}
        onOk={handleOnDelete}
        onCancel={() => setConfirmDelete(false)}
        width={400}
      >
        <ModalContentWrapper>
          {deleteLoading ? (
            <Spin />
          ) : (
            <p>Are you sure you want to delete the professional?</p>
          )}
        </ModalContentWrapper>
      </Modal>
    </>
  )
}

export default ProfessionalUserForm;