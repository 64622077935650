import React from 'react'
import { ImportantNotesInfoData, ImportantNotesInfoWrapper } from './style'
import { importantNotes } from '../../../../constants/dummy_data/client'

type Props = {}

const ImportantNotesInfo = (props: Props) => {
  return (
    <ImportantNotesInfoWrapper>
      <p>Important things about you to know during the duration of the consulting process</p>
      {importantNotes.map((info: any, index: number) => (
        <ImportantNotesInfoData>
          <div>{++index}.</div>
          <div>
            <p>{info.value.description}</p>
            <em>(year when this event occurred: {info.value.eventOccured})</em>
          </div>
        </ImportantNotesInfoData>
      ))}
    </ImportantNotesInfoWrapper>
  )
}

export default ImportantNotesInfo