import React, { useMemo, useState } from 'react'
import {
  BodyWrapper,
  Container,
  Header,
  LogBodyContainer,
  LogFooterContainer,
  LogQuestionContainer,
  MaximizedContainer,
  RadiosContainer,
  StyledTabs,
  UsernameWrapper,
  LogInputsContainer,
  StyledFormUpload,
  NoGeneralClientInfoWrapper,
  TimelineWrapper,
  TimelineBodyContainer,
  TimelineFooterContainer,
  TimeLineEventBodyContainer
} from './style'
import { Button, DatePicker, DatePickerProps, Input, Select, TabsProps, UploadProps, message } from 'antd'
import MaterialIcon from '../../../components/Icons/MaterialIcon'
import { useDispatch, useSelector } from 'react-redux'
import { professionalLogActions } from '../../../redux/slices/professionalLogSlice'
import { RootState } from '../../../redux/store'
import { StyledButton } from '../../../components/Common/Button/style'
import Tiptap from '../../../components/Common/Form/Tiptap'
import { InboxOutlined } from '@ant-design/icons'
import { VasScoreType } from '../../../types'
import { useCreateProfessionalLog, useUpdateProfessionalLog } from '../../../hooks/professionals/useProfessionalLog'
import { useGetProfessionalClient } from '../../../hooks/professionals/useProfessionalClient'
import { getAge } from '../../../helpers'
import dayjs from 'dayjs'
import { useCreateClientTimeline, useGetClientTimelines, useUpdateClientTimeline } from '../../../hooks/professionals/useClientTimelines'

type CustomRadioProps = {
  index: number
  value: number
  handleRadioToggle: (choicesIndex: number, type: VasScoreType) => void
  type: VasScoreType
}

type LogQuestionProps = {
  title: string
  showLabel?: boolean
  type: VasScoreType
}

interface MinimizedContainerProps {
  onMinimize: (e: any) => void
  onCloseLog: () => void
}

interface MaximizedContainerProps {
  tabs: TabsProps['items']
  currentTab: string
  onMinimize: (e: any) => void
  onCloseLog: () => void
};

interface LogFooterProps {
  footerIndex: number
  onChangeFooterIndex: (index: number) => void
};

const LogQuestion: React.FC<LogQuestionProps> = ({
  title,
  showLabel,
  type
}) => {
  const dispatch = useDispatch();
  const handleRadioToggle = (choicesIndex: number, type: VasScoreType) => {
    dispatch(
      professionalLogActions.setLogData({
        key: type,
        value: choicesIndex
      })
    );
  }

  return (
    <LogQuestionContainer>
      <p>{title}</p>
      {showLabel && (
        <div className="label-wrapper">
          <label>1 = solved</label>
          <label>10 = very present</label>
        </div>
      )}
      <RadiosContainer>
        {[...Array(10)].map((_, index) => (
          <CustomRadio
            key={`radio_${index}`}
            index={index}
            value={index + 1}
            type={type}
            handleRadioToggle={handleRadioToggle}
          />
        ))}
      </RadiosContainer>
    </LogQuestionContainer>
  );
};

const CustomRadio: React.FC<CustomRadioProps> = ({
  value,
  index,
  handleRadioToggle,
  type
}) => {
  const logs = useSelector((state: RootState) => state.professionalLog.logs);
  return (
    <div className="radio-wrapper" onClick={(e) => {
      e.stopPropagation();
      handleRadioToggle(index, type);
    }}>
      <div>
        <span>{++index}</span>
      </div>
      <input
        type="radio"
        name={`radios_${type}`}
        checked={logs[type] === value}
        onChange={() => handleRadioToggle(index, type)}
      />
    </div>
  )
};

const DragDropZone = () => {
  const logs = useSelector((state: RootState) => state.professionalLog.logs);
  const dispatch = useDispatch();
  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file, fileList) => {
      dispatch(
        professionalLogActions.setLogData({
          key: 'attachments',
          value: logs.attachments
            ? [...logs.attachments, ...fileList]
            : [...fileList]
        })
      );
      return false;
    },
    onRemove(file) {
      if (logs.attachments) {
        dispatch(
          professionalLogActions.setLogData({
            key: 'attachments',
            value: logs.attachments.filter((attachment: any) => attachment.uid !== file.uid)
          })
        )
      }

      if (logs.attachmentUrls) {
        dispatch(
          professionalLogActions.setLogData({
            key: 'attachmentUrls',
            value: logs.attachmentUrls.filter((attachment: any) => attachment !== file.url)
          })
        )
      }
    },
    onDrop(e) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      dispatch(
        professionalLogActions.setLogData({
          key: 'attachments',
          value: logs.attachments
            ? [...logs.attachments, ...droppedFiles]
            : droppedFiles,
        })
      );
      console.log('Dropped files', e.dataTransfer.files);
    },
    defaultFileList: logs.attachmentUrls ? logs.attachmentUrls.map((attachment: any, index: number) => ({
      uid: ++index,
      name: attachment.split('/').pop(),
      status: 'done',
      url: attachment
    })) : []
  };

  return (
    <StyledFormUpload {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
        banned files.
      </p>
    </StyledFormUpload>
  )
}

const LogScores = () => {
  const SCORE_DATA: { id: number, title: string, type: VasScoreType, showLabel: boolean }[] = [
    {
      id: 1,
      title: 'Presence of Main Health Complaint',
      type: 'mainHealthProblemVasScore',
      showLabel: true
    },
    {
      id: 2,
      title: 'General Wellbeing',
      type: 'generalWellBeingVasScore',
      showLabel: false
    },
    {
      id: 3,
      title: 'Energy Level',
      type: 'energyLevelVasScore',
      showLabel: false
    },
  ];

  return (
    <>
      {SCORE_DATA.map((score_data, index) => (
        <LogQuestion
          key={`log_question_${index}`}
          title={score_data.title}
          showLabel={score_data.showLabel}
          type={score_data.type}
        />
      ))}
    </>
  )
};

const LogInputs = () => {
  const dispatch = useDispatch();
  const logs = useSelector((state: RootState) => state.professionalLog.logs);
  const [showClientSummary, setShowClientSummary] = useState<boolean>(false);

  const onChangeContent = (value: string) => {
    dispatch(
      professionalLogActions.setLogData({
        key: 'content',
        value
      })
    );
  };

  const onChangeClientSummary = (value: string) => {
    dispatch(
      professionalLogActions.setLogData({
        key: 'clientSummaryAndActions',
        value
      })
    );
  };

  const onChangeTitle = (value: string) => {
    dispatch(
      professionalLogActions.setLogData({
        key: 'title',
        value
      })
    );
  }

  const onToggleClientSummary = () => {
    setShowClientSummary(!showClientSummary);
  }

  return (
    <LogInputsContainer>
      <Input value={logs.title} placeholder="Title (Optional)" onChange={(e) => onChangeTitle(e.target.value)} />
      <div className="input-text">
        <div className="header">
          <span>Log</span>
        </div>
        <Tiptap
          content={logs.content}
          onChange={onChangeContent}
          fullHeight={!showClientSummary}
        />
      </div>
      <div className="input-text">
        <div className="header" onClick={onToggleClientSummary}>
          <span>Client summary and actions</span>
          <MaterialIcon
            icon={showClientSummary ? 'unfold_less' : 'unfold_more'}
            className="material-icons-sharp"
          />
        </div>
        {showClientSummary && (
          <Tiptap
            content={logs.clientSummaryAndActions}
            onChange={onChangeClientSummary}
          />
        )}
      </div>
    </LogInputsContainer>
  );
};

const LogBody = () => {
  const [footerIndex, setFooterIndex] = useState(0);
  const { selectedClient } = useSelector((state: RootState) => state.professionalLog);
  const onChangeFooterIndex = (index: number) => {
    setFooterIndex(index);
  };

  const renderBodyContent = () => {
    switch (footerIndex) {
      case 0:
        return <LogScores />
      case 1:
        return <LogInputs />
      case 2:
        return <DragDropZone />;
      default:
    }
  };

  return (
    <LogBodyContainer>
      <div>
        <UsernameWrapper>
          <p>{selectedClient?.firstName} {selectedClient?.familyName}</p>
        </UsernameWrapper>
        <BodyWrapper>
          {renderBodyContent()}
        </BodyWrapper>
      </div>
      <LogFooter
        footerIndex={footerIndex}
        onChangeFooterIndex={onChangeFooterIndex}
      />
    </LogBodyContainer>
  )
};

const TimelineInput = () => {
  const dispatch = useDispatch();
  const { timeline, selectedClient, selectedAge, selectedDateOfEvent } = useSelector((state: RootState) => state.professionalLog);
  const ageOption = useMemo(() => {
    const clientAge = getAge(selectedClient?.bio.dateOfBirth as string);
    return [...Array(clientAge)].map((_, index) => ({
      value: index + 1,
      label: index + 1
    })).reverse()
  }, [selectedClient]);

  const onChangeContent = (value: string) => {
    dispatch(
      professionalLogActions.setTimelineData({
        key: 'content',
        value
      })
    );
  };

  const onSelectAge = (value: string) => {
    if (value) {
      const age = parseInt(value, 10);
      const currentDate = dayjs(selectedClient?.bio.dateOfBirth);
      const birthYear = (currentDate.year()) + age;
      const birthMonth = currentDate.month() + 1;
      const birthDay = currentDate.date();
      const birthDateForPicker = dayjs().year(birthYear).month(birthMonth - 1).date(birthDay);

      dispatch(
        professionalLogActions.setSelectedAge(age)
      );

      dispatch(
        professionalLogActions.setSelectedDateOfEvent(birthDateForPicker)
      );

      dispatch(
        professionalLogActions.setTimelineData({
          key: 'age',
          value: age,
        })
      );

      dispatch(
        professionalLogActions.setTimelineData({
          key: 'year',
          value: birthYear,
        })
      );

      dispatch(
        professionalLogActions.setTimelineData({
          key: 'month',
          value: birthMonth,
        })
      );

      dispatch(
        professionalLogActions.setTimelineData({
          key: 'day',
          value: birthDay,
        })
      );
    } else {
      console.log('No date of birth available');
    }
  };

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    if (date) {
      const year = date.year();
      const month = date.month() + 1;
      const day = date.date();

      dispatch(
        professionalLogActions.setTimelineData({
          key: 'day',
          value: day,
        })
      );

      dispatch(
        professionalLogActions.setTimelineData({
          key: 'month',
          value: month,
        })
      );

      dispatch(
        professionalLogActions.setTimelineData({
          key: 'year',
          value: year,
        })
      );

      console.log('Year:', year, 'Month:', month, 'Day:', day);
      if (selectedClient?.bio?.dateOfBirth) {
        const birthDate = dayjs(selectedClient.bio.dateOfBirth);
        const selectedDate = dayjs(date);
        const age = selectedDate.diff(birthDate, 'year');

        dispatch(
          professionalLogActions.setSelectedAge(age)
        );
        console.log('Age:', typeof age);
      } else {
        console.log('No date of birth available');
      }
    } else {
      console.log('No date selected');
    }
  };

  return (
    <TimelineWrapper>
      <div className="date-container">
        <div>
          <span>Age at the time of the event</span>
          <Select
            value={selectedAge?.toString()}
            onChange={onSelectAge}
            options={ageOption}
            size="small"
          />
        </div>
        <span>OF</span>
        <div>
          <span>Date</span>
          <DatePicker
            value={selectedDateOfEvent}
            onChange={onChangeDate}
            size="small"
          />
        </div>
      </div>
      <Tiptap
        content={timeline.content ?? ''}
        onChange={onChangeContent}
      />
    </TimelineWrapper>
  )
};

const TimelineFooter = () => {
  const dispatch = useDispatch();
  const { resetTimelineData, toggleOpen } = professionalLogActions;
  const { timeline, isEditTimeline } = useSelector((state: RootState) => state.professionalLog);
  const { create: createClientTimeline, loading: loadingCreateClientTimeline } = useCreateClientTimeline();
  const { update: updateClientTimeline, loading: loadingUpdateClientTimeline } = useUpdateClientTimeline();
  const { refetch: refetchClientTimeline } = useGetClientTimelines(timeline.clientId as string);

  const onSubmit = async () => {
    if (isEditTimeline) {
      try {
        const res = await updateClientTimeline(timeline);
        console.log('updateClientTimeline', res)
      } catch (error: any) {
        console.error('isEditTimeline', error);
      } finally {
        refetchClientTimeline();
        dispatch(
          resetTimelineData()
        );
        dispatch(
          toggleOpen()
        );
      }
      return;
    }
    try {
      const res = await createClientTimeline(timeline);
      console.log('recreateClientTimelines', res);
    } catch (error: any) {
      console.error('createTimeline', error);
    } finally {
      refetchClientTimeline();
      dispatch(
        resetTimelineData()
      );
      dispatch(
        toggleOpen()
      );
    }
    console.log(timeline);
  };

  return (
    <TimelineFooterContainer>
      <Button
        onClick={onSubmit}
        type="primary"
        size="small"
        loading={loadingCreateClientTimeline || loadingUpdateClientTimeline}
      >
        {isEditTimeline ? 'Update' : 'Save'} Timeline
      </Button>
      <Button
        type="text"
        icon={
          <MaterialIcon
            icon="delete"
            className="material-icons-round"
            style={{ fontSize: 24, color: '#718096' }}
          />
        }
        size="small"
      />
    </TimelineFooterContainer>
  )
};

const LogFooter: React.FC<LogFooterProps> = ({
  footerIndex,
  onChangeFooterIndex
}) => {
  const dispatch = useDispatch();
  const { resetLogData, toggleOpen } = professionalLogActions;
  const logs = useSelector((state: RootState) => state.professionalLog.logs);
  const isEditLog = useSelector((state: RootState) => state.professionalLog.isEditLog);
  const { create: createProfessionalLog, loading: loadingCreateProfessionalLog } = useCreateProfessionalLog();
  const { update: updateProfessionalLog, loading: loadingUpdateProfessionalLog } = useUpdateProfessionalLog();
  const { refetch: refetchProfessionalClient } = useGetProfessionalClient(parseInt(logs.clientId as string));
  const isDisabled = Object.entries(logs).some(([key, value]) => {
    if (key === 'attachments') {
      return false;
    }
    return value === '' || value === null || (Array.isArray(value) && value.length === 0);
  });

  const onSave = async () => {
    if (isEditLog) {
      try {
        const result = await updateProfessionalLog(logs);
        console.log(result);
      } catch (err) {
        console.error('onSaveLog', err);
      } finally {
        refetchProfessionalClient();
        dispatch(
          resetLogData()
        );
        dispatch(
          toggleOpen()
        );
      }
      return;
    }
    try {
      const result = await createProfessionalLog(logs);
      console.log(result);
    } catch (err) {
      console.error('onSaveLog', err);
    } finally {
      refetchProfessionalClient();
      dispatch(
        resetLogData()
      );
      dispatch(
        toggleOpen()
      );
    }
  };

  return (
    <LogFooterContainer>
      <div>
        <Button
          onClick={() => onChangeFooterIndex(0)}
          type="text"
          icon={
            <MaterialIcon
              icon="pin"
              className="material-icons-outlined"
              style={{ fontSize: 24, color: '#718096' }}
            />
          }
          size="small"
          style={{
            borderBottom: footerIndex === 0 ? '1px solid #718096' : '',
            borderRadius: 0
          }}
        />
        <Button
          onClick={() => onChangeFooterIndex(1)}
          type="text"
          icon={
            <MaterialIcon
              icon="edit_note"
              className="material-icons-sharp"
              style={{ fontSize: 24, color: '#718096' }}
            />
          }
          size="small"
          style={{
            borderBottom: footerIndex === 1 ? '1px solid #718096' : '',
            borderRadius: 0
          }}
        />
        <Button
          onClick={() => onChangeFooterIndex(2)}
          type="text"
          icon={
            <MaterialIcon
              icon="attachment"
              className="material-icons-sharp"
              style={{ fontSize: 24, color: '#718096' }}
            />
          }
          size="small"
          style={{
            borderBottom: footerIndex === 2 ? '1px solid #718096' : '',
            borderRadius: 0
          }}
        />
        <StyledButton
          onClick={onSave}
          padding=".5rem 1rem"
          disabled={isDisabled}
          loading={loadingCreateProfessionalLog || loadingUpdateProfessionalLog}
        >
          {isEditLog ? 'Update' : 'Save'} Log
        </StyledButton>
      </div>
      <div className="delete-wrapper">
        <Button
          type="text"
          icon={
            <MaterialIcon
              icon="delete"
              className="material-icons-round"
              style={{ fontSize: 24, color: '#718096' }}
            />
          }
          size="small"
        />
      </div>
    </LogFooterContainer>
  );
};

const TimeLineEventBody = () => {
  const { selectedClient } = useSelector((state: RootState) => state.professionalLog);
  return (
    <TimeLineEventBodyContainer>
      <UsernameWrapper>
        <p>{selectedClient?.firstName} {selectedClient?.familyName}</p>
      </UsernameWrapper>
      {selectedClient?.bio ? (
        <TimelineBodyContainer>
          <TimelineInput />
          <TimelineFooter />
        </TimelineBodyContainer>
      ) : (
        <NoGeneralClientInfoWrapper>
          <div>
            <MaterialIcon icon="report_problem" className="material-icons-outlined" />
            <p>Please ask your client to fill in the Bio part in General Client Info.</p>
          </div>
        </NoGeneralClientInfoWrapper>
      )}
    </TimeLineEventBodyContainer>
  )
};

const Minimized: React.FC<MinimizedContainerProps> = ({
  onMinimize,
  onCloseLog
}) => {
  const { selectedClient } = useSelector((state: RootState) => state.professionalLog);
  return (
    <Container>
      <div>
        <span>{selectedClient?.firstName} {selectedClient?.familyName}</span>
      </div>
      <div className="action-btns">
        <Button
          onClick={(e: any) => onMinimize(e)}
          type="text"
          icon={
            <MaterialIcon
              icon="maximize"
              className="material-icons-sharp"
              style={{ color: 'white' }}
            />
          }
          size="small"
        />
        <Button
          type="text"
          icon={
            <MaterialIcon
              icon="open_in_full"
              className="material-icons-sharp"
              style={{ color: 'white' }}
            />
          }
          size="small"
        />
        <Button
          onClick={onCloseLog}
          type="text"
          icon={
            <MaterialIcon
              icon="close"
              className="material-icons-sharp"
              style={{ color: 'white' }}
            />
          }
          size="small"
        />
      </div>
    </Container>
  );
};

const Maximized: React.FC<MaximizedContainerProps> = ({
  tabs,
  currentTab,
  onMinimize,
  onCloseLog
}) => {
  const dispatch = useDispatch();
  const { setCurrentTab } = professionalLogActions;

  const checkCurrentTab = useMemo(() => (
    currentTab === 'log' ? '1' : '2'
  ), [currentTab]);

  const onChangeTab = (key: string) => {
    dispatch(setCurrentTab(key === '1' ? 'log' : 'timeline'))
  };

  return (
    <MaximizedContainer>
      <Header>
        <div>
          <span>New Log</span>
        </div>
        <div className="action-btns">
          <Button
            onClick={(e: any) => onMinimize(e)}
            type="text"
            icon={
              <MaterialIcon
                icon="maximize"
                className="material-icons-sharp"
                style={{ color: 'white' }}
              />
            }
            size="small"
          />
          <Button
            type="text"
            icon={
              <MaterialIcon
                icon="open_in_full"
                className="material-icons-sharp"
                style={{ color: 'white' }}
              />
            }
            size="small"
          />
          <Button
            onClick={onCloseLog}
            type="text"
            icon={
              <MaterialIcon
                icon="close"
                className="material-icons-sharp"
                style={{ color: 'white' }}
              />
            }
            size="small"
          />
        </div>
      </Header>
      <StyledTabs
        defaultActiveKey="1"
        activeKey={checkCurrentTab}
        items={tabs}
        size="small"
        tabBarGutter={0}
        onChange={onChangeTab}
      />
    </MaximizedContainer>
  );
};

const Logger = () => {
  const dispatch = useDispatch();
  const isMinimized = useSelector((state: RootState) => state.professionalLog.minimized);
  const currentTab = useSelector((state: RootState) => state.professionalLog.currentTab);
  const { toggleOpen, toggleMinimize } = professionalLogActions;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'LOG',
      children: <LogBody />,
    },
    {
      key: '2',
      label: 'TIMELINE EVENT',
      children: <TimeLineEventBody />,
    }
  ];

  const onMinimize = (e: any) => {
    e.stopPropagation();
    dispatch(
      toggleMinimize()
    );
  };

  const onCloseLog = () => {
    dispatch(
      toggleOpen()
    );
  };

  return (
    <>
      {isMinimized ? (
        <Minimized
          onMinimize={onMinimize}
          onCloseLog={onCloseLog}
        />
      ) : (
        <Maximized
          tabs={items}
          currentTab={currentTab}
          onMinimize={onMinimize}
          onCloseLog={onCloseLog}
        />
      )}
    </>
  );
};

export default Logger;