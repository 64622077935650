import React from 'react'
import { _FORM_ITEM_LAYOUT, _IR_COMBI, _MINERALS, _TAIL_FORM_ITEM_LAYOUT } from '../../../../constants';
import { Label, MineralsFormContainer, MineralsWrapper, StyledForm } from './style';
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Radio, Row, Select, Upload, UploadProps, message } from 'antd';
import UserLookup from '../../../Common/Form/UserLookup';
import { UploadOutlined } from '@ant-design/icons';
import { useCreateIRCombi } from '../../../../hooks/admin/useIRCombi';

const { Option } = Select;

const IRCombiForm = () => {
  const { create: createIRCombi } = useCreateIRCombi();
  const [form] = StyledForm.useForm();

  const onFinish = async (values: any) => {
    const {
      client,
      professional,
      ...irCombiData
    } = values;
    // console.log('Received values of form: ', values);
    const inputValues = {
      professionalId: professional,
      irCombiData: {
        clientId: 2,
        professionalViewable: true,
        clientViewable: true,
        isSeenByPro: true,
        isSyntheticInsulinUse: false,
        ...irCombiData
      }
    }
    await createIRCombi(inputValues);
  };

  const props: UploadProps = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="ir_combi_form"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>Import IR Combi</p>
      <UserLookup />
      <Form.Item
        labelAlign="left"
        name="reportNumber"
        label="Report number"
        rules={[
          {
            required: true,
            message: 'Report number can\'t be blank',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="dateOfAnalysis"
        label="Date of analysis"
        labelAlign="left"
        rules={[{ required: true, message: 'Date of analysis can\'t be blank' }]}
      >
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Diabetic"
        name="isDiabetic"
      >
        <Radio.Group>
          <Radio value={true}> Yes </Radio>
          <Radio value={false}> No </Radio>
        </Radio.Group>
      </Form.Item>
      {_IR_COMBI.map((ir_combi, index) => (
        <Form.Item
          key={`${ir_combi.label}_${index}`}
          initialValue={0.0}
          labelAlign="left"
          label={ir_combi.label}
          name={ir_combi.name}
          rules={[
            {
              required: true,
              message: `${ir_combi.label} can\'t be blank`,
            },
          ]}
        >
          <InputNumber defaultValue={0.0} style={{ width: '100%' }} step={0.01} />
        </Form.Item>
      ))}
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  );
};

export default IRCombiForm;