import React from 'react'
import { ModalContentWrapper, StyledTable } from './style';
import TableHeader from '../TableHeader';
import { StyledButton } from '../Button/style';
import { CommonTableProps } from './type';
import { Modal } from 'antd';

const CommonTable = <T extends object>({
  showNewModal,
  onNewData,
  showEditModal,
  onEditData,
  handleBack,
  modalForm,
  columns,
  dataSource,
  tableType,
  modalWidth,
  total,
  page,
  perPage,
  setCurrentPage,
  totalData
}: CommonTableProps<T>) => {

  return (
    <>
      <TableHeader>
        <em>Showing {total}</em>
        <StyledButton
          onClick={() => onNewData(true)}
          icon={<i className="material-icons-sharp">add</i>}
        >
          {`New ${tableType}`}
        </StyledButton>
      </TableHeader>
      <StyledTable
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          total: totalData,
          current: page,
          onChange: (page, pageSize) => {
            setCurrentPage && setCurrentPage(page);
          }
        }}
      />
      <Modal
        title={`New ${tableType}`}
        open={showNewModal}
        onOk={() => onNewData(false)}
        onCancel={handleBack}
        footer={null}
        width={modalWidth ?? 900}
      >
        <ModalContentWrapper>
          {modalForm}
        </ModalContentWrapper>
      </Modal>
      <Modal
        title={`Edit ${tableType}`}
        open={showEditModal}
        onOk={() => onEditData(false)}
        onCancel={handleBack}
        footer={null}
        width={900}
      >
        <ModalContentWrapper>
          {modalForm}
        </ModalContentWrapper>
      </Modal>
    </>
  )
};

export default CommonTable;