import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { ClientFormProps } from './type';
import { TWO_BTN_TAIL_FORM_ITEM_LAYOUT, _FORM_ITEM_LAYOUT } from '../../../../constants';
import { CancelDeleteContainer, ModalContentWrapper, SaveBtnContainer, StyledSaveBtn } from './style';

const { Option } = Select;

const ClientUserForm: React.FC<ClientFormProps> = ({ onBack, onSubmit, onReset, defaultValues, loading, isEdit }) => {
  const timezones = useSelector((state: any) => state?.timezone.timezones);
  const timezoneOptions = timezones?.map((timezone: string) => ({
    value: timezone,
    label: timezone
  }));
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onSubmit(values);
  };

  const handleOnBack = () => {
    onBack();
  };

  const handleOnDelete = async () => {
    // const res = await deleteProfessionalUser(defaultValues?.id);
    console.log(defaultValues?.id);
  }

  useEffect(() => {
    form.setFieldsValue({
      id: defaultValues?.id,
      email: defaultValues?.email,
      firstName: defaultValues?.firstName,
      familyName: defaultValues?.familyName,
    });
  }, [defaultValues, form]);

  useEffect(() => {
    if (!onReset) {
      form.resetFields();
    }
  }, [onReset]);

  return (
    <>
      <Form
        {..._FORM_ITEM_LAYOUT}
        form={form}
        name="user_professional_form"
        onFinish={onFinish}
        style={{ marginTop: '1rem' }}
        scrollToFirstError
      >
        <Form.Item hidden name='id'>
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="firstName"
          label="First name"
          rules={[
            {
              required: true,
              message: 'First name can\'t be blank',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="familyName"
          label="Family name"
          rules={[
            {
              required: true,
              message: 'Family name can\'t be blank',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'Email must be a valid email format',
            },
            {
              required: true,
              message: 'Email can\'t be blank.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="language"
          labelAlign="left"
          label="Language"
          hasFeedback
          rules={[{ required: true, message: 'Please select a language!' }]}
        >
          <Select placeholder="Please select a language">
            <Option value="en">English</Option>
            <Option value="nl">Dutch</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="timezone"
          labelAlign="left"
          label="Timezone"
          hasFeedback
          rules={[{ required: true, message: 'Please select a timezone!' }]}
        >
          <Select
            options={timezoneOptions}
            placeholder="Please select a language"
          />
        </Form.Item>
        <Form.Item {...TWO_BTN_TAIL_FORM_ITEM_LAYOUT}>
          <SaveBtnContainer>
            {!isEdit && (
              <Button onClick={handleOnBack} type="text">
                Back
              </Button>
            )}
            <StyledSaveBtn type="primary" htmlType="submit" loading={loading}>
              Save
            </StyledSaveBtn>
          </SaveBtnContainer>
          {isEdit && (
            <CancelDeleteContainer>
              <Button onClick={() => setConfirmDelete(true)} type="primary" style={{ background: 'red' }}>
                Delete
              </Button>
              <Button onClick={handleOnBack} type="text">
                Back
              </Button>
            </CancelDeleteContainer>
          )}
        </Form.Item>
      </Form>
      <Modal
        title='Delete Professional'
        open={confirmDelete}
        onOk={handleOnDelete}
        onCancel={() => setConfirmDelete(false)}
        width={400}
      >
        <ModalContentWrapper>
          <p>Are you sure you want to delete the professional?</p>
        </ModalContentWrapper>
      </Modal>
    </>
  )
}

export default ClientUserForm;