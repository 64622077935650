import { useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { CREATE_ACTION_PLAN_STATUS, DELETE_ACTION_PLAN_STATUS, PROFESSIONAL_UPDATE_CLIENT_ACTION_PLAN_STATUS, UPDATE_ACTION_PLAN_STATUS } from '../../services/graphql/mutations/professional';
import { useCallback, useState } from 'react';
import { professionalActions } from '../../redux/slices/professionalSlice';
import { GET_ACTION_PLAN_STATUSES } from '../../services/graphql/queries/common';

export const useCreateActionPlanStatus = () => {
  const [createActionPlanStatus, { loading }] = useMutation(CREATE_ACTION_PLAN_STATUS);

  const createData = useCallback(async (values: any) => {
    try {
      const { data } = await createActionPlanStatus({
        variables: {
          input: {
            params: {
              color: values.color,
              sorting: values.sorting,
              name: values.name
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in createActionPlanStatus: ', error);
    }
  }, [createActionPlanStatus]);

  return {
    create: createData,
    loading
  };
};

export const useUpdateActionPlanStatus = () => {
  const [updateActionPlanStatus, { loading }] = useMutation(UPDATE_ACTION_PLAN_STATUS);

  const updateData = useCallback(async (values: any) => {
    try {
      const { data } = await updateActionPlanStatus({
        variables: {
          input: {
            id: values.id,
            params: {
              color: values.color,
              sorting: values.sorting,
              name: values.name
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in updateActionPlanStatus: ', error);
    }
  }, [updateActionPlanStatus]);

  return {
    update: updateData,
    loading
  };
};

export const useDeleteActionPlanStatus = () => {
  const [deleteActionPlanStatus, { loading }] = useMutation(DELETE_ACTION_PLAN_STATUS);

  const deleteData = useCallback(async (id: number) => {
    try {
      const { data } = await deleteActionPlanStatus({
        variables: {
          input: {
            id
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in deleteActionPlanStatus: ', error);
    }
  }, [deleteActionPlanStatus]);

  return {
    delete: deleteData,
    loading
  };
};

export const useGetAllActionPlanStatuses = () => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery(GET_ACTION_PLAN_STATUSES);

  const fetchAllActionPlanStatuses = useCallback(() => {
    if (data) {
      dispatch(
        professionalActions.setActionPlanStatuses(data?.getActionPlanStatuses)
      )
    }
  }, [data, dispatch]);

  return {
    data: data?.getActionPlanStatuses,
    fetchData: fetchAllActionPlanStatuses,
    loading
  };
};

export const useUpdateClientActionPlanStatus = () => {
  const [updateClientActionPlanStatus, { loading }] = useMutation(PROFESSIONAL_UPDATE_CLIENT_ACTION_PLAN_STATUS);

  const updateData = useCallback(async (clientId: string, actionPlanStatusId: any) => {
    try {
      const { data } = await updateClientActionPlanStatus({
        variables: {
          clientId,
          actionPlanStatusId
        }
      });
      return data.updateClientActionPlanStatus;
    } catch (error) {
      console.log('Error in updateClientActionPlanStatus: ', error);
    }
  }, [updateClientActionPlanStatus]);

  return {
    update: updateData,
    loading
  };
}