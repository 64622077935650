import { Form } from 'antd';
import sc from 'styled-components';

export const StyledForm = sc(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;

export const Label = sc.p`
  color: #9A9A9A;
  font-size: .875rem !important;
  margin: 0 !important ;
`;

export const MineralsWrapper = sc.div`
`;

export const MineralsFormContainer = sc.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NewTestOrder = sc.div`
  display: flex;
  justify-content: space-between;

  p {
    font-size: .875rem;
    margin-right: .5rem;
  }

  .ant-btn {
    background-color: #f7fafc;
    color: #a0aec0;
    font-size: .75rem;
    border: 0;

    &:hover {
      color: #f56565 !important;
      background-color: #fff5f5;
    }
  }
`;

export const AddMoreBtnContainer = sc.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0;

  .ant-btn {
    color: #48bb78;
    font-size: .75rem;
    border: 1px solid #68d391;

    &:hover {
      color: #38a169 !important;
      background-color: #c6f6d5;
    }
  }
`;

export const NewTestContainer = sc.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const NewTestFormWrapper = sc.div`
  display: flex;
  gap: 5px;
`;