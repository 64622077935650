import React from 'react'
import { Container, FixedContainer, HRVItem, ScrollableContainer, StyledCard } from './style'
import CommonLayout from '../../../../../layouts/Common'
import { HRVHeader } from '../Result/style'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const HRVShowResult = () => {
  const location = useLocation();
  const { payload } = location.state;
  const [client] = useSelector((state: any) => [state.professionals.selectedClient]);

  console.log('HRVSHOWResult', Object.values(payload.data));
  return (
    <CommonLayout title="HRV Results" width="1000px">
      <StyledCard>
        <HRVHeader>
          <div className="hrv-details">
            <div>
              <p>Report number</p>
              <p>12345</p>
            </div>
            <div>
              <p>Date of analysis:</p>
              <p>12345</p>
            </div>
            <div>
              <p>Client</p>
              <p>12345</p>
            </div>
            <div>
              <p>Client ID</p>
              <p>12345</p>
            </div>
          </div>
        </HRVHeader>
      </StyledCard>
      <StyledCard>
        <Container>
          <FixedContainer>
            <div className="mb-16">
              <p>Measurement start time</p>
              <p>Duration</p>
              <p>Heart rate</p>
            </div>
            <div className="mb-5">
              <p>Stress and recovery balance</p>
              <p>Amount of stress reactions</p>
              <p>Amount of recovery</p>
              <p>Recovery during daytime</p>
            </div>
            <div className="mb-5">
              <p>Restorative effect of sleep</p>
              <p>Length of sleep</p>
              <p>Amount of recovered sleep</p>
              <p>Quality of recovery (HRV)</p>
              <p>Self-reported sleep quality</p>
            </div>
            <div className="mb-16">
              <p>Health effects of physical activity</p>
              <p>Duration of physical activity</p>
            </div>
            <div className='mb-16'>
              <p>Total expenditure</p>
              <p>Expenditure by intensity</p>
            </div>
            <div className="mb-5">
              <p>Number of steps</p>
            </div>
            <div className="mb-5">
              <p>Lifestyle assessment score</p>
            </div>
            <div className="mb-5">
              <p>Stress state classification</p>
              <p>Alcohol</p>
              <p>Medication</p>
              <p>Self-reported sleep quality</p>
              <p>Stress state</p>
            </div>
            <div className="mb-24-5">
              <p>Heart Rate Variability</p>
              <p>Average RMSSD</p>
            </div>
            <div className="mb-16">
              <p>Training effect report</p>
              <p>Number of reported training events</p>
              <p>Measurement start time of event</p>
              <p>Duration of event</p>
              <p>Heart rate for report</p>
            </div>
            <div className="mb-16">
              <p>Training effect event</p>
              <p>Type Recovery</p>
              <p>EPOC</p>
              <p>Energy Expenditure</p>
            </div>
          </FixedContainer>
          <ScrollableContainer>
            {Object.values(payload?.data).map((hrvData: any, index: number) => {
              return (
                <HRVItem key={`hrvData_${index}`}>
                  <div className="mb-5">
                    <p>{hrvData.measurement_start_time}</p>
                    <p>{`${hrvData.duration} HH:MM`}</p>
                    <p>{`Low: ${hrvData?.heart_rate?.low}`}</p>
                    <p>{`Average: ${hrvData?.heart_rate?.avg}`}</p>
                    <p>{`High: ${hrvData?.heart_rate?.high}`}</p>
                  </div>
                  <div className="mb-5">
                    <p>{`${hrvData?.stress_and_recovery_balance} %`}</p>
                    <p>{`${hrvData.amount_of_stress_reactions} %`}</p>
                    <p>{`${hrvData.amount_of_recovery} %`}</p>
                    <p>{`${hrvData.recovery_during_daytime} HH:MM`}</p>
                  </div>
                  <div className="mb-5">
                    <p>{`${hrvData.restorative_effect_of_sleep} %`}</p>
                    <p>{`${hrvData.length_of_sleep} HH:MM`}</p>
                    <p>{`${hrvData.amount_of_recovered_sleep} %`}</p>
                    <p>{`${hrvData.quality_of_recovery} (MS)`}</p>
                    <p>{`${hrvData.self_reported_sleep_quality}`}</p>
                  </div>
                  <div className="mb-5">
                    <p>{`${hrvData.health_effects_of_physical_activity} %`}</p>
                    <p>{`Light: ${hrvData.duration_of_physical_activity.light} HH:MM`}</p>
                    <p>{`Moderate: ${hrvData.duration_of_physical_activity.moderate} HH:MM`}</p>
                    <p>{`Vigorous: ${hrvData.duration_of_physical_activity.vigorous} HH:MM`}</p>
                  </div>
                  <div className='mb-5'>
                    <p>{`${hrvData.total_expenditure.total} %`}</p>
                    <p>{`Vigorous & moderate: ${hrvData.total_expenditure.vigorous_and_moderate} (Kcal)`}</p>
                    <p>{`Light: ${hrvData.total_expenditure.light} (Kcal)`}</p>
                    <p>{`Other: ${hrvData.total_expenditure.other} (Kcal)`}</p>
                  </div>
                  <div className="mb-5">
                    <p>{`${hrvData.number_of_steps}`}</p>
                  </div>
                  <div className="mb-5">
                    <p>{`${hrvData.lifestyle_assessment_score} %`}</p>
                  </div>
                  <div className="mb-5">
                    <p>&nbsp;</p>
                    <p>{`${hrvData.stress_state_classification.alcohol}`}</p>
                    <p>{`${hrvData.stress_state_classification.medication}`}</p>
                    <p>{`${hrvData.stress_state_classification.self_reported_sleep_quality}`}</p>
                    <p>{`${hrvData.stress_state_classification.stress_state}`}</p>
                  </div>
                  <div className="mb-5">
                    <p>&nbsp;</p>
                    <p>{`During awake time: ${hrvData.average_rmssd.during_awake_time} ms.`}</p>
                    <p>{`During sleep time: ${hrvData.average_rmssd.during_sleep_time} ms.`}</p>
                    <p>{`Relative difference: ${hrvData.average_rmssd.relative_difference}`}</p>
                    <p>{`Age based minimal score: ${hrvData.average_rmssd.aged_based_minimal_score}`}</p>
                    <p>{`Av RMSSD nigh / age based minimum: ${hrvData.average_rmssd.age_based_minimum} %`}</p>
                  </div>
                  <div className="mb-5">
                    <p>&nbsp;</p>
                    <p>{`${hrvData.number_of_reported_training_events}`}</p>
                    <p>{`${hrvData.measurement_start_time_of_report}`}</p>
                    <p>{`${hrvData.duration_of_event}`}</p>
                    <p>{`Low: ${hrvData.heart_rate_for_report.low}`}</p>
                    <p>{`Avg: ${hrvData.heart_rate_for_report.avg}`}</p>
                    <p>{`High: ${hrvData.heart_rate_for_report.high}`}</p>
                  </div>
                  <div className="mb-16">
                    <p>&nbsp;</p>
                    <p>{`${hrvData.type_of_recovery}`}</p>
                    <p>{`${hrvData.epoc} (ml/kg)`}</p>
                    <p>{`${hrvData.energy_expenditure}`}</p>
                  </div>
                </HRVItem>
              );
            })}
          </ScrollableContainer>
        </Container>
      </StyledCard>
    </CommonLayout>
  )
}

export default HRVShowResult