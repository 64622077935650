import React, { useState } from 'react';
import CommonLayout from '../../../layouts/Common';
import CommonTable from '../../../components/Common/Table';
import { ActionBtn, ActionBtnWrapper, HeaderData, NameData, UrlData } from './style';
import { ColumnsType } from 'antd/es/table';
import { DataType } from './type';
import { hrvData } from '../../../constants/dummy_data/hrv';
import IRCombiForm from '../../../components/Forms/Admin/IRCombi';
import useListAnalysesView from '../../../hooks/admin/useListAnalysesView';
import { formatDate } from '../../../helpers';
import { Spin } from 'antd';

const IRCombi = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: irCombiData, total, loading, perPage } = useListAnalysesView('ir_combi', currentPage as number);
  const [openNewIRCombi, setOpenNewIRCombi] = useState<boolean>(false);
  const [openEditIRCombi, setOpenEditIRCombi] = useState<boolean>(false);

  const columns: ColumnsType<DataType> = [
    {
      title: <HeaderData>Client</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.client}`}</NameData>
      ),
    },
    {
      title: '',
      render: (text, record) => (
        <NameData>{`${record.clientGid}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Professional</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.professional}`}</NameData>
      ),
    },
    {
      title: '',
      render: (text, record) => (
        <NameData>{`${record.professionalCode}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Added by</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.addedBy}`}</NameData>
      )
    },
    {
      title: <HeaderData>Date</HeaderData>,
      render: (text, record) => (
        <UrlData>{`${formatDate(record.date)}`}</UrlData>
      ),
    },
    {
      title: <HeaderData>Actions</HeaderData>,
      render: (text, record) => (
        <ActionBtn
          active={record.professionalViewable}
          type="text"
          icon={
            <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>policy</i>
          }
        >
          {record.professionalViewable ? 'Disable' : 'Enable'}
        </ActionBtn>
      ),
    }
  ];

  const handleBack = () => {
    setOpenNewIRCombi(false);
    setOpenEditIRCombi(false);
  };

  return (
    <CommonLayout title="IR Combi" width="1000px">
      {loading ? <Spin size="large" /> :
        <CommonTable
          showNewModal={openNewIRCombi}
          onNewData={setOpenNewIRCombi}
          showEditModal={openEditIRCombi}
          onEditData={setOpenEditIRCombi}
          handleBack={handleBack}
          modalForm={<IRCombiForm />}
          columns={columns}
          dataSource={irCombiData}
          tableType="IR Combi"
          total={`${total} rows`}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          totalData={total}
        />
      }
    </CommonLayout>
  )
};

export default IRCombi;