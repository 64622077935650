import styled from 'styled-components';
import { theme } from '../../../styles/theme';

export const NotificationTitle = styled.span`
  color: ${theme.color.primary};
  font-size: 15px;
  font-weight: 700;
  margin: 0;
`;

export const NotificationErrorTitle = styled.span`
  color: ${theme.color.error};
  font-size: 15px;
  font-weight: 700;
  margin: 0;
`;