import { Divider } from 'antd';
import { QuestionnaireItemProps } from './types';
import { QuestionnaireItemContainer, QuestionnaireItemDate, QuestionnaireItemTitle, ResultContainer } from './style';

const QuestionnaireItem: React.FC<QuestionnaireItemProps> = ({
  title,
  date,
  isDone
}) => {
  return (
    <QuestionnaireItemContainer>
      <QuestionnaireItemTitle>{title}</QuestionnaireItemTitle>
      <QuestionnaireItemDate>{date}</QuestionnaireItemDate>
      <ResultContainer>
        {isDone ? (
          <>
            <p>Resultaten</p>
            <i className="material-icons-sharp">visibility</i>
          </>
        ) : (
          <>
            <p>Start</p>
            <i className="material-icons-sharp">play_arrow</i>
          </>
        )}
      </ResultContainer>
      <Divider />
    </QuestionnaireItemContainer>
  )
}

export default QuestionnaireItem;