import sc from 'styled-components';

export const ImportantNotesInfoWrapper = sc.div`
  margin-bottom: 1.5rem;
  p {
    margin: 0.75rem 0;
  }
`;

export const ImportantNotesInfoData = sc.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: .875rem;

  div:nth-child(1) {
    flex-shrink: 0;
    width: 1.25rem;

  }

  div:nth-child(2) {
    flex-grow: 1;
    p {
      margin: 0;
    }

    em {
      font-size: .875rem;
      color: #2d3748;
    }
  }
`;