import React, { useState } from 'react'
import { AvatarContainer, EditProfileContainer, EditProfileTitle, backButtonStyle, cardBodyStyle, cardStyle } from './style'
import { Button, Card, Form, Input, Select, Upload } from 'antd'
import { beforeUpload, getBase64 } from '../../../helpers';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './EditProfile.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import useGetProfessional from '../../../hooks/professionals/useGetProfessional';
import CustomLoading from '../../../components/Common/Loading';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
    xl: { span: 8 },
    xxl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
    lg: {
      span: 3,
      offset: 21
    },
    xl: {
      span: 3,
      offset: 21
    },
    xxl: {
      span: 3,
      offset: 21
    }
  },
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 70 }}>
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Form.Item>
)

const EditProfile = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const {
    data: professional,
    fetchData: fetchProfessional,
    refetch,
    loading: loadingProfessional
  } = useGetProfessional(user?.id);
  // const professional = useSelector((state) => state.professionals.)

  const defaultProfessionalValues = {
    ...professional,
    telephoneNumber: professional?.contactDetail?.telephoneNumber,
    addressStreet: professional?.contactDetail?.addressStreet,
    addressPlace: professional?.contactDetail?.addressPlace,
    addressPostalCode: professional?.contactDetail?.addressPostalCode,
  };

  console.log('EditProfile', defaultProfessionalValues);
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  if (loadingProfessional) {
    return <CustomLoading />
  }

  return (
    <EditProfileContainer>
      <EditProfileTitle>
        <h1>Edit Account Info</h1>
        <Button
          onClick={() => navigate(-1)}
          shape="circle"
          icon={<i className="material-icons-sharp" style={{ fontSize: 20, color: '#718096' }}>arrow_back</i>}
          style={backButtonStyle}
        />
      </EditProfileTitle>
      <Card style={cardStyle} styles={{ body: cardBodyStyle }}>
        <AvatarContainer>
          <Upload
            name="avatar"
            listType="picture-circle"
            className={styles.uploadContainer}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" className={styles.imageStyle} /> : uploadButton}
          </Upload>
        </AvatarContainer>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{ ...defaultProfessionalValues }}
          style={{ marginTop: '1rem' }}
          scrollToFirstError
        >
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First name"
            rules={[
              {
                required: true,
                message: 'Please input your First name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="familyName"
            label="Family name"
            rules={[
              {
                required: true,
                message: 'Please input your Family name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="telephoneNumber"
            label="Telephone number"
            rules={[{ required: true, message: 'Please input your Telephone number!' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="addressStreet"
            label="Street"
            rules={[
              {
                required: true,
                message: 'Please input your Street!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="number_addition"
            label="Number (+addition)"
            rules={[
              {
                required: true,
                message: 'Please input your Number (+addition)!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="addressPlace"
            label="Place"
            rules={[
              {
                required: true,
                message: 'Please input your Place!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="addressPostalCode"
            label="Postal Code"
            rules={[
              {
                required: true,
                message: 'Please input your Postal Code!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </EditProfileContainer>
  )
}

export default EditProfile