import React, { useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { UserAdminFormProps } from './type';
import { useSelector } from 'react-redux';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
    xl: { span: 8 },
    xxl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
  },
  labelWrap: true
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
    lg: {
      span: 5,
      offset: 19
    },
    xl: {
      span: 5,
      offset: 19
    },
    xxl: {
      span: 5,
      offset: 19
    }
  },
};

const UserAdminForm: React.FC<UserAdminFormProps> = ({ onBack, onSubmit, onReset, defaultValues, loading }) => {
  const timezones = useSelector((state: any) => state?.timezone.timezones);
  const timezoneOptions = timezones?.map((timezone: string) => ({
    value: timezone,
    label: timezone
  }));
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    onSubmit(values);
  };

  const handleOnBack = () => {
    onBack();
  };

  useEffect(() => {
    if (!onReset) {
      form.resetFields();
    }
  }, [onReset, form]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="user_admin_form"
      onFinish={onFinish}
      style={{ marginTop: '1rem' }}
      initialValues={{ ...defaultValues }}
      scrollToFirstError
    >
      <Form.Item
        labelAlign="left"
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'Email must be a valid email format',
          },
          {
            required: true,
            message: 'Email can\'t be blank.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        name="firstName"
        label="First name"
        rules={[
          {
            required: true,
            message: 'First name can\'t be blank',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        name="familyName"
        label="Family name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        name="password"
        label="Password"
        rules={[
          // {
          //   validator: (rule, value) => {
          //     if (!value && value.length >= 6) {
          //       return Promise.resolve();
          //     }
          //     return Promise.reject('Password length must be at least be 6 characters long.');
          //   },
          // },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        name="passwordConfirmation"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Password confirmation doesn\'t match Password'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="language"
        labelAlign="left"
        label="Language"
        hasFeedback
        rules={[{ required: true, message: 'Please select a language!' }]}
      >
        <Select placeholder="Please select a language">
          <Option value="en">English</Option>
          <Option value="nl">Dutch</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="timezone"
        labelAlign="left"
        label="Timezone"
        hasFeedback
        rules={[{ required: true, message: 'Please select a timezone!' }]}
      >
        <Select
          options={timezoneOptions}
          placeholder="Please select a language"
        />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button onClick={handleOnBack} type="text">
          Back
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserAdminForm