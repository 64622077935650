import sc from 'styled-components';
import { theme } from '../../../../styles/theme';

export const QuestionnaireItemContainer = sc.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-divider {
    margin-top: 20px;
  }
`;

export const QuestionnaireItemTitle = sc.p`
  font-weight: 700;
  font-size: 16px;
`;

export const QuestionnaireItemDate = sc.p`
  font-weight: 400;
  font-size: 16px;
`;

export const ResultContainer = sc.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-size: 16px;
    color: ${theme.color.primary};
  }

  i {
    font-size: 20px;
    color: ${theme.color.primary};
    margin-top: 3px;
  }
`;