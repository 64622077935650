import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import { SingleQuestionCol } from '../../../../components/Questionnaire/SingleQuestion';

const ConditionsDuringPregnancy = () => {
  const dispatch = useDispatch();
  const conditionsDuringPregnancyQuestionnaire = useSelector((state: any) => state.questionnaires.conditionsDuringPregnancy);
  const currentConditionsDuringPregnancyQuestionnaire = useMemo(() => {
    return conditionsDuringPregnancyQuestionnaire?.questions.filter((q: any) => q.page === conditionsDuringPregnancyQuestionnaire.currentPage)
  }, [conditionsDuringPregnancyQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: conditionsDuringPregnancyQuestionnaire.currentPage + 1,
      type: 'conditionsDuringPregnancy'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: conditionsDuringPregnancyQuestionnaire.currentPage - 1,
      type: 'conditionsDuringPregnancy'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'conditionsDuringPregnancy' }));
  };

  return (
    <Container>
      <h1>Conditions during pregnancy of your mother</h1>
      <p>
        There are increasing insights in science that provide arguments for the hypothesis that our health is largely, if not mainly, shaped in the perinatal period. By this we mean the period between conception and birth and roughly the first 1-2 years. Epigenetics plays a crucial role in this. This science covers all those factors that can have a major impact on growth and development but are not part of human DNA. In certain cases, however, these epigenetic disturbances can be passed on through the generations, which may give the impression that these are genetics (genes). The following questions relate to this period:
      </p>
      <div className="page-container">
        <div>
          <p>{`Page ${conditionsDuringPregnancyQuestionnaire.currentPage} of ${conditionsDuringPregnancyQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentConditionsDuringPregnancyQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <SingleQuestionCol
                  label={question.label}
                  choices={question.choices}
                  handleRadioToggle={handleRadioToggle}
                  order={question.question_num}
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {conditionsDuringPregnancyQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {conditionsDuringPregnancyQuestionnaire.currentPage === conditionsDuringPregnancyQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default ConditionsDuringPregnancy