import React from 'react'
import CommonLayout from '../../../layouts/Common'
import { FilterActionsWrapper, NoDataWrapper, PopOverItem, PopOverWrapper } from './style'
import { Popover, Select, Spin } from 'antd'
import { StyledButton } from '../../../components/Common/Button/style'
import MaterialIcon from '../../../components/Icons/MaterialIcon'
import { theme } from '../../../styles/theme'
import useTags from '../../../hooks/common/useTags'
import { TagsType } from '../../../types'

const ProfessionalCommunity = () => {
  const { data: tags, loading } = useTags();

  const options = tags?.map((tag: TagsType) => ({
    label: tag.name,
    value: tag.id
  }))

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
  };

  const popOverContent = () => (
    <PopOverWrapper>
      <PopOverItem>
        <span>Author</span>
        <div>
          <button>All</button>
          <span>|</span>
          <button>Clear</button>
        </div>
      </PopOverItem>
      <PopOverItem>
        <span>Pilot Groups</span>
        <div>
          <button>All</button>
          <span>|</span>
          <button>Clear</button>
        </div>
      </PopOverItem>
      <PopOverItem>
        <span>Categories</span>
        <div>
          <button>All</button>
          <span>|</span>
          <button>Clear</button>
        </div>
      </PopOverItem>
    </PopOverWrapper>
  )

  return (
    <CommonLayout title="Community" width="950px">
      <FilterActionsWrapper>
        <Select
          mode="multiple"
          allowClear
          style={{ minWidth: 200, maxWidth: 400 }}
          placeholder="Filter by tags"
          onChange={handleChange}
          options={options}
        />
        <Popover placement="bottomRight" content={popOverContent} arrow={false}>
          <StyledButton icon={<MaterialIcon className="material-icons-sharp" icon="filter_alt" style={{ fontSize: 20 }} />}>Show Only</StyledButton>
        </Popover>
      </FilterActionsWrapper>
      {loading ? (
        <Spin />
      ) : (
        <NoDataWrapper>
          <MaterialIcon
            className="material-icons-sharp"
            icon="folder_off"
            style={{ fontSize: '4rem', color: theme.color.primary }}
          />
          <span>No forum topics found.</span>
        </NoDataWrapper>
      )}
    </CommonLayout>
  )
}

export default ProfessionalCommunity