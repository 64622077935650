import { useCallback } from 'react';
import { LIST_PROFESSIONALS_QUERY } from '../../services/graphql/queries/admin';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { professionalActions } from '../../redux/slices/professionalSlice';

export default function useGetAllProfessionals(page?: number, perPage?: number) {
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(LIST_PROFESSIONALS_QUERY, {
    variables: {
      page,
      perPage
    }
  });

  dispatch(
    professionalActions.setProfessionals(
      data?.listProfessionals?.data
    )
  );
  const fetchProfessionals = useCallback(() => {
    dispatch(
      professionalActions.setProfessionals(
        data?.listProfessionals?.data
      )
    );
  }, [data, dispatch]);

  return {
    data: data?.listProfessionals?.data,
    fetchData: fetchProfessionals,
    refetch,
    loading,
    page: data?.listProfessionals?.page,
    perPage: data?.listProfessionals?.perPage,
    total: data?.listProfessionals?.total,
  };
};

export const useGetPaginatedProfessionals = (page?: number, perPage?: number) => {
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(LIST_PROFESSIONALS_QUERY, {
    variables: {
      page,
      perPage
    }
  });

  dispatch(
    professionalActions.setProfessionals(
      data?.listProfessionals?.data
    )
  );
  const fetchProfessionals = useCallback(() => {
    dispatch(
      professionalActions.setProfessionals(
        data?.listProfessionals?.data
      )
    );
  }, [data, dispatch]);

  return {
    data: data?.listProfessionals?.data,
    fetchData: fetchProfessionals,
    refetch,
    loading,
    page: data?.listProfessionals?.page,
    perPage: data?.listProfessionals?.perPage,
    total: data?.listProfessionals?.total,
  };
};
