import styled from 'styled-components';

export const ChoicesContainer = styled.div`
  .ant-radio-group {
    font-size: 1rem;
    font-weight: inherit;
    margin-top: 1rem;
  }

  > h3 {
    font-weight: 700;
    font-size: 1rem;
  }

  > div table {
    width: 100%;
    border-spacing: 0 10px;
    table-layout: fixed;

    thead {
      tr th {
      }
    }

    tbody {
      tr td {
        padding: 0 .25rem;
        text-align: center;

        > div {
          padding: 1rem 0;
          background: #f7fafc;
          cursor: pointer;
        }
      }
    }
  }
`;

export const ChoicesColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > .question-container {
    background: #f7fafc;
    padding: 1rem 1.25rem;

    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .other {
      display: block;
      background: white;
      padding: 1rem;

      > p {
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }
`;