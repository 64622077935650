import { Button, Form } from 'antd';
import sc from 'styled-components';

export const StyledForm = sc(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;

export const Label = sc.p`
  color: #9A9A9A;
  font-size: .875rem !important;
  margin: 0 !important ;
`;

export const MineralsWrapper = sc.div`
`;

export const MineralsFormContainer = sc.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SaveBtnContainer = sc.div`
  margin-bottom: 10px;
  display: flex;
`;

export const StyledSaveBtn = sc(Button)`
  width: 100%;
`;

export const CancelDeleteContainer = sc.div`
  display: flex;
  gap: 2px;
`;

export const ModalContentWrapper = sc.div`
  padding: 1.5rem;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;