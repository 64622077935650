import React, { useState } from 'react';
import CommonLayout from '../../../layouts/Common';
import CommonTable from '../../../components/Common/Table';
import { ActionBtn, HeaderData, NameData, UrlData } from './style';
import { ColumnsType } from 'antd/es/table';
import { DataType } from './type';
import HRVForm from '../../../components/Forms/Admin/HRV';
import useListAnalysesView from '../../../hooks/admin/useListAnalysesView';
import { formatDate } from '../../../helpers';
import { Spin } from 'antd';

const HRV = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: hrvData, total, loading, perPage } = useListAnalysesView('hrv', currentPage as number);
  const [openNewHRV, setOpenNewHRV] = useState<boolean>(false);
  const [openEditHRV, setOpenEditHRV] = useState<boolean>(false);

  const columns: ColumnsType<DataType> = [
    {
      title: <HeaderData>Client</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.client}`}</NameData>
      ),
    },
    {
      title: '',
      render: (text, record) => (
        <NameData>{`${record.clientGid}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Professional</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.professional}`}</NameData>
      ),
    },
    {
      title: '',
      render: (text, record) => (
        <NameData>{`${record.professionalCode}`}</NameData>
      ),
    },
    {
      title: <HeaderData>Added by</HeaderData>,
      render: (text, record) => (
        <NameData>{`${record.addedBy}`}</NameData>
      )
    },
    {
      title: <HeaderData>Date</HeaderData>,
      render: (text, record) => (
        <UrlData>{`${formatDate(record.date)}`}</UrlData>
      ),
    },
    {
      title: <HeaderData>Actions</HeaderData>,
      render: (text, record) => (
        <ActionBtn
          active={record.professionalViewable}
          type="text"
          icon={
            <i className="material-icons-outlined" style={{ fontSize: 'inherit' }}>policy</i>
          }
        >
          {record.professionalViewable ? 'Disable' : 'Enable'}
        </ActionBtn>
      ),
    }
  ];

  const handleBack = () => {
    setOpenNewHRV(false);
    setOpenEditHRV(false);
  };

  return (
    <CommonLayout title="HRV" width="1000px">
      {loading ? <Spin size="large" /> :
        <CommonTable
          showNewModal={openNewHRV}
          onNewData={setOpenNewHRV}
          showEditModal={openEditHRV}
          onEditData={setOpenEditHRV}
          handleBack={handleBack}
          modalForm={<HRVForm />}
          modalWidth={1200}
          columns={columns}
          dataSource={hrvData}
          tableType="HRV"
          total={`${total} rows`}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          totalData={total}
        />
      }
    </CommonLayout>
  )
};

export default HRV;