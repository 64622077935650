import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';
import MultiRowQuestion from '../../../../components/Questionnaire/MultiRowQuestion';

const Neurotransmitters = () => {
  const dispatch = useDispatch();
  const neurotransmittersQuestionnaire = useSelector((state: any) => state.questionnaires.neurotransmitters);
  const currentNeurotransmittersQuestionnaire = useMemo(() => {
    return neurotransmittersQuestionnaire?.questions.filter((q: any) => q.page === neurotransmittersQuestionnaire.currentPage)
  }, [neurotransmittersQuestionnaire]);

  const currentPart = useMemo(() => {
    return neurotransmittersQuestionnaire.currentPage <= 4 ? neurotransmittersQuestionnaire.parts[0] : neurotransmittersQuestionnaire.parts[1];
  }, [neurotransmittersQuestionnaire]);

  const currentPartLabel = useMemo(() => {
    const toDeduct = neurotransmittersQuestionnaire.currentPage <= 4 ? 1 : 5;
    return currentPart.partLabel[neurotransmittersQuestionnaire.currentPage - toDeduct];
  }, [neurotransmittersQuestionnaire, currentPart]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: neurotransmittersQuestionnaire.currentPage + 1,
      type: 'neurotransmitters'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: neurotransmittersQuestionnaire.currentPage - 1,
      type: 'neurotransmitters'
    }));
  };

  const handleRadioToggleMulti = (choicesIndex: number, subQuestionsIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleMultiChoice({ choicesIndex, subQuestionsIndex, questionsIndex }));
  };

  return (
    <Container>
      <h1>Neurotransmitters</h1>
      <div className='paragraphs'>
        <p>At the level of our central nervous system, neurotransmitters in particular play an important role. A disruption of these neurotransmitters has major consequences for mental and physical functioning. To get an idea of your predisposition / sensitivity and current state of the 4 most important neurotransmitters serotonin, acetylcholine, gaba and dopamine, we use the questionnaire below.
        </p>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${neurotransmittersQuestionnaire.currentPage} of ${neurotransmittersQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <div className="part-container">
        <h2>{currentPart.title}</h2>
        <div>
          {currentPart.description.map((desc: string) => (
            <p>{desc}</p>
          ))}
        </div>
      </div>
      <QuestionMainContainer>
        <h3>{currentPartLabel}</h3>
        {(currentNeurotransmittersQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <MultiRowQuestion
                  label={question.label}
                  subQuestions={question.subQuestions}
                  handleRadioToggleMulti={handleRadioToggleMulti}
                  order={question.question_num}
                  choicesLabelType='number'
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {neurotransmittersQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {neurotransmittersQuestionnaire.currentPage === neurotransmittersQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
};

export default Neurotransmitters;

