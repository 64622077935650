import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion, { SingleQuestionCol } from '../../../../components/Questionnaire/SingleQuestion';
import MultiSelectQuestion from '../../../../components/Questionnaire/MultiSelectQuestion';

const MentalEmotionalFunctioning = () => {
  const dispatch = useDispatch();
  const mentalEmotionalFunctioningQuestionnaire = useSelector((state: any) => state.questionnaires.mentalEmotionalFunctioning);
  const currentMentalEmotionalFunctioningQuestionnaire = useMemo(() => {
    return mentalEmotionalFunctioningQuestionnaire?.questions.filter((q: any) => q.page === mentalEmotionalFunctioningQuestionnaire.currentPage)
  }, [mentalEmotionalFunctioningQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: mentalEmotionalFunctioningQuestionnaire.currentPage + 1,
      type: 'mentalEmotionalFunctioning'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: mentalEmotionalFunctioningQuestionnaire.currentPage - 1,
      type: 'mentalEmotionalFunctioning'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'mentalEmotionalFunctioning' }));
  };

  const handleMultiSelect = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleMultiSelect({ choicesIndex, questionsIndex, type: 'mentalEmotionalFunctioning' }));
  };

  const renderContent = (question: any) => {
    switch (question.type) {
      case 'radio':
        return (
          <SingleQuestion
            label={question.label}
            choices={question.choices}
            handleRadioToggle={handleRadioToggle}
            order={question.question_num}
            minMaxLabel
            maxLabel={question.maxLabel}
            minLabel={question.minLabel}
          />
        )
      case 'radioCol':
        return (
          <SingleQuestionCol
            label={question.label}
            choices={question.choices}
            handleRadioToggle={handleRadioToggle}
            order={question.question_num}
          />
        )
      case 'checkbox':
        return (
          <MultiSelectQuestion
            label={question.label}
            choices={question.choices}
            handleMultiSelect={handleMultiSelect}
            order={question.question_num}
            other={question.other}
          />
        )
      default:
    }
  };

  return (
    <Container>
      <h1>Mental / emotional functioning – Your Personality</h1>
      <div className='paragraphs'>
        <p>A specific part of the intake concerns various questions about your mental and emotional health.</p>
        <p>Based on the answers provided in the General wellbeing questionnaire, we think it is important to ask you a number of additional questions about this. Would you answer the questions below? The outcome of this questionnaire will be discussed with you in our meeting.</p>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${mentalEmotionalFunctioningQuestionnaire.currentPage} of ${mentalEmotionalFunctioningQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentMentalEmotionalFunctioningQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                {renderContent(question)}
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {mentalEmotionalFunctioningQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {mentalEmotionalFunctioningQuestionnaire.currentPage === mentalEmotionalFunctioningQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default MentalEmotionalFunctioning;

