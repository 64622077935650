import styled from 'styled-components';

export const Container = styled.div`
  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: inherit;
  }

  > p {
    margin-bottom: 2rem;
    font-size: 1rem;
  }

  .page-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    p {
      border-bottom: 1px solid #2d3748;
      padding: 0 .25rem;
    }
  }
`;

export const QuestionMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const QuestionWrapper = styled.div`
  border: 1px solid #e2e8f0;
  padding: 1rem;
  border-radius: .25rem;
`;

export const CardFooter = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > div {
    display: flex;
    gap: .5rem;
  }
`;