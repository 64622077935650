import React, { useMemo } from 'react'
import { CardFooter, Container, QuestionMainContainer, QuestionWrapper } from './style'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { questionnairesActions } from '../../../../redux/slices/questionnaireSlice';
import SingleQuestion from '../../../../components/Questionnaire/SingleQuestion';

const EmotionRegulation = () => {
  const dispatch = useDispatch();
  const emotionRegulationQuestionnaire = useSelector((state: any) => state.questionnaires.emotionRegulation);
  const currentEmotionRegulationQuestionnaire = useMemo(() => {
    return emotionRegulationQuestionnaire?.questions.filter((q: any) => q.page === emotionRegulationQuestionnaire.currentPage)
  }, [emotionRegulationQuestionnaire]);

  const onSubmit = () => {
    console.log('Submitted');
  };

  const onNextPage = () => {
    dispatch(questionnairesActions.setPage({
      page: emotionRegulationQuestionnaire.currentPage + 1,
      type: 'emotionRegulation'
    }));
  };

  const onPrevPage = () => {
    dispatch(questionnairesActions.setPage({
      page: emotionRegulationQuestionnaire.currentPage - 1,
      type: 'emotionRegulation'
    }));
  };

  const handleRadioToggle = (choicesIndex: number, questionsIndex: number) => {
    dispatch(questionnairesActions.toggleChoice({ choicesIndex, questionsIndex, type: 'emotionRegulation' }));
  };

  return (
    <Container>
      <h1>Emotion Regulation</h1>
      <div className='paragraphs'>
        <p>We would like to ask you some questions about your emotional life, in particular how you control (that is, regulate and manage) your emotions.</p>
        <p>The questions below involve two distinct aspects of your emotional life</p>
        <p>One is your emotional experience, or what you feel like inside. The other is your emotional expression, or how you show your emotions in the way you talk, gesture, or behave.</p>
        <p>Although some of the following questions may seem similar to one another, they differ in important ways. For each item, please answer to what degree you agree with each statement. The outcome of this questionnaire will be discussed with you in our meeting.</p>
      </div>
      <div className="page-container">
        <div>
          <p>{`Page ${emotionRegulationQuestionnaire.currentPage} of ${emotionRegulationQuestionnaire.totalPage}`}</p>
        </div>
      </div>
      <QuestionMainContainer>
        {(currentEmotionRegulationQuestionnaire)
          .map((question: any, index: number) => {
            return (
              <QuestionWrapper key={`question_${index}`}>
                <SingleQuestion
                  label={question.label}
                  choices={question.choices}
                  handleRadioToggle={handleRadioToggle}
                  order={question.question_num}
                />
              </QuestionWrapper>
            )
          })}
      </QuestionMainContainer>
      <CardFooter>
        <div>
          <Button>Continue later</Button>
          {emotionRegulationQuestionnaire.currentPage > 1 && <Button onClick={onPrevPage}>Back</Button>}
          {emotionRegulationQuestionnaire.currentPage === emotionRegulationQuestionnaire.totalPage ? (
            <Button onClick={onSubmit}>Submit</Button>
          ) : (
            <Button onClick={onNextPage}>Next</Button>
          )}
        </div>
      </CardFooter>
    </Container>
  )
}

export default EmotionRegulation;

