import { Button } from 'antd';
import sc from 'styled-components';

export const NameData = sc.span`
  font-size: .875rem;
`;

export const UrlData = sc.a`
  color: #319795;
  text-decoration: none;
  font-weight: 700;
  &:hover {
    color: #2c7a7b;
    text-decoration: underline;
  }
`;

export const HeaderData = sc.span`
  font-size: .875rem;
  font-weight: 400;
  color: #718096;
`;

export const ModalContentWrapper = sc.div`
  padding: 1.5rem;
  padding-bottom: 0;
`;

export const ActionBtn = sc(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 5px;
  box-shadow: none;

  .ant-btn-icon {
    margin-inline-end: 1px !important;
    font-size: 24px;
  }

  span {
    font-size: .875rem;
  }

  &:hover {
    background: transparent !important;
  }
`;