import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { GET_CLIENT_TIMELINE_EVENTS } from '../../services/graphql/queries/professional';
import { PROFESSIONAL_CREATE_TIMELINE_EVENT, PROFESSIONAL_DELETE_TIMELINE_EVENT, PROFESSIONAL_UPDATE_TIMELINE_EVENT } from '../../services/graphql/mutations/professional';

export const useGetClientTimelines = (clientId: string) => {
  const { data, loading, refetch } = useQuery(GET_CLIENT_TIMELINE_EVENTS, {
    variables: {
      clientId
    }
  });

  const fetchClientTimelineEvents = useCallback(() => {
    if (data) {
      return data.clientTimelineEvents;
    }
  }, [data]);

  return {
    data: data?.clientTimelineEvents,
    fetchData: fetchClientTimelineEvents,
    loading,
    refetch
  };
};

export const useCreateClientTimeline = () => {
  const [createClientTimeline, { loading }] = useMutation(PROFESSIONAL_CREATE_TIMELINE_EVENT);

  const createData = useCallback(async (values: any) => {
    try {
      const { data } = await createClientTimeline({
        variables: {
          input: {
            params: {
              clientId: values.clientId,
              content: values.content,
              year: values.year,
              day: values.day,
              month: values.month
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in createClientTimeline: ', error);
    }
  }, [createClientTimeline]);

  return {
    create: createData,
    loading
  };
};

export const useUpdateClientTimeline = () => {
  const [updateClientTimeline, { loading }] = useMutation(PROFESSIONAL_UPDATE_TIMELINE_EVENT);

  const updateData = useCallback(async (values: any) => {
    try {
      const { data } = await updateClientTimeline({
        variables: {
          input: {
            params: {
              clientId: values.clientId,
              content: values.content,
              year: values.year,
              day: values.day,
              month: values.month,
              id: values.id
            },
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in updateClientTimeline: ', error);
    }
  }, [updateClientTimeline]);

  return {
    update: updateData,
    loading
  };
};

export const useDeleteClientTimeline = () => {
  const [deleteClientTimeline, { loading }] = useMutation(PROFESSIONAL_DELETE_TIMELINE_EVENT);

  const deleteData = useCallback(async (id: string) => {
    console.log('deleteData', id);
    try {
      const { data } = await deleteClientTimeline({
        variables: {
          input: {
            id
          },
        }
      });
      return data;
    } catch (error) {
      console.log('Error in deleteClientTimeline: ', error);
    }
  }, [deleteClientTimeline]);

  return {
    delete: deleteData,
    loading
  };
};