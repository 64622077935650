import { Card } from 'antd';
import styled from 'styled-components';
import { StyledTableProps } from './type';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
`;

export const StyledTable = styled.table<StyledTableProps>`
  margin-bottom: 2rem;
  width: 100%;
  border-collapse: ${(props) => props.collapsed ? 'collapse' : ''};
  border: ${(props) => `${props.border} solid #e2e8f0` ?? ''};
  break-inside: avoid;

  font-size: 16px;

  thead tr th {
    padding: .25rem .5rem;
  }

  tbody tr td {
    padding: .25rem .5rem;
  }

  th[colspan="99"],
  td[colspan="99"] {
    background-color: #f7fafc;
    font-weight: 700;
  }

  .bold {
    font-weight: 700;
  }

  .capitalize {
    text-transform: capitalize;
  }
`;

export const BackgroundInformationWrapper = styled.div`
  margin-bottom: 2rem;

  > h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
  }

  > P {
    font-size: 1rem;
  }
`;

export const AnalysisResultsWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  > h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
  }
`;

export const OrderStyle = styled.ol`
  margin-left: 2.5rem;
  list-style-type: decimal;
  padding: 0;
`;

export const HsCRPWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  > h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
  }

  > p {
    white-space: pre-wrap;
  }

  .italic {
    font-style: italic;
  }

  > ul {
    margin-left: 2.5rem;
    padding: 0;
    list-style-type: disc;
  }
`;

export const LDLoxWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  > h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
  }
  > p {
    white-space: pre-wrap;
  }
`;

export const HomocysteineWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  > h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
  }
  > p {
    white-space: pre-wrap;
  }
`;

export const HOMAWrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  > h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
  }
  > p {
    white-space: pre-wrap;
  }

  > ul {
    margin-left: 2.5rem;
    list-style-type: lower-alpha;
    padding: 0;
  }

  .italic {
    font-style: italic;
  }
`;

export const HOMA2Wrapper = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  > h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
  }
  > p {
    white-space: pre-wrap;
  }

  > ul {
    margin-left: 2.5rem;
    list-style-type: lower-alpha;
    padding: 0;
  }

  .italic {
    font-style: italic;
  }
`;