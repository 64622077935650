import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { professionalActions } from '../../redux/slices/professionalSlice';
import { LIST_PROFESSIONAL_MESSAGES } from '../../services/graphql/queries/professional';
import { PROFESSIONAL_SEND_MESSAGE } from '../../services/graphql/mutations/professional';
import { MessageType } from '../../types';

export default function useGetMessages(clientId: string) {
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(LIST_PROFESSIONAL_MESSAGES, {
    variables: {
      clientId
    }
  });

  const fetchProfessionalMessages = useCallback(() => {
    dispatch(
      professionalActions.setProfessionalMessages(data?.listProfessionalMessages?.data)
    )
    return data;
  }, [data, dispatch]);

  return {
    data: data?.listProfessionalMessages?.data,
    fetchData: fetchProfessionalMessages,
    refetch,
    loading
  };
};

export const useSendMessage = () => {
  const [professionalSendMessage, { loading }] = useMutation(PROFESSIONAL_SEND_MESSAGE);

  const sendMessage = useCallback(async (clientId: string, values: MessageType) => {
    try {
      const { data } = await professionalSendMessage({
        variables: {
          input: {
            clientId,
            messageParams: {
              title: values.title,
              content: values.content,
              attachments: values.attachments
            },
          },
        }
      });

      return data;
    } catch (error) {
      console.log('Error in createActionPlanStatus: ', error);
    }
  }, [professionalSendMessage]);

  return {
    sendMessage,
    loading
  };
}
