import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import CommonLayout from '../../../layouts/Common'
import { ConversationContainer, CreateMessageContainer, GoToClientButton, InmailWrapper, InputMessageSubWrapper, InputMessageWrapper, LeftChat, MessagesList, RightChat, StyledCard, StyledForm, UserContainer } from './style';
import { Avatar, Button, Input, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import useGetMessages from '../../../hooks/professionals/useGetMessages';
import { formatDate } from '../../../helpers';
import { Message } from '../../../redux/slices/initial_states/type';
import { ClientType } from '../../../types';
import Tiptap from '../../../components/Common/Form/Tiptap';

interface UserListProps {
  client: ClientType
  clientSelected: boolean
  setSelectedMessages: Dispatch<SetStateAction<Message[] | undefined>>
  onSelectClient: (clientId: string) => void
}

interface InputMessageProps {
  content: string
  onChange: (content: string) => void
}

const UserList: React.FC<UserListProps> = ({ client, clientSelected, setSelectedMessages, onSelectClient }) => {
  const { data: messages, loading } = useGetMessages(client.id);

  const onMessageClick = () => {
    onSelectClient(client.id);
    setSelectedMessages(messages);
  };

  const sortedMessages = useMemo(() => (
    messages && [...messages].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  ), [messages]);

  if (loading) {
    return <Spin size="large" />
  }

  return (
    <UserContainer
      userSelected={clientSelected}
      onClick={onMessageClick}
      key={`user_${client.id}`}
    >
      <Button
        className="pin"
        type="text"
        icon={
          <i className="material-icons-sharp" style={{ fontSize: 'inherit', transform: 'rotate(45deg)' }}>push_pin</i>
        }
      />
      <Avatar size={64} icon={<UserOutlined />} />
      <div className="userinfo">
        <p className="name">{client.firstName} {client.familyName}</p>
        <div className="message" dangerouslySetInnerHTML={{ __html: sortedMessages[0].content }} />
        <p className="date">{formatDate(sortedMessages[0].createdAt)}</p>
      </div>
    </UserContainer>
  );
};

const InputMessage: React.FC<InputMessageProps> = ({ content, onChange }) => {

  const [form] = StyledForm.useForm();
  return (
    <InputMessageSubWrapper>
      <StyledForm.Item
        labelAlign="left"
        name="title"
        label="Title"
      >
        <Input />
      </StyledForm.Item>
      <Tiptap
        content={content}
        onChange={onChange}
      />
    </InputMessageSubWrapper>
  )
}

const InMail = () => {
  const [replying, setReplying] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<any>();
  const clients = useSelector((state: any) => state.professionals.listClients);
  const [selectedMessages, setSelectedMessages] = useState<Message[]>();
  const [content, setContent] = useState<string>('');

  const onChangeMessage = (value: string) => {
    setContent(value);
  }

  const clientName = useMemo(() => {
    const filteredClient = clients?.filter((client: any) => client.id === selectedClientId)[0];
    return `${filteredClient?.firstName} ${filteredClient?.familyName}`;
  }, [selectedClientId, clients]);

  const onSelectClient = (id: string) => {
    setSelectedClientId(id);
  }

  return (
    <CommonLayout
      title="Inmail"
      width="95%"
      customButton={
        selectedClientId &&
        <GoToClientButton
          icon={
            <i className="material-icons-sharp" style={{ fontSize: 'inherit' }}>arrow_forward</i>
          }
          iconPosition="end"
        >
          {clientName}
        </ GoToClientButton>
      }
    >
      <StyledCard>
        <MessagesList>
          <CreateMessageContainer>
            <Button
              type="text"
              icon={
                <i className="material-icons-sharp" style={{ fontSize: 'inherit' }}>create</i>
              }
            />
          </CreateMessageContainer>
          {clients?.map((client: any) => (
            <UserList
              client={client}
              setSelectedMessages={setSelectedMessages}
              onSelectClient={onSelectClient}
              clientSelected={client.id === selectedClientId}
            />
          ))}
        </MessagesList>
        <ConversationContainer>
          {selectedClientId && (
            <div className="header">
              <Avatar size={55} icon={<UserOutlined />} />
              <h1 className="clientname">{clientName}</h1>
            </div>
          )}
          {selectedMessages && (
            <>
              <InmailWrapper>
                {selectedMessages?.map((message: Message) => {
                  if (message?.isFromClient) {
                    return (
                      <LeftChat>
                        <Avatar size={48} icon={<UserOutlined />} />
                        <div className="message">
                          <div className="content">
                            <p className="title">{message?.title}</p>
                            <div dangerouslySetInnerHTML={{ __html: message?.content }} />
                          </div>
                          <div className="datetime">
                            <small>{formatDate(message.createdAt as string)}</small>
                          </div>
                        </div>
                      </LeftChat>
                    )
                  }
                  return (
                    <RightChat>
                      <Avatar size={48} icon={<UserOutlined />} />
                      <div className="message">
                        <div className="content">
                          <p className="title">{message?.title}</p>
                          <div dangerouslySetInnerHTML={{ __html: message?.content }} />
                        </div>
                        <div className="datetime">
                          <small>{formatDate(message.createdAt as string)}</small>
                        </div>
                      </div>
                    </RightChat>
                  )
                })}
              </InmailWrapper>

              <InputMessageWrapper>
                {!replying && (
                  <Button
                    onClick={() => setReplying(true)}
                    type="primary"
                    icon={
                      <i className="material-icons-sharp" style={{ fontSize: 'inherit' }}>reply</i>
                    }
                    iconPosition="start"
                  >
                    Reply
                  </Button>
                )}
                {replying && (
                  <InputMessage
                    content={content}
                    onChange={onChangeMessage}
                  />
                )}
              </InputMessageWrapper>
            </>
          )}
        </ConversationContainer>
      </StyledCard>
    </CommonLayout>
  )
}

export default InMail