import { Form } from 'antd';
import styled from 'styled-components';
import { ImportantNoteOrderProps } from './type';

export const StyledForm = styled(Form)`
  p {
    font-size: 1.125rem;
    font-weight: inherit;
    margin-bottom: .75rem;
  }
`;

export const ImportantNoteOrder = styled.div<ImportantNoteOrderProps>`
  display: flex;
  justify-content: ${(props) => props.isEmpty ? 'flex-start' : 'space-between'};

  p {
    font-size: .875rem;
    margin-right: .5rem;
  }

  .ant-btn {
    background-color: #f7fafc;
    color: #a0aec0;
    font-size: .75rem;
    border: 0;

    &:hover {
      color: #f56565 !important;
      background-color: #fff5f5;
    }
  }
`;

export const ImportantNoteYear = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: .875rem;
    margin-right: .75rem;
  }
`;

export const AddMoreBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0;

  .ant-btn {
    color: #48bb78;
    font-size: .75rem;
    border: 1px solid #68d391;

    &:hover {
      color: #38a169 !important;
      background-color: #c6f6d5;
    }
  }
`;