import styled from 'styled-components';
import { MainContainerProps } from './type';

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  width: ${(props) => props.width ?? '100%'};
  padding-bottom: 1rem;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;

  h1 {
    font-size: 1.5rem;
    font-weight: inherit;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const cardStyle = {
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
};

export const cardBodyStyle = {
  width: '100%'
}

export const backButtonStyle = {
  background: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderColor: '#718096'
};