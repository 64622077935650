import { Button, Input, InputNumber } from 'antd';
import { AddMoreBtnContainer, ImportantNoteOrder, ImportantNoteYear, StyledForm } from './style';
import { _FORM_ITEM_LAYOUT, _TAIL_FORM_ITEM_LAYOUT } from '../../../constants';
import { useState } from 'react';

const { TextArea } = Input;

type ImportantNoteInputProps = {
  isEmpty?: boolean
  index?: number
  year?: number
  handleYearChange?: (index: number, value: number) => void
  onRemove?: (index: number) => void
  onUndoRemove?: () => void
  onAdd?: () => void
}

const ImportantNoteInput: React.FC<ImportantNoteInputProps> = ({
  index,
  year,
  handleYearChange,
  onRemove,
  onUndoRemove,
  onAdd,
  isEmpty
}) => (
  <>
    <ImportantNoteOrder isEmpty={isEmpty}>
      <p>{isEmpty ? '1)' : `${index})`}</p>
      <Button
        size="small"
        onClick={() =>
          isEmpty ?
            onUndoRemove ? onUndoRemove() : null
            :
            onRemove ? onRemove(index ?? 0) : null
        }
      >
        {isEmpty ? 'Undo Remove' : 'Remove'}
      </Button>
    </ImportantNoteOrder>
    {!isEmpty && (
      <>
        <StyledForm.Item name={`important_notes_${index}`}>
          <TextArea rows={4} />
        </StyledForm.Item>
        <StyledForm.Item name={`important_notes_year_${index}`}>
          <ImportantNoteYear>
            <p>Fill in the year of the event described above:</p>
            <InputNumber value={year} onChange={(value: any) => handleYearChange ? handleYearChange(index ?? 0, value) : null} />
          </ImportantNoteYear>
        </StyledForm.Item>
      </>
    )}
    <AddMoreBtnContainer>
      <Button
        size="small"
        onClick={() => onAdd ? onAdd() : null}
      >
        Add more
      </Button>
    </AddMoreBtnContainer>
  </>
);

const ImportantNotesForm = () => {
  const [form] = StyledForm.useForm();
  const [importantNotesInput, setImportantNotesInput] = useState([
    {
      id: 1,
      value: '',
      year: '2016'
    }
  ]);
  const [importantNotesInputHistory, setImportantNotesInputHistory] = useState({
    id: 1,
    value: '',
    year: ''
  });

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  const onAdd = () => {
    setImportantNotesInput((prev) => [
      ...prev,
      {
        id: 1,
        value: '',
        year: ''
      }
    ])
  };

  const onRemove = (index: number) => {
    setImportantNotesInput((prevNotes) => {
      const updatedNotes = [...prevNotes];
      const removedNote = updatedNotes.splice(index - 1, 1)[0];
      setImportantNotesInputHistory({ ...removedNote });
      return updatedNotes;
    });
  };

  const onUndoRemove = () => {
    setImportantNotesInput((prev) => [...prev, importantNotesInputHistory]);
  };

  const handleYearChange = (index: number, value: number) => {
    setImportantNotesInput((prevNotes) => {
      const updatedNotes = [...prevNotes];
      updatedNotes[index - 1] = {
        ...updatedNotes[index - 1],
        year: value !== undefined ? value?.toString() : '',
      };
      return updatedNotes;
    });
  };

  return (
    <StyledForm
      {..._FORM_ITEM_LAYOUT}
      form={form}
      name="allergies_and_others"
      onFinish={onFinish}
      initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
      style={{ marginTop: '1rem' }}
      scrollToFirstError
    >
      <p>Important things about you to know during the duration of the consulting process.</p>
      {importantNotesInput.length > 0 ? (
        <>
          {importantNotesInput.map((data, index) => (
            <ImportantNoteInput
              key={index}
              index={index + 1}
              year={parseInt(data.year)}
              handleYearChange={handleYearChange}
              onRemove={onRemove}
              onAdd={onAdd}
            />
          ))}
        </>
      ) : (
        <ImportantNoteInput
          isEmpty
          onUndoRemove={onUndoRemove}
        />
      )}
      <StyledForm.Item {..._TAIL_FORM_ITEM_LAYOUT}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </StyledForm.Item>
    </StyledForm>
  )
};

export default ImportantNotesForm;